import React, { useState } from 'react'

import './ContactForm.css'

const ContactForm = (props) => {

  const extractSkuFromURL = (url) => {
    const sku = url.split('/')[1];
    if (sku === 'contact') {
      return 'None (form submitted from contact page)'
    } else {
      return sku
    }
  };

  let initialData = {
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
    customerType: 'Other',
    accountNumber: '',
    companySchoolName: '',
    sku: extractSkuFromURL(window.location.pathname),
    submittedFrom: window.location.href,
    message: ''
  };

  const [data, setData] = useState(initialData)
  const [success, setSuccess] = useState(false)
  const [failure, setFailure] = useState(false)
  const [blockSubmission, setBlockSubmission] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const encode = (data) => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&")
  }

  const handleSubmit = (e) => {
    setBlockSubmission(true)
    setIsLoading(true)
    fetch('/', {
      method: 'POST',
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({"form-name": "contact", ...data})
    })
    .then((res) => {
      if (res.status != 200) {
        setFailure(true)
        setData(initialData)
        setBlockSubmission(false)
        setIsLoading(false)
      } else {
        setSuccess(true)
        setData(initialData)
        setBlockSubmission(false)
        setIsLoading(false)
      }
    })
    .catch(() => {
      setFailure(true)
      setData(initialData)
      setBlockSubmission(false)
      setIsLoading(false)
    })

    e.preventDefault()
  }

  const handleChange = (e) => {
    setSuccess(false)
    setFailure(false)
    setData({...data, [e.target.name]: e.target.value})
  }

    return (
            <form
              className={data.customerType != 'School' ? 'contact_form' : 'contact_form_school'}
              autoComplete='off'
              onSubmit={handleSubmit}
              >
              <input type='hidden' name='form-name' value='contact' />
                <label htmlFor='first-name' className='first_name_label'>First Name <small>*</small></label>
                  <input
                    id="first-name"
                    type="text" 
                    name="firstName"
                    value={data.firstName}
                    onChange={handleChange}
                    required
                    className='form_first_name'
                    />
                <label htmlFor='last-name' className='last_name_label'>Last Name <small>*</small></label>
                  <input
                    id="last-name"
                    type="text" 
                    name="lastName"
                    value={data.lastName}
                    onChange={handleChange}
                    required
                    className='form_last_name'
                    />
                <label htmlFor='phone-number' className='phone_number_label'>Phone Number <small>*</small></label>
                  <input
                    id="phone-number"
                    type="tel" 
                    pattern="[0-9 \- \( \)]{3,30}"
                    name="phoneNumber"
                    value={data.phoneNumber}
                    onChange={handleChange}
                    required
                    className='form_phone_number'
                    />
                <label htmlFor='email' className='email_label'>Email <small>*</small></label>
                  <input
                    id="email"
                    type="email" 
                    name="email"
                    value={data.email}
                    onChange={handleChange}
                    required
                    className='form_email'
                    />
                <label htmlFor='customer-type' className='customer_type_label'>Customer Type <small>*</small></label>
                <select
                    id="customer-type"
                    name="customerType"
                    value={data.customerType}
                    onChange={handleChange}
                    required
                    className='form_customer_type'>
                      <option value='Dealer'>Dealer</option>
                      <option value='School'>School</option>
                      <option value='Other'>Other</option>
                  </select>
                {data.customerType === 'Dealer' ?
                  <>
                    <label htmlFor='account-number' className='account_number_label'>Account Number</label>
                      <input
                        id="account-number"
                        type="text" 
                        name="accountNumber"
                        value={data.accountNumber}
                        onChange={handleChange}
                        className='form_account_number'
                        />
                    <label htmlFor='company-school-name' className='company_school_name_label'>{data.customerType === 'Dealer' ? 'Dealer' : 'School'} Name</label>
                    <input
                        id="company-school-name"
                        type="text" 
                        name="companySchoolName"
                        value={data.companySchoolName}
                        onChange={handleChange}
                        required
                        className='form_company_school_name'
                        />
                  </> :
                      null}
                {data.customerType === 'School' ?
                  <>
                    <label htmlFor='company-school-name' className='company_school_name_label'>{data.customerType === 'Dealer' ? 'Dealer' : 'School'} Name</label>
                    <input
                        id="company-school-name"
                        type="text" 
                        name="companySchoolName"
                        value={data.companySchoolName}
                        onChange={handleChange}
                        required
                        className='form_company_school_name'
                        />
                  </> :
                      null}
                <label htmlFor='message' className='message_label'>Message <small>*</small></label>
                  <textarea
                    id="message"
                    name="message"
                    value={data.message}
                    onChange={handleChange}
                    maxLength='1500'
                    required
                    className='form_message'
                    />
                {success && (
                  <div className='form-submit-success-message'>
                      <p>Thank you for your message! You should hear from us soon.</p>
                  </div>
                  )}
                {failure && (
                  <div className='form-submit-failure-message'>
                      <p>Your message failed to send, please try again later or email us directly at customerservice@fisherathletic.com</p>
                  </div>
                  )}
                <button type="submit" className='form_submit_btn' disabled={blockSubmission ? true : false}>
                  {isLoading ? 'Sending...' : 'Send'}
                </button>
                <input
                  id="sku"
                  type="text" 
                  name="sku"
                  value={data.sku}
                  hidden
                />
                <input
                  id="submitted-from"
                  type="text" 
                  name="submittedFrom"
                  value={data.submittedFrom}
                  hidden
                />
            </form>
    )
}

export default ContactForm