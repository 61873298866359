let productData = [
    {
        id: 'p1',
        name: 'Tackle Wheel 28"',
        sku: 'tw2814',
        description: 'Tackle wheel teaches players to adjust their angle in pursuit. Teach open field tackling without bodies. Reduce injuries by eliminating repetitions. Teaches players to wrap up in open field. 22 oz. coated vinyl in center and outside. 19 oz. coated vinyl on faces of wheel.',
        details: [
            '28" Outside diam. x 14" Inside diam.',
            '8" Thick.',
            'Rebond Foam.',
            'Weight: 21 lbs'
        ],
        includes: [
            ``
        ],
        options: ['TW2814: Tackle Wheel 28" (21 lbs)'],
        usedBy: [],
        category: 'football',
        subCategory: 'dummies',
        group: 'Tackle Wheels',
        urlCategory: 'football',
        urlSubCategory: 'dummies',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ftackle-wheel-tw2814-HQ1.jpg?alt=media&token=989abcd1-cf7a-4899-8176-a2d9db62ae6d",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ftackle-wheel-tw2814-HQ1.jpg?alt=media&token=989abcd1-cf7a-4899-8176-a2d9db62ae6d",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ftackle-wheel-tw2814-HQ2.jpg?alt=media&token=3a37ddfa-efc0-4e3e-b63a-179a7d92b6b8",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ftackle-wheel-tw2814-HQ3.jpg?alt=media&token=c7a2216a-e88d-463a-9011-63311223dc26",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ftackle-wheels-HQ2.jpg?alt=media&token=505f449a-58db-4629-9ae0-701be6de40d5"
        ]
    },{
        id: 'p2',
        name: 'Tackle Wheel 36"',
        sku: 'tw3616',
        description: 'Tackle wheel teaches players to adjust their angle in pursuit. Teach open field tackling without bodies. Reduce injuries by eliminating repetitions. Teaches players to wrap up in open field. 22 oz. coated vinyl in center and outside. 19 oz. coated vinyl on faces of wheel.',
        details: [
            '36" Outside diam. x 16" Inside diam.',
            '8" Thick.',
            'Rebond Foam.',
            'Weight: 32 lbs'
        ],
        includes: [
            ``
        ],
        options: ['TW3616: Tackle Wheel 36" (32 lbs)'],
        usedBy: [],
        category: 'football',
        subCategory: 'dummies',
        group: 'Tackle Wheels',
        urlCategory: 'football',
        urlSubCategory: 'dummies',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ftackle-wheel-tw3616-HQ1.jpg?alt=media&token=d2aa663e-00d8-417b-871d-5680157f7d67",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ftackle-wheel-tw3616-HQ1.jpg?alt=media&token=d2aa663e-00d8-417b-871d-5680157f7d67",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ftackle-wheels-HQ2.jpg?alt=media&token=505f449a-58db-4629-9ae0-701be6de40d5"
        ]
    },{
        id: 'p3',
        name: 'Tackle Wheel 42"',
        sku: 'tw4220',
        description: 'Tackle wheel teaches players to adjust their angle in pursuit. Teach open field tackling without bodies. Reduce injuries by eliminating repetitions. Teaches players to wrap up in open field. 22 oz. coated vinyl in center and outside. 19 oz. coated vinyl on faces of wheel.',
        details: [
            '42" Outside diam. x 20" Inside diam.',
            '9" Thick.',
            'PolyFoam.',
            'Weight: 15 lbs'
        ],
        includes: [
            ``
        ],
        options: ['TW4220: Tackle Wheel 42" (15 lbs)'],
        usedBy: [],
        category: 'football',
        subCategory: 'dummies',
        group: 'Tackle Wheels',
        urlCategory: 'football',
        urlSubCategory: 'dummies',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ftackle-wheel-tw4220-HQ1.jpg?alt=media&token=e3c61763-d46b-405f-965c-e80081f46f69",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ftackle-wheel-tw4220-HQ1.jpg?alt=media&token=e3c61763-d46b-405f-965c-e80081f46f69",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ftackle-wheels-HQ2.jpg?alt=media&token=505f449a-58db-4629-9ae0-701be6de40d5"
        ]
    },{
        id: 'p4',
        name: 'Tackle Wheel 48"',
        sku: 'tw4825',
        description: 'Tackle wheel teaches players to adjust their angle in pursuit. Teach open field tackling without bodies. Reduce injuries by eliminating repetitions. Teaches players to wrap up in open field. 22 oz. coated vinyl in center and outside. 19 oz. coated vinyl on faces of wheel.',
        details: [
            '48" Outside diam. x 25" Inside diam.',
            '11" Thick.',
            'PolyFoam.',
            'Weight 21 lbs'
        ],
        includes: [
            ``
        ],
        options: ['TW4825: Tackle Wheel 48" (21 lbs)'],
        usedBy: [],
        category: 'football',
        subCategory: 'dummies',
        group: 'Tackle Wheels',
        urlCategory: 'football',
        urlSubCategory: 'dummies',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ftackle-wheel-tw4825-HQ1.jpg?alt=media&token=1a677c04-218e-4e5a-b425-739355ccc147",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ftackle-wheel-tw4825-HQ1.jpg?alt=media&token=1a677c04-218e-4e5a-b425-739355ccc147",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ftackle-wheel-tw4825-HQ2.jpg?alt=media&token=fadb2ed4-272a-4742-b96b-dfb012cda332",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ftackle-wheels-HQ2.jpg?alt=media&token=505f449a-58db-4629-9ae0-701be6de40d5"
        ]
    },{
        id: 'p5',
        name: 'Tackle Wheel 54"',
        sku: 'tw5428',
        description: 'Tackle wheel teaches players to adjust their angle in pursuit. Teach open field tackling without bodies. Reduce injuries by eliminating repetitions. Teaches players to wrap up in open field. 22 oz. coated vinyl in center and outside. 19 oz. coated vinyl on faces of wheel.',
        details: [
            '54" Outside diam. x 28" Inside diam.',
            '12" Thick.',
            'PolyFoam.',
            'Weight: 24 lbs'
        ],
        includes: [
            ``
        ],
        options: ['TW5428: Tackle Wheel 54" (24 lbs)'],
        usedBy: [],
        category: 'football',
        subCategory: 'dummies',
        group: 'Tackle Wheels',
        urlCategory: 'football',
        urlSubCategory: 'dummies',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ftackle-wheel-tw5428-HQ1.jpg?alt=media&token=0035cdc9-dba7-498b-9fe9-ddd999199436",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ftackle-wheel-tw5428-HQ1.jpg?alt=media&token=0035cdc9-dba7-498b-9fe9-ddd999199436",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ftackle-wheel-tw5428-HQ2.jpg?alt=media&token=3bec1b9e-a78c-4dfa-a6ad-449c387f3c72",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ftackle-wheel-tw5428-HQ3.jpg?alt=media&token=5836c95c-7fdb-46d8-9236-3e2c075d4e9e",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ftackle-wheels-HQ2.jpg?alt=media&token=505f449a-58db-4629-9ae0-701be6de40d5"
        ]
    },{
        id: 'p6',
        name: 'Tackle Wheel 60"',
        sku: 'tw6030',
        description: 'Tackle wheel teaches players to adjust their angle in pursuit. Teach open field tackling without bodies. Reduce injuries by eliminating repetitions. Teaches players to wrap up in open field. 22 oz. coated vinyl in center and outside. 19 oz. coated vinyl on faces of wheel.',
        details: [
            '60" Outside diam. x 30" Inside diam.',
            '12" Thick.',
            'PolyFoam.',
            'Weight: 27 lbs'
        ],
        includes: [
            ``
        ],
        options: ['TW6030: Tackle Wheel 60" (27 lbs)'],
        usedBy: [],
        category: 'football',
        subCategory: 'dummies',
        group: 'Tackle Wheels',
        urlCategory: 'football',
        urlSubCategory: 'dummies',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ftackle-wheel-tw6030-HQ1.jpg?alt=media&token=105c7595-8ea0-46d3-9d95-e1ba6c74fcec",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ftackle-wheel-tw6030-HQ1.jpg?alt=media&token=105c7595-8ea0-46d3-9d95-e1ba6c74fcec",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ftackle-wheel-tw6030-HQ2.jpg?alt=media&token=20be0184-3abf-4253-a237-6d5ee68933e5",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ftackle-wheel-tw6030-HQ3.jpg?alt=media&token=8bd0ab92-a5d0-425c-a310-32468aa2ab11",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ftackle-wheels-HQ2.jpg?alt=media&token=505f449a-58db-4629-9ae0-701be6de40d5"
        ]
    },{
        id: 'p7',
        name: 'Brute Sled',
        sku: '9712ap',
        description: "Individual components allow stations to be added as the need arises. Basic sleds can start with man capability and expanded as you desire. A 5 man sled can be easily broken down to a 2 and a 3 man sled. Sled skids are formed, not cut and welded. A 30 degree angle assures you of a smooth transition over your grass without chewing up the turf. Safe for artificial turf. Each brute sled pad's core is built from high density, 100 lb., impact resistant foam. Durable, 18 oz. vinyl covers are then double and triple stitched to withstand harsh weather and years of hard use. Choose from 6 pad styles and 11 colors for the brute sled. COACHES PLATFORM NOT INCLUDED.",
        details: [
            "Doesn't chew up grass or artificial turf"
        ],
        includes: [
            ``
        ],
        options: [
            '2 Man Brute Sled',
            '9712AP: Attack pad (373 lbs)',
            '9712T: T pad (373 lbs)',
            '9712M: Man pad (373 lbs)',
            '9712V: V pad (373 lbs)',
            '9712SM: Small man pad (373 lbs)',
            `3 Man Brute Sled`,
            '9713AP: Attack pad (582 lbs)',
            '9713T: T pad (582 lbs)',
            '9713M: Man pad (582 lbs)',
            '9713V: V pad (582 lbs)',
            '9713SM: Small man pad (582 lbs)',
            `4 Man Brute Sled`,
            '9714AP: Attack pad (792 lbs)',
            '9714T: T pad (792 lbs)',
            '9714M: Man pad (792 lbs)',
            '9714V: V pad (792 lbs)',
            '9714SM: Small man pad (792 lbs)',
            `5 Man Brute Sled`,
            '9715AP: Attack pad (1001 lbs)',
            '9715T: T pad (1001 lbs)',
            '9715M: Man pad (1001 lbs)',
            '9715V: V pad (1001 lbs)',
            '9715SM: Small man pad (1001 lbs)',
            `6 Man Brute Sled`,
            '9716AP: Attack pad (1210 lbs)',
            '9716T: T pad (1210 lbs)',
            '9716M: Man pad (1210 lbs)',
            '9716V: V pad (1210 lbs)',
            '9716SM: Small man pad (1210 lbs)',
            `7 Man Brute Sled`,
            '9717AP: Attack pad (1420 lbs)',
            '9717T: T pad (1420 lbs)',
            '9717M: Man pad (1420 lbs)',
            '9717V: V pad (1420 lbs)',
            '9717SM: Small man pad (1420 lbs)'
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'sleds',
        group: '',
        urlCategory: 'football',
        urlSubCategory: 'sleds',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fbrute-sled-9712ap-HQ1.jpg?alt=media&token=48a733b2-dbce-4142-960c-a6532f594d4a",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fbrute-sled-9712ap-HQ1.jpg?alt=media&token=48a733b2-dbce-4142-960c-a6532f594d4a",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fbrute-sled-9712ap-HQ2.jpg?alt=media&token=c8b0acb9-c45f-4191-92b8-b3c16d703974",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fbrute-sled-9712ap-HQ3.jpg?alt=media&token=cc129818-262b-4029-8498-442dac353ea0",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fbrute-sled-5man-9715-HQ2.jpg?alt=media&token=36c16c80-5904-4b78-9a72-b68b3e997df4",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fbrute-sled-pads-HQ1.jpg?alt=media&token=7af24b49-1231-4fd5-9c61-51bc9f7ca921"
        ]
    },{
        id: 'p421',
        name: 'Hedgehog 4 Way Tackler',
        sku: 'HH4R',
        description: `The FISHER “Hedgehog” 4 Way Tackler is the perfect tool to perfect tackling. Comes with four rocket shaped pads that allow the tackler to tumble and the tackle drills to continue non-stop. Lightweight steel frame is durable and guarantees to hold up to any punishment thrown at it. 60” tall from ground to tip. Total weight: 62 lbs. `,
        details: [
            'Lightweight construction',
            `Ultra dense foam built to withstand punishment`,
            'Powder coated to prevent rust'
        ],
        includes: [
            ``
        ],
        options: [
            'Hedgehog 4 Way Tackler',
            'HH4RBL: Tackler with Blue Pads',
            'HH4RGO: Tackler with Gold Pads',
            'HH4RRE: Tackler with Red Pads',
            'HH4ROR: Tackler with Orange Pads',
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'sleds',
        group: '',
        urlCategory: 'football',
        urlSubCategory: 'sleds',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fhh4r-main-image.jpg?alt=media&token=a0aad427-9f32-41e7-88bb-7ba9cd36a090",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fhedgehog-orange.jpg?alt=media&token=6681cf74-8463-4e77-8ef0-4d449a4fc40b",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fhedgehog-royal.jpg?alt=media&token=7f743ce7-6d18-456c-b88d-4332881dce78",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fhedgehog-red.jpg?alt=media&token=9a6949ce-0a5c-42aa-b5c8-96254fcdb144",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fhedgehog-yellow.jpg?alt=media&token=dc95d841-2762-4df5-89fb-238076a1f6d0",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fhh4r-action-1.jpg?alt=media&token=5e6879bc-a27a-4160-89cb-f6be18dcdf25",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fhh4r-action-2.jpg?alt=media&token=5ce28960-0945-45cc-b841-bbfd63316172",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fhh4r-action-3.jpg?alt=media&token=3567a89d-39c6-4926-ba76-2ffe92c943c1",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fhh4r-royal-1.jpg?alt=media&token=4ad88231-e40d-454b-91f0-ca2b6de8e9bf"
            
        ],
         videos: [
             {
                 title: "Hedgehog Drill",
                 image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fhedgehog-video-thumbnail.jpg?alt=media&token=06824225-4dfe-48eb-9998-5eae7f6a9f9a",
                 src: "https://www.youtube.com/embed/sEewPOApfcc?si=YNyyrgvZvLIlDSKG"
             },
             {
                title: "Hedgehog Drill",
                image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fhedgehog-video-thumbnail-2.jpg?alt=media&token=eeea3fb9-69c3-4f83-a85b-ab2fa3672260",
                src: "https://www.youtube.com/embed/mLLYv_nVsbY"
            },
            
         ]
    },{
        id: 'p45',
        name: 'Bull Sled',
        sku: '9902m',
        description: `Main Frame:Tough, durable and guaranteed for five years. Full 5 year warranty on metal parts. All metal surfaces are powder coated to prevent rust. Skids: Sled skids are formed, not cut and welded. 30 degree angles assure a smooth transition over our grass or artificial turf.`,
        details: [
            '5 Year guarantee',
            `Won't chew up grass or artificial turf`,
            'Powder coated to prevent rust'
        ],
        includes: [
            ``
        ],
        options: [
            '2 Man Bull Sled',
            '9902M: Man Pad (455 lbs)',
            '9902MGP: Grab Pad (455 lbs)',
            '9902PP: Pummel Pad (455 lbs)',
            '3 Man Bull Sled',
            '9903M: Man Pad (704 lbs)',
            '9903MGP: Grab Pad (704 lbs)',
            '9903PP: Pummel Pad (704 lbs)',
            '4 Man Bull Sled',
            '9904M: Man Pad (953 lbs)',
            '9904MGP: Grab Pad (953 lbs)',
            '9904PP: Pummel Pad (953 lbs)',
            '5 Man Bull Sled',
            '9905M: Man Pad (1203 lbs)',
            '9905MGP: Grab Pad (1203 lbs)',
            '9905PP: Pummel Pad (1203 lbs)',
            '6 Man Bull Sled',
            '9906M: Man Pad (1452 lbs)',
            '9906MGP: Grab Pad (1452 lbs)',
            '9906PP: Pummel Pad (1452 lbs)',
            '7 Man Bull Sled',
            '9907M: Man Pad (1701 lbs)',
            '9907MGP: Grab Pad (1701 lbs)',
            '9907PP: Pummel Pad (1701 lbs)',
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'sleds',
        group: '',
        urlCategory: 'football',
        urlSubCategory: 'sleds',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fbull-sled-9902m-HQ1.jpg?alt=media&token=0e9ffd50-8ab4-46f8-be95-4eddc7375fd8",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fbull-sled-9902m-HQ1.jpg?alt=media&token=0e9ffd50-8ab4-46f8-be95-4eddc7375fd8",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fbull-sled-9902m-HQ2.jpg?alt=media&token=b3b67e83-a739-4583-921d-48b53ba31f2b",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fbull-sled-9902m-HQ3.jpg?alt=media&token=f7202cf2-4a81-4cf6-8186-445a7823a422",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fbull-sled-detail-HQ2.jpg?alt=media&token=0b566b56-3ab5-475a-b6d1-5cf310481478",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fbull-sled-detail-HQ1.jpg?alt=media&token=7ecee020-fb6f-48df-bb68-3fec74127435",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fbull-sled-pad-options.jpg?alt=media&token=ef99c318-4a23-4f10-8625-7b26c270ab17",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2F3-man-bull-sled-9903-HQ1.jpg?alt=media&token=ff6b4fc5-3b08-4372-9980-4c60b2302e33",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2F4-man-bull-sled-9904-HQ1.jpg?alt=media&token=019b0919-25bf-4971-82c1-03941460b184",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2F5-man-bull-sled-9905-HQ1.jpg?alt=media&token=c3c376ca-d239-446c-98bf-cb2eccd51d94",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2F6-man-bull-sled-9906-HQ1.jpg?alt=media&token=dada3d8b-9280-43a4-9f0b-cb9ab630e58f",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2F7-man-bull-sled-9907-HQ1.jpg?alt=media&token=4ec8b81d-0120-4e45-ab48-eefd63352935",
        ]
    },{
        id: 'p68',
        name: 'Bull Rush Sled',
        sku: '9910m',
        description: `Unique forward slant blocking head with release mechanism allows an individual player to engage, control, drive, and extend upwards all in the same drill. Teach your defensive linemen and linebackers to shed blocks and target running back. The pad drives in 8", then releases upward, simulating real time game action for your players. Wider 52" base keeps sleds stable and extended pad allows players to react off pad and get up field. Powder coated steel for maximum weather resistance and durability.`,
        details: [
            '5 Year guarantee',
            `Won't chew up grass or artificial turf`,
            'Powder coated to prevent rust',
            'Weight: 325 lbs'
        ],
        includes: [
            ``
        ],
        options: [
            'Bull Rush Sled',
            '9910M: Man Pad',
            '9910MGP: Man Grab Pad',
            '9910PP: Pummel Pad',
            'Attachments',
            '9000WH: Sled Weight Horn that can be added to any 9900 series sled. (horn holds up to four 45lb weights)'
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'sleds',
        group: '',
        urlCategory: 'football',
        urlSubCategory: 'sleds',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fbull-rush-sled-9910m-HQ1.jpg?alt=media&token=647b81da-5d3d-4dcc-b348-bb8f320ee7c5",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fbull-rush-action.jpg?alt=media&token=778adcd5-d8be-4253-860a-82a7c6871c35",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fbull-rush-sled-9910m-HQ1.jpg?alt=media&token=647b81da-5d3d-4dcc-b348-bb8f320ee7c5",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fbull-rush-sled-man-grab-pad-9910mgp-HQ1.jpg?alt=media&token=2be63e19-9221-47a8-b700-68219d6f879b",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fbull-rush-sled-pummel-pad-9910pp-HQ1.jpg?alt=media&token=d86fe9ab-8eaa-4e8f-b4a0-68d163a4a555",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fsled-weight-horn-9000wh-HQ1.jpg?alt=media&token=6395f887-5b9b-4d20-a37f-e09c15299772",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fbull-sled-pad-options.jpg?alt=media&token=ef99c318-4a23-4f10-8625-7b26c270ab17",
        ]
    },{
        id: 'p39',
        name: 'Big Boomer Sled',
        sku: '9002ap',
        description: `Realistic fulcrum pivoting action conditions your players to achieve leveraged power over an opponent without losing their balance. The only sled that is completely adjustable. Adjust spring tension, pad height and angle. Blocking Units: Each individual blocking unit is made up of "pigs" and pads. The "pig" is constructed with a high strength 1/4" steel place, double welded for maximum strength and durability. The "pig" attaches to the steel chassis and is fully adjustable.Our exclusive spring tension adjustment is an integral part of each "pig". The pad fits onto the "pig" and is fully adjustable. Pad construction and options: Each Boomer sled pad's core is built from high density, 100 lb., impact resistant foam. Durable, 18oz. vinyl covers are then double and triple stitched to withstand harsh weather and years of hard use. 5 Year Warranty: Main Frame: Tough, durable and guaranteed for 5 years. All metal surfaces are powder coated to prevent rust.`,
        details: [
            'Realistic fulcrum pivoting action',
            'Adjust spring tension, pad height, and angle',
            'Pads are made with high density, 100 lb, impact resistant foam and 18 oz vinyl covers',
            'Expandable: Individual components allow stations to be added as the need arises'
        ],
        includes: [
            ``
        ],
        options: [
            '2 Man Big Boomer Sled',
            '9002M: Man Pad (354 lbs)',
            '9002T: T Pad (354 lbs)',
            '9002LB: Lowboy Pad (354 lbs)',
            '9002AP: Attack Pad (354 lbs)',
            '9002V: V Pad (354 lbs)',
            '3 Man Big Boomer Sled',
            '9003M: Man Pad (554 lbs)',
            '9003T: T Pad (554 lbs)',
            '9003LB: Lowboy Pad (554 lbs)',
            '9003AP: Attack Pad (554 lbs)',
            '9003V: V Pad (554 lbs)',
            '4 Man Big Boomer Sled',
            '9004M: Man Pad (754 lbs)',
            '9004T: T Pad (754 lbs)',
            '9004LB: Lowboy Pad (754 lbs)',
            '9004AP: Attack Pad (754 lbs)',
            '9004V: V Pad (754 lbs)',
            '5 Man Big Boomer Sled',
            '9005M: Man Pad (954 lbs)',
            '9005T: T Pad (954 lbs)',
            '9005LB: Lowboy Pad (954 lbs)',
            '9005AP: Attack Pad (954 lbs)',
            '9005V: V Pad (954 lbs)',
            '6 Man Big Boomer Sled',
            '9006M: Man Pad (1154 lbs)',
            '9006T: T Pad (1154 lbs)',
            '9006LB: Lowboy Pad (1154 lbs)',
            '9006AP: Attack Pad (1154 lbs)',
            '9006V: V Pad (1154 lbs)',
            '7 Man Big Boomer Sled',
            '9007M: Man Pad (1354)',
            '9007T: T Pad (1354)',
            '9007LB: Lowboy Pad (1354)',
            '9007AP: Attack Pad (1354)',
            '9007V: V Pad (1354)',
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'sleds',
        group: '',
        urlCategory: 'football',
        urlSubCategory: 'sleds',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fbig-boomer-sled-9002ap-HQ1.jpg?alt=media&token=f6ee51b2-256d-4fe3-b97e-cb341cf56da8",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fbig-boomer-sled-9002ap-HQ1.jpg?alt=media&token=f6ee51b2-256d-4fe3-b97e-cb341cf56da8",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fbig-boomer-sled-9002ap-HQ2.jpg?alt=media&token=d08b6d17-9277-46d3-bd4f-18e5b536660e",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fbig-boomer-sled-detail-HQ3.jpg?alt=media&token=9eefab82-f7b0-40b7-94c3-34b4b2f9030a",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fbig-boomer-sled-9002ap-HQ3.jpg?alt=media&token=85bbe842-5a83-4d6f-880d-22e58cd6cb3a",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fbig-boomer-sled-9002ap-HQ4.jpg?alt=media&token=236481c2-9e82-49aa-96c8-c280241eac31",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fbig-boomer-sled-detail-HQ2.jpg?alt=media&token=26f075b7-a06b-4709-bb9b-77717ab674b8",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fbig-boomer-sled-pads-HQ1.jpg?alt=media&token=28994df4-6891-4394-b69d-186b0fb44361",
        ]
    },{
        id: 'p25',
        name: 'CL Sled',
        sku: 'cl2m',
        description: `The CL series sleds from Fisher are ideal for middle school through high school programs. Our new TITE-LOCK modular chassis provides the rigidity needed in a multi-unit sled. Player safety is improved due to the wrap around design of our standard and "T" pads. Sled skids are formed, not cut and welded. A 30 degree angle assures you of a smooth transition over your grass without chewing up the turf. Safe for artificial turf. Main frame: tough, durable and guaranteed for 5 years. All metal surfaces are powder coated to prevent rust. The leaf spring design virtually eliminates all spring cracking and breaking common with other sleds.`,
        details: [
            '5 year warranty',
            'Expandable: Extra stations can be added as the need arises',
            'Durable 18 oz vinyl covers',
            'Made with high density, 100 lb, impact resisitant foam',
            'Coaches platform not included',
        ],
        includes: [
            ``
        ],
        options: [
            '2 Man CL Sled',
            'CL2S: Standard pad (247 lbs)',
            'CL2M: Man pad (247 lbs)',
            'CL2T: T pad (247 lbs)',
            '3 Man CL Sled',
            'CL3S: Standard pad (392 lbs)',
            'CL3M: Man pad (392 lbs)',
            'CL3T: T pad (392 lbs)',
            '4 Man CL Sled',
            'CL4S: Standard pad (537 lbs)',
            'CL4M: Man pad (537 lbs)',
            'CL4T: T pad (537 lbs)',
            '5 Man CL Sled',
            'CL5S: Standard pad (681 lbs)',
            'CL5M: Man pad (681 lbs)',
            'CL5T: T pad (681 lbs)',
            '6 Man CL Sled',
            'CL6S: Standard pad (826 lbs)',
            'CL6M: Man pad (826 lbs)',
            'CL6T: T pad (826 lbs)',
            '7 Man CL Sled',
            'CL7S: Standard pad (970 lbs)',
            'CL7M: Man pad (970 lbs)',
            'CL7T: T pad (970 lbs)',
            `Coach's Platform`,
            `CLCP: Coach's Platform`,
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'sleds',
        group: '',
        urlCategory: 'football',
        urlSubCategory: 'sleds',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fcl-sled-cl2m-HQ1.jpg?alt=media&token=d907fae5-685c-4a2b-bf5f-f58801898d92",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fcl-sled-cl2m-HQ1.jpg?alt=media&token=d907fae5-685c-4a2b-bf5f-f58801898d92",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fcl-sled-back-HQ1.jpg?alt=media&token=fcf86505-e258-43e3-b613-a5087482f0d8",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fcl-sled-coaches-platform-HQ1.jpg?alt=media&token=75bfc60a-f4d5-447f-b7db-16e012bae023",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fcl-sled-pads-HQ1.jpg?alt=media&token=45d885f5-6a6a-4d9b-a491-6d8c9f8f496d"
        ]
    },{
        id: 'p52',
        name: 'Combat Sled',
        sku: '9301cs',
        description: `Helps develop upper and lower body strength. Large diameter round steel piping assures smooth transition over grass. Live action spring makes player work hard to take sled to ground. No sharp edges gives added safety to players. Rubber foam grip around face of sled allows for solid and safe grip while using sled.`,
        details: [
            'Transitions smoothly over grass',
            'Builds upper and lower body strength',
            'Live action spring makes player work hard to take sled to the ground'
        ],
        includes: [
            ``
        ],
        options: [
            '9201CS: Combat Sled w/ 9201 base',
            '9910CS: Combat Sled w/ Rush Sled base'
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'sleds',
        group: '',
        urlCategory: 'football',
        urlSubCategory: 'sleds',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fcombat-sled-9301cs-HQ3.jpg?alt=media&token=f5c1fee9-16ae-4a93-b96c-98686957bca3",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fcombat-sled-9301cs-HQ3.jpg?alt=media&token=f5c1fee9-16ae-4a93-b96c-98686957bca3",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2F9201cs-combat-sled-1.jpg?alt=media&token=0771d20e-c162-424b-b326-c855f8a5e8b4",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2F9201cs-combat-sled-2.jpg?alt=media&token=24125fb5-2c61-4018-9618-4c66de73ac16",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2F9201cs-combat-sled-3.jpg?alt=media&token=4ea92c8b-44bb-4b66-8e0b-25436124ead0",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2F9201cs-combat-sled-4.jpg?alt=media&token=024e406f-77dd-46c5-b434-0850ff11d392",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2F9910CS-product.jpg?alt=media&token=0081feb1-0533-4802-a136-58b5c37b68a4",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2F9910CS-action-1.jpg?alt=media&token=cc06fbc5-3bc4-43c6-ad17-bdc527e7e6a1",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2F9910CS-action-2.jpg?alt=media&token=3bdf1f7c-52ec-466d-90a7-ce9bea23408b",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2F9910CS-action-3.jpg?alt=media&token=eadac8b9-ed6e-4491-bd23-e8cbfcc8b337",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2F9910CS-action-4.jpg?alt=media&token=aab90dd4-5547-4f1f-8c36-a9374b41be73",

        ]
    },{
        id: 'p13',
        name: 'Shiver Sled',
        sku: '9708ss',
        description: 'Allows players to work on footwork while shuffling across pad. Players progress down the pad to repeat blows, gaining maximum repetitions. Teaches proper punch technique while using proper body position and footwork. Pad is 12" high x 8" long x 8" thick. Adjust from 32" to 44" tall.',
        details: [
            `Pad is 12" high x 8' long x 8" thick`,
            `Adjusts from 32" to 44" tall`,
            `Doesn't chew up grass or artificial turf`,
            'Weight: 515 lbs'
        ],
        includes: [
            ``
        ],
        options: [
            '9708SS: Shiver sled mounted on runners'
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'sleds',
        group: 'Shiver Sleds',
        urlCategory: 'football',
        urlSubCategory: 'sleds',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fshiver-sled-9708ss-HQ4.jpg?alt=media&token=2db67f60-6cd4-4bb7-a3b2-a48131369301",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fshiver-sled-9708ss-HQ4.jpg?alt=media&token=2db67f60-6cd4-4bb7-a3b2-a48131369301",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fshiver-sled-9708ss-HQ2.jpg?alt=media&token=5cf7e856-8304-48ef-896c-401c94d55c22",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fshiver-sled-9708ss-HQ3.jpg?alt=media&token=1a7ac351-ce93-4e9d-953b-eab4369fcb1a"
        ]
    },{
        id: 'p14',
        name: 'Shiver Sled Ground',
        sku: '9708ssgm',
        description: 'Allows players to work on footwork while shuffling across pad. Players progress down the pad to repeat blows, gaining maximum repetitions. Teaches proper punch technique while using proper body position and footwork. Pad is 12" high x 8" long x 8" thick.',
        details: [
            `Pad is 12" high x 8' long x 8" thick`,
            'Weight: 610 lbs'
        ],
        includes: [
            ``
        ],
        options: [
            '9708SSGM: Shiver sled mounted in ground'
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'sleds',
        group: 'Shiver Sleds',
        urlCategory: 'football',
        urlSubCategory: 'sleds',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fshiver-sled-ground-9708ssgm-HQ2.jpg?alt=media&token=53d9b64a-4e7c-4977-8a44-264ba748ac82",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fshiver-sled-ground-9708ssgm-HQ2.jpg?alt=media&token=53d9b64a-4e7c-4977-8a44-264ba748ac82"
        ]
    },{
        id: 'p15',
        name: '9800 Sled',
        sku: '9802tjr',
        description: `Wrap around padded wings on each pad protect your players. Choice of standard or "T" pad. Safely teaches a young player how to gain leverage on an opponent. Main Frame: Sturdy, tough steel construction. Guaranteed for 5 years. All metal surfaces are powder coated to prevent rust. Expandable: Individual components allow stations to be added as the need arises. Basic sleds can start with 2 man capability and expanded as you desire. A 5 man sled can be easily broken down to a 2 and a 3 man sled. Tite-Lock Chassis: New, improved interlocking modular design. Overlapped channel steel construction provides the rigidity needed in a multi-unit sled. Skids: Sled skids are formed, not cut and welded. A 30 degree angle assures you of a smooth transition over your grass without chewing up the turf. Safe for artificial turf. Pad construction and options: Each 9800 series sled pad's core is built from high density, 100 lb. impact resistant foam. Durable, 18 oz. vinyl covers are then double and triple stitched to withstand harsh weather and years of hard use. Standard and "T" pad styles are available for the 9800 series sleds.`,
        details: [
            `5 year warranty`,
            `Metal surfaces are powder coated to prevent rust`,
            `Expandable: Individual components allow stations to be added as the need arises`,
            `Doesn't chew up grass or artificial turf`
        ],
        includes: [
            ``
        ],
        options: [
            '2 Man 9800 Sled',
            '9802SJR: Standard pad (182 lbs)',
            '9802TJR: "T" pad (182 lbs)',
            '3 Man 9800 Sled',
            '9803SJR: Standard pad (294 lbs)',
            '9803TJR: "T" pad (294 lbs)',
            '4 Man 9800 Sled',
            '9804SJR: Standard pad (406 lbs)',
            '9804TJR: "T" pad (406 lbs)',
            '5 Man 9800 Sled',
            '9805SJR: Standard pad (517 lbs)',
            '9805TJR: "T" pad (517 lbs)',
            '6 Man 9800 Sled',
            '9806SJR: Standard pad (629 lbs)',
            '9806TJR: "T" pad (629 lbs)',
            '7 Man 9800 Sled',
            '9807SJR: Standard pad (741 lbs)',
            '9807TJR: "T" pad (741 lbs)'
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'sleds youth football',
        group: '',
        urlCategory: 'football',
        urlSubCategory: 'youth football',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2F9800-sled-9802tjr-HQ1.jpg?alt=media&token=a044b17f-9c15-4159-81ff-789501889c88",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2F9800-sled-9802tjr-HQ1.jpg?alt=media&token=a044b17f-9c15-4159-81ff-789501889c88",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2F2man-9800-sled-9802-HQ2.jpg?alt=media&token=c00c0065-e1c6-458c-b3c3-7aedbfda6ed3",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2F9800-sled-detail-HQ1.jpg?alt=media&token=f993021b-8f46-4ce3-af38-daafc836bc5c",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2F9800-sled-pads-HQ1.jpg?alt=media&token=22845d08-9214-4953-a756-456305767757"
        ]
    },
    // {
    //     id: 'p21',
    //     name: 'Pro Sackbak Sled',
    //     sku: '9301r',
    //     description: `The Sackbak tackle sled is the closest simulation of a one on one game scenario, whether it is an open field tackle or a block. The Fisher SackBak allows players to apply proper tackling technique and follow through. Fisher's one man tackle sled helps to develop upper and lower body strength while enhancing a player's confidence.`,
    //     details: [
    //         `Sled pad core is built from high quality 100 lb impact resisitant foam`,
    //         `Durable 18 oz vinyl is double and triple stitched`,
    //         `Doesn't chew up grass or artificial turf`,
    //         'Weight: 295 lbs'
    //     ],
    //     includes: [
    //         ``
    //     ],
    //     options: [
    //         '9301R: Round pad',
    //         '9301C: Cone pad',
    //         '9301A: Attack pad'
    //     ],
    //     usedBy: [],
    //     category: 'football',
    //     subCategory: 'sleds',
    //     group: 'Sackback Sleds',
    //     urlCategory: 'football',
    //     urlSubCategory: 'sleds',
    //     image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpro-sackback-sled-9301r-HQ2.jpg?alt=media&token=281e9cd2-0388-4101-947a-737b54a9b424",
    //     images: [
    //         "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpro-sackback-sled-9301r-HQ2.jpg?alt=media&token=281e9cd2-0388-4101-947a-737b54a9b424",
    //         "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpro-sackback-sled-9301r-HQ1.jpg?alt=media&token=a9fbc2cd-8acd-4a07-82c2-ffc7bbcb7298"
    //     ]
    // },
    {
        id: 'p22',
        name: 'Varsity Sackbak Sled',
        sku: '9201',
        description: `The Sackbak tackle sled is the closest simulation of a one on one game scenario, whether it is an open field tackle or a block. The Fisher SackBak allows players to apply proper tackling technique and follow through. Fisher's one man tackle sled helps to develop upper and lower body strength while enhancing a player's confidence.`,
        details: [
            `Sled pad core is built from high quality 100 lb impact resisitant foam`,
            `Durable 18 oz vinyl is double and triple stitched`,
            `Doesn't chew up grass or artificial turf`,
            'Weight: 225 lbs'
        ],
        includes: [
            ``
        ],
        options: [
            '9201R: Round pad',
            '9201C: Cone pad',
            '9201A: Attack pad'
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'sleds',
        group: 'Sackback Sleds',
        urlCategory: 'football',
        urlSubCategory: 'sleds',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2F9201c-sled-pad.jpg?alt=media&token=4d8e8122-3df0-46fe-a1e5-1b4e74f268f2",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2F9201c-sled-pad.jpg?alt=media&token=4d8e8122-3df0-46fe-a1e5-1b4e74f268f2",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2F9201C-sackback-1.jpg?alt=media&token=2c8516be-9d52-4dd8-beda-c720807c187a",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2F9201C-sackback-2.jpg?alt=media&token=ebb278cf-e288-4c08-a829-003ffea5e230",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2F9201C-sackback-3.jpg?alt=media&token=88114e5f-066a-4728-ba31-3a522cb68663",
        ]
    },{
        id: 'p23',
        name: 'JV Tackle Sled',
        sku: '9211',
        description: `Provides proper and safe training where it counts most from the start. This tool reinforces proper tackling techniques. To take the sled from its upright position to the ground, every player must hit low, use proper leg drive, shoulder position and tackling position. Base: 31" x 80" Weight: 175 lbs.`,
        details: [
            `Sled pad core is built from high quality 100 lb impact resisitant foam`,
            `Durable 18 oz vinyl is double and triple stitched`,
            `Doesn't chew up grass or artificial turf`,
            `Base: 31" x 80`,
            'Weight: 175 lbs'
        ],
        includes: [
            ``
        ],
        options: [
            '9211R: Round pad',
            '9211M: Man pad'
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'sleds youth football',
        group: 'Tackle Sleds',
        urlCategory: 'football',
        urlSubCategory: 'youth football',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fjv-sackback-sled-9211r-HQ2.jpg?alt=media&token=4147035b-192b-4476-8dbc-3caccf11afb0",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fjv-sackback-sled-9211r-HQ2.jpg?alt=media&token=4147035b-192b-4476-8dbc-3caccf11afb0",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fyouth-tackle-sled-pads-HQ1.jpg?alt=media&token=e2712dc6-3413-45f3-a69a-d2ada862b4a7"
        ]
    },{
        id: 'p24',
        name: 'Youth Tackle Sled',
        sku: 'JRTS',
        description: `Provides proper and safe training where it counts most from the start. This tool reinforces proper tackling techniques. To take the sled from its upright position to the ground, every player must hit low, use proper leg drive, shoulder position and tackling position. Base: 23.5" x 58" Weight: 125 lbs.`,
        details: [
            `Sled pad core is built from high quality 100 lb impact resisitant foam`,
            `Durable 18 oz vinyl is double and triple stitched`,
            `Doesn't chew up grass or artificial turf`,
            `Base: 23.5" x 58"`,
            'Weight: 125 lbs'
        ],
        includes: [
            ``
        ],
        options: [
            'JRTS1: Round pad',
            'JRTSM: Man pad'
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'sleds youth football',
        group: 'Tackle Sleds',
        urlCategory: 'football',
        urlSubCategory: 'youth football',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fyouth-tackle-sled-jrts1-HQ2.jpg?alt=media&token=ac5d1796-0f7d-4751-b789-b847718e6ee4",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fyouth-tackle-sled-jrts1-HQ2.jpg?alt=media&token=ac5d1796-0f7d-4751-b789-b847718e6ee4",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fyouth-tackle-sled-pads-HQ1.jpg?alt=media&token=e2712dc6-3413-45f3-a69a-d2ada862b4a7"
        ]
    },{
        id: 'p31',
        name: 'Brute 2 Sled',
        sku: '9722jrm',
        description: `Fisher Brute 2 sleds are scaled down versions of our popular Brute sleds and are ideal for 6th through 9th grade players. Our TITE-LOCK modular chassis provides the rigidity needed in a multi-unit sled. Construction: Main Frame: 6" x 1/2" face plates mounted on sturdy 1/4" steel plates. Square tubing runners and skids have a caps and 100% welded flanges. Live action springs: Flat leaf springs deflect to absorb the shock and bounce back to provide realism in drills. Tite-Lock Chassis: New, improved interlocking modular design. Overlapped channel steel construction provides the rigidity needed in a multi-unit sled. Expandable: Individual components allow stations to be added as the need arises. Basic sleds can start with 2 man capability and expanded as you desire. A 5 man sled can be easily broken down to a 2 and a 3 man sled.`,
        details: [
            'Ideal for 6th through 9th grade players',
            '5 year warranty',
            'Expandable: Extra stations can be added as the need arises',
            'Durable 18 oz vinyl covers',
            'Made with high density, 100 lb, impact resisitant foam',
            'Coaches platform not included',
        ],
        includes: [
            ``
        ],
        options: [
            '2 Man Brute 2 Sled',
            '9722JRC: Cone pad (235 lbs)',
            '9722JRM: Man pad (235 lbs)',
            '9722JRR: Round pad (235 lbs)',
            '3 Man Brute 2 Sled',
            '9723JRC: Cone pad (366 lbs)',
            '9723JRM: Man pad (366 lbs)',
            '9723JRR: Round pad (366 lbs)',
            '4 Man Brute 2 Sled',
            '9724JRC: Cone pad (497 lbs)',
            '9724JRM: Man pad (497 lbs)',
            '9724JRR: Round pad (497 lbs)',
            '5 Man Brute 2 Sled',
            '9725JRC: Cone pad (628 lbs)',
            '9725JRM: Man pad (628 lbs)',
            '9725JRR: Round pad (628 lbs)',
            '6 Man Brute 2 Sled',
            '9726JRC: Cone pad (759 lbs)',
            '9726JRM: Man pad (759 lbs)',
            '9726JRR: Round pad (759 lbs)',
            '7 Man Brute 2 Sled',
            '9727JRC: Cone pad (891 lbs)',
            '9727JRM: Man pad (891 lbs)',
            '9727JRR: Round pad (891 lbs)'
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'sleds youth football',
        group: '',
        urlCategory: 'football',
        urlSubCategory: 'youth football',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fbrute-2-sled-9722jrm-HQ1.jpg?alt=media&token=56897712-34d8-400d-b253-d6b71663d082",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fbrute-2-sled-9722jrm-HQ1.jpg?alt=media&token=56897712-34d8-400d-b253-d6b71663d082",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fbrute-sled-2man-9712-HQ2.jpg?alt=media&token=9d3272e0-8e7e-489b-9e86-a2cede797d8c",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fbrute-sled-3man-9713-HQ1.jpg?alt=media&token=cf2dc82e-1e35-4d69-8695-87c0f4d8b4b2",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fbrute2-sled-pads-HQ1.jpg?alt=media&token=adf514f4-468c-4765-bbea-77ab6f780e1d",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fbrute-sled-4man-9714-HQ1.jpg?alt=media&token=e6073232-0def-4b4e-9d74-62d3941d9fb0",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fbrute-sled-5man-9715-HQ1.jpg?alt=media&token=30a073d7-0588-4e52-a388-f25f12c2fd38",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fbrute-sled-6man-9716-HQ1.jpg?alt=media&token=c42bfe58-c16d-4458-8eb6-33a7c58847a4",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fbrute-sled-7man-9717-HQ2.jpg?alt=media&token=ea49767b-3e72-49ab-be69-f338c23d64cd"
        ]
    },{
        id: 'p37',
        name: 'Coaches Platform',
        sku: '9000cp',
        description: `The optional platform has a 36" tall, 2" O.D. hand rail and a sturdy, 30" steel diamond plate base for an excellent non-slip surface. The platform allows an up front view of drills and gives the feel of impact.`,
        details: [
            '36" tall, 2" O.D. hand rail',
            '30" x 30" steel diamond plate base',
            'Non-slip surface'
        ],
        includes: [
            ``
        ],
        options: [
            '9000CP: Coaches Platform',
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'sleds youth football',
        group: 'Coaches Platforms',
        urlCategory: 'football',
        urlSubCategory: 'sleds',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fcoaches-platform-9000cp-HQ2.jpg?alt=media&token=75830117-05a1-4cc8-996f-014399b837cb",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fcoaches-platform-9000cp-HQ2.jpg?alt=media&token=75830117-05a1-4cc8-996f-014399b837cb"
        ]
    },{
        id: 'p38',
        name: 'Coaches Platform CL',
        sku: 'clcp',
        description: `The optional platform has a 36" tall, 2" O.D. hand rail and a sturdy, 30" steel diamond plate base for an excellent non-slip surface. The platform allows an up front view of drills and gives the feel of impact.`,
        details: [
            '36" tall, 2" O.D. hand rail',
            '30" x 30" steel diamond plate base',
            'Non-slip surface'
        ],
        includes: [
            ``
        ],
        options: [
            'CLCP: Coaches Platform CL',
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'sleds',
        group: 'Coaches Platforms',
        urlCategory: 'football',
        urlSubCategory: 'sleds',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fcoaches-platform-cl-clcp-HQ3.jpg?alt=media&token=5db194ff-796f-41c3-bfb1-41658abd84f2",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fcoaches-platform-cl-clcp-HQ3.jpg?alt=media&token=5db194ff-796f-41c3-bfb1-41658abd84f2",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fcoaches-platform-cl-clcp-HQ1.jpg?alt=media&token=84ec65a1-f9eb-4323-8b7e-47e0b8b58d8d",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fcoaches-platform-cl-clcp-HQ2.jpg?alt=media&token=107bf1a0-8a87-4552-bdee-8f00472e52a3"
        ]
    },{
        id: 'p51',
        name: 'Indoor Bull Station',
        sku: '9900ps',
        description: `Platform and Bull Mechanism. Offensive lineman learn the progression of staying low and rolling their hips to maximize power and drive through their opponents . Defensive players work on proper footwork and striking an opponent with their hands. Advantages of the Bull: Change resistance, Resistance stays constant throughout entire motion , 6' inward/15' upward compression.`,
        details: [
            '6" inward / 15" upward compression',
            'Resistance stays constant throughout entire motion',
            'Resistance can be changed'
        ],
        includes: [
            ``
        ],
        options: [
            '9900PS: Platform and Bull Mechanism',
            '9900P: Platform only'
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'strength and speed',
        group: '',
        urlCategory: 'football',
        urlSubCategory: 'strength and speed',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Findoor-bull-station-9900ps-HQ2.jpg?alt=media&token=0b1b4abe-bfeb-483f-8b2c-243facc7c75e",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Findoor-bull-station-9900ps-HQ2.jpg?alt=media&token=0b1b4abe-bfeb-483f-8b2c-243facc7c75e",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Findoor-bull-station-9900PS-HQ1.jpg?alt=media&token=fb6d5484-897e-4ebd-8fb3-17f09c54d394"
        ]
    },{
        id: 'p53',
        name: 'Traditional Goal Post Pad',
        sku: 'gppl1',
        description: `One color lettering, sewn onto the pad. Up to 8 letters per pad. Letters and vinyl available in 17 color options. These come in two closure styles. The Varsity series is a wrap style cover with top and bottom protection that increases durability The PRO series is a fully encased vinyl cover which keeps the foam dry and in perfect shape guaranteeing maximum lifespan. Both styles have a velcro flap in the back for closure. All of our goal post pads meet NCAA and NFHS safety standards. Heavy-duty, 18 ounce coated vinyl nylon covers. Solid polyfoam cylinder with a center cutout to match your goal (please provide pole diameter or circumference when ordering). For custom logos you will want to choose the Elite or Classic style pads. School logos or promotionals are all available from Fisher.`,
        details: [
            'One color lettering',
            'Letters are sewn onto the pad',
            'Up to 8 letters per pad',
            'Letters and vinyl available in 17 color options'
        ],
        includes: [
            ``
        ],
        options: [
            `GPP400/GPP401: 6'H x 13"Diam`,
            `GPP300/GPP301: 6'H x 18"Diam`,
            `GPP200/GPP201: 6'H x 22"Diam`,
            `GPP4007/GPP4017: 7'H x 13"Diam`,
            `GPP3007/GPP3017: 7'H x 18"Diam`,
            `GPP2007/GPP2017: 7'H x 22"Diam`
        ],
        usedBy: [],
        category: 'football  protective padding',
        subCategory: 'gameday outdoor padding',
        group: 'Goal Post Pads',
        urlCategory: 'football  protective padding',
        urlSubCategory: 'gameday outdoor padding',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fgoal-post-pad-gppl1-2400-1.jpg?alt=media&token=41d958f8-6272-45bc-bd0b-3060edd49f6b",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fgoal-post-pad-gppl1-2400-1.jpg?alt=media&token=41d958f8-6272-45bc-bd0b-3060edd49f6b",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fgoal-post-pad-gppl1-2400-2.jpg?alt=media&token=4bc74f87-5c70-4046-95d6-1676ba2e903d",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fgoal-post-pad-cover-chart-HQ1.jpg?alt=media&token=4a522db6-d615-4811-b49d-799784af64a2",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fgoal-post-pad-chart-HQ1.jpg?alt=media&token=27de2377-b7c3-4d26-9bb6-db31a3ecb349"
        ]
    },{
        id: 'p54',
        name: 'Traditional Goal Post Pad',
        sku: 'gppl2',
        description: `Two color lettering, sewn onto the pad. Up to 8 letters per pad. Letters and vinyl available in 17 color options. These come in two closure styles. The Varsity series is a wrap style cover with top and bottom protection that increases durability The PRO series is a fully encased vinyl cover which keeps the foam dry and in perfect shape guaranteeing maximum lifespan. Both styles have a velcro flap in the back for closure. All of our goal post pads meet NCAA and NFHS safety standards. Heavy-duty, 18 ounce coated vinyl nylon covers. Solid polyfoam cylinder with a center cutout to match your goal (please provide pole diameter or circumference when ordering). For custom logos you will want to choose the Elite or Classic style pads. School logos or promotionals are all available from Fisher.`,
        details: [
            'Two color lettering',
            'Letters are sewn onto the pad',
            'Up to 8 letters per pad',
            'Letters and vinyl available in 17 color options'
        ],
        includes: [
            ``
        ],
        options: [
            `GPP400/GPP401: 6'H x 13"Diam`,
            `GPP300/GPP301: 6'H x 18"Diam`,
            `GPP200/GPP201: 6'H x 22"Diam`,
            `GPP4007/GPP4017: 7'H x 13"Diam`,
            `GPP3007/GPP3017: 7'H x 18"Diam`,
            `GPP2007/GPP2017: 7'H x 22"Diam`
        ],
        usedBy: [],
        category: 'football  protective padding',
        subCategory: 'gameday outdoor padding',
        group: 'Goal Post Pads',
        urlCategory: 'football  protective padding',
        urlSubCategory: 'gameday outdoor padding',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fgoal-post-pad-gppl2-2400-13.jpg?alt=media&token=536df892-de02-4746-aabc-1ce41d1c1cde",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fgoal-post-pad-gppl2-2400-13.jpg?alt=media&token=536df892-de02-4746-aabc-1ce41d1c1cde",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fgoal-post-pad-gppl2-2400-14.jpg?alt=media&token=113a5f29-281d-4ac0-bb71-899343ef47e2",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fgoal-post-pad-gppl2-2400-8.jpg?alt=media&token=74a197cf-a28d-4987-927e-fae258f7549f",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fgoal-post-pad-gppl2-2400-9.jpg?alt=media&token=b1a61f4b-1c22-468f-9775-298958651a7f",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fgoal-post-pad-gppl2-2400-7.jpg?alt=media&token=ab945fcb-01c4-450c-b66c-12e734c947d4",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fgoal-post-pad-gppl2-2400-6.jpg?alt=media&token=f28baa80-4288-4014-a636-214df553f733",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fgoal-post-pad-gppl2-2400-5.jpg?alt=media&token=4a875f11-5127-49fb-bf69-d951485ab0e8",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fgoal-post-pad-gppl2-2400-4.jpg?alt=media&token=5e8c4960-cd13-422e-a897-e645eb81f9de",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fgoal-post-pad-gppl2-2400-3.jpg?alt=media&token=23cf9ae6-93fc-4210-a5f2-e4a973e5ca1a",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fgoal-post-pad-gppl2-2400-2.jpg?alt=media&token=8485178c-c511-4822-950d-fc68954f206a",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fgoal-post-pad-gppl2-2400-1.jpg?alt=media&token=7a304136-8d0c-4805-bc9d-68b178b13725",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fgoal-post-pad-cover-chart-HQ1.jpg?alt=media&token=4a522db6-d615-4811-b49d-799784af64a2",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fgoal-post-pad-chart-HQ1.jpg?alt=media&token=27de2377-b7c3-4d26-9bb6-db31a3ecb349",
        ]
    },{
        id: 'p55',
        name: 'Classic Goal Post Pad',
        sku: 'gpplldp',
        description: `Multi-colored lettering and logos printed on All of our goal post pads meet NCAA and NFHS safety standards. Heavy-duty, 18 ounce coated vinyl nylon covers. Solid polyfoam cylinder with a center cutout to match your goal (please provide pole diameter or circumference when ordering). Custom Logos: Add special logos to your Goal Post Pad(s) or Goal Post Pad Cap(s). School logos or promotionals are all available from Fisher. PRICING VARIES DEPENDING ON ART STYLE. CALL FOR YOUR QUOTE!`,
        details: [
            'Multi-colored lettering and logos',
            'Lettering and logos are printed on pad',
            'Solid background in one of our 17 vinyl colors'
        ],
        includes: [
            ``
        ],
        options: [
            `GPP400/GPP401: 6'H x 13"Diam`,
            `GPP300/GPP301: 6'H x 18"Diam`,
            `GPP200/GPP201: 6'H x 22"Diam`,
            `GPP4007/GPP4017: 7'H x 13"Diam`,
            `GPP3007/GPP3017: 7'H x 18"Diam`,
            `GPP2007/GPP2017: 7'H x 22"Diam`
        ],
        usedBy: [],
        category: 'football  protective padding',
        subCategory: 'gameday outdoor padding',
        group: 'Goal Post Pads',
        urlCategory: 'football  protective padding',
        urlSubCategory: 'gameday outdoor padding',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fgoal-post-pad-gpplldp-2400-15.jpg?alt=media&token=d8be0e04-1ab3-41e9-bd10-c72596987d94",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fgoal-post-pad-gpplldp-2400-15.jpg?alt=media&token=d8be0e04-1ab3-41e9-bd10-c72596987d94",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fgoal-post-pad-gpplldp-2400-14.jpg?alt=media&token=23fd940b-872c-469f-8d65-c4d81194c9c1",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fgoal-post-pad-gpplldp-2400-17.jpg?alt=media&token=d6ec295d-ba75-4793-b4ac-00fe01024455",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fgoal-post-pad-gpplldp-2400-9.jpg?alt=media&token=0a7743b0-fecd-498d-9171-aae5e5bcba61",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fgoal-post-pad-gpplldp-2400-1.jpg?alt=media&token=957c7886-e2ea-469a-9eaa-f42097416036",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fgoal-post-pad-gpplldp-2400-10.jpg?alt=media&token=95046cc9-6565-471d-a1f0-0cb735e07345",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fgoal-post-pad-mccutcheon.jpg?alt=media&token=bdafe950-f9aa-4a9f-a6d9-80f3cd9721f6",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fgoal-post-pad-gpplldp-2400-16.jpg?alt=media&token=09558dec-73bb-478b-ab3e-bfff728599aa",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fgoal-post-pad-gpplldp-2400-12.jpg?alt=media&token=46ce2f72-3cf1-45f3-a4be-2033893381e6",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fgoal-post-pad-gpplldp-2400-13.jpg?alt=media&token=7dc23403-3dc0-41b0-bfc6-b57dc509fb23",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fgoal-post-pad-gpplldp-2400-2.jpg?alt=media&token=8f459380-b90e-4e35-aa7a-e8b145bd0c78",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fgoal-post-pad-gpplldp-2400-3.jpg?alt=media&token=15617e22-cbda-4c09-a7a2-6207e9b2a2e0",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fgoal-post-pad-gpplldp-2400-4.jpg?alt=media&token=c1bb17fa-e7d8-4cdb-8a5c-3366f33cf8b0",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fgoal-post-pad-gpplldp-2400-5.jpg?alt=media&token=ed5cd6ee-b6b6-4401-8e4d-299abd14ae47",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fgoal-post-pad-gpplldp-2400-6.jpg?alt=media&token=ffafaefe-9aa4-42f5-82df-baf2585470f7",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fgoal-post-pad-gpplldp-2400-7.jpg?alt=media&token=cce16f21-b063-4ffc-8cd6-d605ad2ce4ff",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fgoal-post-pad-gpplldp-2400-8.jpg?alt=media&token=6c9c76d5-4d6f-4953-ba95-c77665fab18e",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fgoal-post-pad-cover-chart-HQ1.jpg?alt=media&token=4a522db6-d615-4811-b49d-799784af64a2",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fgoal-post-pad-chart-HQ1.jpg?alt=media&token=27de2377-b7c3-4d26-9bb6-db31a3ecb349",
        ]
    },{
        id: 'p56',
        name: 'Elite Goal Post Pad',
        sku: 'gppedp',
        description: `360 degree fully digital print graphics with unlimited colors. Pads printed with detailed, vibrant graphics that are sure to impress fans and opposing teams. This is the ultimate way to show off your schools brand. All of our goal post pads meet NCAA and NFHS safety standards. Heavy-duty, 18 ounce coated vinyl nylon covers. Solid polyfoam cylinder with a center cutout to match your goal (please provide pole diameter or circumference when ordering). Custom Logos: Add special logos to your Goal Post Pad(s) or Goal Post Pad Cap(s). School logos or promotionals are all available from Fisher. PRICING VARIES DEPENDING ON ART STYLE. CALL FOR YOUR QUOTE!`,
        details: [
            '360˚ fully digitally printed graphics',
            'Unlimited colors'
        ],
        includes: [
            ``
        ],
        options: [
            `GPP400/GPP401: 6'H x 13"Diam`,
            `GPP300/GPP301: 6'H x 18"Diam`,
            `GPP200/GPP201: 6'H x 22"Diam`,
            `GPP4007/GPP4017: 7'H x 13"Diam`,
            `GPP3007/GPP3017: 7'H x 18"Diam`,
            `GPP2007/GPP2017: 7'H x 22"Diam`
        ],
        usedBy: [],
        category: 'football  protective padding',
        subCategory: 'gameday outdoor padding',
        group: 'Goal Post Pads',
        urlCategory: 'football  protective padding',
        urlSubCategory: 'gameday outdoor padding',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fgoal-post-pad-gppedp-2400-12.jpg?alt=media&token=2837722a-4aee-4670-9c89-515c4c6a4834",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fgoal-post-pad-gppedp-2400-12.jpg?alt=media&token=2837722a-4aee-4670-9c89-515c4c6a4834",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fgoal-post-pad-gppedp-2400-10.jpg?alt=media&token=566c7158-8b07-4543-96e6-aa422d6827c5",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fgoal-post-pad-gppedp-2400-13.jpg?alt=media&token=1fe9ab11-854b-4a96-8b03-9b9b39aec980",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fgoal-post-pad-gppedp-2400-1.jpg?alt=media&token=1433f354-0b46-463d-ad69-71fabb7a446c",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fgoal-post-pad-gppedp-2400-2.jpg?alt=media&token=74522b6b-5626-4c9f-8a5e-8bfd18aef8ee",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fgoal-post-pad-gppedp-2400-3.jpg?alt=media&token=0fb797ea-aea0-4129-998a-1478918133b8",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fgoal-post-pad-gppedp-2400-4.jpg?alt=media&token=f7d79eb5-e02c-4443-83b2-d3f48ba5846c",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fgoal-post-pad-gppedp-2400-5.jpg?alt=media&token=ad51bae0-8083-4693-93ad-ecf808fe2ee8",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fgoal-post-pad-gppedp-2400-6.jpg?alt=media&token=54104746-1152-4b8b-8b57-12f1b8ae8ff0",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fgoal-post-pad-gppedp-2400-7.jpg?alt=media&token=d69045ee-c804-4eb3-8716-9c9d68a1dd66",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fgoal-post-pad-gppedp-2400-8.jpg?alt=media&token=af016a0d-b4d6-445e-b74f-f6410b809020",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fgoal-post-pad-gppedp-2400-9.jpg?alt=media&token=f19c0eba-f36f-4141-924a-b0956f4d56ad",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fgoal-post-pad-gppedp-2400-11.jpg?alt=media&token=ceac916a-6d0a-4658-aa79-a40dd3922f86",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fgoal-post-pad-cover-chart-HQ1.jpg?alt=media&token=4a522db6-d615-4811-b49d-799784af64a2",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fgoal-post-pad-chart-HQ1.jpg?alt=media&token=27de2377-b7c3-4d26-9bb6-db31a3ecb349"
        ]
    },{
        id: 'p57',
        name: 'Goal Post Pad Cap',
        sku: 'gppc',
        description: `Foam padded cap sits on top of any goal post pad. Add special logos to your Goal Post Pads or Goal Post Pad Caps. School logos or promotionals are all available from Fisher.`,
        details: [
            'Great for advertising',
            'Sits on top of your goal post pad'
        ],
        includes: [
            ``
        ],
        options: [
            `GPPC13: 12"H x 13"Diam`,
            `GPPC18: 12"H x 18"Diam`,
            `GPPC22: 12"H x 22"Diam`
        ],
        usedBy: [],
        category: 'football  protective padding',
        subCategory: 'gameday outdoor padding',
        group: 'Goal Post Pads',
        urlCategory: 'football  protective padding',
        urlSubCategory: 'gameday outdoor padding',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fgoal-post-pad-cap-gppc-2400-1.jpg?alt=media&token=5a050d82-e082-45ce-901e-fb0a0279cb3f",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fgoal-post-pad-cap-gppc-2400-1.jpg?alt=media&token=5a050d82-e082-45ce-901e-fb0a0279cb3f",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fgoal-post-pad-cap-gppc-2400-2.jpg?alt=media&token=269387d7-c9c2-46b0-90dc-48b47d2280d4",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fgoal-post-pad-cap-gppc-2400-3.jpg?alt=media&token=936b8d3e-6e02-40c3-b8d0-a88c3896fccd"
        ]
    },{
        id: 'p58',
        name: 'Yellow Goal Post',
        sku: 'gppcy',
        description: `Powder coated yellow.`,
        details: [
            'Primed and ready to paint',
            'Windstreamers included (WS3)',
            'Sold in pairs',
            'Ships via truck'
        ],
        includes: [
            ``
        ],
        options: [
            `See Goal Post chart in images for option details`,
            `High School: GP1, GP130, GP3, GP3PRO30, GP3-830`,
            `College: GP2E, GP2, GP230, GP4, GP4PRO30, GP4-830`
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'gameday',
        group: 'Goal Posts',
        urlCategory: 'football',
        urlSubCategory: 'gameday',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fgoal-post-yellow-gppcy-HQ1.jpg?alt=media&token=349a741a-fda6-4707-b979-4431ecb80c1b",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fgoal-post-yellow-gppcy-HQ1.jpg?alt=media&token=349a741a-fda6-4707-b979-4431ecb80c1b",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fgoal-post-yellow-gppcy-HQ2.jpg?alt=media&token=f3062c05-baad-4b5b-b0a1-b0e4ca671e96",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fwindstreamer-ws3-HQ1.jpg?alt=media&token=590a2692-b6c9-47f0-8cda-b87d70818a2c",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fgoal-post-sizing-chart-HQ1.jpg?alt=media&token=01da4fe1-7b78-4c93-9698-af23df2625fa"
        ]
    },{
        id: 'p59',
        name: 'White Goal Post',
        sku: 'gppcw',
        description: `Powder coated white.`,
        details: [
            'Primed and ready to paint',
            'Windstreamers included (WS3)',
            'Sold in pairs',
            'Ships via truck'
        ],
        includes: [
            ``
        ],
        options: [
            `See Goal Post chart in images for option details`,
            `High School: GP1, GP130, GP3, GP3PRO30, GP3-830`,
            `College: GP2E, GP2, GP230, GP4, GP4PRO30, GP4-830`
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'gameday',
        group: 'Goal Posts',
        urlCategory: 'football',
        urlSubCategory: 'gameday',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fgoal-post-white-gppcw-HQ1.jpg?alt=media&token=16b4ee87-b563-4085-80ae-eb5949609d6c",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fgoal-post-white-gppcw-HQ1.jpg?alt=media&token=16b4ee87-b563-4085-80ae-eb5949609d6c",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fgoal-post-white-gppcw-HQ2.jpg?alt=media&token=6ea19ba8-467d-47cc-a91a-87fee228e806",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fwindstreamer-ws3-HQ1.jpg?alt=media&token=590a2692-b6c9-47f0-8cda-b87d70818a2c",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fgoal-post-sizing-chart-HQ1.jpg?alt=media&token=01da4fe1-7b78-4c93-9698-af23df2625fa"
        ]
    },{
        id: 'p60',
        name: 'Portable Goal Post',
        sku: '6000pgh',
        description: `Ideal for multi-purpose fields. Quickly and easily installed. Wheels allow complete mobility. 2' Outside Diameter galvanized steel. Portable goal sold each. Wind streamers included. We recommend PMV7216 or GPP400 pads on our Portable and H style Goals for safety.`,
        details: [
            'Ideal for multi-purpose fields',
            'Quickly and easily installed',
            'Wheels allow complete mobility to roll on and off the field',
            '2" O.D galvanized steel',
            'Portable goal sold each',
            'Windstreamers included'
        ],
        includes: [
            ``
        ],
        options: [
            `6000PGC: College Goal: 20'H x 18'6"W`,
            `6000PGH: High School Goal: 20'H x 23'4"W`,
            `6000PGC1: College Goal with 18' x 21' Net and 3 Targets and 1 Upright Set`,
            `6000PGH2: High School Goal with 23' x 21' Net and 3 Targets and 1 Upright Set`,
            `6000CNET: 18' x 21' Net only`,
            `6000HNET: 23' x 21' Net only`,
            `6000TARGETS: 3 Targets: 1 Triangle, 1 Round, and 1 Rectangle`,
            `6000UPRIGHTS: 1 Set of Movable Uprights 8'H x 8"W`
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'gameday',
        group: 'Goal Posts',
        urlCategory: 'football',
        urlSubCategory: 'gameday',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fportable-goal-post-HQ1.jpg?alt=media&token=c11a395b-efe7-44e7-991e-62f18fb8af4c",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fportable-goal-post-HQ1.jpg?alt=media&token=c11a395b-efe7-44e7-991e-62f18fb8af4c",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fportable-goal-post-targets-HQ1.jpg?alt=media&token=d09e6909-1f04-4051-93a2-7d8aa6db4e5b",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fportable-goal-post-wheels-HQ1.jpg?alt=media&token=c6295213-7499-4d37-a951-05e5a1823be2",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fportable-goal-pos-no-net-HQ1.jpg?alt=media&token=08293056-21d6-40a4-9175-f6249beef402",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fwindstreamer-ws3-HQ1.jpg?alt=media&token=590a2692-b6c9-47f0-8cda-b87d70818a2c"
        ]
    },{
        id: 'p61',
        name: 'H-Style Goal',
        sku: '6000hgh',
        description: `Easily installed and removed. Ideal for practice facilities. Includes ground sleeves and wind streamers 2 inch outside dimension galvanized steel. 10 feet high uprights 20 feet high from ground.`,
        details: [
            'Economical / sold separately',
            'Easily installed and removed',
            'Ideal for practice facilities',
            'Includes ground sleeves and windstreamers',
            '2" O.D galvanized steel',
            `10' high uprights / 20' high from ground`
        ],
        includes: [
            ``
        ],
        options: [
            `6000HCH: College Goal: 20'H x 18'6"W`,
            `6000HGH: High School Goal: 20'H x 23'4"W`
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'gameday',
        group: 'Goal Posts',
        urlCategory: 'football',
        urlSubCategory: 'gameday',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fh-style-goal-6000hgh-HQ1.jpg?alt=media&token=296e6784-7c83-431e-b0b6-9dba8a1383f5",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fh-style-goal-6000hgh-HQ1.jpg?alt=media&token=296e6784-7c83-431e-b0b6-9dba8a1383f5",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fwindstreamer-ws3-HQ1.jpg?alt=media&token=590a2692-b6c9-47f0-8cda-b87d70818a2c"
        ]
    },{
        id: 'p62',
        name: 'Half Round Dummies',
        sku: 'hr426',
        description: `Curved surface reduces ankle injuries and improves player safety. Dual purpose dummy for step-over or blocking. Web handles allow you to use it as a regular blocking dummy. Half round speed dummy is designed to increase foot speed.`,
        details: [
            'Curved surface reduces ankle injuries and improves player safety',
            'Dual purpose dummy for stepover or blocking',
            'Web handles allow you to use it as a regular blocking dummy',
            'Designed to increase foot speed'
        ],
        includes: [
            ``
        ],
        options: [
            `HR426: 42"L x 12"W x 6"H (11lbs)`,
            `HR428: 42"L x 16"W x 8"H (17lbs)`,
            `HR488: 48"L x 16"W x 8"H (20lbs)`,
            `HR4212: 42"L x 16"W x 12"H (10lbs)`
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'dummies youth football',
        group: 'Agility Dummies',
        urlCategory: 'football',
        urlSubCategory: 'dummies',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fhalf-round-dummies-HQ1.jpg?alt=media&token=f0ef0bfb-b129-4935-b987-106a395237a9",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fhalf-round-dummies-HQ1.jpg?alt=media&token=f0ef0bfb-b129-4935-b987-106a395237a9",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fhalf-round-dummies-HQ2.jpg?alt=media&token=d68a4f9e-a2c1-49cf-82c0-d81d81ef9bb6",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fhalf-round-dummies-HQ3.jpg?alt=media&token=4e43498b-fc5b-4b4c-a245-e15fb4f07e3e"
        ]
    },{
        id: 'p63',
        name: 'Step Over Dummies',
        sku: 'so424',
        description: `Increases player agility. Stay-in-place trapezoidal design. Backside handles for stand up practice. Special design helps reduce ankle injuries. End handle for moving on and off field.`,
        details: [
            'Increases player agility',
            'Stay-in-place trapezoidal design',
            'Backside handles for stand up practice',
            'Special design helps reduce ankle injuries',
            'End handle for moving on and off field'
        ],
        includes: [
            ``
        ],
        options: [
            `SO424: 42"L x 4'H x 12" Base x 9" Top (10lbs)`,
            `SO426: 42"L x 6'H x 15" Base x 10" Top (14lbs)`,
            `SO488: 48"L x 8'H x 17" Base x 11" Top (20lbs)`,
            `SO4838: 48"L x 10'H x 17" Base x 12" Top (40lbs)`,
            `SO4810: 48"L x 12'H x 17" Base x 11" Top (30lbs)`
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'dummies youth football',
        group: 'Agility Dummies',
        urlCategory: 'football',
        urlSubCategory: 'dummies',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fstep-over-dummies-HQ1.jpg?alt=media&token=569aa47e-3b3b-4abd-8e9e-3f632cf9ccd5",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fstep-over-dummies-HQ1.jpg?alt=media&token=569aa47e-3b3b-4abd-8e9e-3f632cf9ccd5",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fstep-over-dummies-HQ2.jpg?alt=media&token=5f2c31ff-ffa0-4473-b3af-19127b884ef3",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fstep-over-dummies-HQ3.jpg?alt=media&token=568bc9ea-a3e8-4612-a267-785069540b56",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fstep-over-dummies-HQ4.jpg?alt=media&token=ecf0ac13-29d2-4b6f-9abb-60b791cb4396"
        ]
    },{
        id: 'p65',
        name: 'Direct Step',
        sku: 'so4818ds',
        description: `This specially designed step-over has color-coded directional step arrows and is designed to improve a player's agility and mobility. The step-over top has three sets of multi-colored arrows which allow players to react (step) to colored cadence calls. Designed by John Fitz of Catawba College.`,
        details: [
            'Stepover with colored directional arrows',
            'Increases player agility',
            'Backside handles for stand up practice',
            'End handle for moving on and off field'
        ],
        includes: [
            ``
        ],
        options: [
            `SO4818DS: 48"L x 8"H x 18"Base x 8"Top (21 lbs)`
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'dummies',
        group: 'Agility Dummies',
        urlCategory: 'football',
        urlSubCategory: 'dummies',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fdirect-step-HQ1.jpg?alt=media&token=0fff068e-6f7b-44fd-a703-23442b1bc0ff",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fdirect-step-HQ1.jpg?alt=media&token=0fff068e-6f7b-44fd-a703-23442b1bc0ff",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fdirect-step-HQ2.jpg?alt=media&token=9bbea076-5d3a-4a51-b40f-054bcb4eb0bb",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fdirect-step-HQ3.jpg?alt=media&token=d7893be0-8ab9-444f-924d-b340062f42d2"
        ]
    },{
        id: 'p66',
        name: 'Deluxe Windstreamer',
        sku: 'ws3d',
        description: `Know the direction of the wind with our goal post Windstreamers. Simply attach to the top of the goal post uprights. Fits on all goal posts.`,
        details: [
            'Double ply vinyl',
            'Chrome snap hook',
            'Fits all goal posts'
        ],
        includes: [
            ``
        ],
        options: [
            `WS3D: Deluxe Windstreamer`,
            `WS3: Windstreamer`
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'gameday',
        group: 'Windstreamers',
        urlCategory: 'football',
        urlSubCategory: 'gameday',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fwindstreamer-deluxe-ws3d-HQ1.jpg?alt=media&token=f5b77b2b-7d33-46b0-9a76-b8e676975d19",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fwindstreamer-deluxe-ws3d-HQ1.jpg?alt=media&token=f5b77b2b-7d33-46b0-9a76-b8e676975d19",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fwindstreamer-flowing-HQ1.jpg?alt=media&token=a2a7c2d6-089f-40b1-b1ee-dd238dd41d3d"
        ]
    },{
        id: 'p67',
        name: 'Windstreamer',
        sku: 'ws3',
        description: `Know the direction of the wind with our goal post Windstreamers. Simply attach to the top of the goal post uprights. Fits on all goal posts.`,
        details: [
            'Single ply vinyl',
            'Brass grommet',
            'Fits all goal posts'
        ],
        includes: [
            ``
        ],
        options: [
            `WS3: Windstreamer`,
            `WS3D: Deluxe Windstreamer`
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'gameday',
        group: 'Windstreamers',
        urlCategory: 'football',
        urlSubCategory: 'gameday',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fwindstreamer-ws3-HQ1.jpg?alt=media&token=590a2692-b6c9-47f0-8cda-b87d70818a2c",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fwindstreamer-ws3-HQ1.jpg?alt=media&token=590a2692-b6c9-47f0-8cda-b87d70818a2c",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fwindstreamer-flowing-HQ1.jpg?alt=media&token=a2a7c2d6-089f-40b1-b1ee-dd238dd41d3d"
        ]
    },{
        id: 'p69',
        name: 'Punch Pad Player',
        sku: 'hpp',
        description: `Allows players to work on proper punch technique, posture, and footwork. 18 oz UV resistant coated vinyl. High grade polyfoam. Grommets on top and bottom for mounting. Can be used indoors or outdoors for year round practice.`,
        details: [
            '18 oz UV resistant coated vinyl',
            'High grade polyfoam',
            'Grommets on top and bottom for mounting',
            'Can be used indoors or outdoors',
            'Weight: 70 lbs'
        ],
        includes: [
            ``
        ],
        options: [
            `HPP8: 4'H x 8'W x 5" thick, 35lbs`,
            `HPP16: 4'H x 16'W x 5" thick, 70lbs`
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'sleds',
        group: '',
        urlCategory: 'football',
        urlSubCategory: 'sleds',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpunch-pad-player-hpp16-HD1.jpg?alt=media&token=d2d6f15a-7ec0-4150-b071-9327aafc14a6",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpunch-pad-player-hpp16-HD1.jpg?alt=media&token=d2d6f15a-7ec0-4150-b071-9327aafc14a6"
        ]
    },{
        id: 'p70',
        name: 'Punch Pad Target',
        sku: 'hpt',
        description: `Allows players to work on proper punch technique, posture, and footwork. 18 oz UV resistant coated vinyl. High grade polyfoam. Grommets on top and bottom for mounting. Can be used indoors or outdoors for year round practice.`,
        details: [
            '18 oz UV resistant coated vinyl',
            'High grade polyfoam',
            'Grommets on top and bottom for mounting',
            'Can be used indoors or outdoors',
            'Weight: 70 lbs'
        ],
        includes: [
            ``
        ],
        options: [
            `HPT8: 4'H x 8'W x 5" thick, 35lbs`,
            `HPT16: 4'H x 16'W x 5" thick, 70lbs`
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'sleds',
        group: '',
        urlCategory: 'football',
        urlSubCategory: 'sleds',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpunch-pad-target-hpt16-HD1.jpg?alt=media&token=b69831ac-0662-494b-a4a3-366a50b1cc24",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpunch-pad-target-hpt16-HD1.jpg?alt=media&token=b69831ac-0662-494b-a4a3-366a50b1cc24"
        ]
    },{
        id: 'p71',
        name: 'Tackle Wheel Ball Holder',
        sku: 'twbh',
        description: `Holds a football on your tackle wheel.`,
        details: [
            'Holds ball on your tackle wheel',
            'Fits any of our Tackle Wheels'
        ],
        includes: [
            ``
        ],
        options: [
            `TWBH: Tackle Wheel Ball Holder`
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'dummies',
        group: 'Tackle Wheels',
        urlCategory: 'football',
        urlSubCategory: 'dummies',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ftackle-wheel-ball-holder-twbh-HQ1.jpg?alt=media&token=988c05c4-5f3b-4669-80a3-2f40af12b533",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ftackle-wheel-ball-holder-twbh-HQ1.jpg?alt=media&token=988c05c4-5f3b-4669-80a3-2f40af12b533",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ftackle-wheel-ball-holder-twbh-HQ2.jpg?alt=media&token=a81989b9-8738-4d73-abde-c38abc037b10",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ftackle-wheel-ball-holder-twbh-HQ3.jpg?alt=media&token=d6c5e19e-5436-48b4-8b46-e45af6363723",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ftackle-wheel-ball-holder-twbh-HQ4.jpg?alt=media&token=8786686e-f4db-4da6-9662-17503b097dd9",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ftackle-wheel-ball-holder-twbh-HQ5.jpg?alt=media&token=d182d4f3-fe82-4719-877c-b19938cb4157"
        ]
    },{
        id: 'p72',
        name: 'Rocket Pursuer',
        sku: 'pur101r',
        description: `The Pursuer is a tool to help improve tackling techniques by hitting a moving target. The all foam construction makes the Pursuer the most addordable and durable moving tackle system on the market.`,
        details: [
            'Simulates live player movement',
            'Minimize player to player contact',
            'Beginners get used to hitting a moving target',
            'Top and Base all foam construction',
            'Make your players more effective tacklers',
            'Teaching the fundamentals of football has never been easier',
            'Height: 72"',
            'Weight: 34 lbs'
        ],
        includes: [
            ``
        ],
        options: [
            `PUR101R: Rocket Pursuer`
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'dummies',
        group: '',
        urlCategory: 'football',
        urlSubCategory: 'dummies',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpursuer-rocket-pur101r-HQ1.jpg?alt=media&token=6d96a4cc-e849-474a-9e1a-a9837b7ce77d",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpursuer-rocket-pur101r-HQ1.jpg?alt=media&token=6d96a4cc-e849-474a-9e1a-a9837b7ce77d",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpursuer-rocket-pur101r-HQ2.jpg?alt=media&token=eb96dcdc-6c23-4bcc-9d1d-34af41eaf200",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpursuer-rocket-pur101r-HQ3.jpg?alt=media&token=93cfc4f2-0566-4cce-b09e-defeb1e8846f",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpursuer-rocket-pur101r-HQ4.jpg?alt=media&token=59ae3f80-c68d-4383-8e9f-41e7780d408c"
        ]
    },{
        id: 'p73',
        name: 'Spartan Pursuer',
        sku: 'pur102s',
        description: `The Pursuer is a tool to help improve tackling techniques by hitting a moving target. The all foam construction makes the Pursuer the most addordable and durable moving tackle system on the market.`,
        details: [
            'Simulates live player movement',
            'Minimize player to player contact',
            'Beginners get used to hitting a moving target',
            'Top and Base all foam construction',
            'Make your players more effective tacklers',
            'Teaching the fundamentals of football has never been easier',
            'Height: 62"',
            'Weight: 28 lbs'
        ],
        includes: [
            ``
        ],
        options: [
            `PUR102S: Spartan Pursuer`
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'dummies',
        group: '',
        urlCategory: 'football',
        urlSubCategory: 'dummies',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpursuer-spartan-pur102s-HQ1.jpg?alt=media&token=58217e7f-c470-492a-955e-60f2d6c78f4c",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpursuer-spartan-pur102s-HQ1.jpg?alt=media&token=58217e7f-c470-492a-955e-60f2d6c78f4c",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpursuer-spartan-pur102s-HQ2.jpg?alt=media&token=425772ed-87e2-4915-b96e-27ef7a1dc1ba",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpursuer-spartan-pur102s-HQ3.jpg?alt=media&token=8c3ca09f-b2e4-45c9-8cd2-94d1c0afdd95",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpursuer-spartan-pur102s-HQ4.jpg?alt=media&token=ea426d5c-ef54-47be-b00f-1b5b2d877829"
        ]
    },{
        id: 'p74',
        name: 'Agility Chute',
        sku: 'agc4060',
        description: `The Agility Chute is a great way to teach backs and receivers to keep their pad level low and maintain a low center of gravity.`,
        details: [
            'Color: Orange',
            'Height: 60"',
            'Width: 40"'
        ],
        includes: [
            ``
        ],
        options: [
            `AGC4060: Agility Chute`
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'chutes',
        group: '',
        urlCategory: 'football',
        urlSubCategory: 'chutes',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fagility-chute-agc4060-HQ1.jpg?alt=media&token=de346f70-16b6-465f-a22a-239177bce978",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fagility-chute-agc4060-HQ1.jpg?alt=media&token=de346f70-16b6-465f-a22a-239177bce978",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fagility-chute-agc4060-HQ3.jpg?alt=media&token=fdf986f6-30c3-4a2f-b76c-dab6b63e12f4",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fagility-chute-agc4060-HQ4.jpg?alt=media&token=e5d0a0a1-7d58-4cfe-a5a3-024b71361c6a",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fagility-chute-agc4060-HQ2.jpg?alt=media&token=8391d5b3-eaef-4934-91fb-a4a7e767708f"
        ]
    },{
        id: 'p75',
        name: 'Adjustable Agility Chute',
        sku: 'agcadjp',
        description: `The Adjustable Agility Chute is a great way to teach backs and receivers to keep their pad level low and maintain a low center of gravity. The Adjustable Agility Chute adjusts from 28" to 60" tall, giving you the option for many different drills.`,
        details: [
            'The Adjustable Agility Chute adjusts from 28" to 60" tall, giving you the option for many different drills.',
            'Height: 28" to 60" (adjustable)',
            'Width: 40"'
        ],
        includes: [
            ``
        ],
        options: [
            `AGCADJP: Powder Coated in White, Forest, Green, Purple, Royal, Navy, Red, Maroon, Black, Orange`,
            `AGCADJN: Natural Aluminum (silver) color`
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'chutes',
        group: '',
        urlCategory: 'football',
        urlSubCategory: 'chutes',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fadjustable-agility-chute-agcadjp-HQ1.jpg?alt=media&token=8c22431c-1291-4004-9f3d-224e1e552d87",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fadjustable-agility-chute-agcadjp-HQ1.jpg?alt=media&token=8c22431c-1291-4004-9f3d-224e1e552d87",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fadjustable-agility-chute-agcadjp-HQ2.jpg?alt=media&token=3483b422-491c-4f79-b409-3c4de79e23b7",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fadjustable-agility-chute-agcadjp-HQ3.jpg?alt=media&token=c65bcdab-3e0f-4b76-87bc-4435d10f83b3"
        ]
    },
    // ,{
    //     id: 'p76',
    //     name: 'Correct Step Configurable Chute Board',
    //     sku: '9670',
    //     description: `Fisher's molded, rigid urethane foam chute boards will not warp, crack, or splinter. All boards feature side beveling to prevent ankle injuries. Our added connector holes provide stability and allow for a variety of board alignments. Various configurations allow for multiple agility and speed drills.`,
    //     details: [

    //         'Molded, rigid urethane foam chute boards will not warp, crack, or splinter',
    //         'Connector holes allow for a variety of board alignments',
    //         'Side beveling helps prevent ankle injuries',
    //         'Weight: 27 lbs'
    //     ],
    //     includes: [
    //         ``
    //     ],
    //     options: [
    //         `9670: Chute Board with "T" (3 units) : 94"x44"x2" : 27 lbs`,
    //         `9671: Long Chute Board (2 units) : 88"x12"x2" : 18 lbs`,
    //         `9672: Short Chute Board (1 unit) : 44"x12"x2" : 9 lbs`,
    //         `9690: 6" PVC Connector (pkg of 6) : 1 lb`,
    //         `9669: "T" only Chute Board : 44"x6"x2" : 6lbs`
    //     ],
    //     category: 'football',
    //     subCategory: 'chutes',
    //     group: '',
    //     urlCategory: 'football',
    //     urlSubCategory: 'chutes',
    //     image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fcorrect-step-configerable-chute-board-9670-HQ1.jpg?alt=media&token=8cd22f92-b372-403a-9a63-53a197b9d2d7",
    //     images: [
    //         "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fcorrect-step-configerable-chute-board-9670-HQ1.jpg?alt=media&token=8cd22f92-b372-403a-9a63-53a197b9d2d7",
    //         "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fcorrect-step-configerable-chute-board-9670-HQ2.jpg?alt=media&token=3c912bb5-dc79-46a3-9e53-ea30ff8e398e"
    //     ]
    // }
    {
        id: 'p77',
        name: 'Correct Step',
        sku: '9665',
        description: `Work the base step, 45 degree angle step and lateral step along with the second step to get your players off the Line of Scrimmage the correct way. A must for younger players just learning the game. Eliminates coming off the Line of Scrimmage flat footed, heel placement first, raising up, over-striding, getting feet too close together, and stepping in the center of the body. Create a 6" power step that will force players to come off their toes, not their heels or flat footed. Forces players to step at a 45 degree angle for slants and position blocks(reach block, cut off block).`,
        details: [
            'A must for younger players just learning the game',
            'Helps get your players off the L.O.S. the correct way',
            'Creates a 6" power step that will force players to come off their toes, not their heels or flat footed',
            'Weight: 15 lbs',
            'Size: 48"L x 18"W'
        ],
        includes: [
            ``
        ],
        options: [
            `9665: Correct Step`
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'chutes',
        group: '',
        urlCategory: 'football',
        urlSubCategory: 'chutes',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2F9665-correct-step-1.jpg?alt=media&token=77617f2e-b277-4878-b532-e4600964fb4e",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2F9665-correct-step-1.jpg?alt=media&token=77617f2e-b277-4878-b532-e4600964fb4e",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2F9665-correct-step-2.jpg?alt=media&token=59add6c1-281d-4d0e-80a5-3b5d2390bf5e",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2F9665-correct-step-3.jpg?alt=media&token=44bd3bdc-e6e3-4fd5-ace8-09a1a5484548",
        ]
    },{
        id: 'p78',
        name: 'Chute Boards',
        sku: '9673',
        description: `Fisher's molded, rigid urethane foam chute boards will not warp, crack, or splinter. All boards feature side beveling to prevent ankle injuries. Our added connector holes provide stability and allow for a variety of board alignments. Various configurations allow for multiple agility and speed drills.`,
        details: [
            'Folding Chute Board with handles',
            'High impact foam covered with Gold 18 oz vinyl',
            'Add custom logos or artwork (max artwork dimensions: 6"W x 36"L',
            'Size: 96"x12"x2"',
            'Weight: 12 lbs'
        ],
        includes: [
            ``
        ],
        options: [
            `9673: Folding Chute Board, 12 lbs, 96"x12"x2"`,
            `9674: Short "T", 3 lbs,44"x6"x2"`,
            `9675: "T" Set, 15 lbs, 96"x12"x2"`
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'chutes',
        group: '',
        urlCategory: 'football',
        urlSubCategory: 'chutes',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fchute-board-folding-9673-HQ1.jpg?alt=media&token=d98cdbb7-2023-464b-a523-42bab80efd38",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fchute-board-folding-9673-HQ1.jpg?alt=media&token=d98cdbb7-2023-464b-a523-42bab80efd38",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fchute-board-folding-9673-HQ2.jpg?alt=media&token=e2d477a5-8bf3-45ab-8394-9814d0a0b1aa",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fchute-board-folding-9673-HQ3.jpg?alt=media&token=8310338b-ce50-4a61-8e66-b7fc57947c7e",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fchute-board-short-t-9674-HQ1.jpg?alt=media&token=0031ca8b-486b-4d7b-bc8b-bc7b7b9e1c86"
        ]
    },{
        id: 'p79',
        name: 'Flex Chute',
        sku: 'fxc2712',
        description: `Largest Square Foot Chute in industry. Mesh top protects helmets from scratching. Chain mounting system allows for adjustment of angle. Teaches players in multiple positions to stay low. Flexibility of chute allows for multiple drills to be practiced.`,
        details: [
            `Available in 6' and 12' widths`,
            `Available in 18', 27', and 36' lengths`,
            `Height adjusts from 36" to 56"`,
            `Largest sq ft chute in the industry`,
            'Weight: 840 lbs',
            'Wheels not included',
        ],
        includes: [
            ``
        ],
        options: [
            `Flex Chute`,
            `FXC186: 18'Long x 6'Wide (620 lbs)`,
            `FXC1812: 18'Long x 12'Wide (700 lbs)`,
            `FXC276: 27'Long x 6'Wide (735 lbs)`,
            `FXC2712: 27'Long x 12'Wide (840 lbs)`,
            `FXC366: 36'Long x 6'Wide (870 lbs)`,
            `FXC3612: 36'Long x 12'Wide (995 lbs)`,
            `Accessories`,
            `FXCWK: Optional weel kit (set of 4)`,
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'chutes',
        group: '',
        urlCategory: 'football',
        urlSubCategory: 'chutes',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fflex-chute-fxc2712-HQ1.jpg?alt=media&token=f3029ed0-ce4b-459c-8238-8b41356ae23b",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fflex-chute-fxc2712-HQ1.jpg?alt=media&token=f3029ed0-ce4b-459c-8238-8b41356ae23b",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fflex-chute-fxc2712-HQ2.jpg?alt=media&token=5ceb0c64-ca51-47f0-b01a-e0ec9fda3120",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fflex-chute-fxc2712-HQ3.jpg?alt=media&token=122b8dca-3011-4123-a2cd-260aedd36097",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fflex-chute-fxc2712-HQ4.jpg?alt=media&token=d1462b45-da5f-4b1f-a019-9bafe55e0302",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fflex-chute-wheel-kit-fxcwk-HQ1.jpg?alt=media&token=5c4e97cc-eed7-4472-b62e-8db3220759bb"
        ]
    },{
        id: 'p80',
        name: 'Open Chute',
        sku: 'oc1010',
        description: `Aluminum construction with sturdy aluminum castings on all 4 corners. Telescoping legs for 10" of adjustability from 44" to 60" (without wheels). Work all directions in a good athletic football position. All sizes come with a blue vinyl mesh top.`,
        details: [
            `Blue vinyl mesh top`,
            `Aluminum construction`,
            `Telescoping legs for 10" of adjustability from 44" to 60" in height (without wheels)`,
            `Dimensions: 10' x 10'`,
            'Weight: 95 lbs',
            'Wheels not included',
        ],
        includes: [
            ``
        ],
        options: [
            `Open Chute`,
            `OC1010: 10' x 10' - (95 lbs)`,
            `OC1020: 10' x 20' - (190 lbs)`,
            `OC105: 5' x 10' - (76 lbs)`,
            `Accessories`,
            `OCWK: Wheel Kit (fits all Open Chutes)`
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'chutes',
        group: '',
        urlCategory: 'football',
        urlSubCategory: 'chutes',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fopen-chute-oc1010-HQ1.jpg?alt=media&token=84a63cad-9e9f-4019-a902-f004e4a7f1d1",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fopen-chute-oc1010-HQ1.jpg?alt=media&token=84a63cad-9e9f-4019-a902-f004e4a7f1d1",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fopen-chute-oc1010-HQ4.jpg?alt=media&token=e0ff0af5-6151-42bd-8a4a-842da5733f37",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fopen-chute-oc1010-HQ3.jpg?alt=media&token=b1b76a4d-df0a-4bb4-8a6c-4dfb50ee506d",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fopen-chute-oc105-HQ1.jpg?alt=media&token=63774e99-5e5f-4567-9478-245242e4ccb4",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fopen-chute-oc105-HQ2.jpg?alt=media&token=2be6138d-4497-4fc8-977a-236da0a2fa12",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fopen-chute-oc105-HQ3.jpg?alt=media&token=0ea2bb2e-aaac-417c-ba97-04fc782d7f93"
        ],
        videos: [
            {
                title: "Open Chute Drill Drill",
                image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Foc-video-thumbnail.jpg?alt=media&token=1f7e2f60-a33c-4bef-8da1-78599d45d039",
                src: "https://www.youtube.com/embed/QRSwKIt9SrU?si=lAoRc5kqShWyTVBy"
            },
            {
                title: "Open Chute Drill Drill",
                image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Foc-video-thumbnail.jpg?alt=media&token=1f7e2f60-a33c-4bef-8da1-78599d45d039",
                src: "https://www.youtube.com/embed/Uld1c9qd4K0?si=dxNmbMnq4IhXGfHG"
            }
        ],
    },{
        id: 'p81',
        name: 'Trap Chute',
        sku: '96520',
        description: `4' deep with adjustable height (42" to 60"). Quarterback passing drills with chute in a vertical position. One person can rotate and lock the chute angle. Durable powder coated frame. Nylon mesh "no scratch" top cover protects your players and gear.`,
        details: [
            `Durable powder coated frame`,
            `Nylon mesh "no-scratch" top cover protects your players and gear`,
            `Available in 10', 15', 20', and 25' widths`,
            `Adjustable height (42" to 60")`,
            `Dimensions: 4'W x 20L'`,
            'Weight: 500 lbs',
            'Wheels not included'
        ],
        includes: [
            ``
        ],
        options: [
            `Trap Chute`,
            `96510: 4'W x 10'L (300 lbs)`,
            `96515: 4'W x 15'L (400 lbs)`,
            `96520: 4'W x 20'L (500 lbs)`,
            `96525: 4'W x 25'L (600 lbs)`,
            `Accessories`,
            `9680: Optional Wheel Kit`
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'chutes',
        group: '',
        urlCategory: 'football',
        urlSubCategory: 'chutes',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ftrap-chute-96520-HQ1.jpg?alt=media&token=2f752ef2-17a1-40e7-b718-95f156c13cf7",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ftrap-chute-96520-HQ1.jpg?alt=media&token=2f752ef2-17a1-40e7-b718-95f156c13cf7",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ftrap-chute-96520-HQ2.jpg?alt=media&token=02ef09e8-37a0-4d91-b907-5413113df060",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ftrap-chute-96520-HQ3.jpg?alt=media&token=70df8b50-d687-4d8c-8080-09f163d247eb",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ftrap-chute-96520-HQ4.jpg?alt=media&token=5d4ab6cf-0cd5-47a5-90ff-0f7db29cc705",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ftrap-chute-96520-HQ5.jpg?alt=media&token=ba8ff447-9f15-4518-8d01-a6d0058ab801"
        ]
    },{
        id: 'p82',
        name: 'Lineman Chute',
        sku: '9635hd',
        description: `Black powder coat finish. Heavier and sturdier than competitive chutes. Double head guard helps keep player's head in proper position. Dimensions: 42" entry x 52" exit x 48" wide x 50" deep. Expandable Design. Ships truck.`,
        details: [
            `Black powder coated finish`,
            `Heavier and sturdier than competitive chutes`,
            `Double head guard helps keep player's head in proper position`,
            `Expandable design`,
            'Weight: 380 lbs'
        ],
        includes: [
            ``
        ],
        options: [
            `9631HD: 1 Man Chute 4' x 4' x 5' (108 lbs)`,
            `9632HD: 2 Man Chute 8' x 4' x 5' (176 lbs)`,
            `9633HD: 3 Man Chute 12' x 4' x 5' (244 lbs)`,
            `9634HD: 4 Man Chute 16 x 4' x 5' (312 lbs)`,
            `9635HD: 5 Man Chute 20' x 4' x 5' (380 lbs)`,
            `9636HD: 6 Man Chute 24' x 4' x 5' (448 lbs)`,
            `9637HD: 7 Man Chute 28' x 4' x 5' (516 lbs)`
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'chutes',
        group: '',
        urlCategory: 'football',
        urlSubCategory: 'chutes',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Flineman-chute-9635hd-HQ1.jpg?alt=media&token=bd8e82d7-fed7-41f3-9eb2-78ea38e413c8",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Flineman-chute-9635hd-HQ1.jpg?alt=media&token=bd8e82d7-fed7-41f3-9eb2-78ea38e413c8",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Flineman-chute-9635hd-HQ2.jpg?alt=media&token=4b387db5-e9db-49ad-a35e-57b686299bad",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Flineman-chute-9635hd-HQ3.jpg?alt=media&token=bc7be3cb-9092-4b45-b436-1d4721ea313a"
        ]
    },{
        id: 'p83',
        name: 'Power Blaster',
        sku: 'bla12hd9002m',
        description: `Teaches players to lower shoulders, explode through the defense and hold on to the ball. Great for both offensive and defensive skills. Improve quickness, agility, leg drive, ball security, and proper footwork. Increases repititions and decreases injuries. Variable resistance from 25-250 lbs per arm. Pads adjust up and down.`,
        details: [
            'A great tool for teaching player aggresiveness and the importance of covering the ball',
            `12 arms for greater resistance`,
            `Arm height adjusts 22" to 26" from ground`,
            `Arms adjust from 25 to 250 lbs resistance each`,
            `Arms adjust in tension, height, and angle`,
            `Heavy duty steel cast parts`,
            `Choose from 3 different sled pad styles`,
            `Lifetime warranty on arms`,
            `5 year warranty on Blaster`,
        ],
        includes: [
            ``
        ],
        options: [
            `BLA12HD9002M: Power Blaster`
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'blasters',
        group: '',
        urlCategory: 'football',
        urlSubCategory: 'blasters',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpower-blaster-bla12hd9002m-HD1.jpg?alt=media&token=ba845393-9fe1-4dc0-bc37-c5de62740b89",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpower-blaster-bla12hd9002m-HD1.jpg?alt=media&token=ba845393-9fe1-4dc0-bc37-c5de62740b89",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpower-blaster-bla12hd9002m-HD2.jpg?alt=media&token=3014a22c-c151-4bab-bc49-5e3cdc15265d",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpower-blaster-bla12hd9002m-HD3.jpg?alt=media&token=568c87b2-8cf7-4fa1-8a82-064e1137bea9",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpower-blaster-bla12hd9002m-HD4.jpg?alt=media&token=1673a993-267b-4eb1-afd6-dca741ea400b",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fblaster-with-boomer-sled-pads-options-HQ1.jpg?alt=media&token=5e07cd5c-81af-417d-9a05-16a14e6cf4d9"
        ]
    },{
        id: 'p84',
        name: 'Blaster 12',
        sku: `blaster12`,
        description: `Teaches players to lower shoulders, explode through the defense and hold on to the ball. Great for both offensive and defensive skills. Improve quickness, agility, leg drive, ball security, and proper footwork. Increases repititions and decreases injuries. Variable resistance from 25-250 lbs per arm. Pads adjust up and down.`,
        details: [
            'A great tool for teaching player aggresiveness and the importance of covering the ball',
            `12 arms for greater resistance`,
            `Arm height adjusts 22" to 26" from ground`,
            `Arms adjust from 25 to 250 lbs resistance each`,
            `Arms adjust in tension, height, and angle`,
            `Heavy duty steel cast parts`,
            `Lifetime warranty on arms`,
            `5 year warranty on Blaster`,
        ],
        includes: [
            ``
        ],
        options: [
            `BLASTER12: Blaster 12 (640 lbs)`
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'blasters',
        group: '',
        urlCategory: 'football',
        urlSubCategory: 'blasters',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fblaster12-HQ1.jpg?alt=media&token=267a1060-4c0e-4a5a-948b-9130719488d0",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fblaster12-HQ1.jpg?alt=media&token=267a1060-4c0e-4a5a-948b-9130719488d0",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fblaster12-HQ2.jpg?alt=media&token=28a29566-b762-41e1-98c5-f67e865dd16d"
        ]
    },{
        id: 'p85',
        name: 'Blaster with Hanging Dummy',
        sku: `bla12hd`,
        description: `Teaches players to lower shoulders, explode through the defense and hold on to the ball. Great for both offensive and defensive skills. Improve quickness, agility, leg drive, ball security, and proper footwork. Increases repititions and decreases injuries. Variable resistance from 25-250 lbs per arm. Pads adjust up and down.`,
        details: [
            'Hanging Dummy is manufactured with 18 oz coated vinyl',
            'Dummy Size: 16" Diam x 54" Tall',
            'Dummy available in (11) 18 oz vinyl colors',
            `12 arms for greater resistance`,
            `Arm height adjusts 22" to 26" from ground`,
            `Arms adjust from 25 to 250 lbs resistance each`,
            `Arms adjust in tension, height, and angle`,
            `Heavy duty steel cast parts`,
            `Lifetime warranty on arms`,
            `5 year warranty on Blaster`,
        ],
        includes: [
            ``
        ],
        options: [
            `BLA12HD: Blaster with Hanging Dummy`
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'blasters',
        group: '',
        urlCategory: 'football',
        urlSubCategory: 'blasters',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fblaster-with-hanging-dummy-bla12hd-HQ1.jpg?alt=media&token=d6c5ad30-b5a4-44ea-a4c0-0f0d44f568fb",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fblaster-with-hanging-dummy-bla12hd-HQ1.jpg?alt=media&token=d6c5ad30-b5a4-44ea-a4c0-0f0d44f568fb",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fblaster-with-hanging-dummy-bla12hd-HQ2.jpg?alt=media&token=093ce818-882c-4d9a-8099-a6da891b9fe9"
        ]
    },{
        id: 'p86',
        name: 'Blaster with Boomer Sled Pad',
        sku: `bla129002`,
        description: `Teaches players to lower shoulders, explode through the defense and hold on to the ball. Great for both offensive and defensive skills. Improve quickness, agility, leg drive, ball security, and proper footwork. Increases repititions and decreases injuries. Variable resistance from 25-250 lbs per arm. Pads adjust up and down.`,
        details: [
            `12 arms for greater resistance`,
            `Arm height adjusts 22" to 26" from ground`,
            `Arms adjust from 25 to 250 lbs resistance each`,
            `Arms adjust in tension, height, and angle`,
            `Heavy duty steel cast parts`,
            `Choose from 3 different sled pad styles`,
            `Lifetime warranty on arms`,
            `5 year warranty on Blaster`,
        ],
        includes: [
            ``
        ],
        options: [
            `BLA129002M: Man Pad`,
            `BLA129002T: "T" Pad`,
            `BLA129002C: Cone Pad`
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'blasters',
        group: '',
        urlCategory: 'football',
        urlSubCategory: 'blasters',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fblaster-with-boomer-sled-pads-bla129002-HQ1.jpg?alt=media&token=a1e0e11c-aaa7-44c8-ba82-51b1fb01dfd1",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fblaster-with-boomer-sled-pads-bla129002-HQ1.jpg?alt=media&token=a1e0e11c-aaa7-44c8-ba82-51b1fb01dfd1",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fblaster-with-boomer-sled-pads-bla129002-HQ2.jpg?alt=media&token=fb2d91b5-f896-4b63-b0d1-b3df29b4fc39",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fblaster-with-boomer-sled-pads-options-HQ1.jpg?alt=media&token=5e07cd5c-81af-417d-9a05-16a14e6cf4d9"
        ]
    },{
        id: 'p87',
        name: 'Blaster',
        sku: `blaster`,
        description: `Arms adjust from 25 to 250 lbs each. Arms can be locked back out of the way. Arms adjustable in tension, height and angle. Arms won't break and fall off. Heavy duty steel cast parts. Once properly assembled and installed, the original Smitty's Blaster will provide years of trouble-free service. Due to its size, the Blaster is shipped disassembled, however complete and easy to follow instructions are included. To assure that your Blaster will always be in top notch condition, we carry a complete inventory of replacement parts. To order a Blaster parts list call 1-800-438-6028. The Blaster is shipped Freight On Board Salisbury, NC.`,
        details: [
            `Easy to set up`,
            `Arms adjust from 25 to 250 lbs each`,
            `Lifetime warranty on arms`,
            `5 year warranty on Blaster`,
        ],
        includes: [
            ``
        ],
        options: [
            `BLASTER: Blaster`
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'blasters',
        group: '',
        urlCategory: 'football',
        urlSubCategory: 'blasters',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fblaster-HQ1.jpg?alt=media&token=82c8d10d-e197-4b7a-aa9d-1131f6a9eb99",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fblaster-HQ1.jpg?alt=media&token=82c8d10d-e197-4b7a-aa9d-1131f6a9eb99",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fblaster-HQ2.jpg?alt=media&token=518c2140-c87e-4d79-b72d-4d9956b9e11a"
        ]
    },{
        id: 'p88',
        name: 'Power Frame',
        sku: `pf1021654`,
        description: `Beam height adjusts from 76" to 92". Easy set up. Heavy duty steel construction. Three different dummy options. Never-Go-Flat Wheels.`,
        details: [
            `Beam height adjusts from 76" to 92"`,
            `Easy set up`,
            `Heavy duty steel construction`,
            `Available in 10' and 20' widths`,
            `Three different dummy options`,
            `Never-Go-Flat wheels`,
        ],
        includes: [
            ``
        ],
        options: [
            `PF1011654: 10ft frame + (1) 16"x54" Hanging Dummy (500 lbs)`,
            `PF1011872: 10ft frame + (1) 18"x72" Hanging Dummy (520 lbs)`,
            `PF1013860: 10ft frame + (1) 38"x60" Hanging Dummy (755 lbs)`,
            `PF1021654: 10ft frame + (2) 16"x54" Hanging Dummy (570 lbs)`,
            `PF1021872: 10ft frame + (2) 18"x72" Hanging Dummy (610 lbs)`,
            `PF2031654: 20ft frame + (3) 16"x54" Hanging Dummy (730 lbs)`,
            `PF2031872: 20ft frame + (3) 18"x72" Hanging Dummy (790 lbs)`,
            `PF2041654: 20ft frame + (4) 16"x54" Hanging Dummy (800 lbs)`,
            `PF2041872: 20ft frame + (4) 18"x72" Hanging Dummy (880 lbs)`,
            `FHD1654: "Fullback" Dummy (70 lbs) 16" Diam x 54" Tall`,
            `FHD1872: "Linebacker" Dummy (90 lbs) 18" Diam x 72" Tall`,
            `FHD3860: "Bad" Dummy (325 lbs) 38" Diam x 60" Tall`
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'blasters',
        group: '',
        urlCategory: 'football',
        urlSubCategory: 'blasters',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpower-frame-pf1021654-HQ1.jpg?alt=media&token=8b51e9f9-a5bd-4cd4-8a3a-5b9807b069f7",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpower-frame-pf1021654-HQ1.jpg?alt=media&token=8b51e9f9-a5bd-4cd4-8a3a-5b9807b069f7",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpower-frame-pf1021654-HQ2.jpg?alt=media&token=fe3ccf4f-977a-405a-8ad6-2ec4a50cecae",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpower-frame-pf1021654-HQ3.jpg?alt=media&token=5b6f205e-a078-4428-b57c-2c72c6cc41d3"
        ]
    },{
        id: 'p89',
        name: 'The Club',
        sku: 'hd1500',
        description: `Practice live drills with maximum protection. Defensive lineman practice Swim/Rip/Chop. Arm slides into pad with handle inside. Individual. 24" L x 9-1/2" outside diameter.`,
        details: [
            'Practice live drills with maximum protection',
            'Defensive linemen practice - Swim/Rip/Chop',
            'Arm slides into pad with handles inside',
            'Standard color: red w/ black tip',
            '24" Long x 9 1/2" Outside Diam'
        ],
        includes: [
            ``
        ],
        options: [
            `HD1500: Arm Shield`
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'dummies strength and speed',
        group: '',
        urlCategory: 'football',
        urlSubCategory: 'dummies',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fthe-club-hd1500pr-HQ1.jpg?alt=media&token=c634d899-742b-4395-a688-d4536ee63ac4",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fthe-club-hd1500pr-HQ1.jpg?alt=media&token=c634d899-742b-4395-a688-d4536ee63ac4",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fthe-club-hd1500pr-HQ2.jpg?alt=media&token=dd04d929-0f67-42d3-b271-c34eee705139",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fthe-club-hd1500pr-HQ3.jpg?alt=media&token=2156b77e-0fd0-4d34-9d2b-932a6096fb42"
        ]
    },{
        id: 'p90',
        name: 'Combat Club',
        sku: 'hd3500',
        description: `Improve lineman and skill position technique and help test ball security. Five strategic handle grips. 72"L x 10"Dia.`,
        details: [
            'Improve lineman and skill position technique',
            'Help test ball security',
            'Five strategic handle grips',
            '72"L x 10"Diam',
            'Weight: 7 lbs'
        ],
        includes: [
            ``
        ],
        options: [
            `HD3500: Combat Club 72"L x 10"Diam. (7 lbs)`
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'dummies',
        group: '',
        urlCategory: 'football',
        urlSubCategory: 'dummies',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fcombat-club-hd3500-HQ1.jpg?alt=media&token=be73607f-fb88-40ab-86fd-d46390579411",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fcombat-club-hd3500-HQ1.jpg?alt=media&token=be73607f-fb88-40ab-86fd-d46390579411",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fcombat-club-hd3500-HQ2.jpg?alt=media&token=0950ff66-8eb4-42ec-a338-e3f18b34a0a8",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fhd3500-combat-club-action.jpg?alt=media&token=8c628cbe-d333-4fd1-b54e-dd44b3d835ac",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fcombat-club-hd3500-HQ3.jpg?alt=media&token=7966aade-ef47-4797-89b1-96c457d52438",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fcombat-club-hd3500-HQ4.jpg?alt=media&token=384f6d7e-b9b6-4f3f-920a-e4f9c56458a0"
        ],
        
    },{
        id: 'p91',
        name: 'Utility Stick',
        sku: 'us326',
        description: `Teaches players proper ball holding technique. The Utility Stick can be used for fumble drills to knock the ball loose. It can also be used for passing drills to extend a defenders reach.`,
        details: [
            `32"L x 6"Diam polyfoam`,
            `18"L rubber handle grip`,
            `Available in 18oz royal blue`,
        ],
        includes: [
            ``
        ],
        options: [
            `US326: Utility Stick 32"L x 6"Diam.`
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'dummies',
        group: '',
        urlCategory: 'football',
        urlSubCategory: 'dummies',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Futility-stick-us326-HQ1.jpg?alt=media&token=df4099f8-ab84-41fb-b1cd-a569b40ba3d5",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Futility-stick-us326-HQ1.jpg?alt=media&token=df4099f8-ab84-41fb-b1cd-a569b40ba3d5",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Futility-stick-us326-HQ2.jpg?alt=media&token=a4b5844b-da41-4ef0-8718-86deaef1ee29"
        ]
    },{
        id: 'p92',
        name: 'Curved Body Shield',
        sku: 'hd100',
        description: `Shield and Dummies designed especially for your junior football program. Maximum protection for the upper body. Excellent for training with restrained contact. Built using durable polyfoam and 18 oz. coated vinyl. 2 padded web handles.`,
        details: [
            `Suitable for junior high through college`,
            `Maximum protection for the upper body`,
            `Excellent for training with restrained contact`,
            `Built using durable polyfoam and 18oz coated vinyl`,
            `2 padded web handles`,
            `Dimensions: 24" x 16" x 4"`,
            `Weight: 3 lbs`,
        ],
        includes: [
            ``
        ],
        options: [
            `HD100: Curved Body Shield 24" x 16" x 4" (3 lbs)`
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'dummies youth football',
        group: 'Body Shields',
        urlCategory: 'football',
        urlSubCategory: 'dummies',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fcurved-body-shield-hd100-HQ4.jpg?alt=media&token=24ffc88c-b55d-425d-842f-f88209be8426",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fcurved-body-shield-hd100-HQ4.jpg?alt=media&token=24ffc88c-b55d-425d-842f-f88209be8426",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fcurved-body-shield-hd100-HQ1.jpg?alt=media&token=39212b96-a268-42bc-b97d-27c7436fcbc4",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fcurved-body-shield-hd100-HQ2.jpg?alt=media&token=e5e797a6-db1c-4802-ab36-6184dea6c483",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fcurved-body-shield-hd100-HQ3.jpg?alt=media&token=57501ac4-00d7-4789-89fe-8ae6932902e7"
        ]
    },{
        id: 'p93',
        name: 'Atlantic Curved Body Shield',
        sku: 'hd200',
        description: `Two padded web handles. Suitable for college and advanced high school practice. Heavier weight bonded foam. Web handles with foam inserts for a sure grip. Box "X" tack with vinyl reinforced backs. Die cut 100 Indentation Load Deflection foam keeps its shape for years. Block numbers provide target points.`,
        details: [
            `2 padded web handles`,
            `Suitable for college and advanced high school practice`,
            `Heavier weight bonded foam`,
            `Dimensions: 32" x 17" x 4"`,
            `Weight: 8 lbs`,
        ],
        includes: [
            ``
        ],
        options: [
            `HD200: Atlantic Curved Body Shield 32" x 17" x 4" (8 lbs)`
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'dummies youth football',
        group: 'Body Shields',
        urlCategory: 'football',
        urlSubCategory: 'dummies',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fatlantic-curved-body-shield-hd200-HQ3.jpg?alt=media&token=6059d8f2-74c8-479e-aefa-70631ce3ea2b",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fatlantic-curved-body-shield-hd200-HQ3.jpg?alt=media&token=6059d8f2-74c8-479e-aefa-70631ce3ea2b",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fatlantic-curved-body-shield-hd200-HQ1.jpg?alt=media&token=eece62ec-47aa-4f8c-ac26-ed4d4cea1000",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fatlantic-curved-body-shield-hd200-HQ2.jpg?alt=media&token=ae5b6139-d86e-44b8-98af-d828cd09c6bc"
        ]
    },{
        id: 'p94',
        name: 'Curved Body Shield',
        sku: 'hd150',
        description: `Shield and Dummies designed especially for your junior football program. Maximum protection for the upper body. Excellent for training with restrained contact. Built using durable polyfoam and 18 oz. coated vinyl. 2 padded web handles.`,
        details: [
            `Suitable for junior high through college`,
            `Maximum protection for the upper body`,
            `Excellent for training with restrained contact`,
            `Built using durable polyfoam and 18oz coated vinyl`,
            `2 padded web handles`,
            `Dimensions: 30" x 18" x 4"`,
            `Weight: 4 lbs`,
        ],
        includes: [
            ``
        ],
        options: [
            `HD150: Curved Body Shield 30" x 18" x 4" (4 lbs)`
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'dummies',
        group: 'Body Shields',
        urlCategory: 'football',
        urlSubCategory: 'dummies',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fcurved-body-shield-hd150-HQ3.jpg?alt=media&token=8a09af63-434a-4f40-84a5-ec241faba230",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fcurved-body-shield-hd150-HQ3.jpg?alt=media&token=8a09af63-434a-4f40-84a5-ec241faba230",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fcurved-body-shield-hd150-HQ1.jpg?alt=media&token=035be636-e03e-435b-a6e2-4efa564ff679",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fcurved-body-shield-hd150-HQ2.jpg?alt=media&token=30087df9-d909-409d-a816-0883acc7131a"
        ]
    },{
        id: 'p95',
        name: 'Jumbo Curved Shield',
        sku: '10003',
        description: `Extra large design gives even more protection. Rugged construction ensures safety and durability. Builds confidence . Larger size protects players while allowing more intense contact during drills. 3 Handles for stability. Web handles with foam inserts for a sure grip. Box "X" tack with vinyl reinforced backs. Die cut 100 Indentation Load Deflection foam keeps its shape for years. Block numbers provide target points.`,
        details: [
            `Extra large design gives even more protection`,
            `Rugged construction ensures safety and durability`,
            `Builds confidence`,
            `Larger size protects players while allowing more intense contact during drills`,
            `3 handles for stability`,
            `Dimensions: 36" x 22" x 5"`,
            `Weight: 6 lbs`,
        ],
        includes: [
            ``
        ],
        options: [
            `10003: Jumbo Curved Shield 36" x 22" x 5" (6 lbs)`
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'dummies',
        group: 'Body Shields',
        urlCategory: 'football',
        urlSubCategory: 'dummies',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fjumbo-curved-shield-10003-HQ3.jpg?alt=media&token=3b681042-1b74-4aae-a4d0-711b48ab6887",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fjumbo-curved-shield-10003-HQ3.jpg?alt=media&token=3b681042-1b74-4aae-a4d0-711b48ab6887",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fjumbo-curved-shield-10003-HQ1.jpg?alt=media&token=94309b95-3132-4e61-a771-c6e0640e4a2c",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fjumbo-curved-shield-10003-HQ2.jpg?alt=media&token=a5b4d582-3903-41b9-b653-1db02b24b4f2"
        ]
    },{
        id: 'p96',
        name: 'Rectangular Body Shield',
        sku: 'hd505',
        description: `Extra large design gives even more protection. Rugged construction ensures safety and durability. Builds confidence . Larger size protects players while allowing more intense contact during drills. 3 Handles for stability. Web handles with foam inserts for a sure grip. Box "X" tack with vinyl reinforced backs. Die cut 100 Indentation Load Deflection foam keeps its shape for years. Block numbers provide target points.`,
        details: [
            `Ideal for junior and senior high schools`,
            `Light and maneuverable`,
            `3 handles for stability`,
            `Dimensions: 25" x 20" x 6"`,
            `Weight: 5 lbs`,
        ],
        includes: [
            ``
        ],
        options: [
            `HD505: 25" x 20" x 6 (5 lbs)`,
            `HD505-3: 25" x 20" x 3 (2 lbs)`,
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'dummies youth football',
        group: 'Body Shields',
        urlCategory: 'football',
        urlSubCategory: 'dummies',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Frectangular-body-shield-hd505-HQ2.jpg?alt=media&token=722c1482-77a5-4c00-b253-78c2224a2a00",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Frectangular-body-shield-hd505-HQ2.jpg?alt=media&token=722c1482-77a5-4c00-b253-78c2224a2a00",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Frectangular-body-shield-hd505-HQ1.jpg?alt=media&token=26840a75-4890-455f-b1e8-1fb08d6ba519"
        ]
    },{
        id: 'p97',
        name: 'Contour Body Shield',
        sku: 'hd104',
        description: `Easily absorbs tough blows . 6' thick polyfoam. 3 handles for better control.`,
        details: [
            `Easily absorbs tough blows`,
            `6" thick polyfoam`,
            `3 handles for better control`,
            `Dimensions: 31" x 21" x 6"`,
            `Weight: 5 lbs`,
        ],
        includes: [
            ``
        ],
        options: [
            `HD104: Contour Body Shield 31" x 21" x 6" (5 lbs)`
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'dummies youth football',
        group: 'Body Shields',
        urlCategory: 'football',
        urlSubCategory: 'dummies',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fcontour-body-shield-hd104-HQ2.jpg?alt=media&token=02f02e82-39ce-4b92-b009-acb0a5606407",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fcontour-body-shield-hd104-HQ2.jpg?alt=media&token=02f02e82-39ce-4b92-b009-acb0a5606407",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fcontour-body-shield-hd104-HQ1.jpg?alt=media&token=db07f727-71c3-4f0e-82fd-7e9a37b92ca0"
        ]
    },{
        id: 'p98',
        name: 'Big Beulah Body Shield',
        sku: 'hd500',
        description: `Easily absorbs tough blows . 6' thick polyfoam. 3 handles for better control.`,
        details: [
            `Light and maneuverable`,
            `Creates larger hitting surface`,
            `Designed by college coaches`,
            `3 handles for better control`,
            `Ideal for linebacker drills`,
            `Reduces player injuries`,
            `Dimensions: 36" x 24" x 8"`,
            `Weight: 8 lbs`,
        ],
        includes: [
            ``
        ],
        options: [
            `HD500: Big Beulah Body Shield 36" x 24" x 8" (8 lbs)`
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'dummies',
        group: 'Body Shields',
        urlCategory: 'football',
        urlSubCategory: 'dummies',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fbig-beulah-body-shield-hd500-HQ2.jpg?alt=media&token=ba0bbae5-9311-415e-9359-c4f4f50a0753",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fbig-beulah-body-shield-hd500-HQ2.jpg?alt=media&token=ba0bbae5-9311-415e-9359-c4f4f50a0753",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fbig-beulah-body-shield-hd500-HQ1.jpg?alt=media&token=6d881fdb-c5a7-43cd-b5d2-8d731279b0c9"
        ]
    },{
        id: 'p99',
        name: 'T Shield',
        sku: 'hd130',
        description: `Large hitting surface for linebacker drills or offensive line passing drills. Allows the hitting player to force upwards developing proper technique. 3 stabilizing handles.`,
        details: [
            `Large hitting surface for linebacker drills or offensive line passing drills`,
            `Allows the hitting player to force upwards developing proper technique`,
            `3 stabilizing handles`,
            `Dimensions: 32" x 24" x 5"`,
            `Weight: 10 lbs`,
        ],
        includes: [
            ``
        ],
        options: [
            `HD130: T Shield 32" x 24" x 5" (10 lbs)`
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'dummies',
        group: 'Body Shields',
        urlCategory: 'football',
        urlSubCategory: 'dummies',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ft-shield-hd130-HQ2.jpg?alt=media&token=8ef383ca-d121-4242-a620-0e3133e43871",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ft-shield-hd130-HQ2.jpg?alt=media&token=8ef383ca-d121-4242-a620-0e3133e43871",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ft-shield-hd130-HQ1.jpg?alt=media&token=fd65007f-2af2-4cd2-b24f-c5d4ee9ad747"
        ]
    },{
        id: 'p100',
        name: 'Man Shield',
        sku: 'hd800',
        description: `Man shaped dummy for more realistic drills. Easy to hold and control.`,
        details: [
            `Man shaped dummy for more realistic drills`,
            `Easy to hold and control`,
            `Dimensions: 37" x 31" x 4"`,
            `Weight: 5 lbs`,
        ],
        includes: [
            ``
        ],
        options: [
            `HD800: Man Shield 37" x 31" x 4" (5 lbs)`
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'dummies youth football',
        group: 'Body Shields',
        urlCategory: 'football',
        urlSubCategory: 'dummies',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fman-shield-hd800-HQ2.jpg?alt=media&token=cf48fbab-ace4-4b82-9061-2ea6ed75584c",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fman-shield-hd800-HQ2.jpg?alt=media&token=cf48fbab-ace4-4b82-9061-2ea6ed75584c",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fman-shield-hd800-HQ1.jpg?alt=media&token=b4c1424d-5763-43c5-bfca-a4b9fdb8271e"
        ]
    },{
        id: 'p101',
        name: 'Man Shield',
        sku: 'hd700',
        description: `Man shaped dummy for more realistic drills. Easy to hold and control.`,
        details: [
            `Teaches proper technique for controlling opponents`,
            `3 handles for better control`,
            `Dimensions: 29" x 22" x 5"`,
            `Weight: 4 lbs`,
        ],
        includes: [
            ``
        ],
        options: [
            `HD700: Man Shield 29" x 22" x 5" (4 lbs)`
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'dummies youth football',
        group: 'Body Shields',
        urlCategory: 'football',
        urlSubCategory: 'dummies',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fman-shield-hd700-HQ2.jpg?alt=media&token=2a6077a7-bb8f-48a4-95d4-51dbd2a5b127",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fman-shield-hd700-HQ2.jpg?alt=media&token=2a6077a7-bb8f-48a4-95d4-51dbd2a5b127",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fman-shield-hd700-HQ1.jpg?alt=media&token=9fa2ee12-b97e-425c-8855-b052cf69fb85"
        ]
    },{
        id: 'p102',
        name: 'Full Body Shield',
        sku: 'hd306',
        description: `Protects chest and knees. Allows use of knees for increased resistance. Prevents "hip pointers".`,
        details: [
            `Great for use during lineman chute drills`,
            `Protects chest and knees`,
            `Allows use of knees for increased resistance`,
            `Prevents "hip pointers"`,
            `Dimensions: 48" x 18" x 6"`,
            `Weight: 8 lbs`,
        ],
        includes: [
            ``
        ],
        options: [
            `HD306: Full Body Shield (6" thick)`,
            `HD300: Full Body Shield (4" thick)`
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'dummies',
        group: 'Body Shields',
        urlCategory: 'football',
        urlSubCategory: 'dummies',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ffull-body-shield-hd306-HQ2.jpg?alt=media&token=43c00c10-9b9f-4421-a8de-85d4eb0fea6b",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ffull-body-shield-hd306-HQ2.jpg?alt=media&token=43c00c10-9b9f-4421-a8de-85d4eb0fea6b",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ffull-body-shield-hd306-HQ1.jpg?alt=media&token=d19c716c-2182-4d55-b89f-0c0054567ed1"
        ]
    },{
        id: 'p103',
        name: 'Full Body Shield',
        sku: 'hd320',
        description: `6' high density foam for maximum protection. Large 21' x 48' shield covers more exposed areas during practice. Additional outside handles for lineman drills. Upper body target for realistic target area.`,
        details: [
            `6" high density foam for maximum protection`,
            `Large 21"W x 48"H shield covers more exposed areas during practice`,
            `Additional outside handles for lineman drills`,
            `Upper body target for realistic target area`,
            `Dimensions: 48" x 21" x 6"`,
            `Weight: 8 lbs`,
        ],
        includes: [
            ``
        ],
        options: [
            `HD320: Full Body Shield 48" x 21" x 6" (8 lbs)`
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'dummies',
        group: 'Body Shields',
        urlCategory: 'football',
        urlSubCategory: 'dummies',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ffull-body-shield-hd320-HQ1.jpg?alt=media&token=363bfaba-13c8-4621-ab7c-4a5b86343fd4",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ffull-body-shield-hd320-HQ1.jpg?alt=media&token=363bfaba-13c8-4621-ab7c-4a5b86343fd4",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ffull-body-shield-hd320-HQ2.jpg?alt=media&token=012f0b5a-291f-409c-8054-05df9453d0b2",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ffull-body-shield-hd320-HQ3.jpg?alt=media&token=c0edf8d8-95f7-43c6-a81f-c483d7a26290"
        ]
    },{
        id: 'p104',
        name: 'Curved Forearm Shield',
        sku: 'hd400',
        description: `Popular for linemen and linebackers. Ideal for all levels of play. Maximum protection for hand and forearm. No shifting on the body. Freedom of movement. 2 heavy duty Flex Straps.`,
        details: [
            `Popular for linemen and linebackers`,
            `Ideal for all levels of play`,
            `Maximum protection for hand and forearm`,
            `No shifting on the body`,
            `Freedom of movement`,
            `2 heavy duty Flex Straps`,
            `Dimensions: 20" x 15" x 3"`,
            `Weight: 2 lbs`,
        ],
        includes: [
            ``
        ],
        options: [
            `HD400: Curved Forearm Shield (adult size)`,
            `HD400JR: Curved Forearm Shield (junior size)`
        ],
        usedBy: [],
        category: 'football gym',
        subCategory: 'dummies youth football training items',
        group: 'Body Shields',
        urlCategory: 'football gym',
        urlSubCategory: 'dummies youth football training items',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fcurved-forearm-shield-hd400-HQ1.jpg?alt=media&token=2db00bc7-d5c1-4af1-b203-ef932ffee995",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fcurved-forearm-shield-hd400-HQ1.jpg?alt=media&token=2db00bc7-d5c1-4af1-b203-ef932ffee995",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fcurved-forearm-shield-hd400-HQ2.jpg?alt=media&token=bd776ba9-6630-429d-8790-64a48498d3fe",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fcurved-forearm-shield-hd400-HQ3.jpg?alt=media&token=a5e699c8-4a28-4d4b-8436-c8aa9f3a3d73",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fcurved-forearm-shield-hd400-HQ4.jpg?alt=media&token=d9675e7e-0d5e-40e0-9e48-557427bbed1c"
        ]
    },{
        id: 'p105',
        name: 'Pummel Shield',
        sku: 'hd600',
        description: `Allows players to grasp the shield to simulate live game action. Soft two layer foam to protect players hands. Flexible - allows players to move and react during drills.`,
        details: [
            `Allows players to grasp the shield to simulate live game action`,
            `Soft two layer foam to protect players hands`,
            `Flexible - allows players to move and react during drills`,
            `Dimensions: 24" x 19" x 3"`,
            `Front Pad: 3" thick`
        ],
        includes: [
            ``
        ],
        options: [
            `HD600: Pummel Shield 24" x 19" x 3"`
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'dummies',
        group: 'Body Shields',
        urlCategory: 'football',
        urlSubCategory: 'dummies',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpummel-shield-hd600-HQ1.jpg?alt=media&token=0eefebcd-6d2c-42f0-8ed8-d6522f4746fd",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpummel-shield-hd600-HQ1.jpg?alt=media&token=0eefebcd-6d2c-42f0-8ed8-d6522f4746fd",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpummel-shield-hd600-HQ2.jpg?alt=media&token=b4b7c9d0-d302-4b65-99c4-1ea47538aa35"
        ]
    },{
        id: 'p106',
        name: 'Strike Vest',
        sku: 'agsv',
        description: `Provides excellent body protection and lets players engage with their hands free. Defensive backs can work on jam techniques and wide receivers can work on release techniques. The Strike Vest is fully adjustable in both shoulders and waist.`,
        details: [
            `Provides excellent body protection`,
            `Lets players engage with their hands free`,
            `Defensive backs work on jam techniques`,
            `Wide receivers work on release techniques`,
            `Fully adjustable in both shoulders and waist`,
            `Front Pad: 12" x 14" x 4"`
        ],
        includes: [
            ``
        ],
        options: [
            `AGSV: Strike Vest`
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'dummies',
        group: '',
        urlCategory: 'football',
        urlSubCategory: 'dummies',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fstrike-vest-agsv-HQ1.jpg?alt=media&token=693ffbd7-ff6c-42c1-901e-41c3cb3fafb8",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fstrike-vest-agsv-HQ1.jpg?alt=media&token=693ffbd7-ff6c-42c1-901e-41c3cb3fafb8",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fstrike-vest-agsv-HQ2.jpg?alt=media&token=045a69cc-6456-44f7-8d18-35fb62b6e47b",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fstrike-vest-agsv-HQ3.jpg?alt=media&token=5a84c225-2def-4653-8bf5-241486a64016",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fstrike-vest-agsv-HQ4.jpg?alt=media&token=45bd3327-d7d3-4cc7-8b28-e416aa4b4065"
        ]
    },{
        id: 'p107',
        name: 'Stand Up Dummy',
        sku: 'sud4214',
        description: `Rolled polyfoam core - won't sag with age. 18-ounce double-sided vinyl coated nylon. Double ply, multiple stitched handles. Twin-stitched, double thick bottoms.`,
        details: [
            `Rolled polyfoam core - won't sag with age`,
            `Double ply, multiple stitched handles`,
            `18 oz double-sided vinyl coated nylon`,
            `Twin-stitched, double thick bottoms`,
            `Weight: 16 lbs`,
            `Dimensions: 42" Tall x 14" Dia`
        ],
        includes: [
            ``
        ],
        options: [
            `SUD4214: Stand Up Dummy 42" Tall x 14" Dia (16 lbs)`
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'dummies youth football',
        group: 'Stand Up Dummies',
        urlCategory: 'football',
        urlSubCategory: 'dummies',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fstand-up-dummy-sud4214-HQ1.jpg?alt=media&token=526c37a0-8a73-4752-bec9-8076caa16a6c",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fstand-up-dummy-sud4214-HQ1.jpg?alt=media&token=526c37a0-8a73-4752-bec9-8076caa16a6c",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fstand-up-dummy-sud4214-HQ2.jpg?alt=media&token=5f2756a9-ab9f-466f-ba4b-b66060e26920"
        ]
    },{
        id: 'p108',
        name: 'Stand Up Dummy',
        sku: 'sud4814',
        description: `Rolled polyfoam core - won't sag with age. 18-ounce double-sided vinyl coated nylon. Double ply, multiple stitched handles. Twin-stitched, double thick bottoms.`,
        details: [
            `Rolled polyfoam core - won't sag with age`,
            `Double ply, multiple stitched handles`,
            `18 oz double-sided vinyl coated nylon`,
            `Twin-stitched, double thick bottoms`,
            `Weight: 18 lbs`,
            `Dimensions: 48" Tall x 14" Dia`
        ],
        includes: [
            ``
        ],
        options: [
            `SUD4814: Stand Up Dummy 48" Tall x 14" Dia (18 lbs)`
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'dummies youth football',
        group: 'Stand Up Dummies',
        urlCategory: 'football',
        urlSubCategory: 'dummies',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fstand-up-dummy-sud4814-HQ1.jpg?alt=media&token=4072cc04-3896-4be9-a705-5804daf55c7b",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fstand-up-dummy-sud4814-HQ1.jpg?alt=media&token=4072cc04-3896-4be9-a705-5804daf55c7b",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fstand-up-dummy-sud4814-HQ2.jpg?alt=media&token=dc267647-b1d9-4b65-9da0-91acc1a65d87"
        ]
    },{
        id: 'p109',
        name: 'Stand Up Dummy',
        sku: 'sud5414',
        description: `Rolled polyfoam core - won't sag with age. 18-ounce double-sided vinyl coated nylon. Double ply, multiple stitched handles. Twin-stitched, double thick bottoms.`,
        details: [
            `Rolled polyfoam core - won't sag with age`,
            `Double ply, multiple stitched handles`,
            `18 oz double-sided vinyl coated nylon`,
            `Twin-stitched, double thick bottoms`,
            `Weight: 20 lbs`,
            `Dimensions: 54" Tall x 14" Dia`
        ],
        includes: [
            ``
        ],
        options: [
            `SUD5414: Stand Up Dummy 54" Tall x 14" Dia (20 lbs)`
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'dummies',
        group: 'Stand Up Dummies',
        urlCategory: 'football',
        urlSubCategory: 'dummies',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fstand-up-dummy-sud5414-HQ1.jpg?alt=media&token=3f6cc3b2-aa1c-4487-86dd-f01556d9965b",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fstand-up-dummy-sud5414-HQ1.jpg?alt=media&token=3f6cc3b2-aa1c-4487-86dd-f01556d9965b",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fstand-up-dummy-sud5414-HQ2.jpg?alt=media&token=e5acd747-b804-4f7e-9342-5a23360cc5ce"
        ]
    },{
        id: 'p110',
        name: 'Stand Up Dummy',
        sku: 'sud4216',
        description: `Rolled polyfoam core - won't sag with age. 18-ounce double-sided vinyl coated nylon. Double ply, multiple stitched handles. Twin-stitched, double thick bottoms.`,
        details: [
            `Rolled polyfoam core - won't sag with age`,
            `Double ply, multiple stitched handles`,
            `18 oz double-sided vinyl coated nylon`,
            `Twin-stitched, double thick bottoms`,
            `Weight: 22 lbs`,
            `Dimensions: 42" Tall x 16" Dia`
        ],
        includes: [
            ``
        ],
        options: [
            `SUD4216: Stand Up Dummy 42" Tall x 16" Dia (22 lbs)`
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'dummies',
        group: 'Stand Up Dummies',
        urlCategory: 'football',
        urlSubCategory: 'dummies',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fstand-up-dummy-sud4216-HQ1.jpg?alt=media&token=bd04c522-9736-45ec-9382-5da836c05980",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fstand-up-dummy-sud4216-HQ1.jpg?alt=media&token=bd04c522-9736-45ec-9382-5da836c05980",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fstand-up-dummy-sud4216-HQ2.jpg?alt=media&token=889818cf-920d-47c5-8f73-5eb0923f7fe2"
        ]
    },{
        id: 'p111',
        name: 'Stand Up Dummy',
        sku: 'sud4816',
        description: `Rolled polyfoam core - won't sag with age. 18-ounce double-sided vinyl coated nylon. Double ply, multiple stitched handles. Twin-stitched, double thick bottoms.`,
        details: [
            `Rolled polyfoam core - won't sag with age`,
            `Double ply, multiple stitched handles`,
            `18 oz double-sided vinyl coated nylon`,
            `Twin-stitched, double thick bottoms`,
            `Weight: 25 lbs`,
            `Dimensions: 48" Tall x 16" Dia`
        ],
        includes: [
            ``
        ],
        options: [
            `SUD4816: Stand Up Dummy 48" Tall x 16" Dia (25 lbs)`
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'dummies',
        group: 'Stand Up Dummies',
        urlCategory: 'football',
        urlSubCategory: 'dummies',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fstand-up-dummy-sud4816-HQ1.jpg?alt=media&token=24ac1fc2-981a-4ea4-a4ec-358d81de8ba7",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fstand-up-dummy-sud4816-HQ1.jpg?alt=media&token=24ac1fc2-981a-4ea4-a4ec-358d81de8ba7",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fstand-up-dummy-sud4816-HQ2.jpg?alt=media&token=1b26c8d1-731c-4ddd-9804-c68c25c14140"
        ]
    },{
        id: 'p112',
        name: 'Stand Up Dummy',
        sku: 'sud5416',
        description: `Rolled polyfoam core - won't sag with age. 18-ounce double-sided vinyl coated nylon. Double ply, multiple stitched handles. Twin-stitched, double thick bottoms.`,
        details: [
            `Rolled polyfoam core - won't sag with age`,
            `Double ply, multiple stitched handles`,
            `18 oz double-sided vinyl coated nylon`,
            `Twin-stitched, double thick bottoms`,
            `Weight: 27 lbs`,
            `Dimensions: 54" Tall x 16" Dia`
        ],
        includes: [
            ``
        ],
        options: [
            `SUD5416: Stand Up Dummy 54" Tall x 16" Dia (27 lbs)`
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'dummies',
        group: 'Stand Up Dummies',
        urlCategory: 'football',
        urlSubCategory: 'dummies',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fstand-up-dummy-sud5416-HQ1.jpg?alt=media&token=488af740-f932-4743-a7a9-f7bb4ba418bb",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fstand-up-dummy-sud5416-HQ1.jpg?alt=media&token=488af740-f932-4743-a7a9-f7bb4ba418bb"
        ]
    },{
        id: 'p113',
        name: 'Stand Up Dummy',
        sku: 'sud7516',
        description: `Rolled polyfoam core - won't sag with age. 18-ounce double-sided vinyl coated nylon. Double ply, multiple stitched handles. Twin-stitched, double thick bottoms.`,
        details: [
            `Rolled polyfoam core - won't sag with age`,
            `Double ply, multiple stitched handles`,
            `18 oz double-sided vinyl coated nylon`,
            `Twin-stitched, double thick bottoms`,
            `Weight: 40 lbs`,
            `Dimensions: 75" Tall x 16" Dia`
        ],
        includes: [
            ``
        ],
        options: [
            `SUD7516: Stand Up Dummy 75" Tall x 16" Dia (40 lbs)`
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'dummies',
        group: 'Stand Up Dummies',
        urlCategory: 'football',
        urlSubCategory: 'dummies',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fstand-up-dummy-sud7516-HQ1.jpg?alt=media&token=5a2065b1-3277-4e75-ad15-46e894431e08",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fstand-up-dummy-sud7516-HQ1.jpg?alt=media&token=5a2065b1-3277-4e75-ad15-46e894431e08",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fstand-up-dummy-sud7516-HQ2.jpg?alt=media&token=2efec249-ef35-481d-8c33-4b33989a749c",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fstand-up-dummy-sud7516-HQ3.jpg?alt=media&token=ba25d96c-3855-4bed-8771-a0ef3a22ad23",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fstand-up-dummy-sud7516-HQ4.jpg?alt=media&token=bce47b80-49ae-4600-9977-900542a3875a"
        ]
    },{
        id: 'p114',
        name: 'Heavyweight Dummy',
        sku: 'hw70',
        description: `Manufactured with high grade foams in combination. Strong, firm and resilient. Stands up to rigorous practices.`,
        details: [
            `Manufactured with high grade foams in combination`,
            `Strong, firm, and resiliant`,
            `Stands up to rigorous practices`,
            `Front Pad: 12" x 14" x 4"`
        ],
        includes: [
            ``
        ],
        options: [
            `HW35: 42" Tall x 16" Dia x (35 lbs)`,
            `HW40: 48" Tall x 16" Dia x (40 lbs)`,
            `HW45: 54" Tall x 16" Dia x (45 lbs)`,
            `HW60: 42" Tall x 16" Dia x (60 lbs)`,
            `HW65: 48" Tall x 16" Dia x (65 lbs)`,
            `HW70: 54" Tall x 16" Dia x (70 lbs)`,
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'dummies',
        group: 'Stand Up Dummies',
        urlCategory: 'football',
        urlSubCategory: 'dummies',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fheavyweight-dummy-hw70-HQ1.jpg?alt=media&token=4e8a3eb0-fe19-4eb8-b796-7aff92788edc",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fheavyweight-dummy-hw70-HQ1.jpg?alt=media&token=4e8a3eb0-fe19-4eb8-b796-7aff92788edc",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fheavyweight-dummy-hw60-HQ1.jpg?alt=media&token=69188b3e-35a3-4e58-a5ed-341f54a73a52",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fheavyweight-dummies-HQ1.jpg?alt=media&token=2271a563-d344-47be-b67f-b920716f4a7f",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fheavyweight-dummies-chart.jpg?alt=media&token=7ac5b4ad-3d72-4bb6-807f-ca58a1ddaf90"
        ]
    },{
        id: 'p115',
        name: 'Junior Pop Up Dummy',
        sku: '10145',
        description: `Like having extra players during your practices. Durable 3 layer 18 oz. vinyl base and single layer, 18 oz. Vinyl body assures you of long dummy life. Four carrying handles give you the ability to easily handle and transport the dummy. Safest Pop-up dummy on the market. Heavy protected base allows dummy to "pop" back up after contact while affording maximum player protection. Rubber coated protective base won't bruise or sting player's lower body.`,
        details: [
            `Like having extra players during your practices`,
            `Durable 3 layer, 18 oz vinyl base and single layer, 18 oz`,
            `Four carrying handles give you the ability to easily handle and transport the dummy`,
            `Safest Pop-Up Dummy in the market`,
            `Heavy base allows dummy to react properly`,
            `Heavy protected base allows dummy to "pop" back up after contact while affording maximum player protection`,
            `Rubber coated protective base won't bruise or sting player's lower body`,
            `Weight: 80 lbs`,
            `Height: 45"`
        ],
        includes: [
            ``
        ],
        options: [
            `10145: Junior Pop Up Dummy 45"H (80 lbs)`
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'dummies youth football',
        group: 'Pop Up Dummies',
        urlCategory: 'football',
        urlSubCategory: 'dummies',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpop-up-dummy-junior-10145-HQ1.jpg?alt=media&token=604c9645-118a-44f7-9ad0-15fe8e010cab",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpop-up-dummy-junior-10145-HQ1.jpg?alt=media&token=604c9645-118a-44f7-9ad0-15fe8e010cab",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpop-up-dummy-junior-10145-HQ2.jpg?alt=media&token=574597f1-9dfa-42f7-b672-1c1b6d3302ed"
        ]
    },{
        id: 'p116',
        name: 'Varsity Pop Up Dummy',
        sku: '10155',
        description: `Like having extra players during your practices. Durable 3 layer 18 oz. vinyl base and single layer, 18 oz. Vinyl body assures you of long dummy life. Four carrying handles give you the ability to easily handle and transport the dummy. Safest Pop-up dummy on the market. Heavy protected base allows dummy to "pop" back up after contact while affording maximum player protection. Rubber coated protective base won't bruise or sting player's lower body.`,
        details: [
            `Like having extra players during your practices`,
            `Durable 3 layer, 18 oz vinyl base and single layer, 18 oz`,
            `Four carrying handles give you the ability to easily handle and transport the dummy`,
            `Safest Pop-Up Dummy in the market`,
            `Heavy base allows dummy to react properly`,
            `Heavy protected base allows dummy to "pop" back up after contact while affording maximum player protection`,
            `Rubber coated protective base won't bruise or sting player's lower body`,
            `Weight: 110 lbs`,
            `Height: 60"`
        ],
        includes: [
            ``
        ],
        options: [
            `10155: Varsity Pop Up Dummy 60"H (110 lbs)`
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'dummies youth football',
        group: 'Pop Up Dummies',
        urlCategory: 'football',
        urlSubCategory: 'dummies',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpop-up-dummy-varsity-10155-HQ1.jpg?alt=media&token=cbc00abc-c93b-444b-9bc9-dc4f6c7f93c3",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpop-up-dummy-varsity-10155-HQ1.jpg?alt=media&token=cbc00abc-c93b-444b-9bc9-dc4f6c7f93c3",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpop-up-dummy-varsity-10155-HQ2.jpg?alt=media&token=0e136919-1ba2-4e4c-99a5-6c9db395d03b"
        ]
    },{
        id: 'p117',
        name: 'Pro Pop Up Dummy',
        sku: '10172',
        description: `Like having extra players during your practices. Durable 3 layer 18 oz. vinyl base and single layer, 18 oz. Vinyl body assures you of long dummy life. Four carrying handles give you the ability to easily handle and transport the dummy. Safest Pop-up dummy on the market. Heavy protected base allows dummy to "pop" back up after contact while affording maximum player protection. Rubber coated protective base won't bruise or sting player's lower body.`,
        details: [
            `Like having extra players during your practices`,
            `Durable 3 layer, 18 oz vinyl base and single layer, 18 oz`,
            `Four carrying handles give you the ability to easily handle and transport the dummy`,
            `Safest Pop-Up Dummy in the market`,
            `Heavy base allows dummy to react properly`,
            `Heavy protected base allows dummy to "pop" back up after contact while affording maximum player protection`,
            `Rubber coated protective base won't bruise or sting player's lower body`,
            `Weight: 115 lbs`,
            `Height: 72"`
        ],
        includes: [
            ``
        ],
        options: [
            `10172: Pro Pop Up Dummy 72"H (115 lbs)`
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'dummies youth football',
        group: 'Pop Up Dummies',
        urlCategory: 'football',
        urlSubCategory: 'dummies',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpop-up-dummy-pro-10172-HQ1.jpg?alt=media&token=75ee5437-001e-4f74-bfc7-53ac770fbcdb",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpop-up-dummy-pro-10172-HQ1.jpg?alt=media&token=75ee5437-001e-4f74-bfc7-53ac770fbcdb",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpop-up-dummy-pro-10172-HQ2.jpg?alt=media&token=ca011104-f14e-43ff-a442-7974db9dbfe8"
        ],
        videos: [
            {
                title: "10172 Pop Up Workout",
                image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2F10172-video-thumbail-1.jpg?alt=media&token=b912118c-d67a-4c4e-8608-de3df7e6eef2",
                src: "https://www.youtube.com/embed/azsxRyBnjaw"
            }
        ]
    },{
        id: 'p118',
        name: 'Spartan Pop Up Dummy',
        sku: '10159spr',
        description: `Like having extra players during your practices. Durable 3 layer 18 oz. vinyl base and single layer, 18 oz. Vinyl body assures you of long dummy life. Four carrying handles give you the ability to easily handle and transport the dummy. Safest Pop-up dummy on the market. Heavy protected base allows dummy to "pop" back up after contact while affording maximum player protection. Rubber coated protective base won't bruise or sting player's lower body.`,
        details: [
            `Like having extra players during your practices`,
            `Durable 3 layer, 18 oz vinyl base and single layer, 18 oz`,
            `Four carrying handles give you the ability to easily handle and transport the dummy`,
            `Safest Pop-Up Dummy in the market`,
            `Heavy base allows dummy to react properly`,
            `Heavy protected base allows dummy to "pop" back up after contact while affording maximum player protection`,
            `Rubber coated protective base won't bruise or sting player's lower body`,
            `Weight: 82 lbs`,
            `Height: 59"`
        ],
        includes: [
            ``
        ],
        options: [
            `10159SPR: Spartan Pop Up Dummy 59"H (82 lbs)`
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'dummies youth football',
        group: 'Pop Up Dummies',
        urlCategory: 'football',
        urlSubCategory: 'dummies',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpop-up-dummy-spartan-10159spr-HQ1.jpg?alt=media&token=3f83d1fa-acf8-4d0d-8aff-5696e6d7abb2",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpop-up-dummy-spartan-10159spr-HQ1.jpg?alt=media&token=3f83d1fa-acf8-4d0d-8aff-5696e6d7abb2",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpop-up-dummy-spartan-10159spr-HQ2.jpg?alt=media&token=3f338e20-7dac-43c0-9a49-8634f3f6cbcc",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpop-up-dummy-spartan-10159spr-HQ3.jpg?alt=media&token=f9d12ed5-189d-4468-9382-603bcb8e9fa7"
        ]
    },{
        id: 'p119',
        name: 'Varsity Pop Up Dummy with Wings',
        sku: '10155w',
        description: `Like having extra players during your practices. Durable 3 layer 18 oz. vinyl base and single layer, 18 oz. Vinyl body assures you of long dummy life. Four carrying handles give you the ability to easily handle and transport the dummy. Safest Pop-up dummy on the market. Heavy protected base allows dummy to "pop" back up after contact while affording maximum player protection. Rubber coated protective base won't bruise or sting player's lower body.`,
        details: [
            `Like having extra players during your practices`,
            `Durable 3 layer, 18 oz vinyl base and single layer, 18 oz`,
            `Four carrying handles give you the ability to easily handle and transport the dummy`,
            `Safest Pop-Up Dummy in the market`,
            `Heavy base allows dummy to react properly`,
            `Heavy protected base allows dummy to "pop" back up after contact while affording maximum player protection`,
            `Rubber coated protective base won't bruise or sting player's lower body`,
            `Weight: 112 lbs`,
            `Height: 60"`
        ],
        includes: [
            ``
        ],
        options: [
            `10155W: Varsity Pop Up Dummy with Wings 60"H (112 lbs)`
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'dummies youth football',
        group: 'Pop Up Dummies',
        urlCategory: 'football',
        urlSubCategory: 'dummies',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpop-up-dummy-pro-wings-10155w-HQ1.jpg?alt=media&token=213c0cdb-ea3d-42f9-97ff-a413e2cc7553",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpop-up-dummy-pro-wings-10155w-HQ1.jpg?alt=media&token=213c0cdb-ea3d-42f9-97ff-a413e2cc7553"
        ]
    },{
        id: 'p120',
        name: 'Pro Pop Up Dummy with Wings',
        sku: '10172w',
        description: `Like having extra players during your practices. Durable 3 layer 18 oz. vinyl base and single layer, 18 oz. Vinyl body assures you of long dummy life. Four carrying handles give you the ability to easily handle and transport the dummy. Safest Pop-up dummy on the market. Heavy protected base allows dummy to "pop" back up after contact while affording maximum player protection. Rubber coated protective base won't bruise or sting player's lower body.`,
        details: [
            `Like having extra players during your practices`,
            `Durable 3 layer, 18 oz vinyl base and single layer, 18 oz`,
            `Four carrying handles give you the ability to easily handle and transport the dummy`,
            `Safest Pop-Up Dummy in the market`,
            `Heavy base allows dummy to react properly`,
            `Heavy protected base allows dummy to "pop" back up after contact while affording maximum player protection`,
            `Rubber coated protective base won't bruise or sting player's lower body`,
            `Weight: 117 lbs`,
            `Height: 72"`
        ],
        includes: [
            ``
        ],
        options: [
            `10172W: Pro Pop Up Dummy with Wings 72"H (117 lbs)`
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'dummies youth football',
        group: 'Pop Up Dummies',
        urlCategory: 'football',
        urlSubCategory: 'dummies',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpop-up-dummy-pro-wings-10172w-HQ1.jpg?alt=media&token=09524119-749b-45a8-8fe2-510dc4d923cd",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpop-up-dummy-pro-wings-10172w-HQ1.jpg?alt=media&token=09524119-749b-45a8-8fe2-510dc4d923cd",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpop-up-dummy-pro-wings-10172w-HQ2.jpg?alt=media&token=70602156-1f96-4890-b942-57c483df3919",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpop-up-dummy-pro-wings-10172w-HQ3.jpg?alt=media&token=4ed61eb1-d631-4a5b-99ba-acea573415b6"
        ]
    },{
        id: 'p121',
        name: 'Pop Up Dummy Arms',
        sku: 'ar10000',
        description: `Detachable arms for more life-like drills. Fits up to 16' diameter dummies . Includes Velcro for ball attachment. 18 oz. coated vinyl.`,
        details: [
            `Detachable arms for more life-like drills`,
            `Fits up to 16" dia. dummies`,
            `Includes Velcro® for ball attachment`
        ],
        includes: [
            ``
        ],
        options: [
            `AR10000: Pop Up Dummy Arms`
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'dummies youth football',
        group: 'Pop Up Dummies',
        urlCategory: 'football',
        urlSubCategory: 'dummies',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpop-up-dummy-arms-ar10000-HQ1.jpg?alt=media&token=55cf2752-a169-4353-8722-d22031699eb8",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpop-up-dummy-arms-ar10000-HQ1.jpg?alt=media&token=55cf2752-a169-4353-8722-d22031699eb8",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpop-up-dummy-arms-ar10000-HQ2.jpg?alt=media&token=e12c3283-c8d3-48ee-b8e0-097e70087a3e"
        ]
    },{
        id: 'p122',
        name: 'Pop Up Dummy Claw Arms',
        sku: 'ar10001',
        description: `Detachable arms for more life-like drills. Fits up to 16' diameter dummies . Includes Velcro for ball attachment. 18 oz. coated vinyl.`,
        details: [
            `Detachable arms for more life-like drills`,
            `Fits up to 16" dia. dummies`,
            `Includes Velcro® for ball attachment`
        ],
        includes: [
            ``
        ],
        options: [
            `AR10001: Pop Up Dummy Claw Arms`
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'dummies youth football',
        group: 'Pop Up Dummies',
        urlCategory: 'football',
        urlSubCategory: 'dummies',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpop-up-dummy-claw-arms-ar10001-HQ1.jpg?alt=media&token=430ee518-be46-46ca-897f-27bbae54e9e1",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpop-up-dummy-claw-arms-ar10001-HQ1.jpg?alt=media&token=430ee518-be46-46ca-897f-27bbae54e9e1",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpop-up-dummy-claw-arms-ar10001-HQ2.jpg?alt=media&token=a3d89bbc-02ff-4e5c-8325-481e2272a04b"
        ]
    },{
        id: 'p123',
        name: 'Pop Up Dummy Claw Arms Drop Back Left',
        sku: 'ar10003',
        description: `Detachable arms for more life-like drills. Fits up to 16' diameter dummies . Includes Velcro for ball attachment. 18 oz. coated vinyl.`,
        details: [
            `Detachable arms for more life-like drills`,
            `Fits up to 16" dia. dummies`,
            `Includes Velcro® for ball attachment`
        ],
        includes: [
            ``
        ],
        options: [
            `AR10003: Pop Up Dummy Claw Arms Drop Back Left`
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'dummies youth football',
        group: 'Pop Up Dummies',
        urlCategory: 'football',
        urlSubCategory: 'dummies',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpop-up-dummy-claw-arms-drop-back-left-ar10003-HQ1.jpg?alt=media&token=09e9551e-44e8-4f8b-b719-78a02c2c7641",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpop-up-dummy-claw-arms-drop-back-left-ar10003-HQ1.jpg?alt=media&token=09e9551e-44e8-4f8b-b719-78a02c2c7641",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpop-up-dummy-claw-arms-drop-back-left-ar10003-HQ2.jpg?alt=media&token=a0f07f3e-9a73-49b2-bb54-85193c8ff78f"
        ]
    },{
        id: 'p124',
        name: 'Pop Up Dummy Claw Arms Drop Back Right',
        sku: 'ar10002',
        description: `Detachable arms for more life-like drills. Fits up to 16' diameter dummies . Includes Velcro for ball attachment. 18 oz. coated vinyl.`,
        details: [
            `Detachable arms for more life-like drills`,
            `Fits up to 16" dia. dummies`,
            `Includes Velcro® for ball attachment`
        ],
        includes: [
            ``
        ],
        options: [
            `AR10002: Pop Up Dummy Claw Arms Drop Back Right`
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'dummies youth football',
        group: 'Pop Up Dummies',
        urlCategory: 'football',
        urlSubCategory: 'dummies',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpop-up-dummy-claw-arms-drop-back-right-ar10002-HQ1.jpg?alt=media&token=8b22828c-3cc9-4efb-bc69-5c31858441c4",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpop-up-dummy-claw-arms-drop-back-right-ar10002-HQ1.jpg?alt=media&token=8b22828c-3cc9-4efb-bc69-5c31858441c4",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpop-up-dummy-claw-arms-drop-back-right-ar10002-HQ2.jpg?alt=media&token=a2c4c5be-a3a9-49b4-aa97-27b237594e7e"
        ]
    },{
        id: 'p125',
        name: 'Slot Block Chute Drill Dummy',
        sku: 'sb5522',
        description: `Ideal for chute & board drills. Shaped for proper cut blocking techniques.`,
        details: [
            `Ideal for chute and board drills`,
            `Shaped for proper cut blocking techniques`,
            `15" Base`,
            `Dimensions: 55"H x 18"W`,
            `Weight: 17 lbs`
        ],
        includes: [
            ``
        ],
        options: [
            `SB5522: Slot Block Chute Drill Dummy 55"H x 18"W (17 lbs)`
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'dummies',
        group: 'Blocking Dummies',
        urlCategory: 'football',
        urlSubCategory: 'dummies',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fslot-block-chute-drill-dummy-sb5522-HQ1.jpg?alt=media&token=917f3071-61be-4b17-a300-e277c294573b",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fslot-block-chute-drill-dummy-sb5522-HQ1.jpg?alt=media&token=917f3071-61be-4b17-a300-e277c294573b",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fslot-block-chute-drill-dummy-sb5522-HQ2.jpg?alt=media&token=71ec9836-c8fc-4a5d-9376-1803660782f4"
        ]
    },{
        id: 'p126',
        name: 'Slot Block Chute Drill Dummy',
        sku: 'sb4822',
        description: `Ideal for chute & board drills. Shaped for proper cut blocking techniques.`,
        details: [
            `Ideal for chute and board drills`,
            `Shaped for proper cut blocking techniques`,
            `15" Base`,
            `Dimensions: 48"H x 18"W`,
            `Weight: 11 lbs`
        ],
        includes: [
            ``
        ],
        options: [
            `SB4822: Slot Block Chute Drill Dummy 48"H x 18"W (11 lbs)`
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'dummies',
        group: 'Blocking Dummies',
        urlCategory: 'football',
        urlSubCategory: 'dummies',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fslot-block-chute-drill-dummy-sb4822-HQ1.jpg?alt=media&token=5c7d2987-509b-474f-a7e2-5f66f8e3a1ef",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fslot-block-chute-drill-dummy-sb4822-HQ1.jpg?alt=media&token=5c7d2987-509b-474f-a7e2-5f66f8e3a1ef",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fslot-block-chute-drill-dummy-sb4822-HQ2.jpg?alt=media&token=62c75668-e100-4f57-a8a4-952ed2ddbc66"
        ]
    },{
        id: 'p127',
        name: 'Dual Purpose Square Dummy',
        sku: 'sd14',
        description: `Use as a regular stand up dummy for blocking drills and practices or horizontally as a stepover dummy. Increases player agility. Square design. Backside handles for stand up practice. End handle for moving on and off field.`,
        details: [
            'Use as a regular stand up dummy for blocking drills and practices or horizontally as a stepover dummy',
            'Increases player agility',
            'Square design',
            'Backside handles for stand up practice',
            'End handle for moving on and off field',
            'Dimensions: 50"L x 14"H x 14"Base x 14"Top',
            'Weight: 15 lbs'
        ],
        includes: [
            ``
        ],
        options: [
            `SD14: 50"L x 14"H x 14"Base x 14"Top (15 lbs)`,
            `SD15: 46"L x 15"H x 15"Base x 15"Top (40 lbs)`
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'dummies',
        group: 'Blocking Dummies',
        urlCategory: 'football',
        urlSubCategory: 'dummies',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fdual-purpose-square-dummy-sd14-HQ1.jpg?alt=media&token=3c460263-b732-4e73-ac03-531b341787cd",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fdual-purpose-square-dummy-sd14-HQ1.jpg?alt=media&token=3c460263-b732-4e73-ac03-531b341787cd",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fsquare-dummies-HQ3.jpg?alt=media&token=9fb62a31-85e0-4063-8a23-2c0166674e35",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fsquare-dummies-HQ1.jpg?alt=media&token=9f4472dd-e206-4387-81f9-6686427a8fd5",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fsquare-dummies-HQ2.jpg?alt=media&token=6ba68b81-847a-474e-a104-3b93a1485386"
        ]
    },{
        id: 'p128',
        name: 'Dual Purpose Square Dummy',
        sku: 'sd15',
        description: `Use as a regular stand up dummy for blocking drills and practices or horizontally as a stepover dummy. Increases player agility. Square design. Backside handles for stand up practice. End handle for moving on and off field.`,
        details: [
            'Use as a regular stand up dummy for blocking drills and practices or horizontally as a stepover dummy',
            'Increases player agility',
            'Square design',
            'Backside handles for stand up practice',
            'End handle for moving on and off field',
            'Dimensions: 46"L x 15"H x 15"Base x 15"Top',
            'Weight: 40 lbs'
        ],
        includes: [
            ``
        ],
        options: [
            `SD14: 50"L x 14"H x 14"Base x 14"Top (15 lbs)`,
            `SD15: 46"L x 15"H x 15"Base x 15"Top (40 lbs)`
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'dummies',
        group: 'Blocking Dummies',
        urlCategory: 'football',
        urlSubCategory: 'dummies',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fdual-purpose-square-dummy-sd15-HQ1.jpg?alt=media&token=14933f25-c869-49fd-ba22-d0c633779c56",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fdual-purpose-square-dummy-sd15-HQ1.jpg?alt=media&token=14933f25-c869-49fd-ba22-d0c633779c56",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fsquare-dummies-HQ1.jpg?alt=media&token=9f4472dd-e206-4387-81f9-6686427a8fd5",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fsquare-dummies-HQ2.jpg?alt=media&token=6ba68b81-847a-474e-a104-3b93a1485386"
        ]
    },{
        id: 'p129',
        name: 'Power Dummy',
        sku: 'sm100',
        description: `Best aid for teaching the "hit and explode up" technique. Bonded foam.`,
        details: [
            'Best aid for teaching the "hit and explode up" technique',
            'Bonded foam',
            'Keeps linemen low',
            'Weight gives realistic resistance',
            'Top handle used to keep pad down',
            'Dimensions: 48" x 15" (main body) 6" overhang at top',
            'Weight: 40 lbs'
        ],
        includes: [
            ``
        ],
        options: [
            `SM100: 48" x 15" (main body) 6" overhang at top (40 lbs)`,
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'dummies',
        group: 'Blocking Dummies',
        urlCategory: 'football',
        urlSubCategory: 'dummies',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpower-dummy-sm100-HQ1.jpg?alt=media&token=be8d0ff4-4cb6-473b-93b6-a604c4f1c1fe",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpower-dummy-sm100-HQ1.jpg?alt=media&token=be8d0ff4-4cb6-473b-93b6-a604c4f1c1fe",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpower-dummy-sm100-HQ2.jpg?alt=media&token=4919c801-4960-41f4-a164-ecb858dee214",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpower-dummy-sm100-HQ3.jpg?alt=media&token=95b78ca5-29fc-4fe2-a74a-8b2303dc2b43"
        ]
    },{
        id: 'p130',
        name: 'Adjustable Agility Master',
        sku: 'amadj20',
        description: `Adjustable height from ground to 10" . Sets up in minutes. No nuts, bolts, or screws. Slip together parts for fast assembly. Safe, no sharp corners. Replacement ropes change easily. Dimensions: 60" wide x 20' long.`,
        details: [
            'Adjustable height from ground to 10"',
            'Sets up in minutes',
            'No nuts, bolts, or screws',
            'Slip together parts for fast assembly',
            'Safe - no sharp corners',
            'Replacement ropes change easily',
            `Dimensions: 60"W x 20'L`
        ],
        includes: [
            ``
        ],
        options: [
            `AMADJ20: 60"W x 20'L Adjustable Agility Master`,
            `AMADJRR20: 20'L Replacement Ropes`
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'practice items',
        group: 'Running Ropes',
        urlCategory: 'football',
        urlSubCategory: 'practice items',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fadjustable-agility-master-amadj20-HQ1.jpg?alt=media&token=c34b3cd2-2c16-43e0-87a5-6173945a1325",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fadjustable-agility-master-amadj20-HQ1.jpg?alt=media&token=c34b3cd2-2c16-43e0-87a5-6173945a1325",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fadjustable-agility-master-amadj20-HQ2.jpg?alt=media&token=d86d8001-5034-4050-9e51-3918f50de471"
        ]
    },{
        id: 'p131',
        name: 'Agility Master High Step Trainer',
        sku: 'am20',
        description: `Sets up in minutes. No nuts, bolts or screws. Slip together parts for fast assembly. Safe - no sharp corners. Replacement ropes are inexpensive and easy to change.`,
        details: [
            'Sets up in minutes',
            'No nuts, bolts, or screws',
            'Slip together parts for fast assembly',
            'Safe - no sharp corners',
            'Replacement ropes are inexpensive and easy to change',
            `Dimensions: 54"W x 9"H x 20'L`
        ],
        includes: [
            ``
        ],
        options: [
            `AM15: 15' Long`,
            `AM20: 20' Long`,
            `AM30: 30' Long`
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'practice items',
        group: 'Running Ropes',
        urlCategory: 'football',
        urlSubCategory: 'practice items',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fagility-master-high-step-trainer-am20-HQ1.jpg?alt=media&token=6b31dff1-374c-4ec7-9a14-d6e125cf0453",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fagility-master-high-step-trainer-am20-HQ1.jpg?alt=media&token=6b31dff1-374c-4ec7-9a14-d6e125cf0453",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fagility-master-high-step-trainer-am20-HQ2.jpg?alt=media&token=05ffaf02-81c4-4090-aac9-3e94f68c2f28"
        ]
    },{
        id: 'p132',
        name: 'Speed Demon',
        sku: 'sd1454',
        description: `The Speed Demon helps increase your player's foot speed and agility, without the possibility of sprains or other foot injuries.`,
        details: [
            `Keeps its shape during drills`,
            `No realignment needed after a player runs through`,
            `Use on natural grass, artificial turf, or inside on a gym floor`,
            `Bright gold web ropes are highly visible to players`,
            `Portable, easily moved from one location to another`,
            `Dimensions: 54"W x 14'L`
        ],
        includes: [
            ``
        ],
        options: [
            `SD1454: 54"W x 14'L Speed Demon`
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'practice items',
        group: 'Running Ropes',
        urlCategory: 'football',
        urlSubCategory: 'practice items',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fspeed-demon-sd1454-HQ1.jpg?alt=media&token=2d7ca97b-e02a-4cbf-97c6-2f922c44e915",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fspeed-demon-sd1454-HQ1.jpg?alt=media&token=2d7ca97b-e02a-4cbf-97c6-2f922c44e915",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fspeed-demon-sd1454-HQ2.jpg?alt=media&token=eda93db1-f49e-4f44-8a80-608c491ada8d"
        ]
    },{
        id: 'p133',
        name: 'Steel Folding Ladder',
        sku: 'aglstl1913',
        description: `Fisher Athletic's Steel Folding Ladder is portable and easy to move. Use on grass, artificial turf, or track.`,
        details: [
            `Steel folding ladder`,
            `Portable, easy to move`,
            `Use on grass, artificial turf, or track`,
            `(3) low-profile hinge joints for easy storage`,
            `Heavy duty 1"wide metal flat-bar frame x 1/8" thick`,
            `Inside dimensions of squares: 17" x 17 1/2"`,
            `Undolded Dimensions: 19"W x 13'L x 1/8"`,
            `Folded Dimensions: 19"W x 38 1/4"L x 1 1/4"`,
            `Weight: 18 lbs`
        ],
        includes: [
            ``
        ],
        options: [
            `AGLSTL1913: Steel Folding Ladder 19"W x 13'L x 1/8" thick`
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'practice items strength and speed',
        group: 'Ladders',
        urlCategory: 'football',
        urlSubCategory: 'practice items',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fsteel-folding-ladder-aglstl1913-HQ1.jpg?alt=media&token=32a81f7c-6d1a-43aa-97e7-c4061c37d40a",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fsteel-folding-ladder-aglstl1913-HQ1.jpg?alt=media&token=32a81f7c-6d1a-43aa-97e7-c4061c37d40a",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fsteel-folding-ladder-aglstl1913-HQ2.jpg?alt=media&token=76ed67dd-09f6-4b7e-8355-e3e815ad0f14",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fsteel-folding-ladder-aglstl1913-HQ3.jpg?alt=media&token=1de71b16-dc1a-44de-89bd-e4f74b8bc91f",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fsteel-folding-ladder-aglstl1913-HQ4.jpg?alt=media&token=d5e0d7a1-928b-41f7-b594-f3d19b470dc8"
        ]
    },{
        id: 'p134',
        name: 'Speed Ladder',
        sku: 'agl4815',
        description: `Portable and easy to move. Use on grass, artificial turf, track or gym floors. Shatter proof composite plastic cross pieces.`,
        details: [
            `Portable and easy to move`,
            `Use on grass, artificial turf, track or gym floors`,
            `Shatter proof composite plastic cross pieces`,
            `Weight: 18 lbs`
        ],
        includes: [
            ``
        ],
        options: [
            `AGL1612: 16"W x 12'L (single ladder)`,
            `AGL2420: 24"W x 20'L (single ladder)`,
            `AGL4815: 48"W x 15'L (double ladder)`
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'practice items strength and speed',
        group: 'Ladders',
        urlCategory: 'football',
        urlSubCategory: 'practice items',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fspeed-ladder-agl4815-HQ1.jpg?alt=media&token=5bf849b5-24df-425d-b46e-ac4b76f0fd13",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fspeed-ladder-agl4815-HQ1.jpg?alt=media&token=5bf849b5-24df-425d-b46e-ac4b76f0fd13",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fspeed-ladder-agl2420-HQ1.jpg?alt=media&token=70b73311-6836-42d8-b2ba-8f5f5648e068",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fspeed-ladder-agl1612-HQ1.jpg?alt=media&token=d0e736f6-98c9-462c-877a-0e7c7e36125c",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fspeed-ladders-HQ1.jpg?alt=media&token=9a9ffba2-5faa-43f0-b3dd-7e59c38848c8"
        ]
    },{
        id: 'p135',
        name: 'Line-Up Marker',
        sku: 'lum',
        description: `Seven moveable sleeves identifying offensive positions on one side and defensive positions on the other.`,
        details: [
            `Seven moveable sleeves identifying offensive positions on one side and defensive positions on the other`,
            `Durable 18 oz vinyl`,
            `Carrying bag included`,
            `Dimensions: 35'Long`,
            `Weight: 18 lbs`
        ],
        includes: [
            ``
        ],
        options: [
            `LUM: Line Up Marker (18 lbs)`
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'practice items youth football',
        group: '',
        urlCategory: 'football',
        urlSubCategory: 'practice items',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fline-up-marker-lum-HQ1.jpg?alt=media&token=d81c359d-1618-4d6a-aac9-500ac3545596",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fline-up-marker-lum-HQ1.jpg?alt=media&token=d81c359d-1618-4d6a-aac9-500ac3545596",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fline-up-marker-lum-HQ2.jpg?alt=media&token=5a9bfa8b-1f09-421b-9e14-64746d0df70a"
        ]
    },{
        id: 'p136',
        name: 'Line-Up Disc',
        sku: 'ludisc',
        description: `Seven moveable disc, constructed from molded flexible rubber.`,
        details: [
            `Seven moveable disc`,
            `Constructed from molded flexible rubber`,
            `12" Diameter`,
            `Top side has position letters`,
            `Reverse side can be used for additional team drills`,
            `Carrying bag included`,
            `Weight: 18 lbs`
        ],
        includes: [
            ``
        ],
        options: [
            `LUDISC: Line Up Disc (18 lbs)`
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'practice items youth football',
        group: '',
        urlCategory: 'football',
        urlSubCategory: 'practice items',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fline-up-disc-ludisc-HQ1.jpg?alt=media&token=3f642559-9ff1-44ac-b6fb-2e681c16b13f",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fline-up-disc-ludisc-HQ1.jpg?alt=media&token=3f642559-9ff1-44ac-b6fb-2e681c16b13f",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fline-up-disc-ludisc-HQ2.jpg?alt=media&token=6dcb6645-52d5-4888-8bb9-7bbf757f1968",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fline-up-disc-ludisc-HQ3.jpg?alt=media&token=b333a7c0-707e-4378-9d71-7931da24a6fd",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fline-up-disc-ludisc-HQ4.jpg?alt=media&token=f37b619e-49ac-4b57-b145-7bef856df754"
        ]
    },{
        id: 'p137',
        name: 'Crash Pads',
        sku: 'cp5524',
        description: `Game-like condition without the risk of injury.`,
        details: [
            `Covered with durable 18 oz coated vinyl`,
            `15 colors to choose from`,
            `Carrying handles for transportation`
        ],
        includes: [
            ``
        ],
        options: [
            `CP484: 4'W x 8'L x 4"Thick`,
            `CP4610: 4'W x 6'L x 10"Thick`,
            `CP4810: 4'W x 8'L x 10"Thick`,
            `CP5524: 5'W x 5'L x 24"Thick`,
            `CP5108: 5'W x 10'L x 8"Thick`,
            `CP6824: 6'W x 8'L x 24"Thick`,
            `CP6128: 6'W x 12'L x 8"Thick`,
            `CP61024: 6'W x 10'L x 24"Thick`
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'practice items',
        group: '',
        urlCategory: 'football',
        urlSubCategory: 'practice items',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fcrash-pads-cp5524-HQ1.jpg?alt=media&token=bc79ca2f-beba-475b-8be7-1c84a81a0d45",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fcrash-pads-cp5524-HQ1.jpg?alt=media&token=bc79ca2f-beba-475b-8be7-1c84a81a0d45",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fcrash-pads-cp5524-HQ2.jpg?alt=media&token=d98e816b-9686-468b-9fbf-b291cba76647",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fcrash-pads-cp5524-HQ3.jpg?alt=media&token=d4cb5a66-ecba-4fe3-8c1b-73b5f944b5af",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fcrash-pads-cp5524-HQ4.jpg?alt=media&token=93ee12d6-ef3d-4ed2-be4e-63bed67e4b7a"
        ]
    },{
        id: 'p138',
        name: 'Tug-A-Ball',
        sku: 'tab101',
        description: `Helps reduce fumbles. Live action increases player's ability to hold on to the ball.`,
        details: [
            `High density webbing won't stretch or tear`,
            `The most cost effective way to reduce fumbles`,
            `Use with a weighted pull sled, stretch bands, or a player/coach for resistance`,
            `Available with medium or strong stretch bands for gradual ball deceleration`
        ],
        includes: [
            ``
        ],
        options: [
            `TAB100: Strap only`,
            `TAB101: Strap with Medium Band`,
            `TAB102: Strap with Strong Band`,
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'practice items',
        group: '',
        urlCategory: 'football',
        urlSubCategory: 'practice items',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ftug-a-ball-tab101-HQ1.jpg?alt=media&token=76293f4c-fcb0-4219-8f90-0fe0e8a6b316",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ftug-a-ball-tab101-HQ1.jpg?alt=media&token=76293f4c-fcb0-4219-8f90-0fe0e8a6b316",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ftug-a-ball-tab101-HQ2.jpg?alt=media&token=340c37a3-e407-4ac7-8eeb-25628047eb1c",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ftug-a-ball-tab101-HQ3.jpg?alt=media&token=c5cf37a7-cd28-40c7-ad41-f37625c5b92b",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ftug-a-ball-tab101-HQ4.jpg?alt=media&token=837d6b11-5174-4187-a975-7c23f644bd5d",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ftug-a-ball-tab101-HQ5.jpg?alt=media&token=d8890e1d-8fe7-49de-ac47-1acbc4332ba2"
        ]
    },/*{
        id: 'p139',
        name: 'Impact Ball',
        sku: 'ipb34',
        description: `Proper footwork and handwork is a must. Defend against opponent getting into knees. Used to speed up hand practice low block protection.`,
        details: [
            `Proper footwork and handwork is a must`,
            `Defend against opponent getting into knees`,
            `Used to speed up hands and practice low block protection`,
        ],
        includes: [
            ``
        ],
        options: [
            `IPB34: Impact Ball`
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'practice items',
        group: '',
        urlCategory: 'football',
        urlSubCategory: 'practice items',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fimpact-ball-ipb34-HQ1.jpg?alt=media&token=89a1020c-5275-49a4-9fe6-6d24e2f68263",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fimpact-ball-ipb34-HQ1.jpg?alt=media&token=89a1020c-5275-49a4-9fe6-6d24e2f68263",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fimpact-ball-ipb34-HQ2.jpg?alt=media&token=6c5b02f4-dab9-4d77-a0aa-67a511639b91",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fimpact-ball-ipb34-HQ3.jpg?alt=media&token=dc1bd10d-a55a-43c5-899d-f425cd90c706"
        ]
    },*/{
        id: 'p140',
        name: 'Football on a Stick',
        sku: 'stickball',
        description: `Get players to react to ball, not cadence. Allows coach to stand instead of kneeling`,
        details: [
            `57" finished ball and pole lets coach see entire line of scrimmage`,
            `Foam rubber ball`,
            `Ships ground`,
        ],
        includes: [
            ``
        ],
        options: [
            `STICKBALL: Football on a Stick`
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'practice items',
        group: '',
        urlCategory: 'football',
        urlSubCategory: 'practice items',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ffootball-on-a-stick-stickball-HQ1.jpg?alt=media&token=1054aac5-a972-4b95-a53c-202ba55d4d71",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ffootball-on-a-stick-stickball-HQ1.jpg?alt=media&token=1054aac5-a972-4b95-a53c-202ba55d4d71",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ffootball-on-a-stick-stickball-HQ2.jpg?alt=media&token=4491dc63-bfe3-43b4-90cc-d4943e1a409c",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ffootball-on-a-stick-stickball-HQ3.jpg?alt=media&token=21762d17-3fcc-42c6-acb5-b51c7ce3c346"
        ]
    },{
        id: 'p141',
        name: 'Agility Hoop',
        sku: 'hoop',
        description: `Get off the line and around the corner.`,
        details: [
            `Sturdy PVC piping`,
            `12' Diameter`,
            `Ships ground`,
        ],
        includes: [
            ``
        ],
        options: [
            `HOOP: Agility Hoop`
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'practice items',
        group: '',
        urlCategory: 'football',
        urlSubCategory: 'practice items',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fagility-hoop-hoop-HQ1.jpg?alt=media&token=596775c0-3676-4bff-bce3-d558e636f77b",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fagility-hoop-hoop-HQ1.jpg?alt=media&token=596775c0-3676-4bff-bce3-d558e636f77b",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fagility-hoop-hoop-HQ2.jpg?alt=media&token=a15775de-dcd5-46cb-a50a-51cd82a5797f",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fagility-hoop-hoop-HQ3.jpg?alt=media&token=e18aa6c8-9456-443f-bcbb-9ed3dbe90b64"
        ]
    },{
        id: 'p142',
        name: 'Grid Lids',
        sku: 'gl100',
        description: `This unique device will allow you to quickly and easily change the colors of your scout team's helmet to identify them. You can also simulate your opponents hemlet color with the Grid Lid. During practice sessions, painted helmets and helmet decals are protected from damage by using the Grid Lid.`,
        details: [
            `Elastic, "one size fits all"`,
            `Sold individually`,
            `Colors: blue, gold, orange, red, and kelly green`,
        ],
        includes: [
            ``
        ],
        options: [
            `GL100: Grid Lids`
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'practice items',
        group: '',
        urlCategory: 'football',
        urlSubCategory: 'practice items',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fgrid-lids-gl100-HQ1.jpg?alt=media&token=71c976d7-06c4-4559-aef0-2914a632d14c",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fgrid-lids-gl100-HQ1.jpg?alt=media&token=71c976d7-06c4-4559-aef0-2914a632d14c",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fgl100-sv100-HQ1.jpg?alt=media&token=a8fbf0a9-1723-4a1a-883b-c336dbefc899"
        ]
    },{
        id: 'p143',
        name: 'Vinyl Mesh Practice Vests',
        sku: 'sv100',
        description: `Elastic velcro® straps allow a player to put on the vest quickly without assistance.`,
        details: [
            `Light and breathable`,
            `Heavy duty vinyl mesh`,
            `Will not rip or tear`,
            `Slips over helmet`,
            `Colors: blue, black, red, gold, kelly green, orange, and gray`,
            `Dimensions: 17"W x 20"L`,
        ],
        includes: [
            ``
        ],
        options: [
            `SV100: Vinyl Mesh Practice Vests`
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'practice items',
        group: 'Practice Vests',
        urlCategory: 'football',
        urlSubCategory: 'practice items',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fvinyl-mesh-practice-vests-sv100-HQ1.jpg?alt=media&token=9c75d730-aee4-4240-af7b-f3c69d8680ae",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fvinyl-mesh-practice-vests-sv100-HQ1.jpg?alt=media&token=9c75d730-aee4-4240-af7b-f3c69d8680ae",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fgl100-sv100-HQ1.jpg?alt=media&token=a8fbf0a9-1723-4a1a-883b-c336dbefc899"
        ]
    },{
        id: 'p144',
        name: 'Scout Vests',
        sku: 'sv200',
        description: `Help your players and coaches recognize key players. A must for coaches in the film room.`,
        details: [
            `Vinyl coated nylon mesh`,
            `Position indicators sewn on both sides of vest`,
            `Dimensions: 17"W x 20"L`,
        ],
        includes: [
            ``
        ],
        options: [
            `SV200: 5 Vests H,T,X,Z(blue w/ white letters) Y(red / white letters)`,
            `SV300: 8 Vests H,T,X,Z,F,S,U(blue w/ white letters) Y(red / white letters)`,
            `SV400SO: Special colors and letters`,
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'practice items',
        group: 'Practice Vests',
        urlCategory: 'football',
        urlSubCategory: 'practice items',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fscout-vests-sv200-HQ1.jpg?alt=media&token=56d4e889-5f25-45a3-a286-ef80a7a124d9",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fscout-vests-sv200-HQ1.jpg?alt=media&token=56d4e889-5f25-45a3-a286-ef80a7a124d9",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fscout-vests-sv200-HQ2.jpg?alt=media&token=31b8182b-572d-4c5b-b15d-5156326a66da"
        ]
    },{
        id: 'p145',
        name: 'Quarterback Throwing Net (adjustable)',
        sku: 'qbtn100',
        description: `The QBTN provides several ways to increase quarterbacks' accuracy, throwing mechanics, arm strength, and drops. It provides three target pockets for technique. A great way to provide conditioning during the off-season. It is equiped with wheels for easy mobility.`,
        details: [
            `Increase accuracy, throwing mechanics, arm strength, and drops`,
            `Three target pockets`,
            `Equiped with wheels for easy mobility`,
            `Dimensions: 48"W x 48"L x 68"H`,
            `Frame: 4'H x 6'W adjustable height from 36" to 84"`
        ],
        includes: [
            ``
        ],
        options: [
            `QBTN100: Quarterback Throwing Net (adjustable)`
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'practice items',
        group: '',
        urlCategory: 'football',
        urlSubCategory: 'practice items',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fquarterback-throwing-net-qbtn100-HQ1.jpg?alt=media&token=9c038cae-0df6-4ac8-a3f5-b5dc343bd68b",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fquarterback-throwing-net-qbtn100-HQ1.jpg?alt=media&token=9c038cae-0df6-4ac8-a3f5-b5dc343bd68b",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fquarterback-throwing-net-qbtn100-HQ4.jpg?alt=media&token=601aefdb-b16d-4694-acd3-e2d0a2cdb6f6",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fquarterback-throwing-net-qbtn100-HQ2.jpg?alt=media&token=70753d2a-822a-43f4-a2e1-8ebed3577c91",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fquarterback-throwing-net-qbtn100-HQ3.jpg?alt=media&token=7c3c913e-e86b-4872-8e40-da04650589ac"
        ]
    },{
        id: 'p146',
        name: 'Quarterback Throwing Net',
        sku: 'qbtn200',
        description: `The QBTN provides several ways to increase quarterbacks' accuracy, throwing mechanics, arm strength, and drops. It provides three target pockets for technique. A great way to provide conditioning during the off-season. It is equiped with wheels for easy mobility.`,
        details: [
            `Increase accuracy, throwing mechanics, arm strength, and drops`,
            `Three target pockets`,
            `Equiped with wheels for easy mobility`,
            `Dimensions: 48"W x 48"L x 68"H`
        ],
        includes: [
            ``
        ],
        options: [
            `QBTN200: Quarterback Throwing Net 48"W x 48"L x 68"H`
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'practice items',
        group: '',
        urlCategory: 'football',
        urlSubCategory: 'practice items',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fquarterback-throwing-net-qbtn200-HQ1.jpg?alt=media&token=f39f0517-68db-449c-a9bc-a9dec9b1d044",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fquarterback-throwing-net-qbtn200-HQ1.jpg?alt=media&token=f39f0517-68db-449c-a9bc-a9dec9b1d044",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fquarterback-throwing-net-qbtn100-HQ2.jpg?alt=media&token=70753d2a-822a-43f4-a2e1-8ebed3577c91",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fquarterback-throwing-net-qbtn100-HQ3.jpg?alt=media&token=7c3c913e-e86b-4872-8e40-da04650589ac"
        ]
    },{
        id: 'p147',
        name: 'Deluxe Skill Zone Target System',
        sku: 'szfb1010',
        description: `Heavy duty galvanized steel frame.`,
        details: [
            `(3) 20" removable targets and 1 pair of removable uprights (gold)`,
            `Dimensions: 10'W x 10'H`
        ],
        includes: [
            ``
        ],
        options: [
            `SZFB1010: Deluxe Skill Zone Target System 10'W x 10'H`
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'practice items',
        group: '',
        urlCategory: 'football',
        urlSubCategory: 'practice items',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fdeluxe-skill-zone-target-system-szfb1010-HQ1.jpg?alt=media&token=22bef1e1-922b-466a-914d-9cc1aa0857a1",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fdeluxe-skill-zone-target-system-szfb1010-HQ1.jpg?alt=media&token=22bef1e1-922b-466a-914d-9cc1aa0857a1",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fdeluxe-skill-zone-target-system-szfb1010-HQ2.jpg?alt=media&token=40281ac1-a32f-4618-8a49-a05083b47155",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fdeluxe-skill-zone-target-system-szfb1010-HQ3.jpg?alt=media&token=68773772-aac4-4a19-835f-55a07af287bf"
        ]
    },{
        id: 'p148',
        name: 'Skill Zone Target System',
        sku: 'szfb4875',
        description: `Heavy duty galvanized steel frame.`,
        details: [
            `Ball passes through any of 5 separate targets`,
            `Dimensions: 6'W x 7'H`
        ],
        includes: [
            ``
        ],
        options: [
            `SZFB4875: Skill Zone Target System 6'W x 7'H`
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'practice items',
        group: '',
        urlCategory: 'football',
        urlSubCategory: 'practice items',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fskill-zone-target-system-szfb4875-HQ1.jpg?alt=media&token=9a98e097-90a3-4e40-b02d-0312b4130661",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fskill-zone-target-system-szfb4875-HQ1.jpg?alt=media&token=9a98e097-90a3-4e40-b02d-0312b4130661"
        ]
    },{
        id: 'p149',
        name: 'Football Throwing Net',
        sku: 'tn1812',
        description: `Designed to aid in teaching your QB the mechanics of setting up, throwing and follow through.`,
        details: [
            `Velco® loops allow easy installation and removal`,
            `Pockets: 3 diagonally spaced drop pockets provide a fixed target for developing throwing accuracy`,
            `Dimensions: 12'H x 18'6"W`
        ],
        includes: [
            ``
        ],
        options: [
            `TN1812: Football Throwing Net 12'H x 18'6"W`
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'practice items',
        group: '',
        urlCategory: 'football',
        urlSubCategory: 'practice items',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ffootball-throwing-net-tn1812-HQ1.jpg?alt=media&token=932731e6-5788-42e0-abca-6b80d8c988f4",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ffootball-throwing-net-tn1812-HQ1.jpg?alt=media&token=932731e6-5788-42e0-abca-6b80d8c988f4",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ffootball-throwing-net-tn1812-HQ2.jpg?alt=media&token=3700c671-8b30-42a5-8d62-74eb301dc354"
        ]
    },{
        id: 'p422',
        name: 'PUNT2 Portable Kicking Cage',
        sku: 'punt2',
        description: `The PUNT2 can be assembled in five minutes without tools and is great for kicking practice.`,
        details: [
            `Assembles in five minutes without tools`,
            `Seven components (one net and six metal pieces)`,
            `1 1/2" tubular steel frame is designed for maximum stability`,
            `Heavy-duty slip over nylon net`,
            `Ships Ground`,
        ],
        includes: [
            ``
        ],
        options: [
            `PUNT2: Kicking Cage and Net - 7'H x 4'W (38 lbs)`,
            `PUNT2PK: Punt 2 Package (includes PUNT2 Kicking Cage, Net, and Carrying Case)`,
            `PUNT2BAG: Carrying Case for PUNT2`,
            `67N: Replacement Net for PUNT2`,
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'practice items',
        group: '',
        urlCategory: 'football',
        urlSubCategory: 'practice items',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpunt2-portable-kicking-cage-punt2-HQ1.jpg?alt=media&token=36c5ff80-7b00-4bc0-80b2-f52c78d28272",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpunt2-portable-kicking-cage-punt2-HQ1.jpg?alt=media&token=36c5ff80-7b00-4bc0-80b2-f52c78d28272",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpunt2-portable-kicking-cage-punt2-HQ2.jpg?alt=media&token=0b123e09-a906-4af4-896c-8c9530f5e5a1",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpunt2-portable-kicking-cage-punt2-HQ3.jpg?alt=media&token=e52ed16d-c262-4638-8a07-cadc3d906226"
        ]
    },{
        id: 'p423',
        name: 'PUNT3 Kicking System',
        sku: 'punt3',
        description: `The PUNT3 is easy to set up and can also be broken down in minutes for transportation. Strengthen your player's kicking skills with the PUNT3 Kicking System.`,
        details: [
            'Used for Kicking Practice',
            'Overhead cap keeps the ball in the net',
            'Slip together parts',
            'No hardware required for assembly',
            'Galvanized steel tubing for strength and weather resistance',
            'Net is easy to put on and take off',
            'Breaks down in minutes for transportation',
            `Ships Ground`,
        ],
        includes: [
            ``
        ],
        options: [
            `PUNT3: Kicking Frame and Net - 52"W x 81"H x 4'D`,
            `PUNT3PK: Punt 3 Package (includes PUNT3 Frame, Net, and Carrying Case)`,
            `PUNT3BAG: Carrying Case for PUNT3`,
            `PUNT3NET: Replacement Net for PUNT3`,
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'practice items',
        group: '',
        urlCategory: 'football',
        urlSubCategory: 'practice items',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpunt3-kicking-system-punt3-HQ1.jpg?alt=media&token=ce1bcd60-3fcc-452d-89a5-96640c94ccfe",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpunt3-kicking-system-punt3-HQ1.jpg?alt=media&token=ce1bcd60-3fcc-452d-89a5-96640c94ccfe",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpunt3-kicking-system-punt3-HQ2.jpg?alt=media&token=99afc47e-0459-4bb1-9507-a238caec61b7"
        ]
    },{
        id: 'p424',
        name: 'Kicking Tee',
        sku: 'wksrw',
        description: `Kicking Tee is available in 3 color options.`,
        details: [
            'Portable and easy to use',
            'Available in 3 color options'
        ],
        includes: [
            ``
        ],
        options: [
            `WKSR/W: Kicking Tee in red/white color`,
            `WKSB/W: Kicking Tee in black/white color`,
            `WKSNG/S: Kicking Tee in neon green/silver color`,
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'practice items',
        group: '',
        urlCategory: 'football',
        urlSubCategory: 'practice items',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fkicking-tee-wksrw-HQ2.jpg?alt=media&token=352ea1eb-ce22-475c-8d16-6e2255710421",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fkicking-tee-wksrw-HQ2.jpg?alt=media&token=352ea1eb-ce22-475c-8d16-6e2255710421",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fkicking-tee-wksrw-HQ1.jpg?alt=media&token=9d91eea2-00b9-4999-a7b5-87ae18ca142c"
        ]
    },{
        id: 'p150',
        name: 'Snap Coach',
        sku: 'sct100',
        description: `Unique training device for Long Snaps, Punts, Shot Gun, and Field Goals.`,
        details: [
            `Large Golden Eye target gives your long snapper a good visual aid for precision snaps`,
            `2 separate non-detachable flaps with Velcro® attachment to cover unused targets`,
            `Solid 6' x 7' Steel frame snaps together - no hardware required`,
            `Weight: 44 lbs`
        ],
        includes: [
            ``
        ],
        options: [
            `SCT100: Snap Coach 6' x 7' (44 lbs)`
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'practice items',
        group: '',
        urlCategory: 'football',
        urlSubCategory: 'practice items',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fsnap-coach-sct100-HQ1.jpg?alt=media&token=8b15e206-1f95-4d7b-bdce-8e6cc601d4c7",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fsnap-coach-sct100-HQ1.jpg?alt=media&token=8b15e206-1f95-4d7b-bdce-8e6cc601d4c7",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fsnap-coach-sct100-HQ2.jpg?alt=media&token=ea3aa299-3480-4d32-aa3d-ef65d5db5f3a",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fsnap-coach-sct100-HQ3.jpg?alt=media&token=81843bf8-7d79-4f11-be9b-e47cdd469eef",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fsnap-coach-sct100-HQ4.jpg?alt=media&token=e1c2910c-0f27-40f6-9174-5e67490db09d",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fsnap-coach-sct100-HQ5.jpg?alt=media&token=084c2cec-ba11-4a18-a318-7879c32ad580"
        ]
    },{
        id: 'p151',
        name: 'Diamond On-Deck Circle',
        sku: 'dpodc60',
        description: `Our Diamond On-Deck Circles are the most vibrant and durable on the market. We offer full customization with digitally printed graphics, giving you full control of the look and feel of your brand, increased exposure to gain more sponsors and drive revenue. It's constructed of polyvinyl and visco elastic (memory foam) to endure extensive use and harsh weather conditions. Contact our dealers or Fisher Athletic today and get your own custom On-deck Circles!`,
        details: [
            `Spike resistant`,
            `Coin shaped pattern for better traction`,
            `Digitally printed graphics`,
            `School/Facility Branding`,
            `Sponsorship opportunities`,
            `1/4" thick visco elastic (Memory foam) for comfort`,
            `.75mm thick Polyvinyl to protect your graphics`,
            `Diameter: 60"`,
            `Sold individually`
        ],
        includes: [
            ``
        ],
        options: [
            `DPODC60: Diamond On Deck Circle (5' dia)`
        ],
        usedBy: [],
        category: 'baseball',
        subCategory: 'on deck circles',
        group: '',
        urlCategory: 'baseball',
        urlSubCategory: 'on deck circles',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fon-deck-circle-dpodc60-2400-30.jpg?alt=media&token=57c9bb48-4aeb-4b1d-80e0-0c1c737b31f3",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fon-deck-circle-dpodc60-2400-30.jpg?alt=media&token=57c9bb48-4aeb-4b1d-80e0-0c1c737b31f3",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fon-deck-circle-dpodc60-2400-40.jpg?alt=media&token=cddc48ba-f5d6-4ff5-8df7-590f090f76f3",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fon-deck-circle-dpodc60-2400-39.jpg?alt=media&token=718a80ae-ec96-48b7-87ba-ad41c06c3aeb",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fon-deck-circle-dpodc60-2400-31.jpg?alt=media&token=a12afb9e-8fbb-4c2e-90be-6b4e88e82b77",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fon-deck-circle-dpodc60-2400-32.jpg?alt=media&token=dbd3d793-7cb4-4a1a-aff7-f55725c5ff9e",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fon-deck-circle-dpodc60-2400-33.jpg?alt=media&token=177302fa-bfc6-4679-861c-4ec34bf5fe6b",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fon-deck-circle-dpodc60-2400-34.jpg?alt=media&token=e709e72b-cfe3-4836-9b8a-028e3dd776a4",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fon-deck-circle-dpodc60-2400-35.jpg?alt=media&token=03cd1338-7ab4-4659-b283-27540656a93c",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fon-deck-circle-dpodc60-2400-36.jpg?alt=media&token=3f746166-ff63-476f-9f85-27a5157e48af",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fon-deck-circle-dpodc60-2400-37.jpg?alt=media&token=ae3e3a19-b290-4dd2-9b8f-191d073b6d25",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fon-deck-circle-dpodc60-2400-38.jpg?alt=media&token=8397e316-8d17-4556-a92b-d93a9f57d43b",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fon-deck-circle-dpodc60-2400-29.jpg?alt=media&token=c5f82eb6-d698-4a79-80ff-64b1a1807aca",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fon-deck-circle-dpodc60-2400-28.jpg?alt=media&token=e9b9d345-9aa3-4516-9dd6-fcdf91a7ae09",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fon-deck-circle-dpodc60-HQ23.jpg?alt=media&token=412d2f5f-fb69-4fe6-b7c6-3007b6c86f5d",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fon-deck-circle-dpodc60-HQ27.jpg?alt=media&token=6228f06a-8dc3-4db4-b936-e5b223efe1e0",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fon-deck-circle-dpodc60-HQ26.jpg?alt=media&token=17816a24-4273-4fd4-825d-5e3fd45c78b9",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fon-deck-circle-dpodc60-HQ25.jpg?alt=media&token=9be57658-f8c1-4304-8c2e-a733bf45ed2e",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fon-deck-circle-dpodc60-HQ24.jpg?alt=media&token=5882ed33-b3b2-463b-a2a5-c85a5d98c630",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fon-deck-circle-dpodc60-HQ22.jpg?alt=media&token=88208e3c-aecd-4e74-a255-73235cc5773f",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fon-deck-circle-dpodc60-HQ21.jpg?alt=media&token=11b79028-c440-43db-aed2-17fc03d9dd31",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fon-deck-circle-dpodc60-HQ1.jpg?alt=media&token=c2a9f5f0-c800-421c-bff3-1403cb1323ad",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fon-deck-circle-dpodc60-HQ2.jpg?alt=media&token=85836793-09b3-4916-96e6-1047d56808d8",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fon-deck-circle-dpodc60-HQ3.jpg?alt=media&token=f042ae86-18b7-404a-92bd-83672c5086a7",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fon-deck-circle-dpodc60-HQ4.jpg?alt=media&token=ac6a223b-9095-43e5-8c83-ae75dd2e7473",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fon-deck-circle-dpodc60-HQ5.jpg?alt=media&token=cba0f834-b019-4d46-b157-f0251da5d2d8"
            // "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fon-deck-circle-dpodc60-HQ7.jpg?alt=media&token=305c7cbd-a6c3-4026-9932-9faa1b82ba77",
            // "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fon-deck-circle-dpodc60-HQ9.jpg?alt=media&token=d261bc4b-1bd8-4173-bf96-1a3f105ff560",
            // "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fon-deck-circle-dpodc60-HQ12.jpg?alt=media&token=2c732793-2ef8-4351-91c6-84f3b52d67dd",
            // "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fon-deck-circle-dpodc60-HQ13.jpg?alt=media&token=51704617-7b14-4560-b127-8406750d5bd5",
            // "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fon-deck-circle-dpodc60-HQ14.jpg?alt=media&token=edd0094a-2c6b-47dd-a1b9-fb41a90b2319",
            // "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fon-deck-circle-dpodc60-HQ15.jpg?alt=media&token=b17cd8c4-5e59-482b-87cf-47eacb433d00",
            // "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fon-deck-circle-dpodc60-HQ16.jpg?alt=media&token=156af4a1-1817-4395-84c7-501338e21e92",
            // "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fon-deck-circle-dpodc60-HQ17.jpg?alt=media&token=5fb22e6b-8ef2-4b0f-8e9c-c6c8eeedae5d",
            // "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fon-deck-circle-dpodc60-HQ18.jpg?alt=media&token=16feea83-01f1-4d08-bc0b-f774b7980286",
            // "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fon-deck-circle-dpodc60-HQ19.jpg?alt=media&token=ab4c4ba2-7e59-483a-881f-403ce1c3732e",
            // "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fon-deck-circle-dpodc60-HQ20.jpg?alt=media&token=cebdecf2-54af-4394-bfcc-463cbeaa424c"
        ]
    },{
        id: 'p152',
        name: 'Jugs Machine',
        sku: 'jc200',
        description: `The Jugs Machine Cart allows you to move your Jugs Machine to the most convenient spot for practice. The pneumatic wheels allow for easy transport across any surface without damaging the turf. The front two wheels swivel a full 360 degrees, allowing for easy manuvering across playing surface.`,
        details: [
            `Great for simulating kickoffs!`,
            `Pneumatic wheels allow for easy transport`,
            `The two front wheels swivel a full 360 degrees allowing for easy manuvering`
        ],
        includes: [
            ``
        ],
        options: [
            `JC100: Jugs Machine Cart (cart only): 32" x 62" Wheel Base`,
            `JC200: Jugs Pro (machine only): Max throw distance is 80 yds / Good for spirals and all kicks`,
            `JC300: Jugs Field General (machine only): Max throw distance is 50 yds / Does not do end-over-end kicks`,
            `JCBB: Ball Bin: 18"W x 24"L x 15"H`,
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'practice items',
        group: '',
        urlCategory: 'football',
        urlSubCategory: 'practice items',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fjugs-machine-jc200-HQ1.jpg?alt=media&token=f0a85146-c0df-41cc-ae6f-c08da81aafac",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fjugs-machine-jc200-HQ1.jpg?alt=media&token=f0a85146-c0df-41cc-ae6f-c08da81aafac",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fjugs-machine-jc200-HQ2.jpg?alt=media&token=79d1e7b2-514c-4ab6-b487-8171ae577045",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fjugs-machine-jc200-HQ3.jpg?alt=media&token=e2ed9dbc-fcd8-4274-97bd-50e51687c2ec",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fjugs-machine-jc200-HQ4.jpg?alt=media&token=098effbe-0b4b-4534-baea-60c9babb42a4",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fjugs-machine-jc200-HQ5.jpg?alt=media&token=c04accb6-7c2b-414e-a250-26db9050f672",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fjugs-machine-jc200-HQ6.jpg?alt=media&token=021de3a1-001f-4c9e-b257-17b565636450"
        ]
    },{
        id: 'p153',
        name: 'Catch All Vest',
        sku: 'agca',
        description: `The Catch All Vest helps maximize ball catching fundamentals and repetitions. Players focus on the flight of the ball and prepare body position for its approach. Player concentrates on full arm extension and proper hand placement. Player quickly absorbs ball into body using 5 point hand placement to secure ball.`,
        details: [
            `Use with or without shoulder pads`,
            `Excellent receiver warm-up drill`,
            `Fully adjustable in both shoulders and waist`,
            `Dimensions: 6" Deep x 12" Wide x 12" High`,
        ],
        includes: [
            ``
        ],
        options: [
            `AGCA: Catch All Vest 6"Deep x 12"W x 12"H`
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'practice items',
        group: '',
        urlCategory: 'football',
        urlSubCategory: 'practice items',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fcatch-all-vest-agca-HQ1.jpg?alt=media&token=623de7c1-b09e-401b-8f5d-aee716e8d867",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fcatch-all-vest-agca-HQ1.jpg?alt=media&token=623de7c1-b09e-401b-8f5d-aee716e8d867",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fcatch-all-vest-agca-HQ2.jpg?alt=media&token=af9d70ea-c1f7-4df7-92e6-e04ac84e446d",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fcatch-all-vest-agca-HQ3.jpg?alt=media&token=833753cd-6fa7-4ffc-83cf-b119f2d694f8",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fcatch-all-vest-agca-HQ4.jpg?alt=media&token=692b9cbc-dd4d-488b-9a24-0c7a6fc6d94e"
        ]
    },{
        id: 'p154',
        name: 'Cones',
        sku: 'cone3',
        description: `Great for obstacle courses and marking player positions.`,
        details: [
            `Bright, flourescent orange PVC cones resist heat and fading`,
        ],
        includes: [
            ``
        ],
        options: [
            `CONE3: 3" Tall`,
            `CONE6: 6" Tall`,
            `CONE12: 12" Tall`,
            `CONE18: 18" Tall`,
            `CONE28: 28" Tall`,
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'practice items strength and speed',
        group: '',
        urlCategory: 'football',
        urlSubCategory: 'practice items',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fcones-HQ1.jpg?alt=media&token=eb9d99cd-2dd1-49b9-9348-8dde6bdb5835",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fcones-HQ1.jpg?alt=media&token=eb9d99cd-2dd1-49b9-9348-8dde6bdb5835",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fcones-HQ2.jpg?alt=media&token=8060ab68-c25d-4820-9592-ce8a6857ea95",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fcones-HQ3.jpg?alt=media&token=880d902e-a719-440d-8b55-01712611e7f9"
        ]
    },{
        id: 'p155',
        name: 'Discs',
        sku: 'disc12or',
        description: `Molded rubber disk markers.`,
        details: [
            `Comes in 2 sizes and colors`,
        ],
        includes: [
            ``
        ],
        options: [
            `DISC12OR: 12" Diam (orange)`,
            `DISC12GO: 12" Diam (gold)`,
            `DISC19OR: 19" Diam (orange)`,
            `DISC19GO: 19" Diam (gold)`,
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'practice items strength and speed',
        group: '',
        urlCategory: 'football',
        urlSubCategory: 'practice items',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fdisc-disc12or-HQ1.jpg?alt=media&token=e5797770-f00d-4302-ad11-7a46605b2719",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fdisc-disc12or-HQ1.jpg?alt=media&token=e5797770-f00d-4302-ad11-7a46605b2719",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fdisc-disc12or-HQ2.jpg?alt=media&token=63ed797b-72ae-4dca-a087-4fd706362c5f"
        ]
    },{
        id: 'p156',
        name: 'Electronic Down Marker',
        sku: '5004',
        description: `The Fisher 5004 Electronic Down Marker features a rechargeable battery that holds its charge for 7 hours, lasting the entire game and then some. A battery charger is included with the Fisher 5004. This marker's bold numbers are 13" tall and easy to read. The rocker switch design allows for toggle between bright and super-bright settings, making these markers great for day and night games. Switch between numbers via an easy one touch control. The 13 lb. lightweight design has fully padded, rounded corners and is water resistant.`,
        details: [
            `LED down display`,
            `13" Bold numbers`,
            `7+ hour battery life`,
            `Rechargeable unit/charger included`,
            `One touch switch between numbers`,
            `Power saving mode`,
            `Rocker switch to switch between bright and super bright`,
            `Rounded corners - fully padded head and pole`,
            `Sponsorship opportunity on front and back of head`,
            `Water resistant`,
            `3 year warranty`,
        ],
        includes: [
            ``
        ],
        options: [
            `5004: Electronic Down Marker`,
            `5004BAG: Carry Case for Electronic Down Marker`,
            `5004DPS: Custom logo/sponsor decal`,
        ],
        usedBy: [
            1,
            2,
            3,
            4,
            5,
            6,
            7, 
            8,
            9,
            10,
            11,
            12,
            13,
            14,
            15,
            16,
            17,
            18,
            19,
            20,
            21,
            22
        ],
        category: 'football',
        subCategory: 'gameday',
        group: 'Down Indicators',
        urlCategory: 'football',
        urlSubCategory: 'gameday',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Felectronic-down-marker-5004-HQ1.jpg?alt=media&token=14eebaa1-8c14-44d3-88cd-b8fe96ee4bb2",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Felectronic-down-marker-5004-HQ1.jpg?alt=media&token=14eebaa1-8c14-44d3-88cd-b8fe96ee4bb2",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Felectronic-down-marker-5004-HQ2.jpg?alt=media&token=1a8c54eb-a453-4df9-b2d0-a149fc56d7dc",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Felectronic-down-marker-5004-HQ3.jpg?alt=media&token=4dcecec4-5c6c-4fc5-9766-9f0f4c558404",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Felectronic-down-marker-5004-HQ4.jpg?alt=media&token=f03e11cc-4449-4d03-bdb2-5d023498fef8",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Felectronic-down-marker-5004-HQ5.jpg?alt=media&token=86c719cc-22c2-47bf-9904-34fc6d3d6bc7",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Felectronic-down-marker-5004-HQ6.jpg?alt=media&token=cc71c834-2578-4231-ab76-0ef29021ed45"
        ]
    },{
        id: 'p157',
        name: 'Flip Indicator',
        sku: '3002',
        description: `The Fisher Flip Indicator is completely padded for safety by using closed cell foam. This brightly colored indicator makes it easy for your officials and fans to see the down lines, every time!`,
        details: [
            `Padded for safety`,
            `Available in orange or gold`,
            `2" Padded Pennant`,
            `12" tall, 2 color numbers with lifetime warranty`,
            `Height: 7'`,
        ],
        includes: [
            ``
        ],
        options: [
            `3002OR: Orange Indicator`,
            `3002GO: Gold Indicator`,
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'gameday',
        group: 'Down Indicators',
        urlCategory: 'football',
        urlSubCategory: 'gameday',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fflip-indicator-3002-HQ1.jpg?alt=media&token=c408696c-ec2c-4b11-b3b7-45f12415cc48",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fflip-indicator-3002-HQ1.jpg?alt=media&token=c408696c-ec2c-4b11-b3b7-45f12415cc48",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fflip-indicator-3002-HQ2.jpg?alt=media&token=22451de3-f74d-4c7a-ae2e-d57db2aa7e4c"
        ]
    },{
        id: 'p158',
        name: 'Digital Dial-A-Down',
        sku: '4002',
        description: `The Digital Dial-A-Down is padded for safety by using closed cell foam.`,
        details: [
            `Padded for safety using closed-cell foam`,
            `13" digital numbers operated by a lever system`,
            `7' tall fiberglass padded pole`,
        ],
        includes: [
            ``
        ],
        options: [
            `4002: 7' Digital Dial-A-Down`,
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'gameday',
        group: 'Down Indicators',
        urlCategory: 'football',
        urlSubCategory: 'gameday',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fdigital-dial-a-down-4002-HQ1.jpg?alt=media&token=1a75c1fb-8258-4670-a551-c6a61b358bd6",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fdigital-dial-a-down-4002-HQ1.jpg?alt=media&token=1a75c1fb-8258-4670-a551-c6a61b358bd6",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fdigital-dial-a-down-4002-HQ2.jpg?alt=media&token=f60becbe-f6df-4a6d-a6eb-b3ff6c08f41d"
        ]
    },{
        id: 'p159',
        name: 'Economy Flip Indicator',
        sku: '2002',
        description: `The Fisher Economy Flip Indicator is completely padded for safety by using closed cell foam. This brightly colored indicator makes it easy for your officials and fans to see the down lines, every time!`,
        details: [
            `Galavanized steel pole wrapped in 3/4" closed-cell foam`,
            `12" Tall, 2 color numbers with lifetime warranty`,
            `Height: 7'`,
        ],
        includes: [
            ``
        ],
        options: [
            `2002: 7' Economy Flip Indicator`,
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'gameday',
        group: 'Down Indicators',
        urlCategory: 'football',
        urlSubCategory: 'gameday',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2F2002-updated-2-color.jpg?alt=media&token=6def17b5-a8bc-4995-ba60-af6bd4e26b9b",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2F2002-updated-2-color.jpg?alt=media&token=6def17b5-a8bc-4995-ba60-af6bd4e26b9b",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2F2002-updated-2-color-close-up.jpg?alt=media&token=eccd8a18-9b42-4632-b51b-f85a202748ff"
        ]
    },{
        id: 'p160',
        name: 'Chain Set Target Marker',
        sku: '407685',
        description: `The Chain Set Target Marker marks the 10 yard point for first downs and is the perfect compliment to use with chain sets (sold separately). The marker is bright orange with black target arrows, is weighted for stability, and is equipped with an end handle for convenient transport. Sold as a set of two.`,
        details: [
            `Set of 2`,
            `38" x 10"W`,
            `Weighs 2 lbs`,
            `Orange with black arrows`,
            `Bright target guides players`,
            `End handle for carrying`,
            `Marks 10 yard distance for first downs`
        ],
        includes: [
            ``
        ],
        options: [
            `407685: Chain Set Target Marker`,
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'gameday',
        group: 'Down Indicators',
        urlCategory: 'football',
        urlSubCategory: 'gameday',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fchain-set-target-marker-407685-HQ1.jpg?alt=media&token=de80dd39-7140-472d-bbf3-f0579cf0cab3",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fchain-set-target-marker-407685-HQ1.jpg?alt=media&token=de80dd39-7140-472d-bbf3-f0579cf0cab3",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fchain-set-target-marker-407685-HQ2.jpg?alt=media&token=5686ebf5-7792-46ff-b0c9-6aaf8d5e41ea"
        ]
    },{
        id: 'p422',
        name: 'Deluxe Chain Set Target Marker',
        sku: '407685DLX',
        description: `The Chain Set Target Marker marks the 10 yard point for first downs and is the perfect compliment to use with chain sets (sold separately). The marker is bright orange with black target arrows. The deluxe version is constructed out of spike-proof 75mm thick Polyvinyl and 1/4" memory foam. Marker comes equipped with an end handle for convenient transport and easy storage. Deluxe target markers are sold individually.`,
        details: [
            `Sold individually`,
            `40" x 10"W`,
            `Weighs 5 lbs`,
            `Orange with black arrows`,
            `Bright target guides players`,
            `End handle for carrying and transport`,
            `Marks 10 yard distance for first downs`,
            `Spike resistant`
        ],
        includes: [
            ``
        ],
        options: [
            `407685DLX: Chain Set Target Marker`,
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'gameday',
        group: 'Down Indicators',
        urlCategory: 'football',
        urlSubCategory: 'gameday',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fdeluxe-target-marker-407685dlx-thumb.jpg?alt=media&token=0230b5ab-f589-4f86-a483-20bb8790a653",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fdeluxe-target-marker-407685dlx-thumb.jpg?alt=media&token=0230b5ab-f589-4f86-a483-20bb8790a653",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2F407685dlx-target-marker-4.jpg?alt=media&token=c6ddabee-2fda-479a-b5c5-9f58a4f9844b",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2F407685dlx-target-marker-1.jpg?alt=media&token=5e43a377-a76a-4d5f-a0af-951acfc6c5dd",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2F407685dlx-target-marker-2.jpg?alt=media&token=79cd92bc-a61e-42dd-b9fe-7dafa09b3c58",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2F407685dlx-target-marker-3.jpg?alt=media&token=3bac0710-aff5-4fe4-b51e-fa2cc8a972cd"
        ]
    },{
        id: 'p161',
        name: 'X Indicator',
        sku: '3010',
        description: `The Fisher X Indicator is completely padded for safety by using closed cell foam.`,
        details: [
            `Indicator pad is 3" thick`,
            `Pole padded with 3/4" closed-cell foam`,
            `Height: 7'`,
        ],
        includes: [
            ``
        ],
        options: [
            `3010: X Indicator`,
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'gameday',
        group: 'Down Indicators',
        urlCategory: 'football',
        urlSubCategory: 'gameday',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fx-indicator-3010-HQ1.jpg?alt=media&token=d6013e04-74c1-43ef-acd7-cdbd5ffdedb6",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fx-indicator-3010-HQ1.jpg?alt=media&token=d6013e04-74c1-43ef-acd7-cdbd5ffdedb6",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fx-indicator-3010-HQ2.jpg?alt=media&token=1e071099-0f44-4685-afb9-51a63631124b"
        ]
    },{
        id: 'p162',
        name: 'Arrow Indicator',
        sku: '3010a',
        description: `The Fisher Arrow Indicator is completely padded for safety by using closed cell foam.`,
        details: [
            `Indicator pad is 3" thick`,
            `Pole padded with 3/4" closed-cell foam`,
            `Height: 7'`,
        ],
        includes: [
            ``
        ],
        options: [
            `3010A: Arrow Indicator`,
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'gameday',
        group: 'Down Indicators',
        urlCategory: 'football',
        urlSubCategory: 'gameday',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Farrow-indicator-3010a-HQ1.jpg?alt=media&token=54771b6e-fdfb-4739-b7bf-fe7db0584e91",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Farrow-indicator-3010a-HQ1.jpg?alt=media&token=54771b6e-fdfb-4739-b7bf-fe7db0584e91",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Farrow-indicator-3010a-HQ2.jpg?alt=media&token=0f4eb59b-e183-4c87-9dde-22c0d391623d"
        ]
    },{
        id: 'p163',
        name: 'Economy Chain Set',
        sku: '2003',
        description: `Fisher's 7' Economy series chain set features poles made from PVC piping and include crutch tip ends to protect the field and players.  The set meets NCAA and NFHS regulations.`,
        details: [
            `Set includes two 10 yard indicators`,
            `Available in orange`,
            `Vinyl only pennant`,
            `Pole made out of PVC piping`,
            `Height: 7'`,
        ],
        includes: [
            ``
        ],
        options: [
            `2003: Economy Chain Set`,
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'gameday',
        group: 'Chain Sets',
        urlCategory: 'football',
        urlSubCategory: 'gameday',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Feconomy-chain-set-2003-HQ1.jpg?alt=media&token=c35e6718-f2f4-4667-a20f-f096e5fd850f",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Feconomy-chain-set-2003-HQ1.jpg?alt=media&token=c35e6718-f2f4-4667-a20f-f096e5fd850f",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Feconomy-chain-set-2003-HQ2.jpg?alt=media&token=33c1421d-2401-4616-a161-38ae455aba51"
        ]
    },{
        id: 'p164',
        name: 'Varsity Chain Set',
        sku: '3003',
        description: `Fisher's 7' Varsity series chain set has fully padded galvanized steel poles with crutch tip ends to protect the field and players.  The set meets NCAA and NFHS regulations.`,
        details: [
            `Set includes two 10 yard indicators`,
            `Available in orange and gold`,
            `Vinyl only pennant`,
            `Poles made of galvanized steel and padded in 3/4" foam`,
            `Height: 7'`,
        ],
        includes: [
            ``
        ],
        options: [
            `3003OR: Orange Varsity Chain Set`,
            `3003GO: Gold Varsity Chain Set`,
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'gameday',
        group: 'Chain Sets',
        urlCategory: 'football',
        urlSubCategory: 'gameday',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fvarsity-chain-set-3003-HQ1.jpg?alt=media&token=3a691b74-5df0-426c-a5b1-c1967ebef012",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fvarsity-chain-set-3003-HQ1.jpg?alt=media&token=3a691b74-5df0-426c-a5b1-c1967ebef012",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fvarsity-chain-set-3003-HQ2.jpg?alt=media&token=9dbb1b3a-4229-4751-bbfa-38fef4d891dd"
        ]
    },{
        id: 'p165',
        name: 'Pro Chain Set',
        sku: '3005',
        description: `Fisher's 8' Pro series chain set has fully padded galvanized steel poles with crutch tip ends to protect the field and players.  The set meets NCAA and NFHS regulations.`,
        details: [
            `Set includes two 10 yard indicators`,
            `Available in orange`,
            `1" padded pennant with protective sleeve`,
            `Poles made of galvanized steel and padded in 3/4" foam`,
            `Height: 8'`,
        ],
        includes: [
            ``
        ],
        options: [
            `3005: Pro Chain Set`,
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'gameday',
        group: 'Chain Sets',
        urlCategory: 'football',
        urlSubCategory: 'gameday',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpro-chain-set-3005-HQ1.jpg?alt=media&token=9905ce7e-2edd-4a65-a9b5-ae9133c46da3",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpro-chain-set-3005-HQ1.jpg?alt=media&token=9905ce7e-2edd-4a65-a9b5-ae9133c46da3",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpro-chain-set-3005-HQ2.jpg?alt=media&token=9db743e6-ee7e-480a-9208-b3063d246685",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpro-chain-set-3005-HQ3.jpg?alt=media&token=e72be583-dcf3-473b-8df7-2226b049ad7b",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpro-chain-set-3005-HQ4.jpg?alt=media&token=92254c18-7822-416b-8ac5-f2bc2f754588",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpro-chain-set-3005-HQ5.jpg?alt=media&token=0241e310-80bc-425a-96b2-d3337010b5a8",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpro-chain-set-3005-HQ6.jpg?alt=media&token=ca087223-502d-4b3c-8b8d-67c29da443df"
        ]
    },{
        id: 'p166',
        name: 'Custom Bullseye Cover',
        sku: '3000be',
        description: `Just let us know your diameter and thickness of your current Bullseye and Fisher will transform your current chain set into your school logo, game sponsors, or conference logo. Sold in sets of two.`,
        details: [
            'Great for branding',
            'Add custom artwork of your choice',
            'Sold in sets of two',
        ],
        includes: [
            ``
        ],
        options: [
            `3000BE: Custom Bullseye Cover and Pad`,
            `3000BECO: Custom Bullseye Cover only`
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'gameday',
        group: 'Chain Sets',
        urlCategory: 'football',
        urlSubCategory: 'gameday',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fcustom-bullseye-cover-3000be-HQ1.jpg?alt=media&token=61feae21-bf3f-40e8-b9a4-dec3bbafa4e3",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fcustom-bullseye-cover-3000be-HQ1.jpg?alt=media&token=61feae21-bf3f-40e8-b9a4-dec3bbafa4e3",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fcustom-bullseye-cover-3000be-HQ2.jpg?alt=media&token=23ccdb2d-dbe3-470e-b9a3-f2dc68dbee9c"
        ]
    },{
        id: 'p167',
        name: 'Chain Set Package 1',
        sku: '2011pk',
        description: `This Fisher chain is durable, safe and meets all NCAA and NFHSA regulations. To protect players, the bullseyes are made with a 4" thick polyfoam and the bottom of the poles have crutch tips.  The down indicator is constructed from a 7' PVC piping pole wrapped in 3/4" thick closed cell foam to ensure durability and safety.  For easy visibility, the 7'H economy flip indicators have a 12" tall 1 color number. The set includes a 7'H Economy Flip Indicator and 7'H Economy Chain Set.`,
        details: [
            'Includes: Economy Flip Indicator and Economy Chain Set'
        ],
        includes: [
            `2002: Economy Flip Indicator`,
            `2003: Economy Chain Set`
        ],
        options: [
            ``
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'gameday',
        group: 'Chain Set Packages',
        urlCategory: 'football',
        urlSubCategory: 'gameday',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fchain-set-package-1-2011pk-HQ1.jpg?alt=media&token=9fea4ad6-f9c6-4b66-a3af-ffe211cc359d",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fchain-set-package-1-2011pk-HQ1.jpg?alt=media&token=9fea4ad6-f9c6-4b66-a3af-ffe211cc359d",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Feconomy-chain-set-2003-HQ2.jpg?alt=media&token=33c1421d-2401-4616-a161-38ae455aba51",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Feconomy-flip-indicator-2002-HQ2.jpg?alt=media&token=12510f8d-d828-4324-a122-61381701cb46"
        ]
    },{
        id: 'p168',
        name: 'Chain Set Package 2',
        sku: '3011pk',
        description: `This Fisher chain is durable, safe and meets all NCAA and NFHSA regulations.  To protect players, the bullseyes are made with a 4" thick polyfoam and the bottom of the poles have crutch tips. The down indicator is constructed from a galvanized steel pole wrapped in 3/4" thick closed cell foam to ensure durability and safety.  For easy visibility, the 7'H flip indicator has a 12" tall 1 color number. The set includes a 7'H Flip Indicator and a 7'H Chain Set.`,
        details: [
            `Includes: Flip Indicator and 7' Chain Set`
        ],
        includes: [
            `3002OR: Flip Indicator`,
            `3003OR: 7' Chain Set`
        ],
        options: [
            ``
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'gameday',
        group: 'Chain Set Packages',
        urlCategory: 'football',
        urlSubCategory: 'gameday',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fchain-set-package-2-3011pk-HQ1.jpg?alt=media&token=0ab118a2-af17-43f7-af44-4938dde8e486",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fchain-set-package-2-3011pk-HQ1.jpg?alt=media&token=0ab118a2-af17-43f7-af44-4938dde8e486",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fvarsity-chain-set-3003-HQ2.jpg?alt=media&token=9dbb1b3a-4229-4751-bbfa-38fef4d891dd",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fflip-indicator-3002-HQ2.jpg?alt=media&token=22451de3-f74d-4c7a-ae2e-d57db2aa7e4c"
        ]
    },{
        id: 'p169',
        name: 'Chain Set Package 3',
        sku: '3015pk',
        description: `This Fisher chain is durable, safe and meets all NCAA and NFHSA regulations. To protect players, the bullseyes are made with a 4" thick polyfoam and the bottom of the poles have crutch tips. The down indicator is constructed from a galvanized steel pole wrapped in 3/4" thick closed cell foam to ensure durability and safety. For easy visibility, the 7'H flip indicator has a 12" tall 1 color number. The set includes a 7'H Flip Indicator and an 8'H Chain Set.`,
        details: [
            `Includes: Flip Indicator and 8' Chain Set`
        ],
        includes: [
            `3002OR: Flip Indicator`,
            `3005OR: 8' Chain Set`
        ],
        options: [
            ``
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'gameday',
        group: 'Chain Set Packages',
        urlCategory: 'football',
        urlSubCategory: 'gameday',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fchain-set-package-3-3015pk-HQ1.jpg?alt=media&token=008e7929-8ac2-4466-9056-ee83e7582e0e",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fchain-set-package-3-3015pk-HQ1.jpg?alt=media&token=008e7929-8ac2-4466-9056-ee83e7582e0e",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpro-chain-set-3005-HQ2.jpg?alt=media&token=9db743e6-ee7e-480a-9208-b3063d246685",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fflip-indicator-3002-HQ2.jpg?alt=media&token=22451de3-f74d-4c7a-ae2e-d57db2aa7e4c"
        ]
    },{
        id: 'p170',
        name: 'Chain Set Package 5',
        sku: '3013pk',
        description: `This Fisher chain set field marking package is what your football teams needs to start the season because it is durable, safe and meets all NCAA and NFHSA regulations.  To protect players, the bullseyes are made with a 4" thick polyfoam and the bottom of the poles have crutch tips. The down indicator is constructed from a 7' galvanized steel pole wrapped in 3/4" thick closed cell foam to ensure durability and safety. For easy visibility, the 7'H flip indicators have a 12" tall 1 color number and are padded for safety. The triangular markers have a solid polyfoam body and weighted base with rocket red covers for visibility and stability.  The chain gang vests are made from vinyl nylon with elastic Velcro® straps for easy adjustments.  Easily identify the end zone with these orange vinyl nylon stand-up pylons that stand strand at 18" high.  These target markers are bright so that it can guide players to the first down position. This package includes: flip indicator, varsity chain set, (11) sideline markers, (3) vests, deluxe pylons (3 sets, 12 total), and (2) target markers.`,
        details: [
            `Includes: Flip Indicator, 7' Chain Set, 11 Sideline Markers, 3 Orange Vests, 3 sets of 4 Deluxe Pylons, and 2 Target Markers`
        ],
        includes: [
            `3002OR: Flip Indicator`,
            `3003OR: 7' Chain Set`,
            `SLMTOR: 11 Sideline Markers`,
            `VEST3OR: 3 Orange Vests`,
            `PY1: Deluxe Pylons (3 sets of 4)`,
            `407685: Target Markers (2)`,
        ],
        options: [
            ``
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'gameday',
        group: 'Chain Set Packages',
        urlCategory: 'football',
        urlSubCategory: 'gameday',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fchain-set-package-5-3013pk-HQ1.jpg?alt=media&token=c7e77c98-787c-44cc-bd0d-b0dec9fe5a83",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fchain-set-package-5-3013pk-HQ1.jpg?alt=media&token=c7e77c98-787c-44cc-bd0d-b0dec9fe5a83",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fvarsity-chain-set-3003-HQ2.jpg?alt=media&token=9dbb1b3a-4229-4751-bbfa-38fef4d891dd",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fflip-indicator-3002-HQ2.jpg?alt=media&token=22451de3-f74d-4c7a-ae2e-d57db2aa7e4c",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fchain-set-target-marker-407685-HQ1.jpg?alt=media&token=de80dd39-7140-472d-bbf3-f0579cf0cab3",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fdeluxe-pylons-py1-HQ1.jpg?alt=media&token=6286bb58-7ae8-4410-b49f-9d275466a2e6"
        ]
    }
    ,{
        id: 'p171',
        name: 'Molded Pylon',
        sku: 'py2dp4',
        description: `Fisher Athletic Molded Pylons are flexible and tear resistant, holding up to the toughest hits during a game. Enhance the look of your end zone by adding your team or conference logo to the pylons, included at no extra charge. There are unlimited color options available for your custom design. Just email us your artwork to get the ball rolling. These fluorescent orange pylons are offered in sets of 4, 8, or 12 and are constructed with molded foam that holds its shape while being extra shock absorbing and safe when players make contact. The weighted bottoms provide stability so you don’t have to worry about them falling over on their own.`,
        details: [
            `Safe contact for players`,
            `Hold form and shape`,
            `Shock absorbing`,
            `Weighted bottom`,
            `Unlimited color printing`,
        ],
        includes: [
            ``
        ],
        options: [
            `PY2DP4: Set of 4 pylons`,
            `PY2DP8: Set of 8 pylons`,
            `PY2DP12: Set of 12 pylons`,
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'gameday',
        group: 'Pylons',
        urlCategory: 'football',
        urlSubCategory: 'gameday',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fmolded-pylons-py2dp4-HQ1.jpg?alt=media&token=d11f5a3a-edcb-40ac-81d5-677e9ca5d356",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fmolded-pylons-py2dp4-HQ1.jpg?alt=media&token=d11f5a3a-edcb-40ac-81d5-677e9ca5d356",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fmolded-pylons-py2dp4-HQ2.jpg?alt=media&token=52bba010-6826-4268-8ab6-c77468571289",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fmolded-pylons-py2dp4-HQ3.jpg?alt=media&token=bfd6f435-f877-4003-8bc0-c5d5ffe7ddf3",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fmolded-pylons-py2dp4-HQ4.jpg?alt=media&token=8b00df21-49b0-4f2d-9794-291cdaccb867",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fmolded-pylons-py2dp4-HQ5.jpg?alt=media&token=f37ae639-990d-4139-8a84-7852eb86bf72"
        ]
    },{
        id: 'p172',
        name: 'Plain Molded Pylon',
        sku: 'py2',
        description: `Fisher Athletic Molded Pylons are flexible and tear resistant, holding up to the toughest hits during a game. There are unlimited color options available for your custom design. These fluorescent orange pylons are offered in sets of 4 and are constructed with molded foam that holds its shape while being extra shock absorbing and safe when players make contact. The weighted bottoms provide stability so you don’t have to worry about them falling over on their own.`,
        details: [
            `Safe contact for players`,
            `Hold form and shape`,
            `Shock absorbing`,
            `Weighted bottom`,
        ],
        includes: [
            ``
        ],
        options: [
            `PY2: Set of 4 pylons`,
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'gameday',
        group: 'Pylons',
        urlCategory: 'football',
        urlSubCategory: 'gameday',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fmolded-pylons-plain-py2-HQ1.jpg?alt=media&token=14990653-8a7c-4623-8b13-5b5fdda94a4f",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fmolded-pylons-plain-py2-HQ1.jpg?alt=media&token=14990653-8a7c-4623-8b13-5b5fdda94a4f",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fmolded-pylons-plain-py2-HQ2.jpg?alt=media&token=cb94e8e5-1c88-436d-a73a-5f929fa516c5",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fmolded-pylons-plain-py2-HQ3.jpg?alt=media&token=2e5d4da3-b5e7-409c-a27b-22a492ea9e7b",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fmolded-pylons-py2dp4-HQ4.jpg?alt=media&token=8b00df21-49b0-4f2d-9794-291cdaccb867",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fmolded-pylons-py2dp4-HQ5.jpg?alt=media&token=f37ae639-990d-4139-8a84-7852eb86bf72"
        ]
    },{
        id: 'p173',
        name: 'Digitally Printed Pylon',
        sku: 'py1dp4',
        description: `Our Digitally Printed Pylons allow for you to put your school's logo in as many color options as you want, on any of the four sides of the pylon. Max print size is 3 1/4" wide.`,
        details: [
            `Weight: 3 lbs`,
            `Dimensions: 4" x 4" x 18"H`,
        ],
        includes: [
            ``
        ],
        options: [
            `PY1DP4: Set of 4 pylons`,
            `PY1DP8: Set of 8 pylons`,
            `PY1DP12: Set of 12 pylons`,
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'gameday',
        group: 'Pylons',
        urlCategory: 'football',
        urlSubCategory: 'gameday',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpy1dp-furman.jpg?alt=media&token=13f690bf-539c-485d-bb23-f0b90ce36fb6",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpy1dp-furman.jpg?alt=media&token=13f690bf-539c-485d-bb23-f0b90ce36fb6",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fdigitally-printed-pylons-py1dp4-HQ5.jpg?alt=media&token=136ff88a-f14c-4d27-852e-d222b4876ed5",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fdigitally-printed-pylons-py1dp4-HQ2.jpg?alt=media&token=97be19f3-4d45-4ba3-b4be-63946df35319",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fdigitally-printed-pylons-py1dp4-HQ3.jpg?alt=media&token=665ebe66-c651-479e-93d2-f57c00f8eaa8",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpy1dp-FF.jpg?alt=media&token=39624f22-e13e-4320-a810-6070f479d78e",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpy1dp-rice.jpg?alt=media&token=4d881269-e7f9-446f-8b0d-664554bf7345",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fdigitally-printed-pylons-py1dp4-HQ4.jpg?alt=media&token=113011a5-1bca-40a2-8bb2-21af82039cb8"
        ]
    },{
        id: 'p174',
        name: 'Deluxe Pylon',
        sku: 'py1',
        description: `Our Deluxe Pylons are made from high quality vinyl nylon and are built to always stand straight.`,
        details: [
            `Weight: 3 lbs`,
            `Dimensions: 4" x 4" x 18"H`,
        ],
        includes: [
            ``
        ],
        options: [
            `PY1: Set of 4 pylons (orange)`,
            `PY1GO: Set of 4 pylons (gold)`,
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'gameday',
        group: 'Pylons',
        urlCategory: 'football',
        urlSubCategory: 'gameday',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fdeluxe-pylons-py1-HQ1.jpg?alt=media&token=6286bb58-7ae8-4410-b49f-9d275466a2e6",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fdeluxe-pylons-py1-HQ1.jpg?alt=media&token=6286bb58-7ae8-4410-b49f-9d275466a2e6",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fdeluxe-pylons-py1-HQ2.jpg?alt=media&token=f43d3f60-2c63-4777-8012-6a627d714bc5",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fdeluxe-pylons-py1-HQ3.jpg?alt=media&token=1d9b3087-108a-471a-ada0-ba1d293af2fd",
        ]
    },{
        id: 'p175',
        name: 'Economy Pylon',
        sku: 'py1e',
        description: `Our Economy Pylons are filled in the bottom with sand so they stand straight up. The pylons are official size and are covered with durable vinyl that should provide your program with years of use.`,
        details: [
            `Weight: 3 lbs`,
            `Dimensions: 4" x 4" x 18"H`,
        ],
        includes: [
            ``
        ],
        options: [
            `PY1E: Set of 4 pylons (orange)`,
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'gameday',
        group: 'Pylons',
        urlCategory: 'football',
        urlSubCategory: 'gameday',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Feconomy-pylons-py1e-HQ1.jpg?alt=media&token=55d0a441-ffbb-4e8b-aecc-ad5915fbe579",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Feconomy-pylons-py1e-HQ1.jpg?alt=media&token=55d0a441-ffbb-4e8b-aecc-ad5915fbe579",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Feconomy-pylons-py1e-HQ2.jpg?alt=media&token=15cc95a4-8477-4ba0-aaed-455319403b0c",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Feconomy-pylons-py1e-HQ3.jpg?alt=media&token=bf150e86-7251-4a42-93fa-e51cb68c1da6",
        ]
    },{
        id: 'p176',
        name: 'Pylon Mesh Bag',
        sku: 'pybg',
        description: `Our Pylon Mesh Bag holds 12 pylons and features a durable mesh construction with carry handles.`,
        details: [
            `Holds 12 pylons`,
            `Durable mesh construction`,
        ],
        includes: [
            ``
        ],
        options: [
            `PYBG: Pylon Mesh Bag`,
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'gameday',
        group: 'Accessory Bags',
        urlCategory: 'football',
        urlSubCategory: 'gameday',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fmesh-bag-pybg-HQ1.jpg?alt=media&token=98dae540-063c-456a-9765-e49fcffeae77",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fmesh-bag-pybg-HQ1.jpg?alt=media&token=98dae540-063c-456a-9765-e49fcffeae77",
        ]
    },{
        id: 'p177',
        name: 'Foldable Markers',
        sku: 'slmor11',
        description: `Thick, polyfoam, rocket red covered markers. Markers fold for compact storage. Large 12" numerals.`,
        details: [
            `Large 12" numerals`,
            `Thick polyfoam`,
            `Set of 11`,
        ],
        includes: [
            ``
        ],
        options: [
            `SLMOR11: Black number on orange`,
            `SLMBK11: White number on black`,
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'gameday',
        group: 'Sideline Markers',
        urlCategory: 'football',
        urlSubCategory: 'gameday',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ffoldable-marker-slmor11-HQ1.jpg?alt=media&token=52be6839-9678-46d2-89d5-9fc3f43df4de",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ffoldable-marker-slmor11-HQ1.jpg?alt=media&token=52be6839-9678-46d2-89d5-9fc3f43df4de",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ffoldable-marker-slmor11-HQ2.jpg?alt=media&token=0afa926e-3712-4070-b211-ac1a3cdad32a",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ffoldable-marker-slmor11-HQ3.jpg?alt=media&token=feca4a82-f136-422e-8cf3-9c932c8cec42",
        ]
    },{
        id: 'p178',
        name: 'Triangular Markers',
        sku: 'slmtor',
        description: `Solid polyfoam body, weighted base with rocket red covers. Large numerals (12" tall). Set of eleven includes one 50, and two each 40's, 30's, 20's, 10's and G's.`,
        details: [
            `Large 12" numerals`,
            `Solid polyfoam`,
            `Set of 11`,
            `Weight: 5 lbs each`,
            `Dimensions: 7 1/2"W x 14 1/2"L x 13"Tall`,
        ],
        includes: [
            ``
        ],
        options: [
            `SLMTOR: Black number on orange`,
            `SLMTBK: White number on black`,
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'gameday',
        group: 'Sideline Markers',
        urlCategory: 'football',
        urlSubCategory: 'gameday',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ftriangular-marker-slmtor-HQ1.jpg?alt=media&token=645dc584-c000-44c7-b97e-038980c14034",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ftriangular-marker-slmtor-HQ1.jpg?alt=media&token=645dc584-c000-44c7-b97e-038980c14034",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ftriangular-marker-slmtor-HQ2.jpg?alt=media&token=8d082acb-0062-4f8a-af66-e8fcb32054df",
        ]
    },{
        id: 'p179',
        name: 'Large Triangular Markers',
        sku: 'slmtor115',
        description: `Solid polyfoam body, weighted base with rocket red covers. Large numerals (12" tall). Set of eleven includes one 50, and two each 40's, 30's, 20's, 10's and G's.`,
        details: [
            `Large 12" numerals`,
            `Solid polyfoam`,
            `Set of 11`,
            `Weight: 5 lbs each`,
            `Dimensions: 11 1/2"W x 16"L x 13"Tall`,
        ],
        includes: [
            ``
        ],
        options: [
            `SLMTOR115: Black number on orange`,
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'gameday',
        group: 'Sideline Markers',
        urlCategory: 'football',
        urlSubCategory: 'gameday',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Flarge-triangular-marker-slmtor115-HQ1.jpg?alt=media&token=94b075f6-756a-463d-8305-59310d2e5ba7",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Flarge-triangular-marker-slmtor115-HQ1.jpg?alt=media&token=94b075f6-756a-463d-8305-59310d2e5ba7",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Flarge-triangular-marker-slmtor115-HQ2.jpg?alt=media&token=ee9ab12a-edeb-40bd-94cd-f5e31dd198ba",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Flarge-triangular-marker-slmtor115-HQ3.jpg?alt=media&token=74181029-6b24-481e-9a4a-4d38e0976b29",
        ]
    },{
        id: 'p180',
        name: 'Sideline Marker Covers',
        sku: 'slmtcodp11',
        description: `Our digitally printed Sideline Marker Covers are the perfect way to upgrade your current markers. Each set of covers is designed to your specific measurements and easily slides over your existing marker. Attaches with velcro flap on bottom of marker.`,
        details: [
            `Large 12" numerals`,
            `Set of 11`,
        ],
        includes: [
            ``
        ],
        options: [
            `SLMTCODP11: Set of 11 Sideline Marker Covers`,
            `SLMTCODP1: One Sideline Marker Cover`,
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'gameday',
        group: 'Sideline Markers',
        urlCategory: 'football',
        urlSubCategory: 'gameday',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fsideline-marker-cover-slmtcodp11-HQ1.jpg?alt=media&token=08dab1e8-6404-4de9-abc9-251e28858758",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fsideline-marker-cover-slmtcodp11-HQ1.jpg?alt=media&token=08dab1e8-6404-4de9-abc9-251e28858758",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fsideline-marker-cover-slmtcodp11-HQ2.jpg?alt=media&token=146145fb-5391-44ee-8235-8de61ff0ec47",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fsideline-marker-cover-slmtcodp11-HQ3.jpg?alt=media&token=d9c9ef61-49d0-4ac0-98de-3d3e29d0fd09",
        ]
    },{
        id: 'p181',
        name: 'Spirit Markers',
        sku: 'slmtso',
        description: `Custom Sideline Markers are available in your school colors. Same construction as our SLMT series sideline markers. 18 oz coated vinyl in your choice of 16 colors. Available in multiple colors, numbers, and logos on both ends.`,
        details: [
            `Large 12" numerals`,
            `Solid polyfoam`,
            `Set of 11`,
            `Weight: 5 lbs each`,
            `Dimensions: 11 1/2"W x 16"L x 13"Tall`,
        ],
        includes: [
            ``
        ],
        options: [
            `SLMTSO: Set of 11 custom sideline markers`,
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'gameday',
        group: 'Sideline Markers',
        urlCategory: 'football',
        urlSubCategory: 'gameday',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fcustom-sideline-marker-slmtso-2400-7.jpg?alt=media&token=0e4374e2-d679-47ab-9b6d-1a2b9d06a832",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fslmtso-knights.jpg?alt=media&token=1a2f01c9-0c73-432d-ac01-8de58484f493",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fslmtso-spartans.jpg?alt=media&token=92f3efe2-9446-4bb7-b028-81e8b22ce14e",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fcustom-sideline-marker-slmtso-2400-7.jpg?alt=media&token=0e4374e2-d679-47ab-9b6d-1a2b9d06a832",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fcustom-sideline-marker-slmtso-2400-8.jpg?alt=media&token=638d4caa-b373-4e5f-a826-c2f4547d682a",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fcustom-sideline-marker-slmtso-2400-6.jpg?alt=media&token=d44de647-d525-4cec-8d22-133c97ab5977",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fcustom-sideline-marker-slmtso-2400-9.jpg?alt=media&token=df1a925a-c447-4bb1-b818-8bab31f4e477",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fcustom-sideline-marker-slmtso-2400-5.jpg?alt=media&token=1d686a6f-183e-4a8f-8128-91f5f02e35cd",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fcustom-sideline-marker-slmtso-HQ1.jpg?alt=media&token=2ee60762-bc19-4563-ae4c-bce5970ce2f8",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fslmtso-vikings.jpg?alt=media&token=90d7347f-54b5-444e-a5a9-63965b0bd680",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fcustom-sideline-marker-slmtso-HQ2.jpg?alt=media&token=48b263ac-b24a-4de0-b724-56fad4edd0ce",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fcustom-sideline-marker-slmtso-HQ3.jpg?alt=media&token=00410f0e-e524-4617-bc35-c7bbdddc8a5e",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fcustom-sideline-marker-slmtso-HQ4.jpg?alt=media&token=c8d785ca-4bc3-4b6c-b216-4bd74265dc96",
        ]
    },{
        id: 'p182',
        name: 'Custom Chain Gang Vest',
        sku: 'provest',
        description: `The Custom Chain Gang Vest can be customized the match your schools branding. These high quality vests are made of heavy duty vinyl mesh and feature elastic Velcro straps for a one size fits all design.`,
        details: [
            `Made of heavy duty vinyl mesh`,
            `Velcro straps allow for one size fits all design`,
            `Add your custom logos or artwork`,
            `No limit to the amount of colors that can be printed on vests`,
            `Print on both sides of the vest`,
            `Dimensions: 18"W x 26 3/4"H`,
        ],
        includes: [
            ``
        ],
        options: [
            `PROVEST: 18"W x 26 3/4"H`,
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'gameday',
        group: 'Gameday Vests',
        urlCategory: 'football',
        urlSubCategory: 'gameday',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fprovests-collage.jpg?alt=media&token=61e89de4-353c-4087-935b-48c2ecdb42da",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fprovests-collage.jpg?alt=media&token=61e89de4-353c-4087-935b-48c2ecdb42da",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fcustom-chain-gang-vest-provest-HQ1.jpg?alt=media&token=cc17688c-cbd8-4977-b195-3d886a9d2c1c",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fcustom-chain-gang-vest-provest-HQ2.jpg?alt=media&token=a22e564d-94de-4f57-aad2-4f90b06d7d9e",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fcustom-chain-gang-vest-provest-HQ3.jpg?alt=media&token=c48feb7d-5379-4811-8429-7774457d4fb5",
        ]
    },{
        id: 'p183',
        name: 'Chain Gang Vest',
        sku: 'vest3',
        description: `Standard Chain Gang Vest. Available in orange or gold with diamond pattern print. Made from vinyl nylon with elastic Velcro straps.`,
        details: [
            `Made from vinyl nylon`,
            `Elastic waist strap allows for one size fits all design`,
            `Dimensions: 18"W x 26 3/4"H`
        ],
        includes: [
            ``
        ],
        options: [
            `VEST3: 3 Vest Set (gold)`,
            `VEST1: Individual (gold)`,
            `VEST3OR: 3 Vest Set (orange)`,
            `VEST1OR: Individual (orange)`,
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'gameday',
        group: 'Gameday Vests',
        urlCategory: 'football',
        urlSubCategory: 'gameday',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fchain-gang-vest-vest3-HQ1.jpg?alt=media&token=d667ff22-c6f6-4ad0-8331-75473b844eec",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fchain-gang-vest-vest3-HQ1.jpg?alt=media&token=d667ff22-c6f6-4ad0-8331-75473b844eec",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fchain-gang-vest-vest3-HQ2.jpg?alt=media&token=4dcaf23f-a4ec-44d6-968c-c28803f8df5e",
        ]
    },{
        id: 'p184',
        name: 'Custom Ball Vest',
        sku: 'vest2bcs',
        description: `The Custom Ball Vest features a mesh construction with two pockets that hold one football each. The elastic waist strap allows this vest to fit everyone.`,
        details: [
            `Mesh construction with two ball pockets`,
            `Elastic waist strap allows for one size fits all design`,
            `Print on both sides of the vest`,
        ],
        includes: [
            ``
        ],
        options: [
            `VEST2BCS: Individual Custom Ball Vest`,
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'gameday',
        group: 'Gameday Vests',
        urlCategory: 'football',
        urlSubCategory: 'gameday',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fball-vest-vest2b-HQ1.jpg?alt=media&token=37ec6cbb-df05-47ef-b139-47567fade4b9",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fball-vest-vest2b-HQ1.jpg?alt=media&token=37ec6cbb-df05-47ef-b139-47567fade4b9",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fball-vest-vest2b-HQ2.jpg?alt=media&token=7b26bd61-de4d-4f4f-964e-0a33a48c816c",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fball-vest-vest2b-HQ3.jpg?alt=media&token=7e4acc5c-d058-4e85-844a-9da544268c26",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fball-vest-vest2b-HQ4.jpg?alt=media&token=1dacbf30-8165-4e93-a4ea-640b5ef41bf3",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fball-vest-vest2b-HQ5.jpg?alt=media&token=5dc324bf-9bb8-4858-af57-fe9039e0b5c7",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fball-vest-vest2b-HQ6.jpg?alt=media&token=28d5ce95-262f-4447-8011-bb56ad4ab9be"
        ]
    },{
        id: 'p185',
        name: 'Ball Vest',
        sku: 'vest2b',
        description: `The Ball Vest features a black mesh construction with two pockets that hold one football each. The elastic waist strap allows this vest to fit everyone.`,
        details: [
            `Black mesh construction with two ball pockets`,
            `Elastic waist strap allows for one size fits all design`
        ],
        includes: [
            ``
        ],
        options: [
            `VEST2B: Individual Ball Vest`,
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'gameday',
        group: 'Gameday Vests',
        urlCategory: 'football',
        urlSubCategory: 'gameday',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fball-vest-vest2b-black-HQ1.jpg?alt=media&token=bafb9eed-5f41-4688-b463-4d95d6df04f8",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fball-vest-vest2b-black-HQ1.jpg?alt=media&token=bafb9eed-5f41-4688-b463-4d95d6df04f8",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fball-vest-vest2b-black-HQ2.jpg?alt=media&token=f10096c2-2df2-4ed1-a6c5-aa8a583bc3b3",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fball-vest-vest2b-black-HQ3.jpg?alt=media&token=6eb22577-b988-48ef-8a7c-9a48b5393bde",
        ]
    },{
        id: 'p186',
        name: 'Chain Clip Marker',
        sku: 'cc100',
        description: `Clips to chain for accurate down measuring placement.`,
        details: [
            `Clips to Chain Sets`,
            `Provides accurate down measuring placement`,
        ],
        includes: [
            ``
        ],
        options: [
            `CC100: Chain Marker`,
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'gameday',
        group: '',
        urlCategory: 'football',
        urlSubCategory: 'gameday',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fchain-clip-marker-cc100-HQ1.jpg?alt=media&token=38355545-624b-4997-8518-8d24e789e6be",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fchain-clip-marker-cc100-HQ1.jpg?alt=media&token=38355545-624b-4997-8518-8d24e789e6be",
        ]
    },{
        id: 'p187',
        name: 'Field Accessory Cart',
        sku: 'fac100',
        description: `Carry all the gear for the gridiron in the Field Accessory Cart. This cart measures 66" L x 24" W x 22" H and is built with a sturdy powder coated steel frame with an 18 oz. vinyl mesh interior that is easy to clean with a hose. The cart is equipped with a trailer hitch that can be connected to either a tractor or a golf cart. Three pneumatic tires roll easily over natural or artificial turf. `,
        details: [
            `Powder coated steel frame`,
            `18 oz vinyl mesh interior`,
            `Easy to clean - just hose out the interior`,
            `Trailer hitch for use with tractor or golf cart`,
            `Pneumatic tires roll easily over natural or artificial turf`,
            `Frame Dimensions: 66"L x 24"W x 22"H`,
        ],
        includes: [
            ``
        ],
        options: [
            `FAC100: Field Accessory Cart`,
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'gameday',
        group: '',
        urlCategory: 'football',
        urlSubCategory: 'gameday',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ffield-accessory-cart-fac100-HQ2.jpg?alt=media&token=da7bc7f7-59c1-4350-8e61-b560ad8c55e1",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ffield-accessory-cart-fac100-HQ2.jpg?alt=media&token=da7bc7f7-59c1-4350-8e61-b560ad8c55e1",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ffield-accessory-cart-fac100-HQ1.jpg?alt=media&token=14975477-2136-435f-b518-de6915b57b6d",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ffield-accessory-cart-fac100-2400-3.jpg?alt=media&token=ab4df193-3dd9-4461-9a0f-5036c768389b"
        ]
    },{
        id: 'p188',
        name: 'Sideline Marker Mesh Bag',
        sku: 'c181848',
        description: `The Sideline Marker Mesh Bag bolds up to 11 SLMTs or 22 SLMOR11s. This bag features a durable mesh construction and carry handles.`,
        details: [
            `Holds 11 SLMTs or 22 SLMOR11s`,
            `Dimensions: 52"L x 16"H x 13"W`,
        ],
        includes: [
            ``
        ],
        options: [
            `C181848: Sideline Marker Mesh Bag`,
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'gameday',
        group: 'Accessory Bags',
        urlCategory: 'football',
        urlSubCategory: 'gameday',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fsideline-marker-mesh-bag-c181848-HQ1.jpg?alt=media&token=090e572d-7e9c-426b-afc5-0bde079887ef",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fsideline-marker-mesh-bag-c181848-HQ1.jpg?alt=media&token=090e572d-7e9c-426b-afc5-0bde079887ef",
        ]
    },{
        id: 'p189',
        name: 'Sideline Marker Vinyl Bag',
        sku: 'C181836',
        description: `The Sideline Marker Vinyl Bag bolds up to 11 SLMOR11s. This bag features a durable vinyl construction and carry handles.`,
        details: [
            `Holds 11 SLMOR11s`,
            `Dimensions: 37"L x 17"H x 17"W`,
        ],
        includes: [
            ``
        ],
        options: [
            `C181836: Sideline Marker Mesh Bag`,
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'gameday',
        group: 'Accessory Bags',
        urlCategory: 'football',
        urlSubCategory: 'gameday',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fsideline-marker-vinyl-bag-c181836-HQ1.jpg?alt=media&token=3c8926d0-753d-4c80-8db4-3ac011038184",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fsideline-marker-vinyl-bag-c181836-HQ1.jpg?alt=media&token=3c8926d0-753d-4c80-8db4-3ac011038184",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fsideline-marker-vinyl-bag-c181836-HQ2.jpg?alt=media&token=319cf886-84ea-4785-9edc-afde4c20ded4"
        ]
    },{
        id: 'p190',
        name: 'Chain Set Carry Bag',
        sku: 'cb106',
        description: `The Chain Set Carry Bag holds any 7' or 8' football chain set and a down indicator.`,
        details: [
            `Holds Flip Indicator and 7' or 8' Chain Sets`,
            `CB106 Dimensions: 106"L x 18"H`,
            `CB8836 Dimensions: 88" L x 8" H`,
        ],
        includes: [
            ``
        ],
        options: [
            `CB106: Holds Flip Indicator and 8' Chain sets`,
            `CB8836: Holds Flip Indicator and 7' Chain sets`,
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'gameday',
        group: 'Accessory Bags',
        urlCategory: 'football',
        urlSubCategory: 'gameday',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fchain-set-carry-bag-cb106-HQ1.jpg?alt=media&token=e8d93bd6-2af9-46cb-b8cc-cd8601dfbf13",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fchain-set-carry-bag-cb106-HQ1.jpg?alt=media&token=e8d93bd6-2af9-46cb-b8cc-cd8601dfbf13",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fchain-set-carry-bag-cb106-HQ2.jpg?alt=media&token=0a0df313-33b0-4c19-ad8b-427cd53d127d"
        ]
    },{
        id: 'p191',
        name: 'Pro Style Stencil Set',
        sku: '4600DLX',
        description: `These low-density polyethylene (LPDE) stencils can be rolled and flattened without curl. Sheds paint with ease and won't crack or break. They measure 8' x 4' with 6' numbers.`,
        details: [
            `Durable 1/8" LPDE flexible plastic`,
            `Rolls up easily for convenient storage`,
            `Easy cleaning`,
            `Dimensions: 8' x 4'`,
        ],
        includes: [
            ``
        ],
        options: [
            `4600DLX: Contains: G, 1, 2, 3, 4, 5, 0, hashmark, directional arrow`,
            `4600STD: Contains: G, 1, 2, 3, 4, 5, 0`,
            `4600RN: Individual number`,
            `4600DA: Directional arrow`,
            `4600HM: Hash mark`,
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'gameday',
        group: 'Stencils',
        urlCategory: 'football',
        urlSubCategory: 'gameday',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpro-style-stencil-set-4600dlx-HQ1.jpg?alt=media&token=01367639-fd66-46a3-ba40-26fcd2ebd43c",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpro-style-stencil-set-4600dlx-HQ1.jpg?alt=media&token=01367639-fd66-46a3-ba40-26fcd2ebd43c",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpro-style-stencil-set-4600dlx-HQ2.jpg?alt=media&token=923efcb9-6d19-4276-8c96-e13c87763be2",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2F4600-handles.jpg?alt=media&token=e1d85936-0dea-4633-965a-260696b536ba",
        ]
    },
    // {
    //     id: 'p192',
    //     name: 'Vinyl Stencil Set',
    //     sku: '3600DLX',
    //     description: `This unique slip together number system allows you to paint two numbers at once instead of painting one number at a time. The numbers are 40 oz heavy vinyl and will last for years.`,
    //     details: [
    //         `Durable 40 oz vinyl construction`,
    //         `Numbers fit together for easy alignment`,
    //         `Rolls up easily for convenient storage`,
    //         `Dimensions: 6' numbers`,
    //     ],
    //     includes: [
    //         ``
    //     ],
    //     options: [
    //         `3600DLX: Contains: G, 1, 2, 3, 4, 5, 0, 0, hashmark, directional arrow, 8 alignment poles, and carrying bag. Numbers are 6'.`,
    //         `3600STD: Contains: G, 1, 2, 3, 4, 5, 0, hashmark, directional arrow, 4 alignment poles, and carrying bag. Numbers are 6'.`,
    //         `3400ECO: Contains: G, 1, 2, 3, 4, 5, 0, hashmark, directional arrow, 4 alignment poles, and carrying bag. Numbers are 4'.`,
    //         `3600BG: Deluxe Carry Bag for 6' stencils`,
    //         `3400BG: Deluxe Carry Bag for 4' stencils`,
    //         `3600RN: Individual 6' stencil number`,
    //         `3400RN: Individual 4' stencil number`,
    //         `3600HM: Hash mark`,
    //         `3600DA: Directional arrow`,
    //         `3600PO: Set of poles (4)`,
    //     ],
    //     usedBy: [],
    //     category: 'football',
    //     subCategory: 'gameday',
    //     group: 'Stencils',
    //     urlCategory: 'football',
    //     urlSubCategory: 'gameday',
    //     image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fvinyl-stencil-set-3600dlx-HQ1.jpg?alt=media&token=5701c500-dce6-4647-8546-6752cbf50e03",
    //     images: [
    //         "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fvinyl-stencil-set-3600dlx-HQ1.jpg?alt=media&token=5701c500-dce6-4647-8546-6752cbf50e03",
    //         "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fvinyl-stencil-set-3600dlx-HQ2.jpg?alt=media&token=6a433b84-0574-4e47-9d67-5ce26ec53eff",
    //         "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fvinyl-stencil-set-3600dlx-HQ3.jpg?alt=media&token=3b2394dc-1f19-4b78-a7f0-968f7beb9a37"
    //     ]
    // },
    // {
    //     id: 'p193',
    //     name: 'Deluxe Carry Bag',
    //     sku: '3600bg',
    //     description: `The Deluxe Carry Bag is constructed of high quality vinyl and allows for convenient storage of stencils.`,
    //     details: [
    //         `Carries and stores stencils`,
    //         `Handles allow for easy transportation`
    //     ],
    //     includes: [
    //         ``
    //     ],
    //     options: [
    //         `3600BG: Deluxe Carry Bag for 6' stencils`,
    //         `3400BG: Deluxe Carry Bag for 4' stencils`,
    //     ],
    //     usedBy: [],
    //     category: 'football',
    //     subCategory: 'gameday',
    //     group: 'Stencils',
    //     urlCategory: 'football',
    //     urlSubCategory: 'gameday',
    //     image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fdeluxe-carry-bag-3600bg-HQ1.jpg?alt=media&token=a222dd8f-84ca-4051-871a-3a7e7f4f911e",
    //     images: [
    //         "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fdeluxe-carry-bag-3600bg-HQ1.jpg?alt=media&token=a222dd8f-84ca-4051-871a-3a7e7f4f911e",
    //         "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fdeluxe-carry-bag-3600bg-HQ2.jpg?alt=media&token=827641eb-96d6-4d45-802b-ea72c514318d"
    //     ]
    // },
    {
        id: 'p194',
        name: 'Ad Sign',
        sku: 'ads7236',
        description: `Ad Signs feature a fold over design that allows for easy storage. Ad covers are detachable and can easily be changed.`,
        details: [
            `Print on one or both sides`,
            `3" foam construction for safety`,
            `Breaks down easily for storage`,
            `Pads and covers solds separately`,
        ],
        includes: [
            ``
        ],
        options: [
            `Ad Sign Pad`,
            `ADS3636: 36" x 36" Ad Sign Pad`,
            `ADS7236: 72" x 36" Ad Sign Pad`,
            `ADS7248: 72" x 48" Ad Sign Pad`,
            `ADS9636: 96" x 36" Ad Sign Pad`,
            `ADS9648: 96" x 48" Ad Sign Pad`,
            `Printed Ad Cover`,
            `ADCD3636: 36" x 36" Printed Ad Cover`,
            `ADCD7236: 72" x 36" Printed Ad Cover`,
            `ADCD7248: 72" x 48" Printed Ad Cover`,
            `ADCD9636: 96" x 36" Printed Ad Cover`,
            `ADCD9648: 96" x 48" Printed Ad Cover`,
            `Blank Ad Cover`,
            `ADCB3636: 36" x 36" Blank Ad Cover`,
            `ADCB7236: 72" x 36" Blank Ad Cover`,
            `ADCB7248: 72" x 48" Blank Ad Cover`,
            `ADCB9636: 96" x 36" Blank Ad Cover`,
            `ADCB9648: 96" x 48" Blank Ad Cover`,
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'gameday',
        group: '',
        urlCategory: 'football',
        urlSubCategory: 'gameday',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fad-sign-ads7236-HQ1.jpg?alt=media&token=7cd517d4-660b-4c44-a983-56dab54c6b9b",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fad-sign-ads7236-HQ1.jpg?alt=media&token=7cd517d4-660b-4c44-a983-56dab54c6b9b",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fad-sign-ads7236-HQ3.jpg?alt=media&token=ec7c76f1-0828-45a6-9573-b18fa8e18217",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fad-sign-ads7236-HQ6.jpg?alt=media&token=76bded33-d0e1-439e-9fba-c1c6d2b076d6",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fad-sign-ads7236-HQ4.jpg?alt=media&token=0061d313-ae8b-4c57-ad84-d1f9ba8eaf76",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fad-sign-ads7236-HQ5.jpg?alt=media&token=0443fb06-fbc9-49ff-b39f-362bb2e4e654",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fad-sign-ads7236-HQ2.jpg?alt=media&token=2f696223-aef0-4c2f-9f34-eec1b519bbd5",
        ]
    },{
        id: 'p195',
        name: 'Titan Sideline Protector',
        sku: 'tsso',
        description: `Titan Sideline Protectors are built from the toughest mesh available, 12 oz Titan Mesh, to help protect your field from the wear and tear of practice and gameday, as well as any inclement weather. Water will flow through the mesh without holding any moisture. Grommets are placed every 5 feet. Available in gray, beige, black, red, royal, columbia blue, purple, orange, navy, forest green, and maroon color options.`,
        details: [
            `Built from the toughest mesh available, 12 oz Titan Mesh`,
            `Protect your field from wear and tear`,
            `Water will flow through mesh without holding any moisture`,
            `Customize with school colors and your team mascot or logo`,
        ],
        includes: [
            ``
        ],
        options: [
            `TSSO: Custom Sideline Protector`,
            `TS1575: Sideline Protector 15'W x 75'L`,
            `TS15120: Sideline Protector 15'W x 120'L`,
            `TS15150: Sideline Protector 15'W x 150'L`,
            `TSCB475: Coaches Box (4" stripe down length of protector) 75' Long`,
            `TSCB4120: Coaches Box (4" stripe down length of protector) 120' Long`,
            `TSCB4150: Coaches Box (4" stripe down length of protector) 150' Long`,
            `TSCB4150: Coaches Box (4" stripe down length of protector) Custom Length`,
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'gameday',
        group: '',
        urlCategory: 'football',
        urlSubCategory: 'gameday',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ftitan-sideline-marker-tsso-HQ1.jpg?alt=media&token=8f952a42-1b56-4707-9f78-95f6d0fd3412",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ftitan-sideline-marker-tsso-HQ1.jpg?alt=media&token=8f952a42-1b56-4707-9f78-95f6d0fd3412",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ftitan-sideline-protector-tsso-HQ4.jpg?alt=media&token=c15ee363-8a3e-4323-a4c7-6a18c2395a1b",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ftitan-sideline-marker-tsso-HQ2.jpg?alt=media&token=255d66d3-db5e-4348-b597-10dfbd2a9144",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ftitan-sideline-marker-tsso-HQ3.jpg?alt=media&token=3e5bfb4b-3dfe-473f-8d30-5ccde0a9e5e5"
        ]
    },{
        id: 'p199',
        name: 'Special Teams Tarp',
        sku: 'sttso',
        description: `Customize the color and size of your Special Teams Tarp.`,
        details: [
            `Grommeted every 2 feet on all sides`
        ],
        includes: [
            ``
        ],
        options: [
            `STT: 12' x 5' (black)`,
            `STTC: 12' x 5' (custom color)`,
            `STTSO: Custom size and color`,
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'gameday',
        group: '',
        urlCategory: 'football',
        urlSubCategory: 'gameday',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fspecial-teams-tarp-sttso-HQ1.jpg?alt=media&token=a73261b9-8916-442c-b318-6594ff369f30",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fspecial-teams-tarp-sttso-HQ1.jpg?alt=media&token=a73261b9-8916-442c-b318-6594ff369f30",
        ]
    },{
        id: 'p200',
        name: 'Field Netting',
        sku: 'fn4s1765',
        description: `Custom net sizes and solutions available.`,
        details: [
            `PS models include a Pulley System`,
            `4" square model made for football and soccer - #180 break strength`,
            `1 7/8" model for baseball and softball - #180 break strength`,
            `3" schedule 40 pipe - 21' tall with 4' in the ground`,
            `Custom net sizes and solutions available`
        ],
        includes: [
            ``
        ],
        options: [
            `FN4S1765: 65'W x 17'H with 4" square hole size (no pulley system)`,
            `FN4S1765PS: 65'W x 17'H with 4" square hole size (with pulley system)`,
            `FN4S17100: 100'W x 17'H with 4" square hole size (no pulley system)`,
            `FN4S17100PS: 100'W x 17'H with 4" square hole size (with pulley system)`,
            `FN1S1765: 65'W x 17'H with 1 7/8" square hole size (no pulley system)`,
            `FN1S1765PS: 65'W x 17'H with 1 7/8" square hole size (with pulley system)`,
            `FN1S17100: 100'W x 17'H with 1 7/8" square hole size (no pulley system)`,
            `FN1S17100PS: 100'W x 17'H with 1 7/8" square hole size (with pulley system)`,
            `FN4S4040PS: 40'W x 40'H with 4" square hole size (with pulley system)`,
            `FN1S4040PS: 40'W x 40'H with 1 7/8" square hole size (with pulley system)`,
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'gameday',
        group: '',
        urlCategory: 'football',
        urlSubCategory: 'gameday',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ffield-netting-fn4s1765-HQ1.jpg?alt=media&token=cf2a61d1-61f6-4fa4-89b9-6d715b424cc8",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ffield-netting-fn4s1765-HQ1.jpg?alt=media&token=cf2a61d1-61f6-4fa4-89b9-6d715b424cc8",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ffield-netting-fn4s1765-HQ2.jpg?alt=media&token=66655dc9-df5c-4648-8967-6e75243c74a7",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ffield-netting-fn4s1765-HQ3.jpg?alt=media&token=74f31600-2de6-45a1-97b3-1e77d0558ec7",
        ]
    },{
        id: 'p201',
        name: 'Economy Wrap Type Goal Post Pad',
        sku: 'pmvso',
        description: `The Economy Wrap Type Goal Post Pad is made with 2" polyfoam and attached with Velcro strap or grommets.`,
        details: [
            `2" polyfoam`,
            `Attaches with Velcro strap or grommets`,
            `Choose from 14oz or 18oz vinyl`,
        ],
        includes: [
            ``
        ],
        options: [
            `PMVSO: Special size, 18oz vinyl with Velcro`,
            `PMGSO: Special size, 18oz vinyl with grommets`,
            `PMV72263: 6'H x 26"W x 2"Thick, 18oz vinyl with Velcro`,
            `PMG72263: 6'H x 26"W x 2"Thick, 18oz vinyl with grommets`,
            `PMV72163: 6'H x 16"W x 2"Thick, 18oz vinyl with Velcro`,
            `PMG72163: 6'H x 16"W x 2"Thick, 18oz vinyl with grommets`,
            `PMV72123: 6'H x 12"W x 2"Thick, 18oz vinyl with Velcro`,
            `PMG72123: 6'H x 12"W x 2"Thick, 18oz vinyl with grommets`,
            `PMV72262: 6'H x 26"W x 2"Thick, 14oz vinyl with Velcro`,
            `PMG72262: 6'H x 26"W x 2"Thick, 14oz vinyl with grommets`,
            `PMV72162: 6'H x 16"W x 2"Thick, 14oz vinyl with Velcro`,
            `PMG72162: 6'H x 16"W x 2"Thick, 14oz vinyl with grommets`,
            `PMV72122: 6'H x 12"W x 2"Thick, 14oz vinyl with Velcro`,
            `PMG72122: 6'H x 12"W x 2"Thick, 14oz vinyl with grommets`,
        ],
        usedBy: [],
        category: 'football  protective padding',
        subCategory: 'gameday outdoor padding',
        group: 'Goal Post Pads',
        urlCategory: 'football  protective padding',
        urlSubCategory: 'gameday outdoor padding',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Feconomy-wrap-type-goal-post-pad-pmvso-HQ1.jpg?alt=media&token=2dfab244-0297-4fc9-a3aa-c55589a2702f",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Feconomy-wrap-type-goal-post-pad-pmvso-HQ1.jpg?alt=media&token=2dfab244-0297-4fc9-a3aa-c55589a2702f",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Feconomy-wrap-type-goal-post-pad-pmvso-HQ2.jpg?alt=media&token=bf7062ac-db85-49d5-9388-edbbfe9ba49a"
        ]
    },{
        id: 'p202',
        name: 'All Pro Bag',
        sku: 'ap181836',
        description: `Our All Pro Bag is made from 600 denier polyester and has 2" handles and a 2" detachable shoulder strap. It has a U-shaped zippered opening and two zippered end pockets.`,
        details: [
            `Made from high quality 600 denier polyester`,
            `2" handles`,
            `2" shoulder straps`,
            `U-shaped zippered opening`,
            `Dimensions: 18"W x 16"H x 36"L`,
        ],
        includes: [
            ``
        ],
        options: [
            `AP181636: All Pro Bag (18"W x 16"H x 36"L)`,
            `AP141630: All Pro Bag (14"W x 16"H x 30"L)`,
            `AP121426: All Pro Bag (12"W x 14"H x 26"L)`,
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'gameday',
        group: 'Accessory Bags',
        urlCategory: 'football',
        urlSubCategory: 'gameday',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fall-pro-bag-ap181836-HQ1.jpg?alt=media&token=ca5e8595-cdf5-460d-8438-9426f6485ec9",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fall-pro-bag-ap181836-HQ1.jpg?alt=media&token=ca5e8595-cdf5-460d-8438-9426f6485ec9",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fall-pro-bag-ap181836-HQ2.jpg?alt=media&token=73f302ae-1c87-40b9-a197-6598e8fdcbd5",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fall-pro-bag-ap181836-HQ3.jpg?alt=media&token=e97b3ecb-1728-4af8-b5b5-5a2058105fde",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fall-pro-bag-ap181836-HQ4.jpg?alt=media&token=731c5d05-f41e-492b-96d1-a464761cfd36"
        ]
    },{
        id: 'p203',
        name: 'Gear Bag Deluxe',
        sku: 'gbd151532',
        description: `Our 600 denier Gear Bag Deluxe is designed as a players deluxe travel bag. Includes 3" x 5" ID pocket. Two tone handles provide extra durability and add an enhanced look. Equipped with end handles on both sides of bag in addition to carrying handle. The bag also has side ventilation holes, two on each end of bag.`,
        details: [
            `Made from high quality 600 denier`,
            `Two-tone handles on top and sides of bag`,
            `Ventilation holes on side of bag`,
            `Dimensions: 15"W x 15"H x 32"L`,
        ],
        includes: [
            ``
        ],
        options: [
            `GBD151532: Gear Bag Deluxe (15"W x 15"H x 32"L)`,
            `GBD202035: Gear Bag Deluxe (20"W x 20"H x 35"L)`,
            `GBD202040: Gear Bag Deluxe (20"W x 20"H x 40"L)`,
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'gameday',
        group: 'Accessory Bags',
        urlCategory: 'football',
        urlSubCategory: 'gameday',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fgear-bag-deluxe-gbd202040-HQ1.jpg?alt=media&token=dfde74fd-0b1d-4e8d-ac2f-39cb4670c064",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fgear-bag-deluxe-gbd202040-HQ1.jpg?alt=media&token=dfde74fd-0b1d-4e8d-ac2f-39cb4670c064",
        ]
    },{
        id: 'p204',
        name: 'Weather Beater Bag',
        sku: 'wb151827',
        description: `The Weather Beater Bag is an 18 oz vinyl, moisture-proof bag with an overlap zipper. Includes shoulder strap and molded end handles. Stock color is black.`,
        details: [
            `Made with 18 oz vinyl`,
            `Moisture-proof`,
            `Includes shoulder strap and molded end handles`,
            `Dimensions: 15"W x 18"H x 27"L`,
        ],
        includes: [
            ``
        ],
        options: [
            `WB151827: Weather Beater (15"W x 18"H x 27"L)`,
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'gameday',
        group: 'Accessory Bags',
        urlCategory: 'football',
        urlSubCategory: 'gameday',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fweather-beater-bag-wb151827-HQ1.jpg?alt=media&token=d533a824-d38e-4a15-9155-bcb9df966057",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fweather-beater-bag-wb151827-HQ1.jpg?alt=media&token=d533a824-d38e-4a15-9155-bcb9df966057"
        ]
    },{
        id: 'p205',
        name: 'All American Bag',
        sku: 'aa161636',
        description: `The All American Bag is available in 600 denier polyester and 18 oz vinyl with 2" handles, mesh vents and a wrap-around zipper.`,
        details: [
            `Choose from 600 denier polyester or 18 oz vinyl`,
            `2" handles`,
            `Mesh vents`,
            `Wrap-around zipper`,
            `Dimensions: 16"W x 16"H x 36"L`,
        ],
        includes: [
            ``
        ],
        options: [
            `AA161426: All American Bag (16"W x 14"H x 26"L)`,
            `AA161430: All American Bag (16"W x 14"H x 30"L)`,
            `AA161632: All American Bag (16"W x 16"H x 32"L)`,
            `AA161636: All American Bag (16"W x 16"H x 36"L)`,
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'gameday',
        group: 'Accessory Bags',
        urlCategory: 'football',
        urlSubCategory: 'gameday',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fall-american-bag-aa161636-HQ1.jpg?alt=media&token=f52cb37e-8973-439a-89f9-c3bc551cc6f9",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fall-american-bag-aa161636-HQ1.jpg?alt=media&token=f52cb37e-8973-439a-89f9-c3bc551cc6f9",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fall-american-bag-aa161636-HQ2.jpg?alt=media&token=b01e7a70-5397-4971-accc-fd2192840c57",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fall-american-bag-aa161636-HQ3.jpg?alt=media&token=28aa9a4a-0534-423c-85e8-c825d7781aba"
        ]
    },{
        id: 'p206',
        name: 'The Manager Bag',
        sku: 'fbdc12',
        description: `The Manager Bag is made from 600 denier polyester with a vinyl bottom. The divider section holds 12, 6, or 3 footballs. Shoulder strap and clear identification pocket included. Stock color is black.`,
        details: [
            `Made with 600 denier polyester`,
            `Vinyl bottom`,
            `Holds 12, 6, or 3 footballs`,
            `Shoulder strap`,
            `Clear identification pocket`,
            `Dimensions: 13"W x 19"H x 26"L`,
        ],
        includes: [
            ``
        ],
        options: [
            `FBDC12: The Manager Bag (13"W x 19"H x 26"L)`,
            `FBDC6: The Manager Bag (12"W x 12"H x 19"L)`,
            `FBDC3: The Manager Bag (6"W x 12"H x 19"L)`,
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'gameday',
        group: 'Accessory Bags',
        urlCategory: 'football',
        urlSubCategory: 'gameday',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fthe-manager-bag-fbdc12-HQ1.jpg?alt=media&token=cb118853-bf0c-436c-8ec2-a78b5836b872",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fthe-manager-bag-fbdc12-HQ1.jpg?alt=media&token=cb118853-bf0c-436c-8ec2-a78b5836b872",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fthe-manager-bag-fbdc12-HQ2.jpg?alt=media&token=be44085b-c4ac-401c-b8d2-4c14ba8a0b25",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fthe-manager-bag-fbdc12-HQ3.jpg?alt=media&token=99719259-4c35-4d5d-a66d-05b228fad1c5",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fthe-manager-bag-fbdc12-HQ4.jpg?alt=media&token=4b5b1164-08c1-4475-afa9-b58958211276"
        ]
    },{
        id: 'p421',
        name: 'Ball Heater Bag',
        sku: 'bhb100',
        description: `The Ball Heater Bag features 3 layers of protection (18 oz vinyl, fleece, and quilt). A clear 7 1/4" x 9 1/4" display allows for interchangeable graphics and labels. The Ball Heater Bag can fit up to 3 footballs and be carried comfortably by using the adjustable shoulder straps. Interior mesh pockets can be used for holding heat pads. Stock color is black.`,
        details: [
            `3 Layers of protection (18 oz vinyl, fleece, quilt)`,
            `Clear 7 1/4" x 9 1/4" display allows for interchangeable graphics and labels`,
            `Adjustable shoulder straps`,
            `Fit up to 3 footballs inside`,
            `Interior mesh pockets for holding heat pads`,
        ],
        includes: [
            ``
        ],
        options: [
            `BHB100: Ball Heater Bag (24"W x 14"H)`,
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'gameday',
        group: 'Accessory Bags',
        urlCategory: 'football',
        urlSubCategory: 'gameday',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fball-heater-bag-bhb100-HQ1.jpg?alt=media&token=01a66767-66f3-4721-a41a-f0bc45dcda02",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fball-heater-bag-bhb100-HQ1.jpg?alt=media&token=01a66767-66f3-4721-a41a-f0bc45dcda02",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fball-heater-bag-bhb100-HQ2.jpg?alt=media&token=2c6f6fcd-ce01-47b8-a5cc-b5ce690d7ba1",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fball-heater-bag-bhb100-HQ3.jpg?alt=media&token=19606d9c-20a1-43b4-babd-0bda80cdc4cc",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fball-heater-bag-bhb100-HQ4.jpg?alt=media&token=d6e2bf23-fe8d-47f7-b33f-d775c69689a7",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fball-heater-bag-bhb100-HQ5.jpg?alt=media&token=4932938a-17b6-411f-9e5d-da59199e987b",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fball-heater-bag-bhb100-HQ6.jpg?alt=media&token=27c9924d-19d8-442a-ac3f-dfee0a4426e5",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fball-heater-bag-bhb100-HQ7.jpg?alt=media&token=f7dc9c74-d428-46ff-81e4-84d35e707a28"
        ]
    },{
        id: 'p207',
        name: 'Team Bag',
        sku: 'tb181836',
        description: `Designed specifically for any sport which requires large, bulky equipment. This bag has a perimeter zipper that allows you to completely open the bag for easy storage. Available in 18 oz vinyl, 600 denier polyester, vinyl mesh, or combo vinyl/mesh.`,
        details: [
            `Choose from 18 oz vinyl, 600 denier polyester, vinyl mesh, or combo vinyl/mesh`,
            `Perimeter zipper allows you to completely open the bag`,
            `Holds large, bulky equipment`,
            `Dimensions: 18"W x 18"H x 36"L`,
        ],
        includes: [
            ``
        ],
        options: [
            `TB151526: Team Bag (15"W x 15"H x 26"L)`,
            `TB151532: Team Bag (15"W x 15"H x 32"L)`,
            `TB181832: Team Bag (18"W x 18"H x 32"L)`,
            `TB181836: Team Bag (18"W x 18"H x 36"L)`,
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'gameday',
        group: 'Accessory Bags',
        urlCategory: 'football',
        urlSubCategory: 'gameday',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fteam-bag-tb181836-HQ1.jpg?alt=media&token=d6a39429-59f1-459a-8636-3d6143f90a3d",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fteam-bag-tb181836-HQ1.jpg?alt=media&token=d6a39429-59f1-459a-8636-3d6143f90a3d",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fteam-bag-tb181836-HQ2.jpg?alt=media&token=e343ff44-b487-4d10-a0da-130e18ccc85f"
        ]
    },{
        id: 'p208',
        name: 'Gear Bag',
        sku: 'gb181836',
        description: `The top loading zipper allows you to load balls, shoes, uniforms, and other equipment without the equipment spilling out onto the floor. Available in 18 oz vinyl, 600 denier polyester, vinyl mesh, or combo vinyl/mesh.`,
        details: [
            `Choose from 18 oz vinyl, 600 denier polyester, vinyl mesh, or combo vinyl/mesh`,
            `Top loading zipper is great for storing shoes, uniforms, and other equipment`,
            `Dimensions: 18"W x 18"H x 36"L`,
        ],
        includes: [
            ``
        ],
        options: [
            `GB151526: Gear Bag (15"W x 15"H x 26"L)`,
            `GB151532: Gear Bag (15"W x 15"H x 32"L)`,
            `GB181832: Gear Bag (18"W x 18"H x 32"L)`,
            `GB181836: Gear Bag (18"W x 18"H x 36"L)`,
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'gameday',
        group: 'Accessory Bags',
        urlCategory: 'football',
        urlSubCategory: 'gameday',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fgear-bag-gb181836-HQ1.jpg?alt=media&token=33d8546a-acbf-46ff-a8cf-1689675ac80f",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fgear-bag-gb181836-HQ1.jpg?alt=media&token=33d8546a-acbf-46ff-a8cf-1689675ac80f",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fgear-bag-gb181836-HQ2.jpg?alt=media&token=35aa97ee-ab82-4556-8119-168a54c78ac5",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fgear-bag-gb181836-HQ3.jpg?alt=media&token=48961171-8139-47bf-a396-a6feea086aee",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fgear-bag-gb181836-HQ4.jpg?alt=media&token=54173cb8-bd36-47d7-bb65-1e003c08d96f"
        ]
    },{
        id: 'p209',
        name: 'Fleece Lined Sideline Cape',
        sku: 'slc11',
        description: `The water resistant and wind proof out garment is made from 420 denier nylon pack cloth. Choose from adult or junior sizes. Available in 10 popular colors.`,
        details: [
            `Made from 420 denier nylon pack cloth`,
            `Knee length`,
            `1 color screenprinting available`,
            `Logos free on quantity of 24 or higher`,
            `Custom numbering available on chest or back`,
            `Hood and inside pockets are lined to keep hands warm and dry`,
            `Dimensions: 53"H x 42"W`,
        ],
        includes: [
            ``
        ],
        options: [
            `SLC11: Fleece Lined Adult Cape (53"H x 42"W)`,
            `SLC12: Fleece Lined Junior Cape (32"H x 42"W)`,
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'gameday',
        group: 'Sideline Capes',
        urlCategory: 'football',
        urlSubCategory: 'gameday',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ffleece-lined-sideline-cape-slc11-HQ1.jpg?alt=media&token=516db805-e59f-4dd2-a59d-82dd0e7c10fb",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ffleece-lined-sideline-cape-slc11-HQ1.jpg?alt=media&token=516db805-e59f-4dd2-a59d-82dd0e7c10fb",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ffleece-lined-sideline-cape-slc11-HQ2.jpg?alt=media&token=43ac1cd0-f826-42fa-957b-9f2198eb16b1",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ffleece-lined-sideline-cape-slc11-HQ3.jpg?alt=media&token=1708ba7d-8cf2-416c-aad5-58a6120174c8",
        ]
    },{
        id: 'p210',
        name: 'Quilted Sideline Cape',
        sku: 'slc31',
        description: `The water resistant and wind proof out garment is made from 420 denier nylon pack cloth. Choose from adult or junior sizes. Available in 10 popular colors.`,
        details: [
            `Made from 420 denier nylon pack cloth`,
            `Knee length`,
            `1 color screenprinting available`,
            `Logos free on quantity of 24 or higher`,
            `Custom numbering available on chest or back`,
            `Hood and inside pockets are lined to keep hands warm and dry`,
            `Dimensions: 53"H x 42"W`,
        ],
        includes: [
            ``
        ],
        options: [
            `SLC31: Fleece Lined Adult Cape (53"H x 42"W)`,
            `SLC32: Fleece Lined Junior Cape (32"H x 42"W)`,
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'gameday',
        group: 'Sideline Capes',
        urlCategory: 'football',
        urlSubCategory: 'gameday',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fquilted-sideline-cape-slc31-HQ1.jpg?alt=media&token=c7248eda-6971-4e99-bfa8-a58495efb015",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fquilted-sideline-cape-slc31-HQ1.jpg?alt=media&token=c7248eda-6971-4e99-bfa8-a58495efb015",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fquilted-sideline-cape-slc31-HQ2.jpg?alt=media&token=11616d13-086d-4f23-9e15-577e153c9b0d",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fquilted-sideline-cape-slc31-HQ3.jpg?alt=media&token=8027db8f-7cca-4e8b-a9e3-1344c2f08211",
        ]
    },{
        id: 'p211',
        name: 'Unlined Sideline Cape',
        sku: 'slc01',
        description: `The water resistant and wind proof out garment is made from 420 denier nylon pack cloth. Choose from adult or junior sizes. Available in 10 popular colors.`,
        details: [
            `Made from 420 denier nylon pack cloth`,
            `Knee length`,
            `1 color screenprinting available`,
            `Logos free on quantity of 24 or higher`,
            `Custom numbering available on chest or back`,
            `Hood and inside pockets are lined to keep hands warm and dry`,
            `Dimensions: 53"H x 42"W`,
        ],
        includes: [
            ``
        ],
        options: [
            `SLC01: Fleece Lined Adult Cape (53"H x 42"W)`,
            `SLC02: Fleece Lined Junior Cape (32"H x 42"W)`,
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'gameday',
        group: 'Sideline Capes',
        urlCategory: 'football',
        urlSubCategory: 'gameday',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Funlined-sideline-cape-slc01-HQ1.jpg?alt=media&token=35b6ac28-23e7-4555-b87c-c2ac016ca245",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Funlined-sideline-cape-slc01-HQ1.jpg?alt=media&token=35b6ac28-23e7-4555-b87c-c2ac016ca245",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Funlined-sideline-cape-slc01-HQ2.jpg?alt=media&token=4b1b6e19-5c95-41b1-bc75-e5bcc2209d65",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Funlined-sideline-cape-slc01-HQ3.jpg?alt=media&token=e5afc4c7-e1e6-4e18-a50c-baba141ba3d8",
        ]
    },{
        id: 'p212',
        name: 'The Third Hand',
        sku: 'ths',
        description: `The Third Hand protects your game plan in all weather conditions, zip lock seal!`,
        details: [
            `Rectractable`,
            `Easy and secure clip-on`,
            `Zinc alloy clasp`,
            `Polycarbonate case`,
            `1 year warranty`,
            `Dimensions: 11"H x 8.5"W`,
        ],
        includes: [
            ``
        ],
        options: [
            `THS: 3rd Hand (standard size) 11"H x 8.5"W (3 packs)`,
            `THL: 3rd Hand (legal size) 14"H x 8.5"W (3 packs)`,
            `THSD: 3rd Hand (standard double) 11"H x 8.5"W (2 packs)`,
            `THCombo: One set of all the above`,
            `THLAN: One standard lanyard (3 pack)`,
            `THLANM: One mini lanyard - belt or wrist length (3 pack)`,
            `THRETCLIP: Retractable Clip`,
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'gameday',
        group: '',
        urlCategory: 'football',
        urlSubCategory: 'gameday',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fthird-hand-ths-HQ1.jpg?alt=media&token=b308710c-510a-45c4-80e9-8bb44762d6c5",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fthird-hand-ths-HQ1.jpg?alt=media&token=b308710c-510a-45c4-80e9-8bb44762d6c5",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fthird-hand-ths-HQ2.jpg?alt=media&token=83475b82-412a-492e-a3ff-41d18e8c384e",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fthird-hand-ths-HQ3.jpg?alt=media&token=43d99c10-bae1-4779-af3b-80165fce24da",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fthird-hand-ths-HQ4.jpg?alt=media&token=3a6daec5-fd4c-4700-ac77-673ee322aa05",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fthird-hand-ths-HQ5.jpg?alt=media&token=aff7df91-8df7-4bca-8bc1-c11e2c935829",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fthird-hand-ths-HQ6.jpg?alt=media&token=1a5fcca2-3d5a-4da6-9bfd-b954a311e1be"
        ]
    },{
        id: 'p213',
        name: 'Wristband',
        sku: '1050b',
        description: `Use our wristbands to read play information at a glance.`,
        details: [
            `Clear vinyl face`,
            `Available in black or white`,
        ],
        includes: [
            ``
        ],
        options: [
            `1050B: Black Wristband`,
            `1050W: White Wristband`,
            `1050BD: Double Black Wristband`,
            `1050WD: Double White Wristband`,
            `1050BT: Triple Black Wristband`,
            `1050WT: Triple White Wristband`,
            `1050JRB: Junior Black Wristband`,
            `1050JRW: Junior White Wristband`,
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'gameday',
        group: '',
        urlCategory: 'football',
        urlSubCategory: 'gameday',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fwristband-1050b-HQ1.jpg?alt=media&token=c17f6d88-3887-4a26-9085-e998dd2828d6",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fwristband-1050b-HQ1.jpg?alt=media&token=c17f6d88-3887-4a26-9085-e998dd2828d6",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fwristband-1050b-HQ2.jpg?alt=media&token=d54af6dc-c791-4bc3-a8aa-cb2d2f3ff3a7",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fwristband-1050b-HQ3.jpg?alt=media&token=72c3d63b-2969-4b93-95f0-c3545530dd0f",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fwristband-1050b-HQ4.jpg?alt=media&token=ad09529a-65bd-4aa3-a128-48b1becda756",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fwristband-1050b-HQ5.jpg?alt=media&token=09ed56b8-8f4d-4d8e-b2d3-a44298e8c6c6",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fwristband-1050b-HQ6.jpg?alt=media&token=ae1dbeee-cb62-4cae-abe9-b106475eafc4"
        ]
    },{
        id: 'p214',
        name: 'Stand Up Dummy',
        sku: 'sud3813',
        description: `Rolled polyfoam core - won't sag with age. 18-ounce double-sided vinyl coated nylon. Double ply, multiple stitched handles. Twin-stitched, double thick bottoms.`,
        details: [
            `Rolled polyfoam core - won't sag with age`,
            `Double ply, multiple stitched handles`,
            `18 oz double-sided vinyl coated nylon`,
            `Twin-stitched, double thick bottoms`,
            `Weight: 16 lbs`,
            `Dimensions: 38" Tall x 13" Dia`
        ],
        includes: [
            ``
        ],
        options: [
            `SUD3813: Stand Up Dummy 38"T x 13"Dia (16 lbs)`
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'dummies youth football',
        group: 'Stand Up Dummies',
        urlCategory: 'football',
        urlSubCategory: 'dummies',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fstand-up-dummy-sud3813-HQ1.jpg?alt=media&token=9525e854-75a2-4418-afb1-c09b1ac54336",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fstand-up-dummy-sud3813-HQ1.jpg?alt=media&token=9525e854-75a2-4418-afb1-c09b1ac54336"
        ]
    },{
        id: 'p215',
        name: 'Economy Flex Chute',
        sku: '9601',
        description: `The 35" to 44" height keeps players low, maintaining balance and remaining in a good football hitting position. The modular construction allows 1 to 7 man configurations to be added to removed as desired. Lightweight parts slip together easily for fast assembly. A vinyl top is included. The linemen chutes are a great teaching tool for your offensive linemen. This is a must at any serious football coach's practice!`,
        details: [
            'Expandable - you can inexpensively add sections as you need them',
            'Slip together parts for fast assembly',
            `Dimensions: 35"/44"H x 42"W x 50"deep`
        ],
        includes: [
            ``
        ],
        options: [
            `9601: One Man Chute`,
            `9602: Two Man Chute`,
            `9603: Three Man Chute`,
            `9605: Five Man Chute`,
            `9607: Seven Man Chute`
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'dummies youth football',
        group: '',
        urlCategory: 'football',
        urlSubCategory: 'dummies',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Feconomy-flex-chute-9603-HQ1.jpg?alt=media&token=6f08289a-267b-4294-982b-bece34809fda",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Feconomy-flex-chute-9603-HQ1.jpg?alt=media&token=6f08289a-267b-4294-982b-bece34809fda"
        ]
    },{
        id: 'p216',
        name: 'Impact Plyo Boxes',
        sku: 'ply36303',
        description: `Our Super Safe Impact Plyo Boxes are made from high density foam and are covered with 18 oz vinyl.`,
        details: [
            'The bottoms and tops are 40 oz non-skid vinyl',
            'Each box has two handles for easy repositioning and transportation',
            'Velcro flaps are sewn on for stability when stacking',
            'Stack any combination together for desired height',
            'Tops and bottoms are black',
            '11 colors available',
            'Get it with your own custom logos and lettering',
        ],
        includes: [
            ``
        ],
        options: [
            'PLY36303: 36"L x 30"W x 3"H',
            'PLY36306: 36"L x 30"W x 6"H',
            'PLY363012: 36"L x 30"W x 12"H',
            'PLY363018: 36"L x 30"W x 18"H',
            'PLY363024: 36"L x 30"W x 24"H',
            'PLY3630: Set of 3 (6"H, 12"H, 18"H)',
            'PLY3630SET4: Set of 4 (6"H, 12"H, 18"H, 24"H)',
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'strength and speed',
        group: '',
        urlCategory: 'football',
        urlSubCategory: 'strength and speed',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fplyo-box-ply36303-HQ1.jpg?alt=media&token=a9c76949-0315-485f-ba40-dc9fe7e6b56d",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fplyo-box-ply36303-HQ1.jpg?alt=media&token=a9c76949-0315-485f-ba40-dc9fe7e6b56d",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fplyo-box-ply36303-HQ2.jpg?alt=media&token=225cafa1-c80d-4d97-8b9b-55aa1ae739e1",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fplyo-box-ply36303-HQ3.jpg?alt=media&token=b62757d9-252a-45fd-ae0a-8390b29950df",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fplyo-box-ply36303-HQ4.jpg?alt=media&token=d4ef2bf2-7fe3-46eb-8082-b42b18b8ec8f",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fplyo-box-ply36303-HQ5.jpg?alt=media&token=a9892eb0-af98-4197-acd9-53fa49054a6e",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fplyo-box-ply36303-HQ6.jpg?alt=media&token=006b4f00-4c1c-49fd-bd6a-2c71adf92258"
        ]
    },{
        id: 'p217',
        name: 'Gregory Boxes',
        sku: 'agbox18',
        description: `Multiple use agility box.`,
        details: [
            'Jump over / jump on',
            'End handle for carrying',
            'Stock color is royal with orange top',
            'High density rebond foam covered with 18 oz vinyl',
        ],
        includes: [
            ``
        ],
        options: [
            'AGBOX18: 18" Tall Gregory Box',
            'AGBOX24: 24" Tall Gregory Box',
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'strength and speed',
        group: '',
        urlCategory: 'football',
        urlSubCategory: 'strength and speed',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fgregory-box-agbox18-HQ1.jpg?alt=media&token=dec28582-e9ba-488d-9341-a9471915349b",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fgregory-box-agbox18-HQ1.jpg?alt=media&token=dec28582-e9ba-488d-9341-a9471915349b",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fgregory-box-agbox18-HQ2.jpg?alt=media&token=1a260033-5216-40fd-9ba5-0e21c5967888"
        ],
        videos: [
            {
                title: "Gregory Box Workout",
                image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fgreg-box-video-2.jpg?alt=media&token=28e5c35e-c1cc-4d0f-a13f-96d2115074a3",
                src: "https://www.youtube.com/embed/WPkS-I69-Fo"
            },
            {
                title: "Gregory Box Workout",
                image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fgreg-box-video-1.jpg?alt=media&token=b9019ace-2be6-4f11-8403-0285ecab7052",
                src: "https://www.youtube.com/embed/50ZA9kqalbY"
            }
        ],
    },{
        id: 'p218',
        name: 'Speed Hurdles',
        sku: 'ags6',
        description: `Get the speed you need when you train with Fisher Training Speed Hurdles! Durable, weighted, heavy-duty plastic makes for stable, and long-lasting performance, perfect for a variety of drills to keep you agile and fast on the field. Available in two heights, these trip-resistant speed hurdles let you perform all types of running and jumping drills to up your game!`,
        details: [
            'Sold in sets of 6',
            'Nylon carry bag included',
        ],
        includes: [
            ``
        ],
        options: [
            'AGS6: 6" Speed Hurdles (set of 6)',
            'AGS12: 12" Speed Hurdles (set of 6)',
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'strength and speed',
        group: '',
        urlCategory: 'football',
        urlSubCategory: 'strength and speed',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fspeed-hurdle-ags6-HQ1.jpg?alt=media&token=d2f13f57-61d3-4726-a69d-a3df3b20735a",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fspeed-hurdle-ags6-HQ1.jpg?alt=media&token=d2f13f57-61d3-4726-a69d-a3df3b20735a",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fspeed-hurdle-ags6-HQ2.jpg?alt=media&token=9d160d2c-7a5e-4d9e-b2ee-d9954d56692f",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fspeed-hurdle-ags6-HQ3.jpg?alt=media&token=51188be6-548b-471e-925c-9a39a55b4635",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fspeed-hurdle-ags6-HQ4.jpg?alt=media&token=a96d579d-1e5a-431c-8e81-13d9aa41aaed",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fspeed-hurdle-ags6-HQ5.jpg?alt=media&token=cfed336e-0324-411a-ab5c-659b8091e396"
        ]
    },{
        id: 'p219',
        name: 'Push Pull Sled',
        sku: 'ps4000',
        description: `The innovative design of the Push Pull Sled allows for two methods of training. Pushing the sled teaches players to be explosive and stay low. Pulling the sled develops leg and upper body strength.`,
        details: [
            'Great tool for strength and conditioning',
            'Holds up to 5 olympic size weights',
            'Good for training alone or with a partner',
            'Powder coated 12 gauge steel',
            'Includes sled, harness, and connecting piece',
            'Weights not included',
            'Dimensions: 45"L x 48"W x 40"H'
        ],
        includes: [
            ``
        ],
        options: [
            'PS4000: Push Pull Sled (45"L x 48"W x 40"H) Includes: HAR06 and HAR05',
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'strength and speed',
        group: 'Push Pull Sleds',
        urlCategory: 'football',
        urlSubCategory: 'strength and speed',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpull-push-sled-ps4000-HQ1.jpg?alt=media&token=6942352a-4665-43cf-8d18-f0f5b16ad5f5",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpull-push-sled-ps4000-HQ1.jpg?alt=media&token=6942352a-4665-43cf-8d18-f0f5b16ad5f5",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpull-push-sled-ps4000-HQ2.jpg?alt=media&token=54253ae6-d098-475a-81af-c8266ba3823b",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpull-push-sled-ps4000-HQ3.jpg?alt=media&token=d94afbb7-a29d-4d07-a703-5062210323b2",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpull-push-sleds-HQ1.jpg?alt=media&token=0d5e3a0e-b60d-420f-9c5b-9d554ff244b9",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpull-push-sled-harness-options-HQ1.jpg?alt=media&token=237568c2-9373-436e-ba1c-f34bb94f0fd3"
        ]
    },{
        id: 'p220',
        name: 'Push Pull Sled',
        sku: 'ps3000',
        description: `The innovative design of the Push Pull Sled allows for two methods of training. Pushing the sled teaches players to be explosive and stay low. Pulling the sled develops leg and upper body strength.`,
        details: [
            'Great tool for strength and conditioning',
            'Holds up to 5 olympic size weights',
            'Good for training alone or with a partner',
            'Powder coated 12 gauge steel',
            'Includes sled, harness, and connecting piece',
            'Weights not included',
            'Dimensions: 48"L x 24"W x 36"H'
        ],
        includes: [
            ``
        ],
        options: [
            'PS3000: Push Pull Sled (48"L x 24"W x 36"H) Includes: HAR06 and HAR04',
            'PS3000PAD: Front Pad only (26"W x 30"H x 4"thick)',
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'strength and speed',
        group: 'Push Pull Sleds',
        urlCategory: 'football',
        urlSubCategory: 'strength and speed',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpull-push-sled-ps3000-HQ1.jpg?alt=media&token=acf8f482-08cd-496e-b77c-e6afca3ee0ec",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpull-push-sled-ps3000-HQ1.jpg?alt=media&token=acf8f482-08cd-496e-b77c-e6afca3ee0ec",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpull-push-sled-ps3000-HQ2.jpg?alt=media&token=6477e516-1370-4b74-96f3-2379fe83033d",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpull-push-sled-ps3000-HQ3.jpg?alt=media&token=3bf52ec6-8624-4be3-ba1b-2e3aef82327e",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpull-push-sled-ps3000-HQ4.jpg?alt=media&token=abfb3ad9-99b7-45c3-8830-dacf4bcac863",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpull-push-sled-ps3000-HQ5.jpg?alt=media&token=b8fc89f2-a1c1-4a2d-bec9-e2feeea4f50a",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpull-push-sled-ps3000-HQ6.jpg?alt=media&token=25eed57b-2fbf-4a49-ada7-602c4f9c4b14",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpull-push-sleds-HQ1.jpg?alt=media&token=0d5e3a0e-b60d-420f-9c5b-9d554ff244b9",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpull-push-sled-harness-options-HQ1.jpg?alt=media&token=237568c2-9373-436e-ba1c-f34bb94f0fd3"
        ]
    },{
        id: 'p221',
        name: 'Push Pull Sled',
        sku: 'ps2500',
        description: `The innovative design of the Push Pull Sled allows for two methods of training. Pushing the sled teaches players to be explosive and stay low. Pulling the sled develops leg and upper body strength.`,
        details: [
            'Great tool for strength and conditioning',
            'Holds up to 5 olympic size weights',
            'Good for training alone or with a partner',
            'Powder coated 12 gauge steel',
            'Includes sled, harness, and connecting piece',
            'Weights not included',
            'Dimensions: 35 1/4"L x 20 3/4"W x 9"H'
        ],
        includes: [
            ``
        ],
        options: [
            'PS2500: Push Pull Sled (35 1/4"L x 20 3/4"W x 9"H) Includes: HAR06 and HAR04'
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'strength and speed',
        group: 'Push Pull Sleds',
        urlCategory: 'football',
        urlSubCategory: 'strength and speed',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpull-push-sled-ps2500-HQ1.jpg?alt=media&token=ddc8ae36-2883-48b9-9643-7e28e06b5532",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpull-push-sled-ps2500-HQ1.jpg?alt=media&token=ddc8ae36-2883-48b9-9643-7e28e06b5532",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpull-push-sled-ps2500-HQ2.jpg?alt=media&token=c584ca25-05ca-412a-9d1c-eca27a2e2cd4",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpull-push-sled-ps2500-HQ3.jpg?alt=media&token=6109aa87-c7b7-49cb-b829-768bb5efd3d3",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpull-push-sled-ps2500-HQ4.jpg?alt=media&token=767d9327-f7c7-47c2-9c53-8d669446a394",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpull-push-sled-harness-options-HQ1.jpg?alt=media&token=237568c2-9373-436e-ba1c-f34bb94f0fd3"
        ]
    },{
        id: 'p222',
        name: 'Pull Sled',
        sku: 'ps1500',
        description: `Pulling the sled develops leg and upper body strength.`,
        details: [
            'Great tool for strength and conditioning',
            'Holds up to 5 olympic size weights',
            'Good for training alone or with a partner',
            'Powder coated 12 gauge steel',
            'Includes sled, harness, and connecting piece',
            'Weights not included',
            'Dimensions: 25"L x 16"W x 16"H'
        ],
        includes: [
            ``
        ],
        options: [
            'PS1500: Push Pull Sled (25"L x 16"W x 16"H) Includes: HAR06 and HAR05'
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'strength and speed',
        group: 'Push Pull Sleds',
        urlCategory: 'football',
        urlSubCategory: 'strength and speed',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpull-push-sled-ps1500-HQ1.jpg?alt=media&token=f31d0bef-d6ed-4808-88b6-f1ca0ee3876c",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpull-push-sled-ps1500-HQ1.jpg?alt=media&token=f31d0bef-d6ed-4808-88b6-f1ca0ee3876c",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpull-push-sled-ps1500-HQ2.jpg?alt=media&token=7b70c0d6-c9a2-497f-af74-cdf27d82b762",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpull-push-sled-ps1500-HQ3.jpg?alt=media&token=dbaf7f98-266c-4680-8a61-646fc16976d3",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpull-push-sled-ps1500-HQ4.jpg?alt=media&token=c2687d6d-003f-4857-8a9c-4ea4c4fa86f0",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpull-push-sled-harness-options-HQ1.jpg?alt=media&token=237568c2-9373-436e-ba1c-f34bb94f0fd3"
        ]
    },{
        id: 'p223',
        name: 'Harness with metal clip',
        sku: 'har01',
        description: `Harness that can be used with your Push/Pull Sleds.`,
        details: [
            `Body harness for pulling a Push/Pull Sled`
        ],
        includes: [
            ``
        ],
        options: [
            'HAR01: Harness with metal clip',
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'strength and speed',
        group: 'Push Pull Sleds',
        urlCategory: 'football',
        urlSubCategory: 'strength and speed',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fharness-with-metal-clip-har01-HQ1.jpg?alt=media&token=87d6b17d-2724-4ba5-9fe3-75d1c23d8e87",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fharness-with-metal-clip-har01-HQ1.jpg?alt=media&token=87d6b17d-2724-4ba5-9fe3-75d1c23d8e87"
        ]
    },{
        id: 'p224',
        name: 'Pull Handle',
        sku: 'har02',
        description: `Pull handle for your Push/Pull Sleds.`,
        details: [
            `14' long`,
        ],
        includes: [
            ``
        ],
        options: [
            'HAR02: Pull Handle',
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'strength and speed',
        group: 'Push Pull Sleds',
        urlCategory: 'football',
        urlSubCategory: 'strength and speed',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpull-handle-har02-HQ1.jpg?alt=media&token=02628683-2852-4f09-8d1e-8692a3344a30",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpull-handle-har02-HQ1.jpg?alt=media&token=02628683-2852-4f09-8d1e-8692a3344a30"
        ]
    },{
        id: 'p225',
        name: 'Double Sled to Harness Connector',
        sku: 'har04',
        description: `Pull handle for your push/pull sleds.`,
        details: [
            'For PS2500 and PS3000 models',
        ],
        includes: [
            ``
        ],
        options: [
            'HAR04: Double Sled to Harness Connector (For PS2500 and PS3000 models)',
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'strength and speed',
        group: 'Push Pull Sleds',
        urlCategory: 'football',
        urlSubCategory: 'strength and speed',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fdouble-sled-to-harness-connector-har04-HQ1.jpg?alt=media&token=376269bd-8ef8-4cda-af9b-765348a7e00c",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fdouble-sled-to-harness-connector-har04-HQ1.jpg?alt=media&token=376269bd-8ef8-4cda-af9b-765348a7e00c"
        ]
    },{
        id: 'p226',
        name: 'Single Sled to Harness Connector',
        sku: 'har05',
        description: `Pull handle for your push/pull sleds.`,
        details: [
            'For PS1500 and PS4000 models',
        ],
        includes: [
            ``
        ],
        options: [
            'HAR05: Single Sled to Harness Connector (For PS1500 and PS4000 models)',
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'strength and speed',
        group: 'Push Pull Sleds',
        urlCategory: 'football',
        urlSubCategory: 'strength and speed',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fsingle-sled-to-harness-connector-har05-HQ1.jpg?alt=media&token=367594bc-7edd-4d9e-8cdc-d20d1edfb1b0",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fsingle-sled-to-harness-connector-har05-HQ1.jpg?alt=media&token=367594bc-7edd-4d9e-8cdc-d20d1edfb1b0"
        ]
    },{
        id: 'p227',
        name: 'Harness with Metal Clip',
        sku: 'har06',
        description: `Harness that can be used with your Push/Pull Sleds.`,
        details: [
            `Body harness for pulling a Push/Pull Sled`
        ],
        includes: [
            ``
        ],
        options: [
            'HAR06: Harness with Metal Clip',
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'strength and speed',
        group: 'Push Pull Sleds',
        urlCategory: 'football',
        urlSubCategory: 'strength and speed',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fharness-with-metal-clip-har06-HQ1.jpg?alt=media&token=30452792-d264-4772-81d9-8c03cea9f5d5",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fharness-with-metal-clip-har06-HQ1.jpg?alt=media&token=30452792-d264-4772-81d9-8c03cea9f5d5"
        ]
    },{
        id: 'p228',
        name: 'Weighted Vest',
        sku: 'agvest12',
        description: `Fisher Athletic's Weighted Vest is great for building core strength and adding intensity to your workouts.`,
        details: [
            'Overlapping Velcro enclosures ensures a snug fit for all sizes',
            'One size fits all',
            'Padded shoulders',
            '2" x 4" white pocket on back for identification',
            'Pre-loaded with lead shot blend',
            'Stock color is gray',
        ],
        includes: [
            ``
        ],
        options: [
            'AGVEST12: Weighted Vest (12 lbs)',
            'AGVEST22: Weighted Vest (22 lbs)',
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'strength and speed',
        group: '',
        urlCategory: 'football',
        urlSubCategory: 'strength and speed',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fweighted-vest-agvest12-HQ1.jpg?alt=media&token=3138fbfa-025b-4fb7-9a93-687166d59bfd",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fweighted-vest-agvest12-HQ1.jpg?alt=media&token=3138fbfa-025b-4fb7-9a93-687166d59bfd",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fweighted-vest-agvest12-HQ2.jpg?alt=media&token=d1e80c44-fbe6-4df4-8391-0d2e96e81225",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fweighted-vest-agvest12-HQ3.jpg?alt=media&token=656a043a-3212-4a22-9f55-7ae0cc308263"
        ]
    },{
        id: 'p229',
        name: 'Glute Ham',
        sku: 'agglute',
        description: `Develop your posterier chain, hamstrings, glutes, and more with Fisher Athletic's Glute Ham.`,
        details: [
            'High density foam covered with 18 oz vinyl',
            'Features end handles',
            'Weight: 35 lbs',
            'Dimensions: 32"L x 24"W (base)',
            'Dimensions: 10"T x 16"W (upper pad)'
        ],
        includes: [
            ``
        ],
        options: [
            'AGGLUTE: Glute Ham',
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'strength and speed',
        group: '',
        urlCategory: 'football',
        urlSubCategory: 'strength and speed',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fglute-ham-agglute-HQ1.jpg?alt=media&token=5b99cf9d-1f8b-4985-98b1-8dbfdc87c1c8",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fglute-ham-agglute-HQ1.jpg?alt=media&token=5b99cf9d-1f8b-4985-98b1-8dbfdc87c1c8",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fglute-ham-agglute-HQ2.jpg?alt=media&token=095ccdb0-82a0-45f8-a599-7db81cd7ff25",
        ]
    },{
        id: 'p230',
        name: 'Warrior Ropes',
        sku: 'wrp30',
        description: `The Warrior training rope strengthens muscle groups in your upper back, biceps, and forearms. Excellent for developing finger and hand strength. Use the rope as a station in your functional training circuit.`,
        details: [
            'Available in 2" diameter poly Dacron rope',
            'Ends are whipped with twine, shrink wrapped, and sealed with a rubber cap',
            'Designed to prevent fraying and unraveling.',
            `Dimensions: 30'L x 2"diam`
        ],
        includes: [
            ``
        ],
        options: [
            `WRP30: Warrior Rope (30'L x 2"diam)`,
            `WRP50: Warrior Rope (50'L x 2"diam)`,
            `WRP100: Warrior Rope (100'L x 2"diam)`,
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'strength and speed',
        group: '',
        urlCategory: 'football',
        urlSubCategory: 'strength and speed',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fwarrior-ropes-wrp30-HQ1.jpg?alt=media&token=de81bea5-6c6f-4bcb-a179-d9866df89c11",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fwarrior-ropes-wrp30-HQ1.jpg?alt=media&token=de81bea5-6c6f-4bcb-a179-d9866df89c11",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fwarrior-ropes-wrp30-HQ2.jpg?alt=media&token=6f99b031-1744-4685-b77d-a236f59ce241",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fwarrior-ropes-wrp30-HQ3.jpg?alt=media&token=4d7bb054-2473-479f-ac40-d4c3488e1cfc",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fwarrior-ropes-wrp30-HQ4.jpg?alt=media&token=a8870a9b-0a80-4c88-931e-5f50d2bc5dbd"
        ]
    },{
        id: 'p231',
        name: 'SSR Mini Band',
        sku: 'ssr5',
        description: `Use Fisher Athletic's SSR Bands for strength, stretching, or rehabilitation.`,
        details: [
            'Variable resistance: the resistance increases throughout the range of motion',
            'Enhanced performance: documented improvement in vertical jump, speed, foot quickness and flexibility',
            'Injury reduction: benefits include reduced incidence of sprains, strains, and pulls',
            'Achieve faster recovery times',
        ],
        includes: [
            ``
        ],
        options: [
            `SSR5: SSR Band (mini)`,
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'strength and speed',
        group: 'SSR Bands',
        urlCategory: 'football',
        urlSubCategory: 'strength and speed',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fssr-mini-band-ssr5-HQ1.jpg?alt=media&token=c51d31d1-09a8-4d53-a042-52b72f1b0dfd",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fssr-mini-band-ssr5-HQ1.jpg?alt=media&token=c51d31d1-09a8-4d53-a042-52b72f1b0dfd",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fssr-bands-HQ1.jpg?alt=media&token=1b10132f-5162-4b72-84df-6f043ff70650",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fssr-bands-HQ2.jpg?alt=media&token=349e5511-dc6f-497e-9fb3-266ba064ad73"
        ]
    },{
        id: 'p232',
        name: 'SSR Medium Band',
        sku: 'ssr125',
        description: `Use Fisher Athletic's SSR Bands for strength, stretching, or rehabilitation.`,
        details: [
            'Variable resistance: the resistance increases throughout the range of motion',
            'Enhanced performance: documented improvement in vertical jump, speed, foot quickness and flexibility',
            'Injury reduction: benefits include reduced incidence of sprains, strains, and pulls',
            'Achieve faster recovery times',
        ],
        includes: [
            ``
        ],
        options: [
            `SSR125: SSR Band (medium)`,
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'strength and speed',
        group: 'SSR Bands',
        urlCategory: 'football',
        urlSubCategory: 'strength and speed',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fssr-medium-band-ssr125-HQ1.jpg?alt=media&token=dc834093-b90c-47bc-ac2f-ff6040552acf",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fssr-medium-band-ssr125-HQ1.jpg?alt=media&token=dc834093-b90c-47bc-ac2f-ff6040552acf",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fssr-bands-HQ1.jpg?alt=media&token=1b10132f-5162-4b72-84df-6f043ff70650",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fssr-bands-HQ2.jpg?alt=media&token=349e5511-dc6f-497e-9fb3-266ba064ad73"
        ]
    },{
        id: 'p233',
        name: 'SSR Strong Band',
        sku: 'ssr2',
        description: `Use Fisher Athletic's SSR Bands for strength, stretching, or rehabilitation.`,
        details: [
            'Variable resistance: the resistance increases throughout the range of motion',
            'Enhanced performance: documented improvement in vertical jump, speed, foot quickness and flexibility',
            'Injury reduction: benefits include reduced incidence of sprains, strains, and pulls',
            'Achieve faster recovery times',
        ],
        includes: [
            ``
        ],
        options: [
            `SSR2: SSR Band (strong)`,
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'strength and speed',
        group: 'SSR Bands',
        urlCategory: 'football',
        urlSubCategory: 'strength and speed',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fssr-strong-band-ssr2-HQ1.jpg?alt=media&token=fbc4703b-151d-43a0-981a-f11d8dd23eb2",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fssr-strong-band-ssr2-HQ1.jpg?alt=media&token=fbc4703b-151d-43a0-981a-f11d8dd23eb2",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fssr-bands-HQ1.jpg?alt=media&token=1b10132f-5162-4b72-84df-6f043ff70650",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fssr-bands-HQ2.jpg?alt=media&token=349e5511-dc6f-497e-9fb3-266ba064ad73"
        ]
    },{
        id: 'p234',
        name: 'SSR Ultra Band',
        sku: 'ssr25',
        description: `Use Fisher Athletic's SSR Bands for strength, stretching, or rehabilitation.`,
        details: [
            'Variable resistance: the resistance increases throughout the range of motion',
            'Enhanced performance: documented improvement in vertical jump, speed, foot quickness and flexibility',
            'Injury reduction: benefits include reduced incidence of sprains, strains, and pulls',
            'Achieve faster recovery times',
        ],
        includes: [
            ``
        ],
        options: [
            `SSR25: SSR Band (ultra)`,
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'strength and speed',
        group: 'SSR Bands',
        urlCategory: 'football',
        urlSubCategory: 'strength and speed',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fssr-ultra-band-ssr25-HQ1.jpg?alt=media&token=98ddee0c-85a6-466a-84b2-8f3da5db4ffc",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fssr-ultra-band-ssr25-HQ1.jpg?alt=media&token=98ddee0c-85a6-466a-84b2-8f3da5db4ffc",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fssr-bands-HQ1.jpg?alt=media&token=1b10132f-5162-4b72-84df-6f043ff70650",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fssr-bands-HQ2.jpg?alt=media&token=349e5511-dc6f-497e-9fb3-266ba064ad73"
        ]
    },{
        id: 'p235',
        name: 'Agility Hurdles',
        sku: 'agcaadjp',
        description: `Adjustable hurdle with weighted base`,
        details: [
            'Weighted base',
            'Adjusts from 28" to 60" tall',
            '40" wide',
            'Weight: 30 lbs',
        ],
        includes: [
            ``
        ],
        options: [
            `AGCAADJP: Agility Hurdle (powder coated)`,
            `AGCADJN: Agility Hurdle (natural aluminum silver)`,
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'strength and speed',
        group: '',
        urlCategory: 'football',
        urlSubCategory: 'strength and speed',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fagility-hurdles-agcaadjp-HQ1.jpg?alt=media&token=583fe65f-6c88-407a-beb5-8eb2530c1e39",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fagility-hurdles-agcaadjp-HQ1.jpg?alt=media&token=583fe65f-6c88-407a-beb5-8eb2530c1e39",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fagility-hurdles-agcaadjp-HQ2.jpg?alt=media&token=1ff204c0-087b-4cb1-8838-41e4e3d9ac1a",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fagility-hurdles-agcaadjp-HQ3.jpg?alt=media&token=81f48c68-45cb-43c0-b4d7-416dd2b6ad7b",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fagility-hurdles-agcaadjp-HQ4.jpg?alt=media&token=5bb0a159-1987-4a04-b479-b0e21a78f379"
        ]
    },{
        id: 'p236',
        name: 'Boulder Ball 6 lbs',
        sku: 'bb06',
        description: `Boulder Balls are manufactured to the highest quality and promise to provide an intense and quality workout.`,
        details: [
            'Each weighted ball is the same size, the only difference is the weight',
            '45" circumference',
            '14" diameter',
            'Allows the athlete to increase the weight without changing their movement',
            'Our Boulder Balls have a solid, durable feel and are designed to take plenty of punishment',
            'Weight: 6 lbs',
        ],
        includes: [
            ``
        ],
        options: [
            `BB06: Boulder Ball (purple) 6 lbs`,
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'strength and speed',
        group: 'Boulder Balls',
        urlCategory: 'football',
        urlSubCategory: 'strength and speed',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fboulder-ball-dynamic-6lbs-2400-1.jpg?alt=media&token=2fc12479-efe7-4f61-8944-0560ae63ef57",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fboulder-ball-dynamic-6lbs-2400-1.jpg?alt=media&token=2fc12479-efe7-4f61-8944-0560ae63ef57",
        ]
    },{
        id: 'p237',
        name: 'Boulder Ball 8 lbs',
        sku: 'bb08',
        description: `Boulder Balls are manufactured to the highest quality and promise to provide an intense and quality workout.`,
        details: [
            'Each weighted ball is the same size, the only difference is the weight',
            '45" circumference',
            '14" diameter',
            'Allows the athlete to increase the weight without changing their movement',
            'Our Boulder Balls have a solid, durable feel and are designed to take plenty of punishment',
            'Weight: 8 lbs',
        ],
        includes: [
            ``
        ],
        options: [
            `BB08: Boulder Ball (kelly green) 8 lbs`,
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'strength and speed',
        group: 'Boulder Balls',
        urlCategory: 'football',
        urlSubCategory: 'strength and speed',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fboulder-ball-dynamic-8lbs-2400-1.jpg?alt=media&token=eb9d6b9f-072e-46d6-bb7d-11ff5ae12271",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fboulder-ball-dynamic-8lbs-2400-1.jpg?alt=media&token=eb9d6b9f-072e-46d6-bb7d-11ff5ae12271",
        ]
    },{
        id: 'p238',
        name: 'Boulder Ball 10 lbs',
        sku: 'bb10',
        description: `Boulder Balls are manufactured to the highest quality and promise to provide an intense and quality workout.`,
        details: [
            'Each weighted ball is the same size, the only difference is the weight',
            '45" circumference',
            '14" diameter',
            'Allows the athlete to increase the weight without changing their movement',
            'Our Boulder Balls have a solid, durable feel and are designed to take plenty of punishment',
            'Weight: 10 lbs',
        ],
        includes: [
            ``
        ],
        options: [
            `BB10: Boulder Ball (gray) 10 lbs`,
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'strength and speed',
        group: 'Boulder Balls',
        urlCategory: 'football',
        urlSubCategory: 'strength and speed',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fboulder-ball-dynamic-10lbs-2400-1.jpg?alt=media&token=e37fd2ef-7dbb-481b-abd3-2b1816fd8cb7",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fboulder-ball-dynamic-10lbs-2400-1.jpg?alt=media&token=e37fd2ef-7dbb-481b-abd3-2b1816fd8cb7",
        ]
    },{
        id: 'p239',
        name: 'Boulder Ball 12 lbs',
        sku: 'bb12',
        description: `Boulder Balls are manufactured to the highest quality and promise to provide an intense and quality workout.`,
        details: [
            'Each weighted ball is the same size, the only difference is the weight',
            '45" circumference',
            '14" diameter',
            'Allows the athlete to increase the weight without changing their movement',
            'Our Boulder Balls have a solid, durable feel and are designed to take plenty of punishment',
            'Weight: 12 lbs',
        ],
        includes: [
            ``
        ],
        options: [
            `BB12: Boulder Ball (gold) 12 lbs`,
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'strength and speed',
        group: 'Boulder Balls',
        urlCategory: 'football',
        urlSubCategory: 'strength and speed',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fboulder-ball-dynamic-12lbs-2400-1.jpg?alt=media&token=5055d2a3-ba7b-4a3c-81b2-de95ac4ef439",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fboulder-ball-dynamic-12lbs-2400-1.jpg?alt=media&token=5055d2a3-ba7b-4a3c-81b2-de95ac4ef439",
        ]
    },{
        id: 'p240',
        name: 'Boulder Ball 14 lbs',
        sku: 'bb14',
        description: `Boulder Balls are manufactured to the highest quality and promise to provide an intense and quality workout.`,
        details: [
            'Each weighted ball is the same size, the only difference is the weight',
            '45" circumference',
            '14" diameter',
            'Allows the athlete to increase the weight without changing their movement',
            'Our Boulder Balls have a solid, durable feel and are designed to take plenty of punishment',
            'Weight: 14 lbs',
        ],
        includes: [
            ``
        ],
        options: [
            `BB14: Boulder Ball (red) 14 lbs`,
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'strength and speed',
        group: 'Boulder Balls',
        urlCategory: 'football',
        urlSubCategory: 'strength and speed',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fboulder-ball-dynamic-14lbs-2400-1.jpg?alt=media&token=dda76f2a-a471-42c8-be97-dbaeb7a4de8d",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fboulder-ball-dynamic-14lbs-2400-1.jpg?alt=media&token=dda76f2a-a471-42c8-be97-dbaeb7a4de8d",
        ]
    },{
        id: 'p241',
        name: 'Boulder Ball 16 lbs',
        sku: 'bb16',
        description: `Boulder Balls are manufactured to the highest quality and promise to provide an intense and quality workout.`,
        details: [
            'Each weighted ball is the same size, the only difference is the weight',
            '45" circumference',
            '14" diameter',
            'Allows the athlete to increase the weight without changing their movement',
            'Our Boulder Balls have a solid, durable feel and are designed to take plenty of punishment',
            'Weight: 16 lbs',
        ],
        includes: [
            ``
        ],
        options: [
            `BB16: Boulder Ball (columbia blue) 16 lbs`,
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'strength and speed',
        group: 'Boulder Balls',
        urlCategory: 'football',
        urlSubCategory: 'strength and speed',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fboulder-ball-dynamic-16lbs-2400-1.jpg?alt=media&token=d84f83a7-7b30-427d-b317-f8781c72adb6",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fboulder-ball-dynamic-16lbs-2400-1.jpg?alt=media&token=d84f83a7-7b30-427d-b317-f8781c72adb6",
        ]
    },{
        id: 'p242',
        name: 'Boulder Ball 18 lbs',
        sku: 'bb18',
        description: `Boulder Balls are manufactured to the highest quality and promise to provide an intense and quality workout.`,
        details: [
            'Each weighted ball is the same size, the only difference is the weight',
            '45" circumference',
            '14" diameter',
            'Allows the athlete to increase the weight without changing their movement',
            'Our Boulder Balls have a solid, durable feel and are designed to take plenty of punishment',
            'Weight: 18 lbs',
        ],
        includes: [
            ``
        ],
        options: [
            `BB18: Boulder Ball (tan) 18 lbs`,
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'strength and speed',
        group: 'Boulder Balls',
        urlCategory: 'football',
        urlSubCategory: 'strength and speed',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fboulder-ball-dynamic-18lbs-2400-1.jpg?alt=media&token=0a105f8e-e842-4a36-9aba-25e8857f8a6c",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fboulder-ball-dynamic-18lbs-2400-1.jpg?alt=media&token=0a105f8e-e842-4a36-9aba-25e8857f8a6c",
        ]
    },{
        id: 'p243',
        name: 'Boulder Ball 20 lbs',
        sku: 'bb20',
        description: `Boulder Balls are manufactured to the highest quality and promise to provide an intense and quality workout.`,
        details: [
            'Each weighted ball is the same size, the only difference is the weight',
            '45" circumference',
            '14" diameter',
            'Allows the athlete to increase the weight without changing their movement',
            'Our Boulder Balls have a solid, durable feel and are designed to take plenty of punishment',
            'Weight: 20 lbs',
        ],
        includes: [
            ``
        ],
        options: [
            `BB20: Boulder Ball (gray) 20 lbs`,
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'strength and speed',
        group: 'Boulder Balls',
        urlCategory: 'football',
        urlSubCategory: 'strength and speed',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fboulder-ball-dynamic-20lbs-2400-1.jpg?alt=media&token=06980370-6fd6-4cb1-9b5d-961e83a1b91e",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fboulder-ball-dynamic-20lbs-2400-1.jpg?alt=media&token=06980370-6fd6-4cb1-9b5d-961e83a1b91e",
        ]
    },{
        id: 'p244',
        name: 'S-Core Trainer',
        sku: 'agscore50',
        description: `Fisher Athletic's S-Core Trainer is a great tool for developing core strength.`,
        details: [
            'Six handle locations for flexible workouts',
            'Heavy bag simulates the Seal Team log drills on an indiviual basis',
            'Safe solid rebond foam filled inner core',
            'Dimensions: 48"L x 14"Diam',
        ],
        includes: [
            ``
        ],
        options: [
            `AGSCORE50: S Core Trainer (50 lbs)`,
            `AGSCORE75: S Core Trainer (75 lbs)`,
            `AGSCORE100: S Core Trainer (100 lbs)`,
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'strength and speed',
        group: '',
        urlCategory: 'football',
        urlSubCategory: 'strength and speed',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fs-core-trainer-agscore50-HQ1.jpg?alt=media&token=4b48e4cd-773b-4516-a689-775db4dc7bd3",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fs-core-trainer-agscore50-HQ1.jpg?alt=media&token=4b48e4cd-773b-4516-a689-775db4dc7bd3",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fs-core-trainer-agscore50-HQ2.jpg?alt=media&token=fba7d24b-b092-4121-be12-47668a30c0b9",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fs-core-trainer-agscore50-HQ3.jpg?alt=media&token=080c42d2-09f7-4cfe-8c55-ef2941262186",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fs-core-trainer-agscore50-HQ4.jpg?alt=media&token=17d26807-1aeb-437c-b88b-93eee9ea7e5a"
        ]
    },{
        id: 'p245',
        name: 'Power Bag',
        sku: 'punch40',
        description: `Use Fisher Athletic's Power Bag to build hands, arms, and elbows while providing an excellent upper-body workout.`,
        details: [
            'Extending the bag improves player footwork and balance',
            'New handgrips improve control',
            'Ships without sand to save on freight cost',
            'Zippered back allows for easy fill with sand',
            'Weight: 40 lbs',
        ],
        includes: [
            ``
        ],
        options: [
            `PUNCH40: Power Bag (40 lbs)`,
            `PUNCH25: Power Bag (25 lbs)`,
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'strength and speed',
        group: '',
        urlCategory: 'football',
        urlSubCategory: 'strength and speed',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpower-bag-punch40-HQ1.jpg?alt=media&token=369adbb1-3129-4e32-8f7e-c05734895d66",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpower-bag-punch40-HQ1.jpg?alt=media&token=369adbb1-3129-4e32-8f7e-c05734895d66",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpower-bag-punch40-HQ2.jpg?alt=media&token=617f1cc3-7b84-4bd5-9616-81ef0b5a2637",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpower-bag-punch40-HQ3.jpg?alt=media&token=1882279c-abf1-4777-a90c-03d06fcbbd4d",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpower-bag-punch40-HQ4.jpg?alt=media&token=eecb20da-438e-4d77-9054-67f912daa82a"
        ]
    },{
        id: 'p246',
        name: 'The Log',
        sku: 'aglog',
        description: `Build upper-body strength with The Log.`,
        details: [
            'Eight handles for multiple hand locations on 42" sides and one on each end',
            'Zipper and Velcro opening for adding weight',
            'Add up to 130 lbs of sand',
        ],
        includes: [
            ``
        ],
        options: [
            `AGLOG: The Log (up to 130 lbs)`,
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'strength and speed',
        group: '',
        urlCategory: 'football',
        urlSubCategory: 'strength and speed',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fthe-log-aglog-HQ1.jpg?alt=media&token=d5b0336c-f61d-488a-8805-aadb422443a8",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fthe-log-aglog-HQ1.jpg?alt=media&token=d5b0336c-f61d-488a-8805-aadb422443a8",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fthe-log-aglog-HQ2.jpg?alt=media&token=a059e7f1-1248-487a-b082-d968ea3d5be4",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fthe-log-aglog-HQ3.jpg?alt=media&token=615b7451-3b23-4a30-96a4-e23165723c22",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fthe-log-aglog-HQ4.jpg?alt=media&token=2e223d91-cfd3-43e4-b7bc-dc072e0baaa6"
        ]
    },{
        id: 'p247',
        name: 'Segment Timer',
        sku: 'seg100',
        description: `The Segment Timer makes is easy for coaches and trainers to manage practice sessions on a large LED display.`,
        details: [
            `Operate the Segment Timer from it's smartphone app (available for both iPhone and Android) or radio frequency remote`,
            `Four digit LED panel viewable up to 300ft away`,
            `Left two digits are 7 1/2" tall; right two digits are 12 1/2" tall`,
            `Left digits display number of segments`,
            `Right digits display length of time for each segment`,
            `Program up to 99 segments between 1 second and 99 minutes, 59 seconds`,
            `Final minute counts down in seconds`,
            `Operate remote control up to 65ft away`,
            `Store multiple programs in app, then download to timer when needed`,
            `Adjust volume up to 140dB`,
            `Can handle light rain, but not water proof`,
            `4 operating modes: countdown, count-up, scoreboard, clock mode`,
            `Scoreboard mode displays scores for two teams, up to 99 each`,
            `Freestanding on ground or table or can be hanged from a fence`,
            `Three-year warranty`,
        ],
        includes: [
            ``
        ],
        options: [
            `SEG100: Segment Timer`,
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'strength and speed',
        group: 'Segment Timer',
        urlCategory: 'football',
        urlSubCategory: 'strength and speed',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fsegment-timer-seg100-HQ1.jpg?alt=media&token=11505219-2e35-47b0-ac1e-e241e7af4214",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fsegment-timer-seg100-HQ1.jpg?alt=media&token=11505219-2e35-47b0-ac1e-e241e7af4214",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fsegment-timer-seg100-HQ5.jpg?alt=media&token=5351d487-6f34-41ba-be46-3067b6d78fb1",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fsegment-timer-seg100-HQ6.jpg?alt=media&token=4c5b165d-52ca-401b-a35a-e11727cea22b",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fsegment-timer-seg100-HQ2.jpg?alt=media&token=859cdb4d-830b-432b-9ae7-80ad2166fdf9",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fsegment-timer-seg100-HQ3.jpg?alt=media&token=95dc8f14-3cad-4482-aeb4-630a20488550",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fsegment-timer-seg100-HQ4.jpg?alt=media&token=a77f90f0-d575-48cb-b86b-0a76331b5d75",
        ]
    },{
        id: 'p248',
        name: 'Segment Timer Bag',
        sku: 'segbag',
        description: `Convenient carrying bag for your Segment Timer`,
        details: [
            `Carries and stores your Fisher Athletic Segment Timer`
        ],
        includes: [
            ``
        ],
        options: [
            `SEGBAG: Segment Timer Bag`,
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'strength and speed',
        group: 'Segment Timer',
        urlCategory: 'football',
        urlSubCategory: 'strength and speed',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fsegment-timer-bag-segbag-HQ1.jpg?alt=media&token=b5912930-f3c1-4fcc-a5b2-7b9f97acb0c3",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fsegment-timer-bag-segbag-HQ1.jpg?alt=media&token=b5912930-f3c1-4fcc-a5b2-7b9f97acb0c3",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fsegment-timer-seg100-HQ1.jpg?alt=media&token=11505219-2e35-47b0-ac1e-e241e7af4214",
        ]
    },{
        id: 'p249',
        name: 'Segment Timer Cover',
        sku: 'segcover',
        description: `Protects your Segment Timer from rain`,
        details: [
            `Carries and stores your Fisher Athletic Segment Timer`,
            `Water-proof to protect your Segment Timer from rain`
        ],
        includes: [
            ``
        ],
        options: [
            `SEGCOVER: Segment Timer Cover`,
        ],
        usedBy: [],
        category: 'football',
        subCategory: 'strength and speed',
        group: 'Segment Timer',
        urlCategory: 'football',
        urlSubCategory: 'strength and speed',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fsegment-timer-rain-cover-segcover-HQ1.jpg?alt=media&token=06e75a83-db1d-4cb5-9f31-3650927c566e",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fsegment-timer-rain-cover-segcover-HQ1.jpg?alt=media&token=06e75a83-db1d-4cb5-9f31-3650927c566e",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fsegment-timer-seg100-HQ1.jpg?alt=media&token=11505219-2e35-47b0-ac1e-e241e7af4214",
        ]
    },{
        id: 'p251',
        name: 'Deluxe Mesh Windscreen',
        sku: 'dmws',
        description: `Upgrade your field by adding custom windscreen that not only looks aesthetically pleasing, but also adds privacy and protection from strong winds and dust.`,
        details: [
            'Available in 10 colors / UV resistant',
            '75% to 80% shade density (depending on color)',
            'Grommets every 18" on all sides',
            '40 oz reinforced hem on all four sides (heaviest in industry)',
            'Manufactured to your size specifications',
            'Custom graphics available',
            '8 oz mesh with 9 x 12 mesh count per inch',
        ],
        includes: [
            ``
        ],
        options: [
            `Windscreen`,
            `DMWS: Deluxe Mesh Wind Screen`,
            `Optional Windscreen Features`,
            `WVHM1: Half Moon Cutout with Flap`,
            `TY1: 8" Plastic Cable Ties (black) 100 count`,
            `TY3: 15" Cable Ties (black) 100 count`,
            `HR1: Home Run Stripe (4", 6", 8", 12", and 18") available in 17 colors`,
            `ROPE25BK: Polypropylene Rope (black)`,
            `ROPE25BL: Polypropylene Rope (blue)`,
            `MSCST: Grommeted Center Strip (for Ties)`,
            `MSCSR: Grommeted Center Strip (for Rope)`,
        ],
        usedBy: [],
        category: 'baseball windscreen',
        subCategory: 'windscreen deluxe mesh',
        group: '',
        urlCategory: 'baseball windscreen',
        urlSubCategory: 'windscreen deluxe mesh',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fdeluxe-mesh-windscreen-dmws-riverside.jpg?alt=media&token=20a509e4-4c29-4dc9-b8f4-68541b3dc90f",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fdeluxe-mesh-windscreen-dmws-riverside.jpg?alt=media&token=20a509e4-4c29-4dc9-b8f4-68541b3dc90f",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fdeluxe-mesh-windscreen-dmws-HQ11.jpg?alt=media&token=c8bd3f3c-649f-47b4-ace0-42be4d135bce",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fdeluxe-mesh-windscreen-dmws-HQ18.jpg?alt=media&token=c8be4210-90ad-4e06-adbc-10a5564eb400",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fdeluxe-mesh-windscreen-dmws-HQ12.jpg?alt=media&token=a31dc940-fba8-40d1-b011-caf51d772f44",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fdeluxe-mesh-windscreen-dmws-HQ20.jpg?alt=media&token=3d70859f-3912-4f37-b7a3-3aa4c7dce776",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fdeluxe-mesh-windscreen-dmws-HQ14.jpg?alt=media&token=7b2650f0-288e-494d-84d7-5aa4399492f9",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fdeluxe-mesh-windscreen-dmws-HQ10.jpg?alt=media&token=afae724a-b378-426d-846d-479d1a1168fa",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fdeluxe-mesh-windscreen-dmws-HQ2.jpg?alt=media&token=72f65444-4b4c-4180-a1f9-4bf8e606a9f4",            
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fdeluxe-mesh-windscreen-dmws-HQ1.jpg?alt=media&token=cb270fd6-7a5f-44bc-afa8-90210e16e8db",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fdeluxe-mesh-windscreen-dmws-HQ13.jpg?alt=media&token=4b113e06-f1af-4915-8300-2030a08a8169",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fdeluxe-mesh-windscreen-dmws-HQ3.jpg?alt=media&token=93048656-ba00-4d37-80a3-ee7218178d6d",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fdeluxe-mesh-windscreen-dmws-HQ4.jpg?alt=media&token=6ab3d083-cfb3-4e31-912d-cd96c7a62cc2",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fdeluxe-mesh-windscreen-dmws-HQ6.jpg?alt=media&token=e7d5dba7-c8dc-4490-bad5-6dab4983b525",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fdeluxe-mesh-windscreen-dmws-HQ17.jpg?alt=media&token=21c6baf2-a335-4f95-a6ac-94d9ad10f95e",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fdeluxe-mesh-windscreen-dmws-HQ5.jpg?alt=media&token=92c3e5f5-af3d-43f8-a300-9a052b3f1053",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fdeluxe-mesh-windscreen-dmws-HQ7.jpg?alt=media&token=fee36bf8-3c07-4e4e-a50a-95c9cae4531d",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fdeluxe-mesh-windscreen-dmws-HQ8.jpg?alt=media&token=86b47d8c-4d08-4659-acf7-ad4f88fa7bf9",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fdeluxe-mesh-windscreen-dmws-HQ9.jpg?alt=media&token=0097c8a9-5909-4cd4-be85-0e388b230b13",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fdeluxe-mesh-windscreen-dmws-HQ15.jpg?alt=media&token=c86b2029-5757-410b-92cf-d9cba1f05933",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fdeluxe-mesh-windscreen-dmws-harding.jpg?alt=media&token=487155ef-82f0-424b-8269-e3a42b44904e",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fdeluxe-mesh-windscreen-dmws-mauldin.jpg?alt=media&token=afa6fe1c-d8b6-499d-80ab-e3a325796008",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fdeluxe-mesh-windscreen-dmws-HQ16.jpg?alt=media&token=5dbbfd6a-5724-487e-94e3-e09989f702af"
        ]
    },{
        id: 'p250',
        name: 'Titan Mesh Windscreen',
        sku: 'tmws',
        description: `Upgrade your field by adding custom windscreen that not only looks aesthetically pleasing, but also adds privacy and protection from strong winds and dust.`,
        details: [
            'Available in 7 colors / UV resistant',
            '85% to 90% shade density',
            'Grommets every 18" on all sides',
            'Darker colors reduce visibility',
            '40 oz reinforced hem on all sides (heaviest in industry)',
            'Manufactured to your size specifications',
            'Custom graphics available',
            '12 oz mesh with 18 x 14 mesh count per inch',
        ],
        includes: [
            ``
        ],
        options: [
            `Windscreen`,
            `TMWS: Titan Mesh Wind Screen`,
            `Optional Windscreen Features`,
            `WVHM1: Half Moon Cutout with Flap`,
            `TY1: 8" Plastic Cable Ties (black) 100 count`,
            `TY3: 15" Cable Ties (black) 100 count`,
            `HR1: Home Run Stripe (4", 6", 8", 12", and 18") available in 17 colors`,
            `ROPE25BK: Polypropylene Rope (black)`,
            `ROPE25BL: Polypropylene Rope (blue)`,
            `MSCST: Grommeted Center Strip (for Ties)`,
            `MSCSR: Grommeted Center Strip (for Rope)`,
        ],
        usedBy: [],
        category: 'baseball windscreen',
        subCategory: 'windscreen titan mesh',
        group: '',
        urlCategory: 'baseball windscreen',
        urlSubCategory: 'windscreen titan mesh',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ftitan-mesh-windscreen-tmws-HQ5.jpg?alt=media&token=7ba67ffc-1479-48ac-8ffb-69954fa10c07",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ftitan-mesh-windscreen-tmws-HQ5.jpg?alt=media&token=7ba67ffc-1479-48ac-8ffb-69954fa10c07",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ftitan-mesh-windscreen-tmws-HQ17.jpg?alt=media&token=0107823e-8b09-43da-a9c3-824ad36029be",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ftitan-mesh-windscreen-tmws-HQ18.jpg?alt=media&token=0b0346ee-ae44-41ea-b80f-6fc3802a920a",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ftitan-mesh-windscreen-tmws-HQ3.jpg?alt=media&token=f54a9ce4-48fb-45c3-b039-2f01f0cf9d64",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ftitan-mesh-windscreen-tmws-HQ4.jpg?alt=media&token=b18544a3-faa3-42a2-b8f8-f6ce97932e1f",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ftitan-mesh-windscreen-tmws-HQ6.jpg?alt=media&token=a192247f-23c1-429d-8a3c-142e361e4be4",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ftitan-mesh-windscreen-tmws-HQ16.jpg?alt=media&token=64ba5313-2142-40df-ab35-c2a5452e72ac",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ftitan-mesh-windscreen-tmws-HQ2.jpg?alt=media&token=8faab02a-a092-4470-a874-a8d46e346205",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ftitan-mesh-windscreen-tmws-HQ7.jpg?alt=media&token=15be9130-1b7e-496b-a859-7a7d3a58b39f",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ftitan-mesh-windscreen-tmws-HQ8.jpg?alt=media&token=a29fb957-3658-4f11-a3b4-30856bf6db4f",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ftitan-mesh-windscreen-tmws-HQ9.jpg?alt=media&token=8de392e0-016c-4aef-a095-d61476c1c994",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ftitan-mesh-windscreen-tmws-HQ10.jpg?alt=media&token=74178b11-6ae4-4538-bd8a-808ba681cf33",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ftitan-mesh-windscreen-tmws-HQ11.jpg?alt=media&token=5753d5a1-240f-4661-8fad-599b83511d05",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ftitan-mesh-windscreen-tmws-HQ12.jpg?alt=media&token=c7d89326-72f6-4ed9-8aec-12217fca7c17",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ftitan-mesh-windscreen-tmws-HQ13.jpg?alt=media&token=47dc051b-11c8-4252-a7e2-41fd4b9043b9",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ftitan-mesh-windscreen-tmws-HQ14.jpg?alt=media&token=24485d74-823b-45fd-bb28-8f1347cc70a9",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ftitan-mesh-windscreen-tmws-HQ15.jpg?alt=media&token=892befd8-5c56-4795-817c-e15f50b8f35b"
        ]
    },{
        id: 'p252',
        name: 'Graphic Shield',
        sku: 'msdp',
        description: `Upgrade your field by adding custom windscreen that not only looks aesthetically pleasing, but also adds privacy and protection from strong winds and dust.`,
        details: [
            'UV resistant colors in unlimited combinations',
            'Double reinforced hem on all sides',
            'Grommets every 18" on all sides',
        ],
        includes: [
            ``
        ],
        options: [
            `Windscreen`,
            `MSDP: Graphic Shield (digitally printed windscreen)`,
            `Optional Windscreen Features`,
            `WVHM1: Half Moon Cutout with Flap`,
            `TY1: 8" Plastic Cable Ties (black) 100 count`,
            `TY3: 15" Cable Ties (black) 100 count`,
            `HR1: Home Run Stripe (4", 6", 8", 12", and 18") available in 17 colors`,
            `ROPE25BK: Polypropylene Rope (black)`,
            `ROPE25BL: Polypropylene Rope (blue)`,
            `MSCST: Grommeted Center Strip (for Ties)`,
            `MSCSR: Grommeted Center Strip (for Rope)`,
        ],
        usedBy: [],
        category: 'baseball windscreen facility branding',
        subCategory: 'windscreen graphic shield banners',
        group: '',
        urlCategory: 'baseball windscreen facility branding',
        urlSubCategory: 'windscreen graphic shield banners',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fgraphic-shield-msdp-HQ5.jpg?alt=media&token=28d3fbe5-13f6-4647-82f9-136aa48a703a",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fmsdp-south-florence.jpg?alt=media&token=5a95fb55-7031-4164-9fd6-9c51de83247d",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fgraphic-shield-msdp-HQ5.jpg?alt=media&token=28d3fbe5-13f6-4647-82f9-136aa48a703a",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fgraphic-shield-msdp-HQ2.jpg?alt=media&token=45300ba4-3b26-4ccf-9486-8157089aba27",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fgraphic-shield-msdp-HQ9.jpg?alt=media&token=b9ca64c6-5e51-46de-afe8-ba1b022f5183",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fgraphic-shield-msdp-HQ7.jpg?alt=media&token=b8147124-7cc8-434e-a668-072dacc1d0b8",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fgraphic-shield-msdp-HQ6.jpg?alt=media&token=bc6caf93-f7ea-46ee-a8d6-b788fd44cb68",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fgraphic-shield-msdp-HQ8.jpg?alt=media&token=54092fcc-8978-4b79-8785-771aa6daeb6a",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fgraphic-shield-msdp-HQ1.jpg?alt=media&token=27318d4e-59f4-4c44-bd90-d09049e46a74",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fgraphic-shield-msdp-HQ3.jpg?alt=media&token=ba029e3d-6232-4c02-b95a-5a966372e7d6",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fgraphic-shield-msdp-HQ4.jpg?alt=media&token=b55d69f1-4bb6-4142-8532-6686a00c3c76"
        ]
    },{
        id: 'p253',
        name: 'Fence Top',
        sku: 'bbc100y',
        description: `Fisher Athletic's Fence Top improves the look of your ballpark while also eliminating injury from chain link fence tops.`,
        details: [
            'Eliminates injury from chain link fence tops',
            'Improves the look of your ballpark',
            'Yellow, corrugated plastic tubing is pre-slit for easy installation',
        ],
        includes: [
            ``
        ],
        options: [
            `BBC100Y: Yellow Fence Top (100')`,
            `BBC100G: Green Fence Top (100')`,
            `BBC250Y: Yellow Fence Top (250')`,
            `BBC250G: Green Fence Top (250')`,
            `TY2GO: 19' Gold Cable Ties (100 count)`
        ],
        usedBy: [],
        category: 'baseball',
        subCategory: 'fence tops',
        group: '',
        urlCategory: 'baseball',
        urlSubCategory: 'fence tops',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ffence-top-bbc100y-HQ1.jpg?alt=media&token=55c637f6-4cca-4779-a755-fb494b3fcaa3",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ffence-top-bbc100y-HQ1.jpg?alt=media&token=55c637f6-4cca-4779-a755-fb494b3fcaa3",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ffence-top-bbc100y-HQ2.jpg?alt=media&token=92717b9e-25be-4f76-aeda-0da286c404db",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ffence-top-bbc100y-HQ3.jpg?alt=media&token=6861ce88-4c33-4da6-a630-e42243344ff1"
        ]
    },{
        id: 'p254',
        name: 'Poly-Cap Installer',
        sku: 'pc1',
        description: `Use this tool to quickly and easily install your Fence Top.`,
        details: [
            'Easy to use tool that helps you install Fence Top',
        ],
        includes: [
            ``
        ],
        options: [
            `PC1: Poly Cap Installer`,
        ],
        usedBy: [],
        category: 'baseball',
        subCategory: 'fence tops',
        group: '',
        urlCategory: 'baseball',
        urlSubCategory: 'fence tops',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpoly-cap-installer-pc1-HQ1.jpg?alt=media&token=d1895a4f-1f0c-42ad-b6e2-bd887dfb145a",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpoly-cap-installer-pc1-HQ1.jpg?alt=media&token=d1895a4f-1f0c-42ad-b6e2-bd887dfb145a",
        ]
    },{
        id: 'p255',
        name: `Fence Top Pad`,
        sku: 'trpg10',
        description: `Fisher Athletic's Fence Top Pad improves the look of your ballpark while also eliminating injury from chain link fence tops.`,
        details: [
            'This specialty pad is 13" wide x 10, 12, or 14 feet long',
            'Grommets every 6"',
            '1" closed cell, high density foam padding',
            '18 oz vinyl in 15 color choices',
        ],
        includes: [
            ``
        ],
        options: [
            `TRPG10: 10' Top Pad`,
            `TRPG12: 12' Top Pad`,
            `TRPG14: 14' Top Pad`,
            `TRPGSO: Custom Size Top Pad`,
        ],
        usedBy: [],
        category: 'baseball',
        subCategory: 'fence tops',
        group: '',
        urlCategory: 'baseball',
        urlSubCategory: 'fence tops',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ffence-top-pad-trpg10-HQ1.jpg?alt=media&token=c67818de-eb0e-41c2-8ce8-b610103ae4dc",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ffence-top-pad-trpg10-HQ1.jpg?alt=media&token=c67818de-eb0e-41c2-8ce8-b610103ae4dc",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ffence-top-pad-trpg10-HQ2.jpg?alt=media&token=9c5d8561-e0d5-45d4-8b48-67503fbdb4c8"
        ]
    },{
        id: 'p260',
        name: `Wall Panel (WPS Series)`,
        sku: 'wpscw23so3',
        description: `WPS Series Padding can be mounted to brick, concrete, wood or any solid surface, but also easily mounts to any chain link fence. The WPS series padding is wood backed and is supplied with 1" nailing lips at the top and bottom. Optional "Z" or "U" channel hardware is available for mounting WPS series padding to solid surfaces. Nailing lips are not needed when mounting with "Z" brackets.`,
        details: [
            'Wood Backed',
            'Non-Folding',
            'Choose from 2" or 3" polyfoam',
            '18 oz vinyl',
            `Can be mounted to brick, concrete, wood, chain link fence, or any solid surface`,
            `1" nailing lip at top and bottom`,
            `Optional "Z" or "U" channel hardware is available for mounting WPS series padding to solid surfaces`,
            `2' or 4' width`,
            `4', 5', 6', 7', or 8' height`,
        ],
        includes: [
            ``
        ],
        options: [
            `2" polyfoam`,
            `WPSCW22423: 5/8" CW - 2'W x 4'H x 2"Thick`,
            `WPSCW22443: 5/8" CW - 4'W x 4'H x 2"Thick`,
            `WPSCW22523: 5/8" CW - 2'W x 5'H x 2"Thick`,
            `WPSCW22543: 5/8" CW - 4'W x 5'H x 2"Thick`,
            `WPSCW22623: 5/8" CW - 2'W x 6'H x 2"Thick`,
            `WPSCW22643: 5/8" CW - 4'W x 6'H x 2"Thick`,
            `WPSCW22723: 5/8" CW - 2'W x 7'H x 2"Thick`,
            `WPSCW22743: 5/8" CW - 4'W x 7'H x 2"Thick`,
            `WPSCW22823: 5/8" CW - 2'W x 8'H x 2"Thick`,
            `WPSCW22843: 5/8" CW - 4'W x 8'H x 2"Thick`,
            `WPSCW22SO3: 5/8" CW - 2" Polyfoam - Custom Size`,
            `3" polyfoam`,
            `WPSCW23423: 5/8" CW - 2'W x 4'H x 3"Thick`,
            `WPSCW23423: 5/8" CW - 4'W x 4'H x 3"Thick`,
            `WPSCW23523: 5/8" CW - 2'W x 5'H x 3"Thick`,
            `WPSCW23543: 5/8" CW - 4'W x 5'H x 3"Thick`,
            `WPSCW23623: 5/8" CW - 2'W x 6'H x 3"Thick`,
            `WPSCW23643: 5/8" CW - 4'W x 6'H x 3"Thick`,
            `WPSCW23723: 5/8" CW - 2'W x 7'H x 3"Thick`,
            `WPSCW23743: 5/8" CW - 4'W x 7'H x 3"Thick`,
            `WPSCW23823: 5/8" CW - 2'W x 8'H x 3"Thick`,
            `WPSCW23843: 5/8" CW - 4'W x 8'H x 3"Thick`,
            `WPSCW23SO3: 5/8" CW - 3" Polyfoam - Custom Size`,
        ],
        usedBy: [],
        category: 'baseball  protective padding',
        subCategory: 'protective padding outdoor padding',
        group: 'Outdoor Wall Padding',
        urlCategory: 'baseball  protective padding',
        urlSubCategory: 'protective padding outdoor padding',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fwall-panels-longwood.jpg?alt=media&token=c062e721-6e99-4538-a084-c4dbe5f8991a",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fwall-panel-guardians1.jpg?alt=media&token=3699ce5a-cb21-424f-b784-8d4954ecc440",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fwall-panel-guardians2.jpg?alt=media&token=c647922c-e4e8-4f86-8296-35a64b7fc380",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fwall-panel-guardians3.jpg?alt=media&token=6de4a32d-858c-431d-9ee7-2a2361c84e15",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fwall-panel-guardians4.jpg?alt=media&token=6f77224b-6d98-4625-bd3e-b7fa2388672a",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fwall-panel-unc-1.jpg?alt=media&token=eea59c69-f25b-46c9-adee-6ceac09dcd41",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fwall-panel-unc-2.jpg?alt=media&token=a6f4592f-5c14-4919-93d4-af1315299fa6",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fbackstop-padding-bsp36-HQ1.jpg?alt=media&token=2ca13a1b-38f1-425d-a37e-1c0aeeffcbe0",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fbackstop-padding-bsp36-HQ2.jpg?alt=media&token=1c478f5a-04b0-4d7a-8e88-6fac9d7744ec",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fbackstop-padding-bsp36-HQ3.jpg?alt=media&token=a28a0ad7-8504-4e17-8eb2-7ef48cb6bc1d",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fwall-panel-hpu2.jpg?alt=media&token=a5cd4864-db18-4dc1-9946-5d399876debf",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fwall-panel-hpu.jpg?alt=media&token=88a59a6f-f9ca-4761-a6bb-3d996baadcd2",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fwall-panel-laketravis.jpg?alt=media&token=a57dabc1-7aa6-43d8-8b0e-a4b43acf6f94",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fwall-panel-hickory.jpg?alt=media&token=a4462c5f-7bad-4b47-9f0d-c0bd949dbd7b",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fwall-panels-longwood.jpg?alt=media&token=c062e721-6e99-4538-a084-c4dbe5f8991a",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fwall-panel-charlestonso.jpg?alt=media&token=2428cad4-f046-49f5-a06f-8db03c0e2400",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fwall-panel-davie.jpg?alt=media&token=15110c32-ac95-41f9-9c76-ddf85325f90f",
        ]
    },{
        id: 'p312',
        name: `Wall Panel (PSMS Series)`,
        sku: 'psms223',
        description: `PSMS Series Padding can be mounted to brick, concrete, wood or any solid surface, but also easily mounts to any chain link fence. The PSMS series padding is wood backed and is supplied with 1" nailing lips at the top and bottom.`,
        details: [
            'Wood Backed',
            'Non-Folding',
            'L Shaped',
            'Custom sizing',
            'Choose from 2" or 3" polyfoam',
            '18 oz vinyl or 14 oz fire retardant vinyl',
            `Can be mounted to brick, concrete, wood, chain link fence, or any solid surface`,
            `1" nailing lip at top and bottom`,
        ],
        includes: [
            ``
        ],
        options: [
            `2" polyfoam`,
            `PSM222: 14oz Fire Retardant Vinyl - Indoor`,
            `PSM223: 18oz Vinyl - Indoor`,
            `PSMS223: 18oz Vinyl - Outdoor`,
            `3" polyfoam`,
            `PSM232: 14oz Fire Retardant Vinyl - Indoor`,
            `PSM233: 18oz Vinyl - Indoor`,
            `PSMS233: 18oz Vinyl - Outdoor`,
        ],
        usedBy: [],
        category: 'baseball  protective padding',
        subCategory: 'protective padding outdoor padding',
        group: 'Outdoor Wall Padding',
        urlCategory: 'baseball  protective padding',
        urlSubCategory: 'protective padding outdoor padding',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fwall-panel-L-shape-psms-HQ1.jpg?alt=media&token=9c755236-4607-4076-b0c4-9fc5b8b636f0",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fwall-panel-L-shape-psms-HQ1.jpg?alt=media&token=9c755236-4607-4076-b0c4-9fc5b8b636f0",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fwall-panel-L-shape-psms-HQ2.jpg?alt=media&token=dff6bbc0-47ea-4433-a1ce-b047f5800c1d",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fwall-panel-L-shape-psms-HQ3.jpg?alt=media&token=c33bc129-6696-4688-b0d7-e8f238c06336",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fwall-panel-L-shape-psms-HQ4.jpg?alt=media&token=b963ab81-8620-4408-8114-b723d038ccd2"
        ]
    },{
        id: 'p313',
        name: `Wall Panel (EWM Series)`,
        sku: 'ewm3So3',
        description: `EWM Series Padding can be mounted to brick, concrete, wood or any solid surface, but also easily mounts to any chain link fence. EWM Series Padding has brass grommets at the top and bottom for mounting.`,
        details: [
            'Non-Wood Back',
            'Non-Folding',
            'Choose from 2" or 3" polyfoam',
            '18 oz vinyl',
            `Can be mounted to brick, concrete, wood, chain link fence, or any solid surface`,
            `Brass grommets at top and bottom`,
        ],
        includes: [
            ``
        ],
        options: [
            `2" polyfoam`,
            `EWM22423: 2'W x 4'H x 2" - 18 oz vinyl`,
            `EWM22443: 4'W x 4'H x 2" - 18 oz vinyl`,
            `EWM22523: 2'W x 5'H x 2" - 18 oz vinyl`,
            `EWM22543: 4'W x 5'H x 2" - 18 oz vinyl`,
            `EWM22623: 2'W x 6'H x 2" - 18 oz vinyl`,
            `EWM22643: 4'W x 6'H x 2" - 18 oz vinyl`,
            `EWM22723: 2'W x 7'H x 2" - 18 oz vinyl`,
            `EWM22743: 4'W x 7'H x 2" - 18 oz vinyl`,
            `EWM22823: 2'W x 8'H x 2" - 18 oz vinyl`,
            `EWM22843: 4'W x 8'H x 2" - 18 oz vinyl`,
            `EWM2SO3: Custom Size - 18 oz vinyl`,
            `3" polyfoam`,
            `EWM23423: 2'W x 4'H x 3" - 18 oz vinyl`,
            `EWM23443: 4'W x 4'H x 3" - 18 oz vinyl`,
            `EWM23523: 2'W x 5'H x 3" - 18 oz vinyl`,
            `EWM23543: 4'W x 5'H x 3" - 18 oz vinyl`,
            `EWM23623: 2'W x 6'H x 3" - 18 oz vinyl`,
            `EWM23643: 4'W x 6'H x 3" - 18 oz vinyl`,
            `EWM23723: 2'W x 7'H x 3" - 18 oz vinyl`,
            `EWM23743: 4'W x 7'H x 3" - 18 oz vinyl`,
            `EWM23823: 2'W x 8'H x 3" - 18 oz vinyl`,
            `EWM23843: 4'W x 8'H x 3" - 18 oz vinyl`,
            `EWM3SO3: Custom Size - 18 oz vinyl`,
        ],
        usedBy: [],
        category: 'baseball  protective padding',
        subCategory: 'protective padding outdoor padding',
        group: 'Outdoor Wall Padding',
        urlCategory: 'baseball  protective padding',
        urlSubCategory: 'protective padding outdoor padding',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fwall-panel-ewm-HQ1.jpg?alt=media&token=75b0420f-cb9e-4fae-8bb4-34265fcd42c6",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fwall-panel-ewm-HQ1.jpg?alt=media&token=75b0420f-cb9e-4fae-8bb4-34265fcd42c6",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fwall-panel-ewm-HQ2.jpg?alt=media&token=c9d06731-23ed-41f0-ba51-9dcd608e152d",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fwall-panel-ewm-HQ3.jpg?alt=media&token=57b4f233-1807-4f60-9471-6628a3e64491",
        ]
    },{
        id: 'p314',
        name: `Wall Panel (BSP Series)`,
        sku: 'bsp36',
        description: `BSP Series Padding can be mounted to brick, concrete, wood or any solid surface, but also easily mounts to any chain link fence. BSP Series Padding has brass grommets at the top and bottom for mounting.`,
        details: [
            'Non-Wood Back',
            `Folds in 2' sections`,
            'Choose from 2" or 3" polyfoam',
            '18 oz vinyl',
            `Can be mounted to brick, concrete, wood, chain link fence, or any solid surface`,
            `Brass grommets at top and bottom`,
        ],
        includes: [
            ``
        ],
        options: [
            `2" polyfoam`,
            `BSP36: 6'W x 3'H x 2" - 18 oz vinyl`,
            `BSP38: 8'W x 3'H x 2" - 18 oz vinyl`,
            `BSP310: 10'W x 3'H x 2" - 18 oz vinyl`,
            `BSP312: 12'W x 3'H x 2" - 18 oz vinyl`,
            `BSP46: 6'W x 4'H x 2" - 18 oz vinyl`,
            `BSP48: 8'W x 4'H x 2" - 18 oz vinyl`,
            `BSP410: 10'W x 4'H x 2" - 18 oz vinyl`,
            `BSP412: 12'W x 4'H x 2" - 18 oz vinyl`,
            `BSP22SO: Custom Size - 2" thick - 18 oz vinyl`,
            `3" polyfoam`,
            `BSP363: 6'W x 3'H x 3" - 18 oz vinyl`,
            `BSP383: 8'W x 3'H x 3" - 18 oz vinyl`,
            `BSP3103: 10'W x 3'H x 3" - 18 oz vinyl`,
            `BSP3123: 12'W x 3'H x 3" - 18 oz vinyl`,
            `BSP463: 6'W x 4'H x 3" - 18 oz vinyl`,
            `BSP483: 8'W x 4'H x 3" - 18 oz vinyl`,
            `BSP4103: 10'W x 4'H x 3" - 18 oz vinyl`,
            `BSP4123: 12'W x 4'H x 3" - 18 oz vinyl`,
            `BSP23SO: Custom Size - 3" thick - 18 oz vinyl`,
            `Other`,
            `BSPSO: Backstop Pad (other than 2" or 3" foam)`
        ],
        usedBy: [],
        category: 'baseball  protective padding',
        subCategory: 'protective padding outdoor padding',
        group: 'Outdoor Wall Padding',
        urlCategory: 'baseball  protective padding',
        urlSubCategory: 'protective padding outdoor padding',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fbackstop-wilson.jpg?alt=media&token=680d5357-cedc-46a2-af41-0d46277ee546",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fbackstop-wilson.jpg?alt=media&token=680d5357-cedc-46a2-af41-0d46277ee546",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fbackstop-south-florence.jpg?alt=media&token=601f9998-359a-41df-a101-4e5a34c4ec35",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fbackstop-pad-lenoir.jpg?alt=media&token=47493886-c8d7-4a4b-ba06-a5ca0eced4a3",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fwall-panel-bsp-HQ1.jpg?alt=media&token=ba960032-606b-46a1-b38a-2e091674ce35",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fbackstop-charlestonso.jpg?alt=media&token=4509a969-d950-4a23-9c13-406291bb528c",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fbackstop-svsu.jpg?alt=media&token=b0043ff8-4d89-4272-aa63-a433259efe3f",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fbackstop-wofford.jpg?alt=media&token=c5b60fb3-5c38-45d8-bb21-f3435110a4fd",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fwall-panel-bsp-HQ2.jpg?alt=media&token=aa7d12d0-9adf-49a7-821f-077312ec9067",
        ]
    },{
        id: 'p256',
        name: `Rail Padding`,
        sku: 'rpg184',
        description: `Protective Rail Padding is a high-quality product that provides excellent protection for your rails. Made with durable 18 ounce vinyl, it is designed to fit 2 to 3 inch diameter rails and is available in both 4 and 10 foot sections. The padding itself is made with 1 inch closed cell foam, ensuring that it is both soft and resilient. Additionally, the padding is equipped with grommets every 6 inches, making it easy to install and secure. Whether you are looking to protect your rails from impact or just want to improve their overall safety, Protective Rail Padding is an excellent choice.`,
        details: [
            '18 oz vinyl - 15 colors',
            'Fits 2" to 3" diameter rail',
            `Available in 4' to 10' sections`,
            `1" closed cell foam`,
            `Grommets every 6"`,
        ],
        includes: [
            ``
        ],
        options: [
            `RPG184: 48" Grommeted`,
            `RPG186: 72" Grommeted`,
            `RPG188: 96" Grommeted`,
            `RPG1810: 120" Grommeted`,
            `RPGSO: Special Size`,
            `RPGVSO: Vertical Pad Special Size`,
        ],
        usedBy: [],
        category: 'baseball  protective padding',
        subCategory: 'protective padding outdoor padding',
        group: '',
        urlCategory: 'baseball  protective padding',
        urlSubCategory: 'protective padding outdoor padding',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Frail-padding-liberty-1.jpg?alt=media&token=2aabe4d0-c83a-4314-8551-c2e5509628ae",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Frail-padding-liberty-1.jpg?alt=media&token=2aabe4d0-c83a-4314-8551-c2e5509628ae",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Frail-padding-liberty-2.jpg?alt=media&token=f690d969-81b3-4fd3-841e-6851610cd4c7",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Frail-padding-rpg184-HQ1.jpg?alt=media&token=0fd4f540-6a75-4f86-9bd9-09f48385e8b2",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Frail-padding-rpg184-HQ2.jpg?alt=media&token=743d24c0-b707-4e80-9b54-e0f9b81b86f8",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Frail-padding-rpg184-HQ3.jpg?alt=media&token=8665ceaf-c42c-49db-a842-10e8f4e70a9c",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Frail-padding-rpg184-HQ4.jpg?alt=media&token=0f8ba0f1-8213-42b7-bc49-803f401ce1ef"
        ]
    },{
        id: 'p258',
        name: `Square Rail Padding`,
        sku: 'rpsw184',
        description: `Square Rail Padding is made with 2 inch polyfoam, providing a soft and comfortable surface that absorbs impacts and reduces the risk of injury. It is also easy to mount using mending plates, making installation quick and easy. Overall, the Square Rail Padding is an excellent choice for anyone looking to protect their players from potential injuries. It is durable, versatile, and easy to install, making it the perfect solution for any sports facility.`,
        details: [
            `18 oz vinyl - 15 colors`,
            `Customize to fit any diameter rail`,
            `Available in 4' to 8' sections - custom sizes available`,
            `2" polyfoam`,
            `Mounts with mending plates`,
        ],
        includes: [
            ``
        ],
        options: [
            `RPSW184: 48" long`,
            `RPSW186: 72" long`,
            `RPSW187: 84" long`,
            `RPSW188: 96" long`,
            `RPSWSO: Special Size`,
        ],
        usedBy: [],
        category: 'baseball  protective padding',
        subCategory: 'protective padding outdoor padding',
        group: '',
        urlCategory: 'baseball  protective padding',
        urlSubCategory: 'protective padding outdoor padding',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fsquare-rail-padding-hpu-1.jpg?alt=media&token=492e8172-3e84-49cf-bb81-415588563955",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fsquare-rail-padding-hpu-1.jpg?alt=media&token=492e8172-3e84-49cf-bb81-415588563955",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fsquare-rail-padding-hpu-2.jpg?alt=media&token=c29729cd-ff76-45cc-9281-dedffe3c83da",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fsquare-rail-padding-hpu-3.jpg?alt=media&token=a643a430-dcf4-49eb-b7fe-6ebabebafb82",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Frail-padding-catawba.jpg?alt=media&token=95947bff-7a04-4194-91d7-c04d2eb76082",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fsquare-rail-padding-cso.jpg?alt=media&token=f9ee9e87-4d62-47c2-85ed-9e55b20d0d7a",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fsquare-rail-padding-rpsw184-HQ1.jpg?alt=media&token=f7c155e4-f082-48d2-9da7-348aac1aa480",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fsquare-rail-padding-rpsw184-HQ2.jpg?alt=media&token=9b3f5328-99f8-4202-bcc9-cc4c4e75d094",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fsquare-rail-padding-rpsw184-HQ3.jpg?alt=media&token=9144174a-6b67-4510-9e1b-dd993ee6a354",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fsquare-rail-padding-rpsw184-HQ4.jpg?alt=media&token=b372a355-5639-449c-8588-6f0b9c2e723a"
        ]
    },{
        id: 'p311',
        name: `Permanent Corner Covers`,
        sku: 'pcaps223',
        description: `Wood backed P-CAPS are sealed on 3 sides with the 4th side attached to the rail. Standard pads fit 2" to 3" rails. P-CAPS pads are manufactured to your specific needs and sizes. They are covered in 18 oz coated vinyl.`,
        details: [
            'Made with 18 oz coated vinyl',
            'Fits 2" to 3" rails',
            'Sealed on 3 sides with the 4th side attached to the rail',
        ],
        includes: [
            ``
        ],
        options: [
            `PCAPS223: Permanent Corner Covers (P-CAPS)`,
        ],
        usedBy: [],
        category: 'baseball  protective padding',
        subCategory: 'protective padding outdoor padding',
        group: '',
        urlCategory: 'baseball  protective padding',
        urlSubCategory: 'protective padding outdoor padding',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpermanent-corner-cover-pcc-HQ1.jpg?alt=media&token=70d0d35e-7451-4e5e-a8a0-dcf386ca0c50",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpermanent-corner-cover-pcc-HQ1.jpg?alt=media&token=70d0d35e-7451-4e5e-a8a0-dcf386ca0c50",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpermanent-corner-cover-pcc-HQ2.jpg?alt=media&token=eb3ee6d6-a64e-4ab3-a56e-7998894adb13",
        ]
    },{
        id: 'p259',
        name: `Dugout Bench Padding`,
        sku: 'dbpso',
        description: `Protective padding for your dugout benches.`,
        details: [
            '18 oz vinyl - 15 colors available',
            '2" rebond',
            'Custom built to fit your bench specifications',
        ],
        includes: [
            ``
        ],
        options: [
            `DBPSO: Dugout Bench Padding`,
        ],
        usedBy: [],
        category: 'baseball  protective padding',
        subCategory: 'protective padding outdoor padding',
        group: '',
        urlCategory: 'baseball  protective padding',
        urlSubCategory: 'protective padding outdoor padding',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fdugout-bench-padding-dbpso-HQ1.jpg?alt=media&token=a2bb96ec-730d-4381-b5a8-f91c27df42cf",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fdugout-bench-padding-dbpso-HQ1.jpg?alt=media&token=a2bb96ec-730d-4381-b5a8-f91c27df42cf"
        ]
    },{
        id: 'p261',
        name: `Home Plate Mat`,
        sku: 'hpm612',
        description: `The Home Plate Mat is great for protecting the batter's box. Its top-quality, spike-proof turf features a 5 mm shock-absorbing cushion for a comfortable feel. It includes a permanently inlaid home plate and batter's box lines which can be rotated to even out the use of the mat.`,
        details: [
            'Top quality artificial, spike-proof turf',
            'Movable home plate allows space for catcher',
            '5mm foam backing',
            'Ships ground',
        ],
        includes: [
            ``
        ],
        options: [
            `HPM612: Baseball Home Plate Mat (6' x 12)`,
            `HPM712: Softball Home Plate Mat (7' x 12)`,
        ],
        usedBy: [],
        category: 'baseball',
        subCategory: 'field',
        group: 'Field Protectors',
        urlCategory: 'baseball',
        urlSubCategory: 'field',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fhome-plate-mat-hpm612-HQ1.jpg?alt=media&token=de754fb6-fa72-4804-85ad-055ed612b697",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fhome-plate-mat-hpm612-HQ1.jpg?alt=media&token=de754fb6-fa72-4804-85ad-055ed612b697",
        ]
    },{
        id: 'p262',
        name: `On-Deck Circle`,
        sku: 'odc4',
        description: `Fisher's high-quality On-Deck Circles are great on both baseball and softball fields. The spike-proof turf material is durable enough to withstand spikes over hundreds of swings. The mat includes a foam backing for a sturdy and secure place to practice swings before every bat. Use these mats indoors or outdoors to protect any surface your team practices on.`,
        details: [
            'Top quality artificial, spike-proof turf',
            'Movable home plate allows space for catcher',
            '5mm foam backing',
            'Ships ground',
        ],
        includes: [
            ``
        ],
        options: [
            `ODC4: On Deck Circle (4' dia.)`,
            `ODC6: On Deck Circle (6' dia.)`,
        ],
        usedBy: [],
        category: 'baseball',
        subCategory: 'on deck circles',
        group: '',
        urlCategory: 'baseball',
        urlSubCategory: 'on deck circles',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fon-deck-circle-odc4-HQ1.jpg?alt=media&token=22989772-b063-45c6-a8c9-4d1dcfc96e0f",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fon-deck-circle-odc4-HQ1.jpg?alt=media&token=22989772-b063-45c6-a8c9-4d1dcfc96e0f",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fon-deck-circle-odc4-HQ2.jpg?alt=media&token=7a41d2e9-3560-4165-a853-be05b1db5510"
        ]
    },{
        id: 'p263',
        name: `Pitcher's Mat`,
        sku: 'pm36',
        description: `Protect the front of the mound with this portable and highly-durable pitchers mat. It is ideal for use during practice, warm-ups, and batting practice. The artificial green turf is spike-friendly, allowing pitchers to get work in with a true game day feel.`,
        details: [
            'Top quality artificial, spike-proof turf',
            '36 oz. 5mm foam backing',
            'Ships ground',
        ],
        includes: [
            ``
        ],
        options: [
            `PM36: Pitcher's Mat (3' x 6')`,
            `PM412: Pitcher's Mat (4' x 12')`,
            `PM612: Pitcher's Mat (6' x 12')`,
        ],
        usedBy: [],
        category: 'baseball',
        subCategory: 'field',
        group: 'Field Protectors',
        urlCategory: 'baseball',
        urlSubCategory: 'field',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpitchers-mat-pm36-HQ1.jpg?alt=media&token=c9884d76-3a63-46f5-87c5-7ae69d2461ac",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpitchers-mat-pm36-HQ1.jpg?alt=media&token=c9884d76-3a63-46f5-87c5-7ae69d2461ac",
        ]
    },{
        id: 'p264',
        name: `Titan Turf Protector`,
        sku: 'tpt201550',
        description: `Fisher's Titan Turf Protector is great for protecting your turf during batting practice.`,
        details: [
            `Manufactured with 12 oz., spike-proof Titan Mesh`,
            `Grommets every 36"`,
            `7 colors available: gray, beige, black, red, royal, forest green, and maroon`,
        ],
        includes: [
            ``
        ],
        options: [
            `TPT201550: Titan Turf Protector (20'W x 15'D x 50'L)`,
            `TPT20260: Titan Turf Protector (20'W x 20'D x 60'L)`,
            `TPT20570: Titan Turf Protector (20'W x 25'D x 70'L)`,
        ],
        usedBy: [],
        category: 'baseball',
        subCategory: 'field',
        group: 'Field Protectors',
        urlCategory: 'baseball',
        urlSubCategory: 'field',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ftitan-turf-protector-tpt201550-HQ1.jpg?alt=media&token=e6ff0e3d-e324-4b6b-8a53-824cc39a7818",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ftitan-turf-protector-tpt201550-HQ1.jpg?alt=media&token=e6ff0e3d-e324-4b6b-8a53-824cc39a7818",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ftitan-turf-protector-tpt201550-HQ2.jpg?alt=media&token=e6386a26-128f-4c0e-83b1-a10182974aa0"
        ]
    },{
        id: 'p265',
        name: `Mound Cover`,
        sku: 'ic1006',
        description: `The Mound Cover is great for protecting your field.`,
        details: [
            `Choose from 6 oz or 18 oz option`,
            `6 oz comes in black and silver color`,
            `18 oz available in 15 colors`,
        ],
        includes: [
            ``
        ],
        options: [
            `IC1006: 6" Mound (10' dia.) 4 lbs - 6 oz`,
            `IC1206: 8" Mound (12' dia.) 7 lbs - 6 oz`,
            `IC1806: 10" Mound (18' dia.) 16 lbs - 6 oz`,
            `IC1018: 6" Mound (10' dia.) 4 lbs - 18 oz`,
            `IC1218: 8" Mound (12' dia.) 7 lbs - 18 oz`,
            `IC1818: 10" Mound (18' dia.) 16 lbs - 18 oz`,
        ],
        usedBy: [],
        category: 'baseball',
        subCategory: 'field',
        group: 'Field Protectors',
        urlCategory: 'baseball',
        urlSubCategory: 'field',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fmound-cover-ic1006-HQ1.jpg?alt=media&token=3be2c2ff-e137-4a2e-b39b-6ae403a18281",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fmound-cover-ic1006-HQ1.jpg?alt=media&token=3be2c2ff-e137-4a2e-b39b-6ae403a18281"
        ]
    },{
        id: 'p266',
        name: `Home Plate Cover`,
        sku: 'ic2018',
        description: `The Home Plate Cover is great for protecting your field.`,
        details: [
            `Choose from 6 oz or 18 oz option`,
            `6 oz comes in black and silver color`,
            `18 oz available in 15 colors`,
        ],
        includes: [
            ``
        ],
        options: [
            `IC2006: Home Plate Cover (20' dia.) 18 lbs - 6 oz`,
            `IC2606: Home Plate Cover (26' dia.) 32 lbs - 6 oz`,
            `IC3006: Home Plate Cover (30' dia.) 42 lbs - 6 oz`,
            `IC2018: Home Plate Cover (20' dia.) 40 lbs - 18 oz`,
            `IC2618: Home Plate Cover (26' dia.) 65 lbs - 18 oz`,
            `IC3018: Home Plate Cover (30' dia.) 80 lbs - 18 oz`,
        ],
        usedBy: [],
        category: 'baseball',
        subCategory: 'field',
        group: 'Field Protectors',
        urlCategory: 'baseball',
        urlSubCategory: 'field',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fhome-plate-cover-ic2018-HQ1.jpg?alt=media&token=b152c1d5-d13d-4d46-84d5-cc559aac0ce6",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fhome-plate-cover-ic2018-HQ1.jpg?alt=media&token=b152c1d5-d13d-4d46-84d5-cc559aac0ce6"
        ]
    },{
        id: 'p267',
        name: `Base Cover`,
        sku: 'ic101018',
        description: `The Base Cover is great for protecting your field.`,
        details: [
            `Choose from 6 oz or 18 oz option`,
            `6 oz comes in black and silver color`,
            `18 oz available in 15 colors`,
        ],
        includes: [
            ``
        ],
        options: [
            `IC101006: Base Cover (10' square) 5 lbs - 6 oz`,
            `IC101018: Base Cover (10' square) 12 lbs - 18 oz`,
        ],
        usedBy: [],
        category: 'baseball',
        subCategory: 'field',
        group: 'Field Protectors',
        urlCategory: 'baseball',
        urlSubCategory: 'field',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fbase-cover-ic101018-HQ1.jpg?alt=media&token=db8e553a-71ea-464e-82fa-6a4f4cd2ff44",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fbase-cover-ic101018-HQ1.jpg?alt=media&token=db8e553a-71ea-464e-82fa-6a4f4cd2ff44"
        ]
    },{
        id: 'p268',
        name: `Field Cover`,
        sku: 'fcso',
        description: `Protect your field from inclement weather and be ready to play within minutes.`,
        details: [
            'Handles on all 4 sides of the cover',
            '6.5 oz woven polyethylene (white/black reversible)',
        ],
        includes: [
            ``
        ],
        options: [
            `FC90: Little League Field Cover (90' x 90')`,
            `FC100: Little League Field Cover (100' x 100')`,
            `FC120: Softball Field Cover (120' x 120')`,
            `FC160: Baseball Field Cover (160' x 160')`,
            `FC170: Baseball Field Cover (170' x 170')`,
            `FCSO: Custom Size Cover`,
        ],
        usedBy: [],
        category: 'baseball',
        subCategory: 'field',
        group: 'Field Protectors',
        urlCategory: 'baseball',
        urlSubCategory: 'field',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ffield-cover-fcso-HQ1.jpg?alt=media&token=81a9126d-c915-47d2-8e2f-82266f09d7a0",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ffield-cover-fcso-HQ1.jpg?alt=media&token=81a9126d-c915-47d2-8e2f-82266f09d7a0",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ffield-cover-fcso-HQ2.jpg?alt=media&token=49bec5b8-8621-4ad9-98af-ff69f3511519",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ffield-cover-fcso-HQ3.jpg?alt=media&token=8bfe2c89-4d54-44f1-8b84-78037996a7dc"
        ]
    },{
        id: 'p269',
        name: `Roll Tube`,
        sku: 'rt3020',
        description: `Roll Tube can be used to quickly put away your field covers.`,
        details: [
            'Made from polyethylene plastic',
        ],
        includes: [
            ``
        ],
        options: [
            `RT1520: Roll Tube (15" x 20')`,
            `RT3020: Roll Tube (30" x 20')`,
        ],
        usedBy: [],
        category: 'baseball',
        subCategory: 'field',
        group: 'Field Cover Accessories',
        urlCategory: 'baseball',
        urlSubCategory: 'field',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ffield-cover-fcso-HQ2.jpg?alt=media&token=49bec5b8-8621-4ad9-98af-ff69f3511519",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ffield-cover-fcso-HQ2.jpg?alt=media&token=49bec5b8-8621-4ad9-98af-ff69f3511519",
        ]
    },{
        id: 'p270',
        name: `Stake and Handle`,
        sku: 'gsh',
        description: `Great for keeping your field protectors grounded.`,
        details: [
            'Handle material is 2" webbing',
            'Stake is made from high quality steel',
        ],
        includes: [
            ``
        ],
        options: [
            `GSH: Stake and Handle`,
            `GSR: Handle only`,
            `GS8: 8" Stake`,
        ],
        usedBy: [],
        category: 'baseball',
        subCategory: 'field',
        group: 'Field Cover Accessories',
        urlCategory: 'baseball',
        urlSubCategory: 'field',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fstake-and-handle-gsh-HQ1.jpg?alt=media&token=6d50c5b6-fd16-422f-995f-fd5c9f9183ee",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fstake-and-handle-gsh-HQ1.jpg?alt=media&token=6d50c5b6-fd16-422f-995f-fd5c9f9183ee"
        ]
    },{
        id: 'p271',
        name: `Sand Bag`,
        sku: 'bcb48',
        description: `Great for keeping your field protectors grounded.`,
        details: [
            'Constructed with 18 oz vinyl',
        ],
        includes: [
            ``
        ],
        options: [
            `BCB48: Sand Bag`,
        ],
        usedBy: [],
        category: 'baseball',
        subCategory: 'field',
        group: 'Field Cover Accessories',
        urlCategory: 'baseball',
        urlSubCategory: 'field',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fsand-bag-bcb48-HQ1.jpg?alt=media&token=d84e1e6c-2d84-4413-8cad-96b0e69be29b",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fsand-bag-bcb48-HQ1.jpg?alt=media&token=d84e1e6c-2d84-4413-8cad-96b0e69be29b"
        ]
    },{
        id: 'p272',
        name: `Infield Protector Pro with Wheels`,
        sku: 'ip88w',
        description: `Made with the heaviest gang steel and nets in the industry.`,
        details: [
            '2" Schedule 40 tubing',
            '#72 knotted net',
            '6" diameter, "no flat" wheels',
            '10 year warranty on frame',
            '2 year warranty on netting'
        ],
        includes: [
            ``
        ],
        options: [
            `IP88W: Infield Protector Pro with Wheels (8' x 8')`,
        ],
        usedBy: [],
        category: 'baseball',
        subCategory: 'protective screens',
        group: '',
        urlCategory: 'baseball',
        urlSubCategory: 'protective screens',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Finfield-protector-pro-ip88w-HQ1.jpg?alt=media&token=1087f09d-8507-4719-ab3f-99de2aaabb2a",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Finfield-protector-pro-ip88w-HQ1.jpg?alt=media&token=1087f09d-8507-4719-ab3f-99de2aaabb2a"
        ]
    },{
        id: 'p273',
        name: `L Screen Pro`,
        sku: 'pp88w',
        description: `Made with the heaviest gang steel and nets in the industry.`,
        details: [
            '2" Schedule 40 tubing',
            '#72 knotted net',
            '6" diameter, "no flat" wheels',
            '10 year warranty on frame',
            '2 year warranty on netting'
        ],
        includes: [
            ``
        ],
        options: [
            `PP88W: L Screen Pro with Wheels (8' x 8')`,
        ],
        usedBy: [],
        category: 'baseball',
        subCategory: 'protective screens',
        group: '',
        urlCategory: 'baseball',
        urlSubCategory: 'protective screens',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fl-screen-pro-with-wheels-pp88w-HQ1.jpg?alt=media&token=75c07200-5781-4fe5-8bec-f78e19eb0972",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fl-screen-pro-with-wheels-pp88w-HQ1.jpg?alt=media&token=75c07200-5781-4fe5-8bec-f78e19eb0972"
        ]
    },{
        id: 'p274',
        name: `L Screen Pro`,
        sku: 'pp57',
        description: `Made with the heaviest gang steel and nets in the industry.`,
        details: [
            '2" Schedule 40 tubing',
            '#72 knotted net',
            '6" diameter, "no flat" wheels',
            '10 year warranty on frame',
            '2 year warranty on netting'
        ],
        includes: [
            ``
        ],
        options: [
            `PP57: L Screen Pro (5' x 7')`,
            `PP57W: L Screen Pro with Wheels (5' x 7')`,
        ],
        usedBy: [],
        category: 'baseball',
        subCategory: 'protective screens',
        group: '',
        urlCategory: 'baseball',
        urlSubCategory: 'protective screens',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fl-screen-pro-pp57-HQ1.jpg?alt=media&token=e04670a5-6f4b-4d0a-bb52-3205d5106a66",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fl-screen-pro-pp57-HQ1.jpg?alt=media&token=e04670a5-6f4b-4d0a-bb52-3205d5106a66"
        ]
    },{
        id: 'p275',
        name: `Pitchers Protective Screen Pro`,
        sku: 'pp58st',
        description: `Made with the heaviest gang steel and nets in the industry.`,
        details: [
            '2" Schedule 40 tubing',
            '#72 knotted net',
            '6" diameter, "no flat" wheels',
            '10 year warranty on frame',
            '2 year warranty on netting'
        ],
        includes: [
            ``
        ],
        options: [
            `PP58ST: Pitcher Protector (5' x 8')`,
            `PP58STW: Pitcher Protector with Wheels (5' x 8')`,
        ],
        usedBy: [],
        category: 'baseball',
        subCategory: 'protective screens',
        group: '',
        urlCategory: 'baseball',
        urlSubCategory: 'protective screens',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpitchers-protective-screen-pro-pp58st-HQ1.jpg?alt=media&token=e7650f70-262e-443c-b712-2f7bc185d6cd",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpitchers-protective-screen-pro-pp58st-HQ1.jpg?alt=media&token=e7650f70-262e-443c-b712-2f7bc185d6cd"
        ]
    },{
        id: 'p276',
        name: `L Shaped Pitcher Protector`,
        sku: 'pp67',
        description: `Keeps pitchers safe during batting practice.`,
        details: [
            `3'6" "L" section`,
            `Swedged steel tubing`,
            `Frame held together with snap pins at connecting joints`,
            `Pillow case net - UV treated`,
            `Reversible`,
            `Ships ground`,
        ],
        includes: [
            ``
        ],
        options: [
            `PP67: L Shaped Pitcher Protector (6' wide x 7' tall)`,
            `67C: Carrying Case`,
            //`PP67PD: Pitcher Protector with Padding`,//
            `PP67N: Pillow Case Net`,
            
        ],
        usedBy: [],
        category: 'baseball',
        subCategory: 'protective screens',
        group: '',
        urlCategory: 'baseball',
        urlSubCategory: 'protective screens',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fl-shaped-pitchers-protector-pp67-HQ1.jpg?alt=media&token=bfcfd6b9-a20a-4a49-be6a-340703ca5b25",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fl-shaped-pitchers-protector-pp67-HQ1.jpg?alt=media&token=bfcfd6b9-a20a-4a49-be6a-340703ca5b25"
        ]
    },{
        id: 'p277',
        name: `Infield Protector`,
        sku: 'ip67',
        description: `Keeps your players safe during practice.`,
        details: [
            `Heavy Duty Swedged steel tubing`,
            `Frame held together with snap pins at connecting joints`,
            `Pillow case net - UV treated`,
            `Ships ground`,
        ],
        includes: [
            ``
        ],
        options: [
            `IP67: Standard Infield Protector (6' wide x 7' tall)`,
            `IP67PD: Infield Protector with Padding`,
            `67N: Pillow Case Net`,
            `67C: Carrying Case`,
        ],
        usedBy: [],
        category: 'baseball',
        subCategory: 'protective screens',
        group: '',
        urlCategory: 'baseball',
        urlSubCategory: 'protective screens',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Finfield-protector-ip67-HQ1.jpg?alt=media&token=83bb7d50-4c15-4d22-83a7-db9f5592d396",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Finfield-protector-ip67-HQ1.jpg?alt=media&token=83bb7d50-4c15-4d22-83a7-db9f5592d396"
        ]
    },{
        id: 'p278',
        name: `Combination Fungo Protector and Portable Backstop`,
        sku: 'fp1010',
        description: `Keeps your players safe during practice.`,
        details: [
            `Heavy Duty Swedged steel tubing`,
            `Frame held together with snap pins at connecting joints`,
            `Pillow case net - UV treated`,
            `Ships ground`,
        ],
        includes: [
            ``
        ],
        options: [
            `FP1010: Fungo Protector (10' wide x 10' tall)`,
            `FP1010N: Pillow Case Net`,
        ],
        usedBy: [],
        category: 'baseball',
        subCategory: 'protective screens',
        group: '',
        urlCategory: 'baseball',
        urlSubCategory: 'protective screens',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fcombination-fungo-protector-and-portable-backstop-fp1010-HQ1.jpg?alt=media&token=5f559a2e-6013-4eff-adcf-3f6a9e99593b",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fcombination-fungo-protector-and-portable-backstop-fp1010-HQ1.jpg?alt=media&token=5f559a2e-6013-4eff-adcf-3f6a9e99593b"
        ]
    },{
        id: 'p279',
        name: `Slow Fast Pitch Pitcher Protector`,
        sku: 'sfp67',
        description: `Keeps your players safe during practice.`,
        details: [
            `Heavy Duty Swedged steel tubing`,
            `Frame held together with snap pins at connecting joints`,
            `Pillow case net with a hip level cutout for softball release`,
            `Ships ground`,
        ],
        includes: [
            ``
        ],
        options: [
            `SFP67: Slow/Fast Pitch Protector (6' wide x 7' tall)`,
            `SFP67PD: Protector with Padding`,
            `SFP67N: Pillow case net with pocket`,
            `67C: Carrying case`,
        ],
        usedBy: [],
        category: 'baseball',
        subCategory: 'protective screens',
        group: '',
        urlCategory: 'baseball',
        urlSubCategory: 'protective screens',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fslow-fast-pitch-pitcher-protector-sfp67-HQ1.jpg?alt=media&token=4db47883-c1c2-4ce0-ab64-5217828b7b0a",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fslow-fast-pitch-pitcher-protector-sfp67-HQ1.jpg?alt=media&token=4db47883-c1c2-4ce0-ab64-5217828b7b0a",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fslow-fast-pitch-pitcher-protector-sfp67-HQ2.jpg?alt=media&token=41fa4b8c-1b4e-4e2d-aa5f-4b2b0f8712d6"
        ]
    },{
        id: 'p280',
        name: `The Collector`,
        sku: 'cbn67',
        description: `Great for batting practice.`,
        details: [
            `Heavy Duty Swedged steel tubing`,
            `18 oz vinyl with collector netting`,
            `36" x 28" round opening with an enclosed net to trap baseballs`,
            `Ships ground`,
        ],
        includes: [
            ``
        ],
        options: [
            `CBN67: The Collector`,
            `67C: Carrying Case`,
        ],
        usedBy: [],
        category: 'baseball',
        subCategory: 'training aids',
        group: '',
        urlCategory: 'baseball',
        urlSubCategory: 'training aids',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fthe-collector-cbn67-HQ1.jpg?alt=media&token=1b9131a7-8526-4318-bb10-b037e30db298",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fthe-collector-cbn67-HQ1.jpg?alt=media&token=1b9131a7-8526-4318-bb10-b037e30db298",
        ]
    },{
        id: 'p281',
        name: `Pitching Target`,
        sku: '360pt-2',
        description: `The Pitching Target helps a pitcher with location, thus fine-tuning his effective velocity.`,
        details: [
            '40 oz black vinyl',
            'Steel frame construction',
            'Easy to assemble',
            'Ships ground',
        ],
        includes: [
            ``
        ],
        options: [
            `360PT-1: Pitching Target (5' x 6') no frame`,
            `360PT-2: Pitching Target (5' x 6') with frame`,
            `WK100: Wheel Kit`,
        ],
        usedBy: [],
        category: 'baseball',
        subCategory: 'training aids',
        group: '',
        urlCategory: 'baseball',
        urlSubCategory: 'training aids',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpitching-target-360pt2-HQ1.jpg?alt=media&token=0db01809-6857-49e7-be5b-ddab71c9f353",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpitching-target-360pt2-HQ1.jpg?alt=media&token=0db01809-6857-49e7-be5b-ddab71c9f353",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpitching-target-360pt2-HQ2.jpg?alt=media&token=1e18a679-701b-41ca-999c-fb680f85432e"
        ]
    },{
        id: 'p282',
        name: `Batting Practice Platform Mound`,
        sku: '70006',
        description: `Prevents unnecessary wear and tear on your field.`,
        details: [
            'Carrying handles for easy roll away',
            'Steel powder coated frame with locking wheels',
            'Covered with spike-resistant artificial turf',
        ],
        includes: [
            ``
        ],
        options: [
            `70006: Batting Practice Platform Mound (8'L x 4'W x 10"T)`,
        ],
        usedBy: [],
        category: 'baseball',
        subCategory: 'training aids',
        group: '',
        urlCategory: 'baseball',
        urlSubCategory: 'training aids',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fbatting-practice-platform-mound-70006-HQ1.jpg?alt=media&token=0574ae6f-d702-4b99-9944-65e8fd1e6565",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fbatting-practice-platform-mound-70006-HQ1.jpg?alt=media&token=0574ae6f-d702-4b99-9944-65e8fd1e6565",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fbatting-practice-platform-mound-70006-HQ2.jpg?alt=media&token=d769669a-5e39-4ad2-bff1-ec6168611daf",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fbatting-practice-platform-mound-70006-HQ3.jpg?alt=media&token=48cbfd82-ffcc-418d-b2ca-d3d43ab120d5"
        ]
    },{
        id: 'p283',
        name: `Varsity Batting Cage`,
        sku: 'bt5512h',
        description: `Clean, curved tube design with less structure for ball to ricochet off. 4" O.D aluminum tubing for ease of removal and storage. Individual hoist lines simplify net removal and installation. Includes sleeves. Accommodates 12' and 14' wide nets (sold separately).`,
        details: [
            '4" O.D aluminum tubing for ease of removal and storage',
            'Individual hoist lines simplify net removal and installation',
            'Sleeves included',
            `Accomodates 12' and 14' wide nets (sold separately)`,
        ],
        includes: [
            ``
        ],
        options: [
            `BT5512H: Batting Cage (55'L x 12'H) Frame only`,
            `BN121455: Netting for BT5512H`,
            `BT7012H: Batting Cage (70'L x 12'H) Frame only`,
            `BN121470: Netting for BT7012H`,
        ],
        usedBy: [],
        category: 'baseball',
        subCategory: 'training aids',
        group: '',
        urlCategory: 'baseball',
        urlSubCategory: 'training aids',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fvarsity-batting-cage-bt5512h-HQ1.jpg?alt=media&token=b4f42fba-9c89-4523-b824-3a4731d24c5a",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fvarsity-batting-cage-bt5512h-HQ1.jpg?alt=media&token=b4f42fba-9c89-4523-b824-3a4731d24c5a",
        ]
    },{
        id: 'p284',
        name: `Skill Zone`,
        sku: 'szbb4875',
        description: `The Skill Zone is a great tool for increasing throwing accuracy.`,
        details: [
            `18 oz white vinyl`,
            `Swedged steel tubing`,
            `Reinforced strike zone flap to slow down baseballs`,
        ],
        includes: [
            ``
        ],
        options: [
            `SZBB4875: Skill Zone`,
            `SZBB4875T: Repl. Target`,
            `SZBB4875C: Case`,
        ],
        usedBy: [],
        category: 'baseball',
        subCategory: 'training aids',
        group: '',
        urlCategory: 'baseball',
        urlSubCategory: 'training aids',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fskill-zone-szbb4875-HQ1.jpg?alt=media&token=3261dfaa-0a0d-4d06-966b-0ff17662ce81",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fskill-zone-szbb4875-HQ1.jpg?alt=media&token=3261dfaa-0a0d-4d06-966b-0ff17662ce81",
        ]
    },{
        id: 'p285',
        name: `Portable Batting Shield`,
        sku: 'pbs6072',
        description: `The Portable Batting Shield mounts to chain link fence.`,
        details: [
            `18 oz royal blue vinyl`,
            `Large brass grommets`,
            `Mounts to chain link fence`,
        ],
        includes: [
            ``
        ],
        options: [
            `PBS6072: Portable Batting Shield (60" x 72")`,
            `PBSSO: Portable Batting Shield (custom size)`,
        ],
        usedBy: [],
        category: 'baseball',
        subCategory: 'training aids',
        group: '',
        urlCategory: 'baseball',
        urlSubCategory: 'training aids',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fportable-batting-shield-pbs6072-HQ1.jpg?alt=media&token=cbded6e1-bb59-4a6e-9108-20d3304d5fbe",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fportable-batting-shield-pbs6072-HQ1.jpg?alt=media&token=cbded6e1-bb59-4a6e-9108-20d3304d5fbe",
        ]
    },{
        id: 'p286',
        name: `Strike Zone Net Saver`,
        sku: 'szsbk',
        description: `The Strike Zone provides a white strike zone and can adjust from 30" to 77".`,
        details: [
            `Blue or black with white strike zone`,
            `Provides a visible backdrop`,
            `Adjusts from 30" to 77"`,
        ],
        includes: [
            ``
        ],
        options: [
            `SZSBK: Strike Zone (black)`,
            `SZSBL: Strike Zone (blue)`,
        ],
        usedBy: [],
        category: 'baseball',
        subCategory: 'training aids',
        group: '',
        urlCategory: 'baseball',
        urlSubCategory: 'training aids',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fstrike-zone-net-saver-szsbk-HQ1.jpg?alt=media&token=8bacf05d-494b-4f84-b942-0adf769da2c7",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fstrike-zone-net-saver-szsbk-HQ1.jpg?alt=media&token=8bacf05d-494b-4f84-b942-0adf769da2c7",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fstrike-zone-net-saver-szsbk-HQ2.jpg?alt=media&token=9ce2b3fd-b581-43ff-b6b4-acf414957243"
        ]
    },{
        id: 'p287',
        name: `Netting #504 2.5mm 1-7/8"`,
        sku: 'cnr504178',
        description: `Our most popular netting option. This netting is great for baseball backstop, basketball court netting, batting cages, discus cages, foul ball barriers, indoor baseball facilities, indoor soccer facilities, protective "L" screens, soccer barriers, soccer goals, and tennis barriers/dividers.`,
        details: [
            `Mesh Size: 1-7/8"`,
            `Cord Diameter: 2.5mm`,
            `Break Strength: 180#`,
            `Available in black or white`,
        ],
        includes: [
            ``
        ],
        options: [
            `CNR504178: Netting #504 2.5mm 1-7/8"`,
        ],
        usedBy: [],
        category: 'baseball',
        subCategory: 'netting',
        group: '',
        urlCategory: 'baseball',
        urlSubCategory: 'netting',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fcnr504178-HQ1.jpg?alt=media&token=9f175baa-f123-4bb6-b2c1-d7abd142da5d",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fcnr504178-HQ1.jpg?alt=media&token=9f175baa-f123-4bb6-b2c1-d7abd142da5d",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fnetting-bordering-options-HQ1.jpg?alt=media&token=586dfcc7-2f1c-4924-9ed8-5628a059f73a",
        ]
    },{
        id: 'p288',
        name: `Netting #504 2.5mm 7/8"`,
        sku: 'cnr5078',
        description: `High quality netting.`,
        details: [
            `Mesh Size: 7/8"`,
            `Cord Diameter: 2.5mm`,
            `Break Strength: 180#`,
            `Available in black or white`,
        ],
        includes: [
            ``
        ],
        options: [
            `CNR5078: Netting #504 2.5mm 7/8"`,
        ],
        usedBy: [],
        category: 'baseball',
        subCategory: 'netting',
        group: '',
        urlCategory: 'baseball',
        urlSubCategory: 'netting',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fcnr5078-HQ1.jpg?alt=media&token=a444e4b1-2791-45dd-b1bf-6b6cc40bc0d0",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fcnr5078-HQ1.jpg?alt=media&token=a444e4b1-2791-45dd-b1bf-6b6cc40bc0d0",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fnetting-bordering-options-HQ1.jpg?alt=media&token=586dfcc7-2f1c-4924-9ed8-5628a059f73a",
        ]
    },{
        id: 'p289',
        name: `Netting #504 2.5mm 4"`,
        sku: 'cnr5044',
        description: `This netting is great for basketball court netting, soccer barriers, and soccer goals.`,
        details: [
            `Mesh Size: 4"`,
            `Cord Diameter: 2.5mm`,
            `Break Strength: 200#`,
            `Available in black or white`,
        ],
        includes: [
            ``
        ],
        options: [
            `CNR5044: Netting #504 2.5mm 4"`,
        ],
        usedBy: [],
        category: 'baseball',
        subCategory: 'netting',
        group: '',
        urlCategory: 'baseball',
        urlSubCategory: 'netting',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fcnr5044-HQ1.jpg?alt=media&token=a4f68323-d8d4-43ad-9390-6f138145ecdb",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fcnr5044-HQ1.jpg?alt=media&token=a4f68323-d8d4-43ad-9390-6f138145ecdb",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fnetting-bordering-options-HQ1.jpg?alt=media&token=586dfcc7-2f1c-4924-9ed8-5628a059f73a",
        ]
    },{
        id: 'p290',
        name: `Netting #420 2mm 7/8"`,
        sku: 'cnr42078',
        description: `This netting is great for basketball court netting.`,
        details: [
            `Mesh Size: 7/8"`,
            `Cord Diameter: 2mm`,
            `Break Strength: 120#`,
            `Available in black or white`,
        ],
        includes: [
            ``
        ],
        options: [
            `CNR42078: Netting #420 2mm 7/8"`,
        ],
        usedBy: [],
        category: 'baseball',
        subCategory: 'netting',
        group: '',
        urlCategory: 'baseball',
        urlSubCategory: 'netting',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fcnr42078-HQ1.jpg?alt=media&token=374294bd-3f5b-48a8-b5b1-1570542f869f",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fcnr42078-HQ1.jpg?alt=media&token=374294bd-3f5b-48a8-b5b1-1570542f869f",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fnetting-bordering-options-HQ1.jpg?alt=media&token=586dfcc7-2f1c-4924-9ed8-5628a059f73a",
        ]
    },{
        id: 'p291',
        name: `Netting #72 4mm 1-7/8"`,
        sku: 'cnr72178',
        description: `This netting is great for baseball backstop, basketball court netting, batting cages, discus cages, foul ball barriers, indoor baseball facilities, indoor soccer facilities, protective "L" screens, soccer barriers, and soccer goals.`,
        details: [
            `Mesh Size: 1-7/8"`,
            `Cord Diameter: 4mm`,
            `Break Strength: 561#`,
            `Available in black`,
        ],
        includes: [
            ``
        ],
        options: [
            `CNR72178: Netting #72 4mm 1-7/8"`,
        ],
        usedBy: [],
        category: 'baseball',
        subCategory: 'netting',
        group: '',
        urlCategory: 'baseball',
        urlSubCategory: 'netting',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fcnr72178-HQ1.jpg?alt=media&token=30a2ec8a-cebf-46f1-a86b-cd4bd5edcd80",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fcnr72178-HQ1.jpg?alt=media&token=30a2ec8a-cebf-46f1-a86b-cd4bd5edcd80",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fnetting-bordering-options-HQ1.jpg?alt=media&token=586dfcc7-2f1c-4924-9ed8-5628a059f73a",
        ]
    },{
        id: 'p292',
        name: `Netting #42A 3mm 1-7/8"`,
        sku: 'cnr42a178',
        description: `This netting is great for baseball backstop, basketball court netting, batting cages, foul ball barriers, indoor baseball facilities, indoor soccer facilities, protective "L" screens, soccer barriers, and soccer goals.`,
        details: [
            `Mesh Size: 1-7/8"`,
            `Cord Diameter: 3mm`,
            `Break Strength: 320#`,
            `Available in black or white`,
        ],
        includes: [
            ``
        ],
        options: [
            `CNR72178: Netting #42A 3mm 1-7/8"`,
        ],
        usedBy: [],
        category: 'baseball',
        subCategory: 'netting',
        group: '',
        urlCategory: 'baseball',
        urlSubCategory: 'netting',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fcnr42a178-HQ1.jpg?alt=media&token=331b2c77-c087-44fe-b279-68e272bc6563",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fcnr42a178-HQ1.jpg?alt=media&token=331b2c77-c087-44fe-b279-68e272bc6563",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fnetting-bordering-options-HQ1.jpg?alt=media&token=586dfcc7-2f1c-4924-9ed8-5628a059f73a",
        ]
    },{
        id: 'p293',
        name: `Netting #24 2mm 1-7/8"`,
        sku: 'cnr24178bk',
        description: `High quality netting.`,
        details: [
            `Mesh Size: 1-7/8"`,
            `Cord Diameter: 2mm`,
            `Break Strength: 233#`,
            `Available in black`,
        ],
        includes: [
            ``
        ],
        options: [
            `CNR24178BK: Netting #24 2mm 1-7/8"`,
        ],
        usedBy: [],
        category: 'baseball',
        subCategory: 'netting',
        group: '',
        urlCategory: 'baseball',
        urlSubCategory: 'netting',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fcnr24178bk-HQ1.jpg?alt=media&token=c3874241-1b50-4c07-b743-e7e3b5597160",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fcnr24178bk-HQ1.jpg?alt=media&token=c3874241-1b50-4c07-b743-e7e3b5597160",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fnetting-bordering-options-HQ1.jpg?alt=media&token=586dfcc7-2f1c-4924-9ed8-5628a059f73a",
        ]
    },{
        id: 'p294',
        name: `Netting #36 2.5mm 1-7/8"`,
        sku: 'cnr36178bk',
        description: `High quality netting.`,
        details: [
            `Mesh Size: 1-7/8"`,
            `Cord Diameter: 2.5mm`,
            `Break Strength: 280#`,
            `Available in black`,
        ],
        includes: [
            ``
        ],
        options: [
            `CNR36178BK: Netting #36 2.5mm 1-7/8"`,
        ],
        usedBy: [],
        category: 'baseball',
        subCategory: 'netting',
        group: '',
        urlCategory: 'baseball',
        urlSubCategory: 'netting',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fcnr36178bk-HQ1.jpg?alt=media&token=20e105be-1795-45f7-9f16-d4f00a16054c",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fcnr36178bk-HQ1.jpg?alt=media&token=20e105be-1795-45f7-9f16-d4f00a16054c",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fnetting-bordering-options-HQ1.jpg?alt=media&token=586dfcc7-2f1c-4924-9ed8-5628a059f73a",
        ]
    },{
        id: 'p295',
        name: `Varsity Foul Poles`,
        sku: 'fp20',
        description: `The Varsity Foul Pole rises 17' above the ground, extending foul lines vertically into the air.`,
        details: [
            `3" Sc. 40 pipe, 3.5" O.D`,
            `0.216" wall thickness`,
            `8' x 24" metal mesh`,
            `21' long (17' above ground)`,
            `1/2" #9 flattened expanded metal visibility screens (0.120" thick)`,
            `Shipped primed and ready to paint`,
            `Sold in pairs with optional ground sleeves`
        ],
        includes: [
            ``
        ],
        options: [
            `FP20: Varsity Foul Poles (pair)`,
            `FPSO: Custom Size Foul Poles (pair)`,
            `FP20GS: Ground Sleeve (each) optional`,
        ],
        usedBy: [],
        category: 'baseball',
        subCategory: 'field',
        group: 'Foul Poles',
        urlCategory: 'baseball',
        urlSubCategory: 'field',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ffoul-pole-fp20-HQ1.jpg?alt=media&token=b2e906be-78b4-49c4-9af6-cbe58be648ad",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ffoul-pole-fp20-HQ1.jpg?alt=media&token=b2e906be-78b4-49c4-9af6-cbe58be648ad",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ffoul-pole-chart-HQ1.jpg?alt=media&token=65b33413-e25b-4a15-9a89-d92daf90fcbe",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ffoul-pole-fp20-HQ3.jpg?alt=media&token=8234598a-91f9-46c5-a8d5-7e8c297b8cbd"
        ]
    },{
        id: 'p296',
        name: `Little League Foul Poles`,
        sku: 'fp10',
        description: `The Little League Foul Pole rises 8 1/2' above the ground, extending foul lines vertically into the air.`,
        details: [
            `2" Sc. 40 pipe, 2.375" O.D`,
            `0.154" wall thickness`,
            `4' x 16" metal mesh`,
            `10 1/2' long (8 1/2' above ground)`,
            `1/2" #9 flattened expanded metal visibility screens (0.120" thick)`,
            `Works for up to a 4' high fence`,
            `Shipped primed and ready to paint`,
            `Sold in pairs with optional ground sleeves`
        ],
        includes: [
            ``
        ],
        options: [
            `FP10: Little League Foul Poles (pair)`,
            `FPSO: Custom Size Foul Poles (pair)`,
            `FP10GS: Ground Sleeve (each) optional`,
        ],
        usedBy: [],
        category: 'baseball',
        subCategory: 'field',
        group: 'Foul Poles',
        urlCategory: 'baseball',
        urlSubCategory: 'field',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ffoul-pole-fp10-HQ1.jpg?alt=media&token=8bfe3d53-e959-4318-a5d6-73d254d89072",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ffoul-pole-fp10-HQ1.jpg?alt=media&token=8bfe3d53-e959-4318-a5d6-73d254d89072",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ffoul-pole-chart-HQ1.jpg?alt=media&token=65b33413-e25b-4a15-9a89-d92daf90fcbe",
        ]
    },{
        id: 'p297',
        name: `Horizontal Distance Marker`,
        sku: 'dm1',
        description: `Horizontal Distance Marker for your baseball or softball field.`,
        details: [
            `Mounts with brass grommets`,
            `Add your own custom artwork or let us create it for you`
        ],
        includes: [
            ``
        ],
        options: [
            `DM1: Horizontal Distance Marker (vinyl)`,
            `DM1M: Horizontal Distance Marker (mesh)`,
            `DMSO: Custom Distance Marker (vinyl)`,
            `DMSOM: Custom Distance Marker (mesh)`,
        ],
        usedBy: [],
        category: 'baseball',
        subCategory: 'field',
        group: 'Distance Markers',
        urlCategory: 'baseball',
        urlSubCategory: 'field',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fhorizontal-distance-marker-dm1-HQ1.jpg?alt=media&token=00cd1f2f-da20-4285-ae20-1f536efa3469",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fhorizontal-distance-marker-dm1-HQ1.jpg?alt=media&token=00cd1f2f-da20-4285-ae20-1f536efa3469",
        ]
    },{
        id: 'p298',
        name: `Vertical Distance Marker`,
        sku: 'dm1v',
        description: `Vertical Distance Marker for your baseball or softball field.`,
        details: [
            `Mounts with brass grommets`,
            `Add your own custom artwork or let us create it for you`
        ],
        includes: [
            ``
        ],
        options: [
            `DM1V: Vertical Distance Marker (vinyl)`,
            `DM1M: Vertical Distance Marker (mesh)`,
            `DMSO: Custom Distance Marker (vinyl)`,
            `DMSOM: Custom Distance Marker (mesh)`,
        ],
        usedBy: [],
        category: 'baseball',
        subCategory: 'field',
        group: 'Distance Markers',
        urlCategory: 'baseball',
        urlSubCategory: 'field',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fvertical-distance-marker-dm1v-HQ1.jpg?alt=media&token=95c39c8b-23da-4d34-9f55-be97f9edc243",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fvertical-distance-marker-dm1v-HQ1.jpg?alt=media&token=95c39c8b-23da-4d34-9f55-be97f9edc243",
        ]
    },{
        id: 'p299',
        name: `Custom Distance Marker`,
        sku: 'dmsom',
        description: `Custom Distance Marker for your baseball or softball field.`,
        details: [
            `Mounts with brass grommets`,
            `Add your own custom artwork or let us create it for you`
        ],
        includes: [
            ``
        ],
        options: [
            `DMSO: Custom Distance Marker (vinyl)`,
            `DMSOM: Custom Distance Marker (mesh)`,
        ],
        usedBy: [],
        category: 'baseball',
        subCategory: 'field',
        group: 'Distance Markers',
        urlCategory: 'baseball',
        urlSubCategory: 'field',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fcustom-distance-marker-dmsom-HQ1.jpg?alt=media&token=4e46c576-6e0f-439b-bd8f-6ea542c42838",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fcustom-distance-marker-dmsom-HQ1.jpg?alt=media&token=4e46c576-6e0f-439b-bd8f-6ea542c42838",
        ]
    },{
        id: 'p300',
        name: `Custom Foul Pole Banner`,
        sku: 'fpbso',
        description: `Custom Foul Pole Banner for your foul pole.`,
        details: [
            `Mounts with brass grommets`,
            `Add your own custom artwork or let us create it for you`
        ],
        includes: [
            ``
        ],
        options: [
            `FPBSO: Custom Foul Pole Banner`,
        ],
        usedBy: [],
        category: 'baseball',
        subCategory: 'field',
        group: 'Foul Poles',
        urlCategory: 'baseball',
        urlSubCategory: 'field',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fcustom-foul-pole-banner-fpbso.JPG?alt=media&token=bc962eef-438b-4261-b289-f4464821c0fa",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fcustom-foul-pole-banner-fpbso.JPG?alt=media&token=bc962eef-438b-4261-b289-f4464821c0fa",
        ]
    },{
        id: 'p197',
        name: 'Aluminum Benches',
        sku: 'ab75',
        description: `These benches feature an all aluminum construction with the option to add powder coating. Available in orange, forest green, gold, white, maroon, black, gray, navy, red, kelly green, and royal blue.`,
        details: [
            `All aluminum construction`,
            `10" wide non-skid planks`,
            `Optional powder coating`,
            `2" x 2" legs with horizontal cross brace for superior stability`
        ],
        includes: [
            ``
        ],
        options: [
            `AB75: 7.5' Bench (28 lbs)`,
            `AB15: 15' Bench (50 lbs)`,
            `AB21: 21' Bench (65 lbs)`,
            `AB75PC: 7.5' powder coated Bench (28 lbs)`,
            `AB15PC: 15' powder coated Bench (50 lbs)`,
            `AB21PC: 21' powder coated Bench (65 lbs)`,
        ],
        usedBy: [],
        category: 'football baseball',
        subCategory: 'gameday field',
        group: 'Benches',
        urlCategory: 'football',
        urlSubCategory: 'gameday field',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Faluminum-bench-ab75-HQ1.jpg?alt=media&token=6f2e5f4f-cb67-42e5-94be-42e4e7357541",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Faluminum-bench-ab75-HQ1.jpg?alt=media&token=6f2e5f4f-cb67-42e5-94be-42e4e7357541",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Faluminum-bench-ab75-HQ2.jpg?alt=media&token=c35c9a5f-c98c-49c9-ba0a-e174b10b1839",
        ]
    },{
        id: 'p198',
        name: 'Aluminum Benches with backrest',
        sku: 'ab75b',
        description: `These benches feature a backrest and have an all aluminum construction with the option to add powder coating. Available in orange, forest green, gold, white, maroon, black, gray, navy, red, kelly green, and royal blue.`,
        details: [
            `All aluminum construction`,
            `10" wide non-skid planks`,
            `Optional powder coating`,
            `2" x 2" legs with horizontal cross brace for superior stability`
        ],
        includes: [
            ``
        ],
        options: [
            `AB75B: 7.5' Bench (50 lbs)`,
            `AB15B: 15' Bench (85 lbs)`,
            `AB21B: 21' Bench (116 lbs)`,
            `AB75BPC: 7.5' powder coated Bench (50 lbs)`,
            `AB15BPC: 15' powder coated Bench (85 lbs)`,
            `AB21BPC: 21' powder coated Bench (116 lbs)`,
        ],
        usedBy: [],
        category: 'football baseball',
        subCategory: 'gameday field',
        group: 'Benches',
        urlCategory: 'football',
        urlSubCategory: 'gameday field',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Faluminum-bench-with-backrest-ab75b-HQ1.jpg?alt=media&token=b59e2f8e-ecec-466f-af7c-de290eb99ca8",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Faluminum-bench-with-backrest-ab75b-HQ1.jpg?alt=media&token=b59e2f8e-ecec-466f-af7c-de290eb99ca8",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Faluminum-bench-with-backrest-ab75b-HQ2.jpg?alt=media&token=1c059aa2-b7dd-4014-affc-d10b21121ab0",
        ]
    },{
        id: 'p301',
        name: `Edge Custom Printed Chair`,
        sku: 'ch100dp',
        description: `Get creative and go all out. Our Edge folding chair offers comfort and branding solutions with unlimited color and design options through digital printing.`,
        details: [
            `Includes printing with unlimited colors on any location of the padded seat`,
            `1" thick padded seat back`,
            `2 1/2" thick padded seat`,
            `Powder coated steel frame`,
            `Rubber caps protect floors from being scratched`,
            `19" tall to seat`,
            `32" tall to back rest`,
        ],
        includes: [
            ``
        ],
        options: [
            `CH100DP: Edge Custom Printed Folding Chair (18 lbs)`,
        ],
        usedBy: [],
        category: 'Chairs and Stools',
        subCategory: 'chairs',
        group: '',
        urlCategory: 'Chairs and Stools',
        urlSubCategory: 'chairs',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fedge-chair-ch100dp-2400-13.jpg?alt=media&token=19909c7d-909d-4c31-8f8c-734fb391c6b0",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fch100-richland-center.jpg?alt=media&token=99e20e65-1987-4461-9351-68f4d72ea2ef",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fch100-carver.jpg?alt=media&token=152e4c1f-b09a-43d3-89ba-d6d525182c54",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fedge-chair-ch100dp-2400-11.jpg?alt=media&token=0bfba002-d0d4-4e81-beec-b24339b9350e",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fedge-chair-ch100dp-2400-1.jpg?alt=media&token=d00ea04c-5cfd-458d-975a-2896bc03ed9b",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fch100-ff.jpg?alt=media&token=09e0f854-6170-4cdd-8ce9-4cdd97317c5e",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fch100-katella.jpg?alt=media&token=098656b3-b529-4487-ba30-afac04ec66d5",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fedge-chair-ch100dp-2400-10.jpg?alt=media&token=85a3510a-328c-44d8-a2b0-2a3b34bc2600",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fch100-clairemont.jpg?alt=media&token=a469353d-781c-4d70-a04c-77659a4e571a",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fedge-chair-ch100dp-2400-12.jpg?alt=media&token=aad23385-0700-4bf5-9522-7f63894551e0",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fedge-chair-ch100dp-2400-13.jpg?alt=media&token=19909c7d-909d-4c31-8f8c-734fb391c6b0",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fedge-chair-ch100dp-2400-14.jpg?alt=media&token=6c433c7d-f299-47cc-938c-3ee0ac064830",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fedge-chair-ch100dp-2400-2.jpg?alt=media&token=8d1fb753-b050-42bf-8861-93f72c550d0c",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fedge-chair-ch100dp-2400-3.jpg?alt=media&token=6c68d6d1-f1e7-4bba-a2ce-92ee81e9e173",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fedge-chair-ch100dp-2400-4.jpg?alt=media&token=fb5dbd16-cf10-444c-9ec1-50dccc00fe9d",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fedge-chair-ch100dp-2400-5.jpg?alt=media&token=e6f7d9a0-cbb0-4ac6-bd9d-1520f7be59a4",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fedge-chair-ch100dp-2400-6.jpg?alt=media&token=265365a0-b4b0-412f-b52a-eb3e38c3e941",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fedge-chair-ch100dp-2400-7.jpg?alt=media&token=5720e148-3a94-4fd0-a412-05dac79870e6",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fedge-chair-ch100dp-2400-8.jpg?alt=media&token=63d55d60-16ff-46f4-9f99-226c4785db55",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fedge-chair-ch100dp-2400-9.jpg?alt=media&token=c8617b14-2e6d-42ce-9670-0111c44c1eb4"
        ]
    },{
        id: 'p302',
        name: `CH100-Next Level Custom Printed Chair`,
        sku: 'ch100',
        description: `Take your athletic programs, teams, and/or company to the next level with our screen printed folding chairs. They offer comfort and branding solutions for any event and occasion.`,
        details: [
            `Includes two color artwork in two locations`,
            `1" thick padded seat back`,
            `2 ½" thick padded seat`,
            `Powder coated steel frame`,
            `Rubber caps protect floors from being scratched`,
            `19" tall to seat`,
            `32" tall to back rest`,
            `Additional color and location available for extra charge`,
        ],
        includes: [
            ``
        ],
        options: [
            `CH100: Next Level Custom Printed Folding Chair (18 lbs)`,
            'Available frame colors: Black, Forest Green, Maroon, Navy, Purple, Red, Royal Blue, White, Gray',
            'Available vinyl colors: Athletic Gold, Black, Forest Green, Gray, Kelly Green, Maroon, Navy, Orange, Red, Royal Blue',
            '**Additional Frame colors available with additional charges: Gold and Orange**',
            '**Additional Vinyl colors available with additional charges: Cardinal, Graphite, Gunmetal, Purple**'

        ],
        usedBy: [],
        category: 'Chairs and Stools',
        subCategory: 'chairs',
        group: '',
        urlCategory: 'Chairs and Stools',
        urlSubCategory: 'chairs',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fnext-level-chair-ch100-2400-5.jpg?alt=media&token=8e2c6759-d881-4882-87b3-cbda852627d4",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fch100-golden-bears.jpg?alt=media&token=8c3a19ed-42f7-4ca5-8359-6384d3cd24e7",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fch100-sd-knights.jpg?alt=media&token=2b29fc36-97b9-41f0-a9a8-ab9d6157f3ed",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fch100-se-raleigh.jpg?alt=media&token=d864e97a-2480-4f0b-a41e-96e6e6426b19",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fch100-vass-vipers.jpg?alt=media&token=acada21f-20c4-4a72-b1a8-099ef8d9ea0a",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fch100-tottenville.jpg?alt=media&token=0aae6cb9-c819-4947-839e-798b8a9f16cc",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fch100-kurtzes.jpg?alt=media&token=fb3cbe73-63a3-4f7f-ac9d-3283c4a7e1ae",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fch100-castle-hill.jpg?alt=media&token=60d6094c-f5a9-49da-8011-7d328f47b2d5",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fch100-rogers-pirates.jpg?alt=media&token=a6bcb4bd-5d43-41d7-9b6d-3165fafde153",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fch100-james-river.jpg?alt=media&token=edaf24cc-7e5f-4ef5-aa30-5850657c5d92",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fch100-hayward-hurricanes.jpg?alt=media&token=a432b25b-21e3-4925-97e2-5154037e00fa",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fch100-medford.jpg?alt=media&token=f6d23bc3-2463-403d-8d66-50792c0fd587",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fnext-level-chair-ch100-2400-12.jpg?alt=media&token=3c827005-ee64-4653-a036-434be6912142",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Framapo-chair.jpg?alt=media&token=dc299068-8ba9-4c9a-b1eb-c1dd5ca3aad5",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fbearden-bruins-chairs.jpg?alt=media&token=95038bbe-22e4-472c-8221-1387d67608ea",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Feiu-chair.jpg?alt=media&token=ba429905-11ca-43e5-9410-6f64a8a5e057",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fwest-lafayette-soccer-chair.jpg?alt=media&token=de2dcb07-60a9-4316-bd71-a744bcf5d0cf",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fapp-state-chair.jpg?alt=media&token=09e46e30-05de-4edd-aff2-73fe7bc62956",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fnorth-elementary-tornados-chair.jpg?alt=media&token=d0971c06-6ad6-40cb-a9e3-8516d90ff395",            
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fnext-level-chair-ch100-2400-15.jpg?alt=media&token=646d6842-d7d4-4e67-b32b-85b07f695207",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fnext-level-chair-ch100-2400-9.jpg?alt=media&token=7e201e50-0b92-4da0-a7cb-463a56410e3f",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fnext-level-chair-ch100-2400-10.jpg?alt=media&token=8e08ebef-d0c6-4a94-be63-b51e44f54f3b",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fnext-level-chair-ch100-2400-8.jpg?alt=media&token=14dee1d8-e95a-46a7-a4a3-16c9c06a3bd0",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fnext-level-chair-ch100-2400-1.jpg?alt=media&token=3dfbec28-60cb-486e-95dc-65bdf65be312",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fnext-level-chair-ch100-2400-2.jpg?alt=media&token=dae1fc1f-94ef-429a-ac36-41fe16fa2a57",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fnext-level-chair-ch100-2400-3.jpg?alt=media&token=c5701d97-01d3-4faa-83dc-1bd05f08e06c",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fnext-level-chair-ch100-2400-4.jpg?alt=media&token=3d06532c-ab83-4b89-b1a3-0f47dc39b5af",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fch100-conrad.jpg?alt=media&token=bb266348-f4f8-40a8-936f-e6f918fef95b",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fch100-labette.jpg?alt=media&token=a570c202-6622-417c-98e0-32597d00966d",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fch100-cp.jpg?alt=media&token=8cee6d5c-e47d-4e66-88eb-b5127e199d66",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fnext-level-chair-ch100-2400-6.jpg?alt=media&token=0505230d-688f-409b-9254-92299438219e",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fnext-level-chair-ch100-2400-5.jpg?alt=media&token=8e2c6759-d881-4882-87b3-cbda852627d4",            
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fnext-level-chair-ch100-2400-7.jpg?alt=media&token=4429dfdf-7287-447b-ba3a-90cf6156ece5",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fnext-level-chair-ch100-2400-11.jpg?alt=media&token=25fe360e-c4b9-4e42-a062-c33e1086b461",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fnext-level-chair-ch100-2400-13.jpg?alt=media&token=381a4aa1-5f14-4782-87d1-d30355046cec",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fnext-level-chair-ch100-2400-14.jpg?alt=media&token=f9372d43-e621-40b0-b22c-d5706e27448a",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fch100-davidson-lineup.jpg?alt=media&token=435bcac0-5294-4bd4-bc62-6792778ba2ca",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fch100-asheville-gym.jpg?alt=media&token=dcad171b-73d9-4648-8cc6-ea46bc6e9b27",
        ]
    },{
        id: 'p303',
        name: `Chair Back Decal`,
        sku: 'chbd',
        description: `Fisher Chair Back Decals offer and exceptional avenue for further customizing your custom folding chairs. Whether you want to add advertising, sponsorships, player names, or additional logos, the possibilities are endless. Decals are printed and cut to the exact specifications of chairs. Minimum order quantity of (12).`,
        details: [
            `Great for adding advertisements, sponsorships, player names, or additional logos to your chairs.`,
            `Minimum order quantity of (12)`,
        ],
        includes: [
            ``
        ],
        options: [
            `CHBDM: Magnet Attachment`,
            `CHBDA: Decal Attachment`,
            `CHBD: Chair Back Attachment Pre-Install`,
        ],
        usedBy: [],
        category: 'Chairs and Stools',
        subCategory: 'chairs',
        group: 'Chair Accessories',
        urlCategory: 'Chairs and Stools',
        urlSubCategory: 'chairs',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fchair-back-decal-chbd-HQ1.jpg?alt=media&token=5b4bca84-3600-40ca-9dfb-3cce7212e54b",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fchair-back-decal-chbd-HQ1.jpg?alt=media&token=5b4bca84-3600-40ca-9dfb-3cce7212e54b",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fchair-back-decal-chbd-HQ2.jpg?alt=media&token=a76f33e2-9281-4426-a65d-d34f961a0db6"
        ]
    },{
        id: 'p304',
        name: `Chair Back Cover`,
        sku: 'chbco',
        description: `Dress up your team chair backs with Chair Back Covers.`,
        details: [
            `Custom print your school logo or corporate sponsor`,
            `Ideal for promotions and special events`,
            `30 oz expanded vinyl`,
            `18 vinyl colors`,
            `Radius shaped to fit chair back, 21" wide x 20" high`,
        ],
        includes: [
            ``
        ],
        options: [
            `CHBCO: Chair Back Cover`,
        ],
        usedBy: [],
        category: 'Chairs and Stools',
        subCategory: 'chairs',
        group: 'Chair Accessories',
        urlCategory: 'Chairs and Stools',
        urlSubCategory: 'chairs',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fchair-back-cover-chbco-HQ1.jpg?alt=media&token=3ad98eb6-6b1f-4353-858d-d3ef654ae2af",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fchair-back-cover-chbco-HQ1.jpg?alt=media&token=3ad98eb6-6b1f-4353-858d-d3ef654ae2af",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fchair-back-cover-chbco-HQ2.jpg?alt=media&token=35088045-c21f-4b45-9156-db10280db819"
        ]
    },{
        id: 'p305',
        name: `Chair Ad Board`,
        sku: 'chadbf',
        description: `Durable 1/8" die-bond board for your custom folding chairs. Great for promotions and special events.`,
        details: [
            `Printed on durable 1/8" die-bond board`,
            `Custom print your school logo or corporate sponsor`,
            `Ideal for promotions and special events`,
            `Unlimited colors`,
            `Ability to print full area of board`,
            `Minimum order quantity of (12)`,
            `Board size: 17" wide x 11" high`,
        ],
        includes: [
            ``
        ],
        options: [
            `CHADBF: Front Ad Board (17" wide x 11" high)`,
            `CHADBB: Back Ad Board (17" wide x 11" high)`,
        ],
        usedBy: [],
        category: 'Chairs and Stools',
        subCategory: 'chairs',
        group: 'Chair Accessories',
        urlCategory: 'Chairs and Stools',
        urlSubCategory: 'chairs',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fchair-ad-board-chadbf-HQ1.jpg?alt=media&token=e3a72fa5-c98e-4cfb-9056-217badae4f35",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fchair-ad-board-chadbf-HQ1.jpg?alt=media&token=e3a72fa5-c98e-4cfb-9056-217badae4f35",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fchair-ad-board-chadbf-HQ2.jpg?alt=media&token=f306fb6a-0c69-4461-8b1d-933415e1b651"
        ]
    },{
        id: 'p306',
        name: `Chair Cart (36 chairs)`,
        sku: 'chc100',
        description: `All Chair Carts come with 4" caster wheels, a heavy duty steel frame, and black powder-coating. Included with each cart is also a lifetime warranty on the frame.`,
        details: [
            `Heavy duty steel frame`,
            `4" caster wheels`,
            `Powder coated`,
            `Lifetime warranty on the frame`,
            `Chair Capacity: 36`,
            `Dimensions: 82.5" high x 34" wide x 64" long`,
            `Weight: 126 lbs`,
        ],
        includes: [
            ``
        ],
        options: [
            `CHC100: Chair Cart (36 chairs)`,
        ],
        usedBy: [],
        category: 'Chairs and Stools',
        subCategory: 'chair carts',
        group: '',
        urlCategory: 'Chairs and Stools',
        urlSubCategory: 'chair carts',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fchair-cart-chc100-HQ1.jpg?alt=media&token=7eacbb6c-899b-411a-b87e-e40d88a52af7",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fchair-cart-chc100-HQ1.jpg?alt=media&token=7eacbb6c-899b-411a-b87e-e40d88a52af7",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fchair-cart-chc100-HQ2.jpg?alt=media&token=e1941506-22a0-43d2-9d90-160ff226ea3d"
        ]
    },{
        id: 'p307',
        name: `Chair Cart (22 chairs)`,
        sku: 'chc300',
        description: `All Chair Carts come with 4" caster wheels, a heavy duty steel frame, and black powder-coating. Included with each cart is also a lifetime warranty on the frame.`,
        details: [
            `Heavy duty steel frame`,
            `4" caster wheels`,
            `Powder coated`,
            `Lifetime warranty on the frame`,
            `Chair Capacity: 22`,
            `Dimensions: 36" high x 19" wide x 96" long`,
            `Weight: 100 lbs`,
        ],
        includes: [
            ``
        ],
        options: [
            `CHC300: Chair Cart (22 chairs)`,
        ],
        usedBy: [],
        category: 'Chairs and Stools',
        subCategory: 'chair carts',
        group: '',
        urlCategory: 'Chairs and Stools',
        urlSubCategory: 'chair carts',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fchair-cart-chc300-HQ1.jpg?alt=media&token=b94a13f6-3688-4594-ae67-82585b1cfdff",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fchair-cart-chc300-HQ1.jpg?alt=media&token=b94a13f6-3688-4594-ae67-82585b1cfdff",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fchair-cart-chc300-HQ2.jpg?alt=media&token=f4aacfc0-00ec-4ebb-a3f0-4c2a58ce42fe"
        ]
    },{
        id: 'p308',
        name: `Chair Cart (18 chairs)`,
        sku: 'chc150',
        description: `All Chair Carts come with 4" caster wheels, a heavy duty steel frame, and black powder-coating. Included with each cart is also a lifetime warranty on the frame.`,
        details: [
            `Heavy duty steel frame`,
            `4" caster wheels`,
            `Powder coated`,
            `Lifetime warranty on the frame`,
            `Chair Capacity: 18`,
            `Dimensions: 44" high x 34" wide x 64" long`,
            `Weight: 80 lbs`,
        ],
        includes: [
            ``
        ],
        options: [
            `CHC150: Chair Cart (18 chairs)`,
        ],
        usedBy: [],
        category: 'Chairs and Stools',
        subCategory: 'chair carts',
        group: '',
        urlCategory: 'Chairs and Stools',
        urlSubCategory: 'chair carts',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fchair-cart-chc150-HQ1.jpg?alt=media&token=0a523e86-d77a-4acc-b6ef-38376a950d17",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fchair-cart-chc150-HQ1.jpg?alt=media&token=0a523e86-d77a-4acc-b6ef-38376a950d17",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fchair-cart-chc150-HQ2.jpg?alt=media&token=8c9381b1-9bce-4a8a-ad8a-a0155aa174fc"
        ]
    },{
        id: 'p309',
        name: `Chair Cart Cover`,
        sku: 'chccoverso',
        description: `Protect your Fisher Folding Chairs from scuffs and scratches with Chair Cart Cover. Made with durable 18 oz vinyl and sized to fit over a fully stacked Chair Cart.`,
        details: [
            `Covers available in 13 color options`,
            `Add your logo to the side of the cover`,
        ],
        includes: [
            ``
        ],
        options: [
            `CHCCOVERBK: Chair Cart Cover Stock Black No Art`,
            `CHCCOVERCC: Chair Cart Cover Custom Color No Art`,
            `CHCCOVERSO: Chair Cart Cover Special Order w/ Art`,
        ],
        usedBy: [],
        category: 'Chairs and Stools',
        subCategory: 'chair carts',
        group: '',
        urlCategory: 'Chairs and Stools',
        urlSubCategory: 'chair carts',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fchair-cart-cover-chccoverso-HQ1.jpg?alt=media&token=13e87b88-18bb-4f88-9777-f8221cf45a2e",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fchair-cart-cover-chccoverso-HQ1.jpg?alt=media&token=13e87b88-18bb-4f88-9777-f8221cf45a2e",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fchair-cart-cover-chccoverso-HQ2.jpg?alt=media&token=fd37b48a-3cb5-4cc4-b1e1-5de31aa1affe"
        ]
    },{
        id: 'p310',
        name: `Custom Printed Stool`,
        sku: 'stl1000dp',
        description: `Get creative and go all out. Our Custom Printed Stools offer comfort and branding solutions with unlimited color and design options through digital printing.`,
        details: [
            `1-½" thick padded seat`,
            `6lb rebond foam covered in 30 oz vinyl`,
            `Powder-coated steel frame`,
            `Rubber cap ends to protect floor from being scratched`,
            `Stool tubing: 16 gauge steel`,
            `Seat frame: 16 gauge steel`,
            `Seat hardware and brackets: 12 gauge steel`,
            `21" tall to seat`,
            `27" tall to back rest`,
            `10 year limited warranty on frame`,
        ],
        includes: [
            ``
        ],
        options: [
            `STL1000DP: Custom Printed Stool`,
            `Available in unlimited color options with digital printed graphics`,
            `Available in 11 frame colors`,
            `Black, Forest Green, Maroon, Navy, Purple, Red, Royal Blue, White, Gray, Gold and Orange`,
        ],
        usedBy: [],
        category: 'Chairs and Stools',
        subCategory: 'stools',
        group: '',
        urlCategory: 'Chairs and Stools',
        urlSubCategory: 'stools',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fcustom-printed-stool-stl1000dp-2400-5.jpg?alt=media&token=41b22789-5756-4610-b3e6-df3cdc217f58",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fstool-ohio-state.jpg?alt=media&token=4786badd-a22f-4d5b-8575-c75b288e5439",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fstool-cooper-football.jpg?alt=media&token=96a94eac-95d5-4201-88e0-a8ceb74d922d",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fstool-scott-county.jpg?alt=media&token=363fcdc3-8696-425b-8db7-d1fe84baa89f",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fstool-summit-christian.jpg?alt=media&token=0a620ddd-52fd-4a24-99e2-f8b27e0bd880",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fstool-nations-ford.jpg?alt=media&token=200cbee8-4f6c-4c86-9325-de889d70a8c6",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fstool-salem-lions.jpg?alt=media&token=b441bbd8-f222-4993-a79b-a62749da1357",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fcustom-printed-stool-stl1000dp-2400-6.jpg?alt=media&token=9d963411-6ecf-45fa-92ab-4d57827a7e77",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fstool-fsu.jpg?alt=media&token=fa107c84-f458-441b-ba38-72c488d9ac5b",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fstool-bull-bojangles.jpg?alt=media&token=f7f18e86-ebc5-4010-b063-40190b694aba",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fstool-eagle-e.jpg?alt=media&token=16a1084c-0aae-4616-bf51-2e85d58ff4a3",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fstool-blue-lion.jpg?alt=media&token=4a7befae-6f58-4acc-b72e-174a5ed678a0",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fcustom-printed-stool-stl1000dp-2400-1.jpg?alt=media&token=d258dea9-d1b8-43db-84bb-e06008861ac8",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fcustom-printed-stool-stl1000dp-2400-2.jpg?alt=media&token=87031245-c7dd-46ee-82d0-c42692f83b0a",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fcustom-printed-stool-stl1000dp-2400-3.jpg?alt=media&token=537ac5d6-c79a-4104-ab03-adb9adb8a7d9",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fcustom-printed-stool-stl1000dp-2400-4.jpg?alt=media&token=3cd5615e-4e14-48c5-a2e4-8c2edbad2016",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fcustom-printed-stool-stl1000dp-2400-5.jpg?alt=media&token=41b22789-5756-4610-b3e6-df3cdc217f58",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fcustom-printed-stool-stl1000dp-2400-7.jpg?alt=media&token=3a4ba7ff-c5dd-4796-9ca1-4ae2f593092f",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fcustom-printed-stool-stl1000dp-2400-8.jpg?alt=media&token=46488908-848c-4b61-b12f-5c4e4990d4a8",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fcustom-printed-stool-stl1000dp-2400-9.jpg?alt=media&token=0c1da4e9-58f3-42b5-b6db-23ed74995006",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fcustom-printed-stool-stl1000dp-2400-10.jpg?alt=media&token=9969821c-19ec-4a7d-b847-a7ad0e55d1cf"
        ]
    },{
        id: 'p315',
        name: `Digitally Printed Wall Panel`,
        sku: 'wp2so2',
        description: `Increase your school spirit with the full color digital wall panels. You can use your own photos and artwork or have our art department provide photos, logos, and lettering for maximum impact. Colors and designs are limitless with brilliant inks that will last for years.`,
        details: [
            `Wood Backed`,
            `Non-Folding`,
            `Manufactured with rebond or polyfoam to help reduce the impact and protect athletes`,
            `18 colors available in 14 oz fire retardant or 18 oz materials`,
            `Custom graphics will change the atmosphere in your facility`,
        ],
        includes: [
            ``
        ],
        options: [
            `2" polyfoam`,
            `WP22422: 2'W x 4'H x 2" - 14 oz FR Vinyl`,
            `WP22442: 4'W x 4'H x 2" - 14 oz FR Vinyl`,
            `WP22522: 2'W x 5'H x 2" - 14 oz FR Vinyl`,
            `WP22542: 4'W x 5'H x 2" - 14 oz FR Vinyl`,
            `WP22622: 2'W x 6'H x 2" - 14 oz FR Vinyl`,
            `WP22642: 4'W x 6'H x 2" - 14 oz FR Vinyl`,
            `WP22722: 2'W x 7'H x 2" - 14 oz FR Vinyl`,
            `WP22742: 4'W x 7'H x 2" - 14 oz FR Vinyl`,
            `WP22822: 2'W x 8'H x 2" - 14 oz FR Vinyl`,
            `WP22842: 4'W x 8'H x 2" - 14 oz FR Vinyl`,
            `WP2SO2: Custom Size - 2" thick - 14 oz FR Vinyl`,
            `2" rebond foam`,
            `WP32422: 2'W x 4'H x 2" - 14 oz FR Vinyl`,
            `WP32442: 4'W x 4'H x 2" - 14 oz FR Vinyl`,
            `WP32522: 2'W x 5'H x 2" - 14 oz FR Vinyl`,
            `WP32542: 4'W x 5'H x 2" - 14 oz FR Vinyl`,
            `WP32622: 2'W x 6'H x 2" - 14 oz FR Vinyl`,
            `WP32642: 4'W x 6'H x 2" - 14 oz FR Vinyl`,
            `WP32722: 2'W x 7'H x 2" - 14 oz FR Vinyl`,
            `WP32742: 4'W x 7'H x 2" - 14 oz FR Vinyl`,
            `WP32822: 2'W x 8'H x 2" - 14 oz FR Vinyl`,
            `WP32842: 4'W x 8'H x 2" - 14 oz FR Vinyl`,
            `WP3SO2: Custom Size - 2" thick - 14 oz FR Vinyl`,
            `3" polyfoam`,
            `WP23422: 2'W x 4'H x 3" - 14 oz FR Vinyl`,
            `WP23442: 4'W x 4'H x 3" - 14 oz FR Vinyl`,
            `WP23522: 2'W x 5'H x 3" - 14 oz FR Vinyl`,
            `WP23542: 4'W x 5'H x 3" - 14 oz FR Vinyl`,
            `WP23622: 2'W x 6'H x 3" - 14 oz FR Vinyl`,
            `WP23642: 4'W x 6'H x 3" - 14 oz FR Vinyl`,
            `WP23722: 2'W x 7'H x 3" - 14 oz FR Vinyl`,
            `WP23742: 4'W x 7'H x 3" - 14 oz FR Vinyl`,
            `WP23822: 2'W x 8'H x 3" - 14 oz FR Vinyl`,
            `WP23842: 4'W x 8'H x 3" - 14 oz FR Vinyl`,
            `WP23SO2: Custom Size - 3" thick - 14 oz FR Vinyl`,
        ],
        usedBy: [],
        category: ' protective padding',
        subCategory: 'protective padding indoor padding',
        group: 'Indoor Wall Padding',
        urlCategory: ' protective padding',
        urlSubCategory: 'protective padding indoor padding',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fdigitally-printed-wall-panel-wp-HQ16.jpg?alt=media&token=2bbef04c-175f-4ee7-91e6-ff80a4f65ed0",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fdigitally-printed-wall-panel-wp-HQ16.jpg?alt=media&token=2bbef04c-175f-4ee7-91e6-ff80a4f65ed0",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fdigitally-printed-wall-panel-wp-2400-25.jpg?alt=media&token=f0bfd8e0-4edb-4d08-a2d0-8aca329f2281",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fwall-anel-berry.jpg?alt=media&token=1fd2f648-626c-483a-9ebf-72b300a8dd88",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fdigitally-printed-wall-panel-wp-HQ15.jpg?alt=media&token=5e2820bf-f6e4-49b2-8247-6c2c57e7bf50",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fdigitally-printed-wall-panel-wp-HQ1.jpg?alt=media&token=ed7a4746-2b25-4145-939c-bb4e638302f9",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fdigitally-printed-wall-panel-wp-HQ21.jpg?alt=media&token=0a870122-061a-4624-b82d-8bee9debb7b1",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fdigitally-printed-wall-panel-wp-HQ22.jpg?alt=media&token=d5ea2042-cbbf-4264-9453-787089f9b197",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fdigitally-printed-wall-panel-wp-HQ23.jpg?alt=media&token=361b032b-ea94-4798-bd5c-932cbd8f53ed",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fdigitally-printed-wall-panel-wp-HQ24.jpg?alt=media&token=9a38be0d-9d6b-4d65-9d9e-977f044178e0",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fdigitally-printed-wall-panel-wp-HQ17.jpg?alt=media&token=4c98588f-c46d-44bd-9639-2b1ad6c31960",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fdigitally-printed-wall-panel-wp-HQ18.jpg?alt=media&token=21e11dc6-7015-400b-ba6a-78a9ad16c47e",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fdigitally-printed-wall-panel-wp-HQ19.jpg?alt=media&token=9a06a731-0707-47f1-93de-d739c8223e8c",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fdigitally-printed-wall-panel-wp-HQ20.jpg?alt=media&token=00e25cd7-78f5-467e-a1b1-19b5da2fc70f",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fdigitally-printed-wall-panel-wp-HQ14.jpg?alt=media&token=1d2095ac-2705-4fbd-90b9-3b616241b7cc",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fdigitally-printed-wall-panel-wp-HQ13.jpg?alt=media&token=9f4ac6a7-04f5-4d2c-9076-bce2474b476a",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fdigitally-printed-wall-panel-wp-HQ2.jpg?alt=media&token=c0102c57-6cb4-4d93-804e-8b2eb141e8d2",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fdigitally-printed-wall-panel-wp-HQ3.jpg?alt=media&token=d94df1c1-77c4-459e-86e8-744be6522235",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fdigitally-printed-wall-panel-wp-HQ4.jpg?alt=media&token=65515586-e4fc-418f-a596-167b0ce1e91c",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fdigitally-printed-wall-panel-wp-HQ5.jpg?alt=media&token=4f3dd045-9565-4c17-9cac-57c40f30966e",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fdigitally-printed-wall-panel-wp-HQ6.jpg?alt=media&token=c945ce76-d632-48e1-8a7c-941c52864423",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fdigitally-printed-wall-panel-wp-HQ7.jpg?alt=media&token=d5c03e31-4288-4cbc-b6a2-51a951020b50",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fdigitally-printed-wall-panel-wp-HQ8.jpg?alt=media&token=62dd144e-7884-49bc-8e33-c713fd8184fa"
        ]
    },{
        id: 'p316',
        name: `Non-Folding Removable Wall Mat`,
        sku: 'wm224062',
        description: `Non-Folding Removable Wall Pads offer protection and value. Hang the mats on the wall for protection in your gym or remove them for use as exercise mats. They are easy to mount and remove. Available in 16 colors. These mats are manufactured from 2" 100 lb ILD polyfoam with a vinyl "pipe loop" on the top. Supply your own length of steel pipe and fasten the mat to the wall using mat hanging strips.`,
        details: [
            `Non-Wood Backed`,
            `Non-Folding`,
            `Manufactured with 2" polyfoam to help reduce the impact and protect athletes`,
            `16 colors available in 14 oz fire retardant or 18 oz materials`,
            `Custom graphics will change the atmosphere in your facility`,
        ],
        includes: [
            ``
        ],
        options: [
            `Velcro or Pipe Loop Mounting`,
            `WM224062: 6'W x 4'H x 2" - 14 oz FR Vinyl`,
            `WM224063: 6'W x 4'H x 2" - 18 oz Vinyl`,
            `WM224082: 8'W x 4'H x 2" - 14 oz FR Vinyl`,
            `WM224083: 8'W x 4'H x 2" - 18 oz Vinyl`,
            `WM22SO2: Custom Size - 2" thick - 14 oz FR Vinyl`,
            `WM22SO3: Custom Size - 2" thick - 18 oz Vinyl`,
            `Grommet Mounting`,
            `WMG224062: 6'W x 4'H x 2" - 14 oz FR Vinyl`,
            `WMG224063: 6'W x 4'H x 2" - 18 oz Vinyl`,
            `WMG224082: 8'W x 4'H x 2" - 14 oz FR Vinyl`,
            `WMG224083: 8'W x 4'H x 2" - 18 oz Vinyl`,
            `WMG22SO2: Custom Size - 2" thick - 14 oz FR Vinyl`,
            `WMG22SO3: Custom Size - 2" thick - 18 oz Vinyl`,
        ],
        usedBy: [],
        category: ' protective padding',
        subCategory: 'protective padding indoor padding',
        group: 'Indoor Wall Padding',
        urlCategory: ' protective padding',
        urlSubCategory: 'protective padding indoor padding',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fnon-folding-removable-wall-mat-wm-HQ1.jpg?alt=media&token=12226691-47ca-4a85-844c-6495e1a9d761",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fnon-folding-removable-wall-mat-wm-HQ1.jpg?alt=media&token=12226691-47ca-4a85-844c-6495e1a9d761",
        ]
    },{
        id: 'p317',
        name: `Wall Panel Protective Covers`,
        sku: 'pcso',
        description: `We highly recommend these covers for the protection of your digital and screen printed artwork. They mount directly above your wall panels and can be easily removed and re-mounted.`,
        details: [
            `Mounts above your wall panels`,
            `Easy mounting and removal`,
            `Protects the artwork on your wall panels`
        ],
        includes: [
            ``
        ],
        options: [
            `PC612: 6'H x 12'W Protective Cover`,
            `PSCO: Custom Size Protective Cover`,
        ],
        usedBy: [],
        category: ' protective padding',
        subCategory: 'protective padding indoor padding',
        group: 'Indoor Wall Padding',
        urlCategory: ' protective padding',
        urlSubCategory: 'protective padding indoor padding',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fwall-panel-protective-cover-pcso-HQ1.jpg?alt=media&token=acc4ea19-9c55-41e3-a2f1-4ee8551e9f10",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fwall-panel-protective-cover-pcso-HQ1.jpg?alt=media&token=acc4ea19-9c55-41e3-a2f1-4ee8551e9f10",
        ]
    },{
        id: 'p318',
        name: `L Shape Permanent Stage Mat`,
        sku: 'psm222',
        description: `Concrete, wood, or metal stages adjacent to athletic surfaces are a danger to anyone in an active sport. Help protect your players from injury while dressing up your gym with beatiful, safe, and practical stage pads. Stage pads are easy to mount and custom sizes are available. You can choose between permanent or removable mounting with logos and lettering available. Cut-outs can be provided for access to electrical outlets or other objects. Special pad designs are available.`,
        details: [
            `Wood Back`,
            `Rebond or Polyfoam padding for indoor applications`,
            `Polyfoam padding for outdoor applications`,
            `7/16" OSB backing for indoor applications`,
            `3/4" exterior grade plywood backing for outdoor applications`,
            `1" mounting lip on top or bottom`,
            `Optional Z-bracket mounting`,
            `Made with 18 oz vinyl`,
            `Cut-outs can be made for electrical outlets`,
            `Customize your pads with artwork of your choice`,
        ],
        includes: [
            ``
        ],
        options: [
            `Indoor`,
            `PSM222: 2" Polyfoam - 14 oz FR Vinyl`,
            `PSM223: 2" Polyfoam - 18 oz Vinyl`,
            `PSM232: 3" Polyfoam - 14 oz FR Vinyl`,
            `PSM233: 3" Polyfoam - 18 oz Vinyl`,
            `Outdoor`,
            `PSMS223: 2" Polyfoam - 18 oz Vinyl`,
            `PSMS233: 3" Polyfoam - 18 oz Vinyl`,
        ],
        usedBy: [],
        category: ' protective padding',
        subCategory: 'protective padding indoor padding',
        group: 'Stage Mats',
        urlCategory: ' protective padding',
        urlSubCategory: 'protective padding indoor padding',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpermanently-mounted-stage-mat-psm222-HQ1.jpg?alt=media&token=e0152739-094b-45ac-8af4-64e430bedc75",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpermanently-mounted-stage-mat-psm222-HQ1.jpg?alt=media&token=e0152739-094b-45ac-8af4-64e430bedc75",
        ]
    },{
        id: 'p319',
        name: `L Shape Removable Stage Mat`,
        sku: 'sm222',
        description: `Concrete, wood, or metal stages adjacent to athletic surfaces are a danger to anyone in an active sport. Help protect your players from injury while dressing up your gym with beatiful, safe, and practical stage pads. Stage pads are easy to mount and custom sizes are available. You can choose between permanent or removable mounting with logos and lettering available. Cut-outs can be provided for access to electrical outlets or other objects. Special pad designs are available.`,
        details: [
            `Non-Wood Back`,
            `Polyfoam padding`,
            `Attaches with velcro or grommet flaps`,
            `Choose between 18 oz vinyl or 14 oz fire retardant vinyl`,
            `Cut-outs can be made for electrical outlets`,
            `Customize your pads with artwork of your choice`,
        ],
        includes: [
            ``
        ],
        options: [
            `SM222: 2" Polyfoam - 14 oz FR Vinyl`,
            `SM223: 2" Polyfoam - 18 oz Vinyl`,
            `SM232: 3" Polyfoam - 14 oz FR Vinyl`,
            `SM233: 3" Polyfoam - 18 oz Vinyl`,
        ],
        usedBy: [],
        category: ' protective padding',
        subCategory: 'protective padding indoor padding',
        group: 'Stage Mats',
        urlCategory: ' protective padding',
        urlSubCategory: 'protective padding indoor padding',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fl-shape-removable-stage-mat-sm222-HQ1.jpg?alt=media&token=b59cc417-14a7-4136-b3b1-60498ccda2e6",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fl-shape-removable-stage-mat-sm222-HQ1.jpg?alt=media&token=b59cc417-14a7-4136-b3b1-60498ccda2e6",
        ]
    },{
        id: 'p320',
        name: `Permanent Beam Pad`,
        sku: 'pbc',
        description: `Custom made to fit any area, these specialty covers will protect your athletes and students from sharp, protruding, corners and edges.`,
        details: [
            `Polyfoam padding`,
            `7/16" OSB wood backed for strength and durability`,
            `All panels are safety finished - no exposed staples, raw edges, or sharp corners`,
            `1" mounting lip on top and bottom`,
            `Choose from 18 oz vinyl or 14 oz fire retardant vinyl`,
        ],
        includes: [
            ``
        ],
        options: [
            `3 Sided`,
            `PBC222: 2" Polyfoam - 14 oz FR Vinyl`,
            `PBC223: 2" Polyfoam - 18 oz Vinyl`,
            `PBC232: 3" Polyfoam - 14 oz FR Vinyl`,
            `PBC233: 3" Polyfoam - 18 oz Vinyl`,
            `2 Sided`,
            `PCC222: 2" Polyfoam - 14 oz FR Vinyl`,
            `PCC223: 2" Polyfoam - 18 oz Vinyl`,
            `PCC232: 3" Polyfoam - 14 oz FR Vinyl`,
            `PCC233: 3" Polyfoam - 18 oz Vinyl`,
        ],
        usedBy: [],
        category: ' protective padding',
        subCategory: 'protective padding indoor padding',
        group: 'Beam Padding',
        urlCategory: ' protective padding',
        urlSubCategory: 'protective padding indoor padding',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpermanent-beam-pad-pbc-HQ1.jpg?alt=media&token=0f92fcaa-996b-4e4e-b8ec-310c0033fd8a",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpermanent-beam-pad-pbc-HQ1.jpg?alt=media&token=0f92fcaa-996b-4e4e-b8ec-310c0033fd8a",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpermanent-beam-pad-pbc-HQ2.jpg?alt=media&token=1118018c-78a7-4cf7-bdc8-4c6bae685ba2",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fremovable-4-sided-beam-pad-bp4s22so2-HQ3.jpg?alt=media&token=9f0c41ab-4389-4dce-a424-7b6226df486a",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpermanent-beam-pad-pbc-HQ3.jpg?alt=media&token=594607c6-cb77-42cd-96be-a5a3ed1252f4",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpermanent-beam-pad-pbc-HQ4.jpg?alt=media&token=5b38ffcc-dda3-465b-8395-2cb63699760f",
        ]
    },{
        id: 'p321',
        name: `Removable I-beam Pad`,
        sku: 'ibp2352',
        description: `Removable I-beam Pads are designed to cover the sharp edges and faces of steel I-beams. Constructed with 2" or 3" polyfoam.`,
        details: [
            `Constructed with 3" polyfoam padding`,
            `Designed to cover the sharp edges and faces steel I-beams`,
        ],
        includes: [
            ``
        ],
        options: [
            `3" Polyfoam`,
            `IBP2352: Up to 5' high - 14 oz FR Vinyl`,
            `IBP2353: Up to 5' high - 18 oz Vinyl`,
            `IBP2362: 5' to 6' high - 14 oz FR Vinyl`,
            `IBP2363: 5' to 6' high - 18 oz Vinyl`,
            `IBP2372: 6' to 7' high - 14 oz FR Vinyl`,
            `IBP2373: 6' to 7' high - 18 oz Vinyl`,
            `IBP2382: 7' to 8' high - 14 oz FR Vinyl`,
            `IBP2383: 7' to 8' high - 18 oz Vinyl`,
            `IBP23SO2: Custom size - 14 oz FR Vinyl`,
            `IBP23SO3: Custom size - 18 oz Vinyl`,
        ],
        usedBy: [],
        category: ' protective padding',
        subCategory: 'protective padding indoor padding',
        group: 'Beam Padding',
        urlCategory: ' protective padding',
        urlSubCategory: 'protective padding indoor padding',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fremovable-i-beam-pad-ibp2352-HQ1.jpg?alt=media&token=9a8f8a11-0a55-4c40-b0f4-17177b14f853",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fremovable-i-beam-pad-ibp2352-HQ1.jpg?alt=media&token=9a8f8a11-0a55-4c40-b0f4-17177b14f853",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fremovable-i-beam-pad-ibp2352-HQ2.jpg?alt=media&token=02893121-ac66-43f7-8bd4-94f219444996",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fremovable-i-beam-pad-ibp2352-HQ3.jpg?alt=media&token=b46eb80d-d695-4cd9-b59a-be7de988819a",
        ]
    },{
        id: 'p322',
        name: `Removable 2-sided Corner Pad`,
        sku: 'rcc222',
        description: `Removable 2-sided Corner Pads are designed to cover door openings, sharp corners, or angled edges. Constructed with 2" or 3" polyfoam.`,
        details: [
            `Choose from 2" or 3" polyfoam padding`,
            `Designed to cover door openings, sharp corners, or angles edges`,
        ],
        includes: [
            ``
        ],
        options: [
            `2" Polyfoam`,
            `RCC222: Removable Corner Cover - 14 oz FR Vinyl`,
            `RCC223: Removable Corner Cover - 18 oz Vinyl`,
            `3" Polyfoam`,
            `RCC232: Removable Corner Cover - 14 oz FR Vinyl`,
            `RCC233: Removable Corner Cover - 18 oz Vinyl`,
        ],
        usedBy: [],
        category: ' protective padding',
        subCategory: 'protective padding indoor padding',
        group: 'Beam Padding',
        urlCategory: ' protective padding',
        urlSubCategory: 'protective padding indoor padding',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fremovable-2-sided-beam-pad-rcc222-HQ1.jpg?alt=media&token=7b9fda6c-dd61-4a7b-bc32-e3ced29770e1",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fremovable-2-sided-beam-pad-rcc222-HQ1.jpg?alt=media&token=7b9fda6c-dd61-4a7b-bc32-e3ced29770e1",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fremovable-2-sided-beam-pad-rcc222-HQ2.jpg?alt=media&token=73a06c33-4991-4d95-991f-e93240f343c7",
        ]
    },{
        id: 'p323',
        name: `Removable 3-sided Pad`,
        sku: 'bp3s22so2',
        description: `Removable 3-sided Pads are designed to cover steel or concrete beams, providing protection from wall beams.`,
        details: [
            `Choose from 2" or 3" polyfoam padding`,
            `Designed to cover steel or concrete beams`,
        ],
        includes: [
            ``
        ],
        options: [
            `2" Polyfoam`,
            `BP3S22SO2: 3 Sided Beam Cover - 14 oz FR Vinyl`,
            `BP3S22SO2: 3 Sided Beam Cover - 18 oz Vinyl`,
            `3" Polyfoam`,
            `BP3S23SO2: 3 Sided Beam Cover - 14 oz FR Vinyl`,
            `BP3S23SO2: 3 Sided Beam Cover - 18 oz Vinyl`,
        ],
        usedBy: [],
        category: ' protective padding',
        subCategory: 'protective padding indoor padding',
        group: 'Beam Padding',
        urlCategory: ' protective padding',
        urlSubCategory: 'protective padding indoor padding',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fremovable-3-sided-beam-pad-bp3s22so2-HQ1.jpg?alt=media&token=a6c4e6ae-fd81-4eb6-8a58-f50c437400e1",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fremovable-3-sided-beam-pad-bp3s22so2-HQ1.jpg?alt=media&token=a6c4e6ae-fd81-4eb6-8a58-f50c437400e1",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fremovable-3-sided-beam-pad-bp3s22so2-HQ2.jpg?alt=media&token=9054951c-8ba0-478f-baa8-4e898ab098ad",
        ]
    },{
        id: 'p324',
        name: `Removable 4-sided Pad`,
        sku: 'bp4s22so2',
        description: `Removable 3-sided Pads are designed to cover any type of rectangular post. Full protection from posts in active areas. Constructed with 2" or 3" polyfoam.`,
        details: [
            `Choose from 2" or 3" polyfoam padding`,
            `Designed to cover any type of rectangular post`,
            `Full protection from posts in any active area`,
        ],
        includes: [
            ``
        ],
        options: [
            `2" Polyfoam`,
            `BP4S22SO2: 4 Sided Beam Cover - 14 oz FR Vinyl`,
            `BP4S22SO2: 4 Sided Beam Cover - 18 oz Vinyl`,
            `3" Polyfoam`,
            `BP4S23SO2: 4 Sided Beam Cover - 14 oz FR Vinyl`,
            `BP4S23SO2: 4 Sided Beam Cover - 18 oz Vinyl`,
        ],
        usedBy: [],
        category: ' protective padding',
        subCategory: 'protective padding indoor padding',
        group: 'Beam Padding',
        urlCategory: ' protective padding',
        urlSubCategory: 'protective padding indoor padding',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fremovable-4-sided-beam-pad-bp4s22so2-HQ1.jpg?alt=media&token=e2ad764f-ddb9-4000-9078-ef3f16fcf081",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fremovable-4-sided-beam-pad-bp4s22so2-HQ1.jpg?alt=media&token=e2ad764f-ddb9-4000-9078-ef3f16fcf081",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fremovable-4-sided-beam-pad-bp4s22so2-HQ2.jpg?alt=media&token=43aa90ca-4904-4f86-888d-4eb04b10f09f",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fremovable-4-sided-beam-pad-bp4s22so2-HQ4.jpg?alt=media&token=0b6f61d4-ebdf-4de0-98f0-89907e30228f",
        ]
    },{
        id: 'p325',
        name: `Wall Rough`,
        sku: 'dpwallrough',
        description: `Wall Rough can easily be installed over painted cinder block, sheetrock, brick, and most other surfaces. Let Fisher Athletic transform your sports facility. Our digital wall graphics create an environment that inspires, motivates, and promotes team spirit.`,
        details: [
            `Can be applied to most rough surfaces`,
            `Customize with your own artwork or let Fisher Athletic design it for you`,
        ],
        includes: [
            ``
        ],
        options: [
            `DPWALLROUGH: Digitally Printed Rough Wall Graphics`,
        ],
        usedBy: [],
        category: 'facility branding',
        subCategory: 'adhesive graphics',
        group: 'Wall Graphics',
        urlCategory: 'facility branding',
        urlSubCategory: 'adhesive graphics',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fdpwallrough-gatorlockers.jpg?alt=media&token=49ace2e5-07bb-432b-9dee-c45c85a49d68",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fdpwallrough-gatorlockers.jpg?alt=media&token=49ace2e5-07bb-432b-9dee-c45c85a49d68",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fdpwallrough-benedictine.jpg?alt=media&token=8a7904cb-c37d-4c5f-a051-1f21445fda12",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fdpwallrough-kingstree.jpg?alt=media&token=4b91cc68-b317-414e-93a7-c71e4b02c7ea",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fdpwallrough-freeman.jpg?alt=media&token=e99159e7-b9e2-40df-a43d-353791c37423",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fdpwallrough-union.jpg?alt=media&token=deca915a-c4cc-4576-9ca9-17356d69dd19",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fdpwallrough-bishopoc.jpg?alt=media&token=5a7ff5a9-a4b8-48f2-ab38-933d1cda11d7",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fwall-rough-dpwallrough-HQ1.jpg?alt=media&token=0f5dab77-3520-4f1c-9624-e17c49a82958",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fwall-rough-dpwallrough-HQ2.jpg?alt=media&token=97dd3209-e8f2-4b9e-bf6e-33b08596988c",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fwall-rough-dpwallrough-HQ3.jpg?alt=media&token=3480ad15-2037-444e-95b7-59d8f9cf84cb",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fwall-rough-dpwallrough-HQ4.jpg?alt=media&token=af3ac9ad-ab45-4bb1-beb8-68a555070bfd",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fwall-rough-dpwallrough-HQ5.jpg?alt=media&token=6ad9f1dc-46fd-4354-a328-aa73c1e66ea4",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fwall-rough-dpwallrough-HQ17.jpg?alt=media&token=953c62e8-1afc-442a-8764-5e3ed5ea41c1",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fwall-rough-dpwallrough-HQ6.jpg?alt=media&token=a91033c1-f783-4a3c-9c8f-9abfd12a93a3",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fwall-rough-dpwallrough-HQ7.jpg?alt=media&token=fe63da23-95c7-4bf7-b0c9-85e591a4c238",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fwall-rough-dpwallrough-HQ8.jpg?alt=media&token=97940a98-5201-44c8-bd4a-4dd008e43aab",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fwall-rough-dpwallrough-HQ9.jpg?alt=media&token=29586033-2c6c-4141-a58b-5b1ea0db9bbe",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fwall-rough-dpwallrough-HQ10.jpg?alt=media&token=7ff0b0b3-64d6-49da-94ef-5fbc8a81ee9f",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fwall-rough-dpwallrough-HQ11.jpg?alt=media&token=5f4743c1-af20-4a8f-93f3-4de62d28a6a6",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fwall-rough-dpwallrough-HQ12.jpg?alt=media&token=072af046-4604-4b49-808e-2b53333ff7f8",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fwall-rough-dpwallrough-HQ13.jpg?alt=media&token=1a7f95b4-d00a-42a9-bc6e-9925eaba6dc1",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fwall-rough-dpwallrough-HQ14.jpg?alt=media&token=d9b144df-6aac-4c55-9235-dd636efca400",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fwall-rough-dpwallrough-HQ15.jpg?alt=media&token=a22ef842-a713-425e-8345-f463addabd9a",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fwall-rough-dpwallrough-HQ16.jpg?alt=media&token=a9aad9ec-cb9b-4c79-882d-ad09dc6b9997"
        ]
    },{
        id: 'p326',
        name: `Wall Smooth`,
        sku: 'dpwallsmooth',
        description: `Wall Smooth brings vibrant graphics to most smooth surfaces. Let Fisher Athletic transform your sports facility. Our digital wall graphics create an environment that inspires, motivates, and promotes team spirit.`,
        details: [
            `Can be applied to most smooth surfaces`,
            `Customize with your own artwork or let Fisher Athletic design it for you`,
        ],
        includes: [
            ``
        ],
        options: [
            `DPWALLSMOOTH: Digitally Printed Smooth Wall Graphics`,
        ],
        usedBy: [],
        category: 'facility branding',
        subCategory: 'adhesive graphics',
        group: 'Wall Graphics',
        urlCategory: 'facility branding',
        urlSubCategory: 'adhesive graphics',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fwall-smooth-dpwallsmooth-HQ1.jpg?alt=media&token=6fdf61d8-73a8-4aa1-8ed8-9e0e0c190735",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fwall-smooth-dpwallsmooth-HQ1.jpg?alt=media&token=6fdf61d8-73a8-4aa1-8ed8-9e0e0c190735",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fwall-smooth-dpwallsmooth-HQ2.jpg?alt=media&token=89da8793-4156-40b2-88ff-7979aabe0b33",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fdpwallsmooth-nmb.jpg?alt=media&token=c42a9928-f451-48c6-964c-2ed376b4d604",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fwall-smooth-dpwallsmooth-HQ19.jpg?alt=media&token=d1066291-b459-44e6-99e6-74f9f1c64898",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fwall-smooth-dpwallsmooth-HQ20.jpg?alt=media&token=4e19da27-6694-41e9-8336-3ccb2c89362f",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fwall-smooth-dpwallsmooth-HQ3.jpg?alt=media&token=e611d86a-c151-4404-bd72-a196fa18231f",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fwall-smooth-dpwallsmooth-HQ4.jpg?alt=media&token=a09b75cb-3250-4c8d-bacc-e7fcc59daedf",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fwall-smooth-dpwallsmooth-HQ5.jpg?alt=media&token=ce81ff8d-20d2-4d91-b11e-eea4f121fe6b",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fwall-smooth-dpwallsmooth-HQ6.jpg?alt=media&token=cebc91bd-9f1b-4734-8cec-931f333e891d",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fwall-smooth-dpwallsmooth-HQ7.jpg?alt=media&token=66bcb88f-b0b8-4e0c-a97b-5821114b8ea6",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fwall-smooth-dpwallsmooth-HQ8.jpg?alt=media&token=2140e03e-46d8-49a1-b6af-87ddbc75d606",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fwall-smooth-dpwallsmooth-HQ9.jpg?alt=media&token=975a9b21-90c8-4ad3-a903-3574174b875f",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fwall-smooth-dpwallsmooth-HQ10.jpg?alt=media&token=d527cf8c-dbc8-4181-8383-4fa1c297badf",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fwall-smooth-dpwallsmooth-HQ11.jpg?alt=media&token=c0a1c0ba-f7e5-481a-ac8a-8212486d4c71",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fwall-smooth-dpwallsmooth-HQ12.jpg?alt=media&token=da17d954-b582-402f-995b-c33442ff6e32",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fwall-smooth-dpwallsmooth-HQ13.jpg?alt=media&token=c101c409-2242-4f2e-8606-5cbdec8de2b9",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fwall-smooth-dpwallsmooth-HQ14.jpg?alt=media&token=1b924bba-5583-4890-b78b-d306558bf22a",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fwall-smooth-dpwallsmooth-HQ15.jpg?alt=media&token=76e4249a-7e3f-4a66-b12c-35209135cfaa",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fwall-smooth-dpwallsmooth-HQ16.jpg?alt=media&token=e0819a5d-99a1-4f10-a2e4-d616843273ad",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fwall-smooth-dpwallsmooth-HQ17.jpg?alt=media&token=0d912650-5398-46a0-ab79-133086bc1148",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fwall-smooth-dpwallsmooth-HQ18.jpg?alt=media&token=eccf0ef5-1353-41f9-9425-70a00820f224"
        ]
    },{
        id: 'p327',
        name: `Window Perf`,
        sku: 'dpwinmesh',
        description: `Window Perf creates a new and innovative media space. It is great for advertisements, fundraising, promoting school spirit, updating the look of your facilities and more. It is an aide in reducing heat and glare from sunlight. Our Window Perf is digitally printed which allows our designers to make your vision a reality.`,
        details: [
            `Can help reduce heat and glare from the sun`,
            `Add your own custom artwork or let Fisher Athletic design it for you`,
            `60/40 vinyl to holes ratio`,
        ],
        includes: [
            ``
        ],
        options: [
            `DPWINMESH: Digitally Printed Window Mesh`,
        ],
        usedBy: [],
        category: 'facility branding',
        subCategory: 'adhesive graphics',
        group: '',
        urlCategory: 'facility branding',
        urlSubCategory: 'adhesive graphics',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fdpwinmesh-fauquier.jpg?alt=media&token=31bf38fb-2cfa-4cc9-a062-88be7a67020b",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fdigitally-printed-window-perf-dpwinmesh-HQ1.jpg?alt=media&token=8a9a30b6-ee07-45db-a568-a0bd264b502f",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fdpwinmesh-fauquier.jpg?alt=media&token=31bf38fb-2cfa-4cc9-a062-88be7a67020b",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fdigitally-printed-window-perf-dpwinmesh-HQ2.jpg?alt=media&token=84ee4a3d-6004-46e7-b045-bd0505e082aa",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fdigitally-printed-window-perf-dpwinmesh-HQ3.jpg?alt=media&token=b6947b9c-1f3a-48ae-aa2f-9f759262fc07",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fdpwinmesh-east-yancy.jpg?alt=media&token=c6fa514a-be1d-4064-ac70-eb6a7f04e01d",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fdpwinmesh-varina.jpg?alt=media&token=294a3a9c-c0a1-4d28-8a11-cce65abcc018",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fdigitally-printed-window-perf-dpwinmesh-HQ4.jpg?alt=media&token=f9632ab6-7654-459c-8713-30529d7323df",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fdigitally-printed-window-perf-dpwinmesh-HQ5.jpg?alt=media&token=524bed1f-9b01-4f3c-9468-de8817fc9e6e",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fdigitally-printed-window-perf-dpwinmesh-HQ19.jpg?alt=media&token=2ff730ef-d438-473e-bf26-3d9d2267a86c",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fdigitally-printed-window-perf-dpwinmesh-HQ6.jpg?alt=media&token=9fffe2ad-ce4f-4c9b-ab49-cdc737c9b5c5",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fdigitally-printed-window-perf-dpwinmesh-HQ7.jpg?alt=media&token=3071a2c7-2382-41a5-9a6b-c3938de27080",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fdigitally-printed-window-perf-dpwinmesh-HQ8.jpg?alt=media&token=aa069a5a-e599-478b-9a76-07a4fb2a4dcc",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fdigitally-printed-window-perf-dpwinmesh-HQ9.jpg?alt=media&token=c2379683-e21c-4298-826e-9988f1d75daf",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fdigitally-printed-window-perf-dpwinmesh-HQ10.jpg?alt=media&token=460d0c15-b720-483c-85eb-cf9502f9478a",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fdigitally-printed-window-perf-dpwinmesh-HQ11.jpg?alt=media&token=617c596b-d2cc-4b28-aa47-28d68f69afd2",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fdigitally-printed-window-perf-dpwinmesh-HQ13.jpg?alt=media&token=55ab3a23-70ca-4ec7-93d1-2fecf76f964d",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fdigitally-printed-window-perf-dpwinmesh-HQ14.jpg?alt=media&token=a80f4855-8aa5-4d63-9866-3b97e8a01de9",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fdigitally-printed-window-perf-dpwinmesh-HQ15.jpg?alt=media&token=1ad09a8e-ff3d-4c8a-8ed7-0a3db1d4ee42",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fdigitally-printed-window-perf-dpwinmesh-HQ16.jpg?alt=media&token=f0736ffe-b9d8-4549-9453-7beb57de1a93",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fdigitally-printed-window-perf-dpwinmesh-HQ17.jpg?alt=media&token=bca41874-fdee-4a2f-9f50-4e2f7438f88d",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fdigitally-printed-window-perf-dpwinmesh-HQ18.jpg?alt=media&token=4c06bfa6-ef98-441c-8e8e-e88b3c44fae6",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fdpwinmesh-lake-city.jpg?alt=media&token=aee16b7a-0f23-44c5-ac09-b8fcb45a76ca",
        ]
    },{
        id: 'p328',
        name: `Armor Wrap`,
        sku: 'dpaw100',
        description: `Armor Wrap is easy to install and help boost your branding in any facility. Our Armor Wrap is heavy duty, non-skid aluminum sticker material for use on asphalt, brick, or concrete surfaces. The aluminum is superior to vinyl for tough tough surfaces.`,
        details: [
            `Extremely safe`,
            `Easy installation`,
            `Sweep, peel, and stick`,
            `Non-skid aluminum sticker material`,
            `Use on asphalt, brick, or concrete surfaces`,
        ],
        includes: [
            ``
        ],
        options: [
            `DPAW100: Armor Wrap - Outdoor Vinyl`,
        ],
        usedBy: [],
        category: 'facility branding',
        subCategory: 'adhesive graphics',
        group: '',
        urlCategory: 'facility branding',
        urlSubCategory: 'adhesive graphics',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Farmor-wrap-dpaw100-HQ1.jpg?alt=media&token=8349e6ac-d909-43b5-af44-2044ffb14bd6",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Farmor-wrap-dpaw100-HQ1.jpg?alt=media&token=8349e6ac-d909-43b5-af44-2044ffb14bd6",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Farmor-wrap-dpaw100-HQ2.jpg?alt=media&token=3bc85ba8-9e94-4dd0-8ea9-af2f9c3a6e6c",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Farmor-wrap-dpaw100-HQ3.jpg?alt=media&token=caf1f5c5-a982-4d85-8c04-b9ede72612a8",
        ]
    },{
        id: 'p329',
        name: `Swag Mat`,
        sku: 'dpsmso',
        description: `Swag Mats are great for branding any facility. Use them in sports arenas, trade shows, locker rooms, or gymnasiums. Back printed graphics prevent wear on your artwork.`,
        details: [
            `Anti-skid backing`,
            `Can be placed on any surface`,
            `Easily rolled up for storage`,
            `Artwork included`,
            `Back printed graphics prevent wear on your artwork`,
            `Protects floors from scuffs`,
            `Easy clean with mild detergent or disinfectant cleaner`,
        ],
        includes: [
            ``
        ],
        options: [
            `DPSMSO: Custom Size Swag Mat`,
            `DPSM44: 4' x 4' Swag Mat`,
            `DPSM45: 4' x 5' Swag Mat`,
            `DPSM46: 4' x 6' Swag Mat`,
            `DPSM48: 4' x 8' Swag Mat`,
            `DPSM410: 4' x 10' Swag Mat`,
            `DPSM412: 4' x 12' Swag Mat`,
            `DPSM425: 4' x 25' Swag Mat`,
            `DPSM430: 4' x 30' Swag Mat`,
            `DPSM325: 3' x 25' Swag Mat`,
            `DPSM330: 3' x 30' Swag Mat`,
        ],
        usedBy: [],
        category: 'facility branding gym Chairs and Stools',
        subCategory: 'floor gym accessories swag mats',
        group: '',
        urlCategory: 'facility branding gym chairs and stools',
        urlSubCategory: 'floor gym accessories swag mats',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fswag-mat-dpsmso-2400-1.jpg?alt=media&token=e9995b42-d538-491f-b356-075ae5dd8a4b",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fswag-mat-dpsmso-2400-1.jpg?alt=media&token=e9995b42-d538-491f-b356-075ae5dd8a4b",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fswag-mat-dpsmso-2400-2.jpg?alt=media&token=06af22e8-2207-444f-9cc2-0f3f5cb94497",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fswag-mat-dpsmso-mc.jpg?alt=media&token=98e9df3d-4fc4-4d68-b9f9-bdd66eed42fd",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fswag-mat-dpsmso-2400-3.jpg?alt=media&token=07873e0d-b0d6-4e2d-a72d-04529a8627e7",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fswag-mat-dpsmso-2400-4.jpg?alt=media&token=5dccd3af-40f5-417f-8fb5-c810c559b5e0",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fswag-mat-dpsmso-2400-5.jpg?alt=media&token=5f24a085-73ff-4706-8f76-e71925a2bd48"
        ]
    },{
        id: 'p330',
        name: `Scorers Table`,
        sku: 'st25b',
        description: `Scorers Tables are a great way to brand your facility. Artwork is added at no additional charge.`,
        details: [
            `Can mount to bleacher or floor`,
            `3 grometted cable holes`,
            `3" rubber casters`,
            `6 outlet power strip`,
            `Interior shelving`,
            `20 vinyl colors available`,
            `2" thick padding (30 oz)`,
            `2 cup holder per 5' section`,
            `Any table 10' or longer can be broken up into 5' sections for storage purposes`,
        ],
        includes: [
            ``
        ],
        options: [
            `Bleacher Mount Table`,
            `ST5B: 5' frame`,
            `ST10B: 10' frame`,
            `ST15B: 15' frame`,
            `ST20B: 20' frame`,
            `ST25B: 25' frame`,
            `Floor Mount Table`,
            `ST5F: 5' frame`,
            `ST10F: 10' frame`,
            `ST15F: 15' frame`,
            `ST20F: 20' frame`,
            `ST25F: 25' frame`,
            `Storage Covers`,
            `ST5FCO: 5' Floor Mount Cover`,
            `ST5BCO: 5' Bleacher Mount Cover`,
            `ST10FCO: 10' Floor Mount Cover`,
            `ST10BCO: 10' Bleacher Mount Cover`,
            `Possession Arrow`,
            `ST10PA: Possession Arrow`,
        ],
        usedBy: [],
        category: 'facility branding gym',
        subCategory: 'floor gym accessories',
        group: 'Scorers Table',
        urlCategory: 'facility branding gym',
        urlSubCategory: 'floor gym accessories',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fscorers-table-st5f-HQ1.jpg?alt=media&token=f32bf015-caa7-46bf-ad46-bf9479b180fa",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fscorers-table-st5f-HQ1.jpg?alt=media&token=f32bf015-caa7-46bf-ad46-bf9479b180fa",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fscorers-table-st5f-HQ2.jpg?alt=media&token=04d6e869-b408-431a-9b46-b29708a2c631",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fscorers-table-st5f-HQ3.jpg?alt=media&token=c47e11c7-b1dd-4991-8a14-871cda1ae2d8",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fscorers-table-st5f-HQ4.jpg?alt=media&token=ede14e33-f73f-4a4c-bec9-3c90abea019a",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fscorers-table-st5f-HQ5.jpg?alt=media&token=33264bbf-7e5f-465d-9def-8a8aa5b65e95",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fscorers-table-st5f-HQ6.jpg?alt=media&token=1525167c-c336-47eb-9b72-205f918bfce0",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fscorers-table-st5f-HQ7.jpg?alt=media&token=8ea7efd5-3e9c-469a-8256-a0c704811d49",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fscorers-table-st5f-HQ8.jpg?alt=media&token=b4653f06-40eb-49e7-8ff6-4e1b11d04a33",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fscorers-table-st5f-HQ9.jpg?alt=media&token=6b087d1f-807d-4ba0-b17b-3ec53d620797",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fscorers-table-st5f-HQ10.jpg?alt=media&token=371b458c-21a1-45db-8e92-98f144e59a77",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fscorers-table-st5f-HQ11.jpg?alt=media&token=b0e15be1-f02b-43af-a0c0-9f70b7c9eb4a",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fscorers-table-st5f-HQ12.jpg?alt=media&token=534fca45-d390-4e16-811a-42be236a8dd1",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fscorers-table-st5f-HQ13.jpg?alt=media&token=ecb152c1-5412-413d-9ca9-7b502c046ebe",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fscorers-table-st5f-HQ14.jpg?alt=media&token=3a656fa0-6642-4e13-a31d-a6da09720b48",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fscorers-table-st5f-HQ15.jpg?alt=media&token=e7ef1a03-9a62-4257-944b-38ee4f196ac7",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fscorers-table-st5f-HQ16.jpg?alt=media&token=323b77ef-ab82-43f1-bb80-a1443c831966",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fscorers-table-st5f-HQ17.jpg?alt=media&token=dd76dfa7-af1f-4b12-9c25-c52d37c306a3",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fscorers-table-st5f-HQ18.jpg?alt=media&token=2dc8c238-0155-4f99-8f9a-6da0fc6cfeb9",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpossession-arrow-st10pa-HQ1.jpg?alt=media&token=9eaf98e7-a1b3-4e87-9969-f8989eed69ac"
        ]
    },{
        id: 'p331',
        name: `Possession Arrow`,
        sku: 'st10pa',
        description: `Features bright illuminated directional arrows.`,
        details: [
            ``
        ],
        includes: [
            ``
        ],
        options: [
            `ST10PA: Possession Arrow`,
        ],
        usedBy: [],
        category: 'facility branding gym',
        subCategory: 'floor gym accessories',
        group: 'Scorers Table',
        urlCategory: 'facility branding gym',
        urlSubCategory: 'floor gym accessories',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpossession-arrow-st10pa-HQ1.jpg?alt=media&token=9eaf98e7-a1b3-4e87-9969-f8989eed69ac",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpossession-arrow-st10pa-HQ1.jpg?alt=media&token=9eaf98e7-a1b3-4e87-9969-f8989eed69ac"
        ]
    },{
        id: 'p332',
        name: `Temperaplex Wall Sign`,
        sku: 'dparc25',
        description: `A great alternative to glass. These signs offer a modern solution for indoor or outdoor signage.`,
        details: [
            `Mounted with stand-offs`,
            `Temperaplex material is transparent`,
            `Stand-offs raise your sign off the wall for an eye-catching look`,
        ],
        includes: [
            ``
        ],
        options: [
            `Temperaplex Wall Sign`,
            `DPARC25: Temperaplex Wall Sign`,
            `Mounting`,
            `DPSTD1: 3/4" Dia. - 1" Depth (pack of 12 stand-offs)`,
        ],
        usedBy: [],
        category: 'facility branding',
        subCategory: 'signage',
        group: '',
        urlCategory: 'facility branding',
        urlSubCategory: 'signage',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ftemperaplex-wall-sign-dparc25-HQ1.jpg?alt=media&token=6f5886c4-e6d1-4746-bfdb-acef9010fa75",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ftemperaplex-wall-sign-dparc25-HQ1.jpg?alt=media&token=6f5886c4-e6d1-4746-bfdb-acef9010fa75",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ftemperaplex-wall-sign-dparc25-HQ2.jpg?alt=media&token=b5f736ae-8221-4bb7-819c-e10632edb684",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ftemperaplex-wall-sign-dparc25-HQ3.jpg?alt=media&token=439be139-dc1d-4d85-b263-0bd95519e59b",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ftemperaplex-wall-sign-dparc25-HQ4.jpg?alt=media&token=67edda1f-7347-483e-90f9-5b7fb3754c7b",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ftemperaplex-wall-sign-dparc25-HQ5.jpg?alt=media&token=c83ce6ba-55ae-4024-96cd-56caf93b6ec7",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ftemperaplex-wall-sign-dparc25-HQ6.jpg?alt=media&token=a0afe3f8-0e2b-4970-a419-b35da8f95951"
        ]
    },{
        id: 'p334',
        name: `Name Plate`,
        sku: 'np28',
        description: `Locker Name Plates are manufactured using your team's colors, logos, and customized with each individual player's name and/or number. Pick from a template or design your own. Name Plates can be reordered year after year at a reduced cost, as long as the template has not changed. Custom sizes and designs are available with an upcharge based on design.`,
        details: [
            `Add your team's color, logo, and names`,
            `Choose from PVC, magnetic, or decal material`,
        ],
        includes: [
            ``
        ],
        options: [
            `PVC (velcro backing)`,
            `NP28: 2" x 8" PVC Name Plate`,
            `NP2110: 2 1/2" x 10" PVC Name Plate`,
            `NP312: 3" x 12" PVC Name Plate`,
            `NP416: 4" x 16" PVC Name Plate`,
            `Magnetic`,
            `NP28M: 2" x 8" Magnetic Name Plate`,
            `NP2110M: 2 1/2" x 10" Magnetic Name Plate`,
            `NP312M: 3" x 12" Magnetic Name Plate`,
            `NP416M: 4" x 16" Magnetic Name Plate`,
            `Decal`,
            `NP28D: 2" x 8" Decal Name Plate`,
            `NP21100: 2 1/2" x 10" Decal Name Plate`,
            `NP312D1: 3" x 12" Decal Name Plate`,
            `NP412D: 4" x 16" Decal Name Plate`,
        ],
        usedBy: [],
        category: 'facility branding',
        subCategory: 'signage',
        group: '',
        urlCategory: 'facility branding',
        urlSubCategory: 'signage',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fname-plates-np28-HQ1.jpg?alt=media&token=fb3d8924-5dfe-4de2-a14e-a3b09f22579a",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fname-plates-np28-HQ1.jpg?alt=media&token=fb3d8924-5dfe-4de2-a14e-a3b09f22579a",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fname-plates-np28-HQ2.jpg?alt=media&token=88aa5eb8-fd2a-499b-9f96-918d42499182",
        ]
    },{
        id: 'p335',
        name: `Media Banner`,
        sku: 'mb610',
        description: `Media Banners are a unique way to help create an environment that promotes team spirit. It is a great way to bring excitement around your signing day or any other special event. We can provide countless printing options to add a professional look to your media events.`,
        details: [
            `Great for signing day`,
            `Add custom artwork of your choice`,
            `Mounting options include: grommets, backdrop stand, and c-channel`,
        ],
        includes: [
            ``
        ],
        options: [
            `MB610: 6' x 10' Media Banner`,
            `MB712: 7' x 12' Media Banner`,
            `MB814: 8' x 14' Media Banner`,
            `MBSO: Custom Size`,
            `STAND: Backdrop Stand for Media Banner`,
        ],
        usedBy: [],
        category: 'facility branding',
        subCategory: 'banners',
        group: '',
        urlCategory: 'facility branding',
        urlSubCategory: 'banners',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fmedia-banner-mb610-HQ1.jpg?alt=media&token=4ab69731-4aa1-4e79-98ab-396877b82a6f",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fmedia-banner-mb610-duluth.jpg?alt=media&token=0d0ed932-f6ed-4e75-9e13-7595c5eb1a27",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fmedia-banner-mb610-HQ1.jpg?alt=media&token=4ab69731-4aa1-4e79-98ab-396877b82a6f",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fmedia-banner-mb610-HQ2.jpg?alt=media&token=4d4d32d1-8d26-4f9e-bfb7-99b82e804ba5",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fmedia-banner-mb610-HQ3.jpg?alt=media&token=a2322f3f-da84-4593-98c4-e7eadd7df9ec",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fmedia-banner-mb610-HQ4.jpg?alt=media&token=02f56825-1910-40b0-8687-cfdf8c50ef9b",
        ]
    },{
        id: 'p336',
        name: `Table Drape`,
        sku: 'td6',
        description: `Table Draps are a unique way to help create an environment that promotes team spirit. It is a great way to bring excitement around your signing day or any other special event. We can provide countless printing options to add a professional look to your media events.`,
        details: [
            `Great for signing day`,
            `Add custom artwork of your choice`,
        ],
        includes: [
            ``
        ],
        options: [
            `TD6: 6' Table Drape`,
            `TD8: 8' Table Drape`,
        ],
        usedBy: [],
        category: 'facility branding',
        subCategory: 'banners',
        group: '',
        urlCategory: 'facility branding',
        urlSubCategory: 'banners',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ftable-drape-td6-HQ1.jpg?alt=media&token=23293f6e-b06b-4d7f-95ca-26cb64e16767",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ftable-drape-td6-HQ1.jpg?alt=media&token=23293f6e-b06b-4d7f-95ca-26cb64e16767",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ftable-drape-td6-HQ2.jpg?alt=media&token=42bf80aa-b277-4b21-96f7-2fcda3286dba",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ftable-drape-td6-HQ3.jpg?alt=media&token=350c67ef-bab0-48a0-9d0e-2a36ffd4222f",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fmedia-banner-mb610-HQ4.jpg?alt=media&token=02f56825-1910-40b0-8687-cfdf8c50ef9b",
        ]
    },{
        id: 'p337',
        name: `Championship Banners`,
        sku: 'banrtv46',
        description: `Digitally printed vinyl Championship Banners are a perfect way to display your teams success throughout the school. Our banners are made out of 18 oz vinyl that will last through the years. Let our designers design your custom banners to show off your school spirit.`,
        details: [
            `Made with 18 oz vinyl`,
            `Choose from rectangle or pennant shape`,
            `Mounts with c-channel`,
        ],
        includes: [
            ``
        ],
        options: [
            `BANRTV46: 4' x 6' Banner`,
            `BANRTV64: 6' x 4' Banner`,
            `BANRTV47: 4' x 7' Pennant`,
            `BANRTV35: 3' x 5' Pennant`,
        ],
        usedBy: [],
        category: 'facility branding',
        subCategory: 'banners',
        group: '',
        urlCategory: 'facility branding',
        urlSubCategory: 'banners',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fchampionship-banners-banrtv46-HQ1.jpg?alt=media&token=83cb0d3a-03d1-407a-a0bf-ffe6e9f59483",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fchampionship-banners-banrtv46-HQ1.jpg?alt=media&token=83cb0d3a-03d1-407a-a0bf-ffe6e9f59483",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fchampionship-banners-banrtv46-HQ2.jpg?alt=media&token=e27e9a63-a63a-4889-af5d-684bb702565c",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fc-channel-mounting-HQ1.jpg?alt=media&token=cf4a989e-ac7b-4f68-97da-0bc273683b89",
        ]
    },{
        id: 'p338',
        name: `Gym Floor Cover`,
        sku: 'gfc14',
        description: `Protect your expensive gym floor with a Fisher Gym Floor Cover. Ideal for commencements, dances, concerts, banquets... any event.`,
        details: [
            `Choose from 14 oz or 18 oz vinyl`,
            `Long lasting`,
            `Washable`,
            `Tan or gray recommended (these colors do not show dirt and marking as much as darker colors)`,
            `Fire retardant material available`,
            `Mildew resistant`,
            `Waterproof`,
            `Available in 5' and 10' widths`,
        ],
        includes: [
            ``
        ],
        options: [
            `14 oz Vinyl`,
            `GFC14: Gym Floor Cover - Fire Retardant`,
            `18 oz Vinyl`,
            `GFC18: Gym Floor Cover`,
        ],
        usedBy: [],
        category: 'gym',
        subCategory: 'gym accessories',
        group: '',
        urlCategory: 'gym',
        urlSubCategory: 'gym accessories',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fgym-floor-cover-gfc14-HQ1.jpg?alt=media&token=9b2902d4-c127-4a96-9164-2c4d061c8bb2",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fgym-floor-cover-gfc14-HQ1.jpg?alt=media&token=9b2902d4-c127-4a96-9164-2c4d061c8bb2",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fgym-floor-cover-gfc14-HQ2.jpg?alt=media&token=66a1a66a-dbd3-4c15-9c86-9dfb26f4288d"
        ]
    },{
        id: 'p339',
        name: `Gym Floor Cart`,
        sku: 'gfccf',
        description: `The Fisher Gym Floor Cart is the perfect solution for storing your gym floor covers while they are not in use. The cart can hold up to ten rolls of vinyl and is the perfect fit for any size gym. Wheels are 6" in diameter and feature locking swivel casters that allow for easy mobility and will not scratch gym floors.`,
        details: [
            `Gym floor cover cart with steel welded frame`,
            `Locking swivel casters - 6" diameter`,
            `Holds up to (10) 10' x 120' rolls of material`,
            `Dimensions: 35"W x 144"L x 53"H`,
            `Weight: 700 lbs`,
        ],
        includes: [
            ``
        ],
        options: [
            `Cart`,
            `GFCCF: Gym Floor Cart`,
            `Cart Accessories`,
            `GFCCR: Roller System (up to 10 per cart)`,
            `GFC1000MTR: Optional Power Winder`,
            `GFC1000BR: Optional Brush (cleans vinyl as it is stored)`,
        ],
        usedBy: [],
        category: 'gym',
        subCategory: 'gym accessories',
        group: '',
        urlCategory: 'gym',
        urlSubCategory: 'gym accessories',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fgym-floor-cart-gfccf-HQ1.jpg?alt=media&token=89e7772f-8fec-49f1-a6e8-fe32386a75e8",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fgym-floor-cart-gfccf-HQ1.jpg?alt=media&token=89e7772f-8fec-49f1-a6e8-fe32386a75e8",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fgym-floor-cart-gfccf-HQ2.jpg?alt=media&token=42fd0cad-705a-4e5b-8ff5-250e87d8a62e",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fgym-floor-cart-gfccf-HQ3.jpg?alt=media&token=0dd3eacb-4bad-42a5-9f36-456ed596ac23",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fgym-floor-cart-gfccf-HQ4.jpg?alt=media&token=95ffe8a8-448f-4c9e-91f8-f7ee8fdfde45",
        ]
    },{
        id: 'p340',
        name: `Gym Divider Curtain`,
        sku: 'gdc9',
        description: `Make the most economical use of your gym facilities with Fisher Gym Divider Curtains.`,
        details: [
            `Durable and long lasting`,
            `Heavy duty grommets every 18"`,
            `Welded seams`,
            `Sewn edges`,
            `Mildew resistant`,
            `4 different styles`,
        ],
        includes: [
            ``
        ],
        options: [
            `Gym Divider Curtains`,
            `GDC9: Vinyl Coated Nylon Mesh - Vinyl Nylon Borders for Strength`,
            `GDC192: 14 oz Vinyl Coated Nylon Mesh Top - Solid Vinyl Bottom - Vinyl Nylon Borders for Strength`,
            `GDC193: 18 oz Vinyl Coated Nylon Mesh Top - Solid Vinyl Bottom - Vinyl Nylon Borders for Strength`,
            `GDC2: 14 oz Solid Vinyl Curtain`,
            `GDC3: 18 oz Solid Vinyl Curtain`,
            `GDCC: Chain Weight Bottom (can be added to any of our gym divider curtains)`,
            `Mounting`,
            `7710K14: 7' Rail Track`,
            `7710K83: Threaded Rod Track Support`,
            `7710K84: Threaded Rod Split Track Support`,
            `7710K32: Track End Stops`,
            `7710K42: 1" Roller`,
        ],
        usedBy: [],
        category: 'gym',
        subCategory: 'gym accessories',
        group: '',
        urlCategory: 'gym',
        urlSubCategory: 'gym accessories',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fgym-divider-curtain-gdc9-HQ1.jpg?alt=media&token=42939d83-453c-4b5e-beaa-70f84a61bbf7",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fgym-divider-curtain-gdc9-HQ1.jpg?alt=media&token=42939d83-453c-4b5e-beaa-70f84a61bbf7",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fgym-divider-curtain-gdc9-HQ2.jpg?alt=media&token=decc0722-4fb6-4f32-98da-657365e31463",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fgym-divider-curtain-gdc9-HQ3.jpg?alt=media&token=d1b35b9d-3832-4992-98a4-68d550f51c47",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fgym-divider-curtain-gdc9-HQ4.jpg?alt=media&token=970773d4-19a4-4229-8668-4cf2c1c87c73",
        ]
    },{
        id: 'p341',
        name: `Volleyball Post Pad`,
        sku: 'fp172sq',
        description: `Fisher Volleyball Post Pads offer a clean, professional look to your school's volleyball court. Custom lettering and logos offer a great way to show your school spirit.`,
        details: [
            `Attached with Velcro hook and loop to allow easy removal and storage when not in use`,
            `Fits most volleyball court equipment`,
            `72"H x 12"W padding designed for best player protection in the business`,
            `3 sided pad with 2" thick foam padding`,
        ],
        includes: [
            ``
        ],
        options: [
            `Volleyball Post Pad`,
            `FP172SQ: 72"H x 12"W Single Post Pad`,
            `Artwork`,
            `FPL1: 1 Color Letters on 2 Sides`,
            `FPL11: 1 Color Letters on 1 Side`,
            `FPL2: 2 Color Letters on 2 Sides`,
            `FPL12: 2 Color Letters on 1 Side`,
        ],
        usedBy: [],
        category: 'gym',
        subCategory: 'gym accessories',
        group: '',
        urlCategory: 'gym',
        urlSubCategory: 'gym accessories',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fvolleyball-post-pad-fp172sq-HQ1.jpg?alt=media&token=34409ec8-0f31-40f4-afb7-18d0131bcfd9",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fvolleyball-post-pad-fp172sq-HQ1.jpg?alt=media&token=34409ec8-0f31-40f4-afb7-18d0131bcfd9",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fvolleyball-post-pad-fp172sq-HQ2.jpg?alt=media&token=3fb0db60-a8b5-4678-b982-d51f8fc279b8",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fvolleyball-post-pad-fp172sq-HQ3.jpg?alt=media&token=516a22d1-c2c8-4f92-8b37-78b277e3615d",
        ]
    },{
        id: 'p342',
        name: `Bleacher End Closures`,
        sku: 'becso',
        description: `Prevent traffic underneath bleachers and enhance the look of your facility with our vinyl bleacher end closures. Folds up with bleachers. Optional screen printing. 18 oz. vinyl / 18 colors. Custom logos and lettering available.`,
        details: [
            `Folds up with bleachers`,
            `Optional screen printing`,
            `Made with 18 oz vinyl`,
            `Custom logos and lettering available`,
        ],
        includes: [
            ``
        ],
        options: [
            `BECSO: Bleacher End Closure`,
        ],
        usedBy: [],
        category: 'gym',
        subCategory: 'gym accessories',
        group: '',
        urlCategory: 'gym',
        urlSubCategory: 'gym accessories',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fbleacher-end-closure-becso-HQ1.jpg?alt=media&token=332a0bb2-fc40-426f-bd8f-38e984ffb237",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fbleacher-end-closure-becso-HQ1.jpg?alt=media&token=332a0bb2-fc40-426f-bd8f-38e984ffb237",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fbleacher-end-closure-becso-HQ2.jpg?alt=media&token=1cb23de8-78e3-45ec-b886-af8f78184ed0"
        ]
    },{
        id: 'p343',
        name: `Basketball Goal Edge Padding`,
        sku: 'ep7215',
        description: `Fisher safety edge padding fits all manufacturers 72 inch wide glass backboards. The molded relief eliminates the need to trim the padding for the goal mounting plate. All attachment hardware is included. No gluing needed. One edge pad kit will do one 72' backboard. Kit contains pads and mounting hardware. Pad sets are 72' long and 15' high. Unconditional 5 year warranty (indoors only). Meets NCAA & National H.S. requirements. For indoor use only.`,
        details: [
            `One edge pad kit will do one 72" backboard`,
            `Kit contains pads and mounting hardware`,
            `Pad sets are 72" long and 15" high`,
            `Unconditional 5 year warranty (indoors only)`,
            `Meets NCAA & National H.S requirements`,
            `Available in 12 colors`,
            `For indoor use only`,
        ],
        includes: [
            ``
        ],
        options: [
            `EP7215: Basketball Goal Edge Padding Kit`,
        ],
        usedBy: [],
        category: 'gym',
        subCategory: 'gym accessories',
        group: '',
        urlCategory: 'gym',
        urlSubCategory: 'gym accessories',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fbasketball-goal-edge-padding-ep7215-HQ1.jpg?alt=media&token=26971060-6171-4a08-9350-8362aad8d5eb",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fbasketball-goal-edge-padding-ep7215-HQ1.jpg?alt=media&token=26971060-6171-4a08-9350-8362aad8d5eb",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fbasketball-goal-edge-padding-ep7215-HQ2.jpg?alt=media&token=8791904a-5b9d-45f0-a1e6-6a265f932d60",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fbasketball-goal-edge-padding-ep7215-HQ3.jpg?alt=media&token=60cfec64-1648-4e94-840a-30a99ce2d8bb"
        ]
    },{
        id: 'p344',
        name: `Ball Rack`,
        sku: 'kbbr15',
        description: `All welded 16 gauge steel tubing. Heavy duty 3" swivel casters. Holds 15 basketballs. Includes printing your school initials and mascot or name.`,
        details: [
            `All welded 16 gauge steel tubing`,
            `Heavy duty 3" swivel casters`,
            `Holds 15 basketballs`,
            `Includes printing your school initials, and mascot or name (7 1/2" sq. area)`,
            `Dimensions: 51 3/4"L x 20 1/2"W x 29"T`,
        ],
        includes: [
            ``
        ],
        options: [
            `KBBR15: Custom Ball Rack`,
        ],
        usedBy: [],
        category: 'gym',
        subCategory: 'gym accessories',
        group: '',
        urlCategory: 'gym',
        urlSubCategory: 'gym accessories',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fball-rack-kbbr15-HQ1.jpg?alt=media&token=5a548256-f567-4985-988e-6da6d96e9aa0",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fball-rack-kbbr15-HQ1.jpg?alt=media&token=5a548256-f567-4985-988e-6da6d96e9aa0",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fball-rack-kbbr15-HQ2.jpg?alt=media&token=610c91ab-5c47-4993-ba34-8ebfba12343e"
        ]
    },{
        id: 'p345',
        name: `Spirit Playmaker Plus`,
        sku: 'kbm100',
        description: `Durable ABS plastic board. Large half court diagram on back. Dry erase finish over court lines. Lines and clip in 9 colors.`,
        details: [
            `Durable ABS plastic board`,
            `Large half court diagram on back`,
            `Dry erase finish over court lines`,
            `Lines and clip in 9 colors`,
        ],
        includes: [
            ``
        ],
        options: [
            `KBM100: Spirit Playmaker Plus (9" x 15 1/2")`,
        ],
        usedBy: [],
        category: 'gym',
        subCategory: 'gym accessories',
        group: '',
        urlCategory: 'gym',
        urlSubCategory: 'gym accessories',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fspirit-playmaker-plus-kbm100-HQ1.jpg?alt=media&token=ef11e597-fe83-412e-b6bc-8327ac16b17d",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fspirit-playmaker-plus-kbm100-HQ1.jpg?alt=media&token=ef11e597-fe83-412e-b6bc-8327ac16b17d",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fspirit-playmaker-plus-kbm100-HQ2.jpg?alt=media&token=7470b066-037e-47ee-a81f-8c93a01ce703"
        ]
    },{
        id: 'p346',
        name: `The Slipp-Nott`,
        sku: 'kbsn1518',
        description: `Players simply walk across to improve traction. Leaves no residue on sneaker. Used by 23 of 27 professional teams and many top colleges. Includes 60 sheet mat pads.`,
        details: [
            `Players simply walk across to improve traction`,
            `Leaves no residue on sneaker`,
            `Used by 23 of 27 professional teams and many top colleges`,
            `Includes 60 sheet mat pads`,
        ],
        includes: [
            ``
        ],
        options: [
            `KBSN1518: 15" x 18" Base`,
            `KBSNP: 60 Sheet Pads`,
        ],
        usedBy: [],
        category: 'gym',
        subCategory: 'gym accessories',
        group: '',
        urlCategory: 'gym',
        urlSubCategory: 'gym accessories',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fthe-slipp-nott-kbsn1518-HQ1.jpg?alt=media&token=85f47904-b9cd-4fbc-9c92-c9d3ea2d5bcd",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fthe-slipp-nott-kbsn1518-HQ1.jpg?alt=media&token=85f47904-b9cd-4fbc-9c92-c9d3ea2d5bcd"
        ]
    },{
        id: 'p347',
        name: `Basketball Body Shield`,
        sku: 'bb100',
        description: `Maximum protection for the upper body. Excellent for training with restrained contact. Built using heavy-duty vinyl covered foam.`,
        details: [
            `Heavy-duty vinyl covered foam`,
            `Available in black or royal blue`,
        ],
        includes: [
            ``
        ],
        options: [
            `BB100: Basketball Body Shield (24" x 16" x 4") 3 lbs`,
            `BB150: Basketball Body Shield (30" x 18" x 4") 4 lbs`,
        ],
        usedBy: [],
        category: 'gym',
        subCategory: 'training items',
        group: '',
        urlCategory: 'gym',
        urlSubCategory: 'training items',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fbasketball-body-shield-bb100-HQ1.jpg?alt=media&token=737beaea-ae8e-473a-8e9b-3a93490480ac",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fbasketball-body-shield-bb100-HQ1.jpg?alt=media&token=737beaea-ae8e-473a-8e9b-3a93490480ac",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fbasketball-body-shield-bb100-HQ2.jpg?alt=media&token=0449b55b-3271-4e69-8e93-733cd25465ce",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fbasketball-body-shield-bb100-HQ3.jpg?alt=media&token=ec5724d7-ea24-4d76-8f55-ba2d1b991878"
        ]
    },{
        id: 'p348',
        name: `L Shield`,
        sku: 'kbl200',
        description: `Fisher Athletic's L Shield is an effective training aid for play in the post for basketball players.`,
        details: [
            `Available in blue`,
        ],
        includes: [
            ``
        ],
        options: [
            `KBL200: L Shield (30" x 18" x 1 1/2") 2 lbs`,
        ],
        usedBy: [],
        category: 'gym',
        subCategory: 'training items',
        group: '',
        urlCategory: 'gym',
        urlSubCategory: 'training items',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fl-shield-kbl200-HQ1.jpg?alt=media&token=85f98e81-d52c-44fd-b8c9-a81b24df77bf",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fl-shield-kbl200-HQ1.jpg?alt=media&token=85f98e81-d52c-44fd-b8c9-a81b24df77bf",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fl-shield-kbl200-HQ2.jpg?alt=media&token=1a401531-66e7-4eaf-98e8-3b53e40432f5"
        ]
    },{
        id: 'p349',
        name: `Tubby Club`,
        sku: 'tc18',
        description: `The Fisher Tubby Club, developed by legendary coach Tubby Smith, is designed to teach players to shoot the basketball with the proper arch and prevent blocked shots.`,
        details: [
            `Standard color 18 oz vinyl`,
            `Sturdy bonded 100 ILD foam for a long life`,
            `Rubber grip for ease of handling`,
        ],
        includes: [
            ``
        ],
        options: [
            `TC18: 18"L x 6"Diam. Tubby Club`,
            `TC24: 24"L x 6"Diam. Tubby Club`,
            `TC33: 33"L x 6"Diam. Tubby Club`,
        ],
        usedBy: [],
        category: 'gym',
        subCategory: 'training items',
        group: '',
        urlCategory: 'gym',
        urlSubCategory: 'training items',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ftubby-club-tc18-HQ1.jpg?alt=media&token=b780101b-a15f-40bd-9afa-d63169a11919",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ftubby-club-tc18-HQ1.jpg?alt=media&token=b780101b-a15f-40bd-9afa-d63169a11919",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Futility-stick-us326-HQ3.jpg?alt=media&token=4659a54f-79bd-44ac-8d57-459050d8761f"
        ]
    },{
        id: 'p350',
        name: `Folding Rebond Mat`,
        sku: 'fm3240632',
        description: `All rebond foam mats are manufactured using 2" thick, 6 lb density bonded foam and rugged 18 oz coated vinyl. The 2" bonded foam filler gives more support than our urethane mats, but is slightly less firm than our polyethylene mats. This mat is a good choice for elementary school age children, seniors, and for rehabilitation exercises. Specify color choices when ordering.`,
        details: [
            `Ideal for elementary school age children, seniors, and for rehabilitation exercises`,
            `2' wide folding panels for convenient storage`,
            `Seams are double stitched for durability`,
            `Configure multiple mats as needed with convenient Velcro strips`,
            `Available in one solid or contrasting colors`,
            `Choose from 18 colors`,
        ],
        includes: [
            ``
        ],
        options: [
            `4' x 6'`,
            `FM3240630: Without Velcro (33 lbs)`,
            `FM3240632: Velcro on end only (33 lbs)`,
            `FM3240634: Velcro on all sides (33 lbs)`,
            `4' x 8'`,
            `FM3240830: Without Velcro (44 lbs)`,
            `FM3240832: Velcro on end only (44 lbs)`,
            `FM3240834: Velcro on all sides (44 lbs)`,
            `5' x 10'`,
            `FM3251030: Without Velcro (69 lbs)`,
            `FM3251032: Velcro on end only (69 lbs)`,
            `FM3251034: Velcro on all sides (69 lbs)`,
            `6' x 12'`,
            `FM3261230: Without Velcro (99 lbs)`,
            `FM3261232: Velcro on end only (99 lbs)`,
            `FM3261234: Velcro on all sides (99 lbs)`,
        ],
        usedBy: [],
        category: 'training mats',
        subCategory: 'gymnastic mats',
        group: '',
        urlCategory: 'training mats',
        urlSubCategory: 'gymnastic mats',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ffolding-rebond-mat-fm3240632-HQ1.jpg?alt=media&token=b58d0266-c169-486c-8186-d1761a19c231",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ffolding-rebond-mat-fm3240632-HQ1.jpg?alt=media&token=b58d0266-c169-486c-8186-d1761a19c231",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ffolding-rebond-mat-fm3240632-HQ2.jpg?alt=media&token=56f273e6-1561-4b63-902b-372fc372664f",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ffolding-rebond-mat-fm3240632-HQ3.jpg?alt=media&token=c9262bf9-3635-4bb5-81f0-d32405a33613",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ffolding-rebond-mat-fm3240632-HQ4.jpg?alt=media&token=2d51531c-94fd-4a04-8b05-c9686214f794"
        ]
    },{
        id: 'p351',
        name: `Half-fold Rebond Mat`,
        sku: 'hf3240630',
        description: `All rebond foam mats are manufactured using 2" thick, 6 lb density bonded foam and rugged 18 oz coated vinyl. The 2" bonded foam filler gives more support than our urethane mats, but is slightly less firm than our polyethylene mats. This mat is a good choice for elementary school age children, seniors, and for rehabilitation exercises. Specify color choices when ordering.`,
        details: [
            `Ideal for elementary school age children, seniors, and for rehabilitation exercises`,
            `Carrying handles for easy lifting`,
            `Manufactured using 2" thick, 6 lb density bonded foam`,
            `Covered with easy-to-clean, 18 oz vinyl`,
        ],
        includes: [
            ``
        ],
        options: [
            `HF3240630: 4' x 6' Half-fold Rebond Mat (30 lbs)`,
            `HF3240730: 4' x 7' Half-fold Rebond Mat (35 lbs)`,
            `HF3250730: 5' x 7' Half-fold Rebond Mat (44 lbs)`,
            `HF3260830: 6' x 8' Half-fold Rebond Mat (62 lbs)`,
        ],
        usedBy: [],
        category: 'training mats',
        subCategory: 'gymnastic mats',
        group: '',
        urlCategory: 'training mats',
        urlSubCategory: 'gymnastic mats',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fhalf-fold-rebond-mat-hf3240630-HQ1.jpg?alt=media&token=725ef757-0764-4345-8c99-33960da4b2fc",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fhalf-fold-rebond-mat-hf3240630-HQ1.jpg?alt=media&token=725ef757-0764-4345-8c99-33960da4b2fc",
        ]
    },{
        id: 'p352',
        name: `Non-folding Rebond Mat`,
        sku: 'nf3240830',
        description: `All rebond foam mats are manufactured using 2" thick, 6 lb density bonded foam and rugged 18 oz coated vinyl. The 2" bonded foam filler gives more support than our urethane mats, but is slightly less firm than our polyethylene mats. This mat is a good choice for elementary school age children, seniors, and for rehabilitation exercises. Specify color choices when ordering.`,
        details: [
            `Ideal for elementary school age children, seniors, and for rehabilitation exercises`,
            `Manufactured using 2" thick, 6 lb density bonded foam`,
            `Covered with easy-to-clean, 18 oz vinyl`,
        ],
        includes: [
            ``
        ],
        options: [
            `NF3240630: 4' x 6' Non-folding Rebond Mat (30 lbs)`,
            `NF3240830: 4' x 8' Non-folding Rebond Mat (40 lbs)`,
            `NF3250730: 5' x 7' Non-folding Rebond Mat (44 lbs)`,
            `NF3251030: 5' x 10' Non-folding Rebond Mat (65 lbs)`,
            `NF3261230: 6' x 12' Non-folding Rebond Mat (90 lbs)`,
        ],
        usedBy: [],
        category: 'training mats',
        subCategory: 'gymnastic mats',
        group: '',
        urlCategory: 'training mats',
        urlSubCategory: 'gymnastic mats',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fnon-folding-rebond-mat-nf3240830-HQ1.jpg?alt=media&token=f6be0ae0-95f4-4aef-97d6-f1b4e12ef9ae",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fnon-folding-rebond-mat-nf3240830-HQ1.jpg?alt=media&token=f6be0ae0-95f4-4aef-97d6-f1b4e12ef9ae",
        ]
    },{
        id: 'p353',
        name: `Folding Combination Foam Mat`,
        sku: 'elm5240630',
        description: `Two layers of laminated filler builds confidence in young tumblers while still providing an extra measure of safety. The softer top layer is 1" thick polyfoam. The firm bottom layer is 1 1/2" thick Hi D Ethacell.`,
        details: [
            `Ideal for cheerleading, contact training, gymnastics, and martial arts`,
            `Available with Velcro on two ends to make runways, or on four ends to make a complete floor`,
            `2' wide folding panels for convenient storage`,
            `2 1/2" thick`,
            `Seams are double stitched for durability`,
            `Choose from 18 colors`,
        ],
        includes: [
            ``
        ],
        options: [
            `4' x 6'`,
            `ELM5240630: Without Velcro (17 lbs)`,
            `ELM5240632: Velcro on end only (17 lbs)`,
            `ELM5240634: Velcro on all sides (17 lbs)`,
            `4' x 8'`,
            `ELM5240830: Without Velcro (23 lbs)`,
            `ELM5240832: Velcro on end only (23 lbs)`,
            `ELM5240834: Velcro on all sides (23 lbs)`,
            `5' x 10'`,
            `ELM5251030: Without Velcro (36 lbs)`,
            `ELM5251032: Velcro on end only (36 lbs)`,
            `ELM5251034: Velcro on all sides (36 lbs)`,
            `6' x 12'`,
            `ELM5261230: Without Velcro (52 lbs)`,
            `ELM5261232: Velcro on end only (52 lbs)`,
            `ELM5261234: Velcro on all sides (52 lbs)`,
        ],
        usedBy: [],
        category: 'training mats',
        subCategory: 'gymnastic mats',
        group: '',
        urlCategory: 'training mats',
        urlSubCategory: 'gymnastic mats',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ffolding-combination-foam-mat-elm5240632-HQ1.jpg?alt=media&token=3f228445-c44f-41ad-aba8-b40479fcf2aa",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ffolding-combination-foam-mat-elm5240632-HQ1.jpg?alt=media&token=3f228445-c44f-41ad-aba8-b40479fcf2aa",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ffolding-combination-foam-mat-elm5240632-HQ2.jpg?alt=media&token=23545965-d574-48c7-ac68-6659b252542a"
        ]
    },{
        id: 'p354',
        name: `Folding Urethane Mat`,
        sku: 'fm2240630',
        description: `2" 100 ILD Polyfoam is used in this popular mat. This is a less dense composition than HiD Ethacell and is ideal for use with younger children and seniors. Available with Velcro on 2 ends to make runways and Velcro on all ends to make a complete floor.`,
        details: [
            `Ideal for younger children and seniors`,
            `Available with Velcro on two ends to make runways, or on four ends to make a complete floor`,
            `2' wide folding panels for convenient storage`,
            `2" thick`,
            `Seams are double stitched for durability`,
            `Choose from 18 colors`,
        ],
        includes: [
            ``
        ],
        options: [
            `4' x 6'`,
            `FM2240630: Without Velcro (14 lbs)`,
            `FM2240632: Velcro on end only (14 lbs)`,
            `FM2240634: Velcro on all sides (14 lbs)`,
            `4' x 8'`,
            `FM2240830: Without Velcro (19 lbs)`,
            `FM2240832: Velcro on end only (19 lbs)`,
            `FM2240834: Velcro on all sides (19 lbs)`,
            `5' x 10'`,
            `FM2251030: Without Velcro (30 lbs)`,
            `FM2251032: Velcro on end only (30 lbs)`,
            `FM2251034: Velcro on all sides (30 lbs)`,
            `6' x 12'`,
            `FM2261230: Without Velcro (43 lbs)`,
            `FM2261232: Velcro on end only (43 lbs)`,
            `FM2261234: Velcro on all sides (43 lbs)`,
        ],
        usedBy: [],
        category: 'training mats',
        subCategory: 'gymnastic mats',
        group: '',
        urlCategory: 'training mats',
        urlSubCategory: 'gymnastic mats',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ffolding-urethane-mat-fm2240630-HQ1.jpg?alt=media&token=c1fd49dd-0fda-4f34-b56f-d081845df284",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ffolding-urethane-mat-fm2240630-HQ1.jpg?alt=media&token=c1fd49dd-0fda-4f34-b56f-d081845df284",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ffolding-urethane-mat-fm2240630-HQ2.jpg?alt=media&token=c673d274-4291-4544-ab50-a98828d8b527",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ffolding-urethane-mat-fm2240630-HQ3.jpg?alt=media&token=aedc5791-8a6e-4799-8a45-46606b7b699d"
        ]
    },{
        id: 'p355',
        name: `Folding Polyethylene Mat`,
        sku: 'fm1140630',
        description: `1 1/2" thick HiD Ethacell filler provides the support necessary for the more intense exercises performed by young adults, gymnasts, cheerleading, advanced tumblers, contact training, and martial arts. All mats are bacteria and fire resistant and clean easily. Available with Velcro on two ends to make runways and Velcro on all ends to make a complete floor.`,
        details: [
            `Ideal for young adults, gymnasts, cheerleading, advanced tumblers, contact training, and martial arts`,
            `Bacteria and fire resistant`,
            `Cleans easily`,
            `Available with Velcro on two ends to make runways, or on four ends to make a complete floor`,
            `2' wide folding panels for convenient storage`,
            `1 1/2" thick`,
            `Seams are double stitched for durability`,
            `Choose from 18 colors`,
        ],
        includes: [
            ``
        ],
        options: [
            `4' x 6'`,
            `FM1140630: Without Velcro (14 lbs)`,
            `FM1140632: Velcro on end only (14 lbs)`,
            `FM1140634: Velcro on all sides (14 lbs)`,
            `4' x 8'`,
            `FM1140830: Without Velcro (19 lbs)`,
            `FM1140832: Velcro on end only (19 lbs)`,
            `FM1140834: Velcro on all sides (19 lbs)`,
            `5' x 10'`,
            `FM1151030: Without Velcro (29 lbs)`,
            `FM1151032: Velcro on end only (29 lbs)`,
            `FM1151034: Velcro on all sides (29 lbs)`,
            `6' x 12'`,
            `FM1161230: Without Velcro (42 lbs)`,
            `FM1161232: Velcro on end only (42 lbs)`,
            `FM1161234: Velcro on all sides (42 lbs)`,
        ],
        usedBy: [],
        category: 'training mats',
        subCategory: 'gymnastic mats',
        group: '',
        urlCategory: 'training mats',
        urlSubCategory: 'gymnastic mats',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ffolding-polyethylene-mat-fm1140630-HQ1.jpg?alt=media&token=9e9ee950-3d41-4857-88c9-f677f2070908",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ffolding-polyethylene-mat-fm1140630-HQ1.jpg?alt=media&token=9e9ee950-3d41-4857-88c9-f677f2070908",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ffolding-polyethylene-mat-fm1140630-HQ2.jpg?alt=media&token=4be84107-840c-437f-8483-b2b5440eed76"
        ]
    },{
        id: 'p356',
        name: `Folding Personal Mat`,
        sku: '30242',
        description: `This mat folds in half with a carrying handle on each end. 2" high quality polyfoam is covered with easy-to-clean 18 oz vinyl and features a Velcro closure.`,
        details: [
            `2" polyfoam filling`,
            `Covered with 18 oz vinyl`,
            `Convenient carrying handles for easy transportation`,
        ],
        includes: [
            ``
        ],
        options: [
            `30242: 2' x 4' Folding Personal Mat (7 lbs)`,
            `30252: 2' x 5' Folding Personal Mat (8 lbs)`,
            `30262: 2' x 6' Folding Personal Mat (10 lbs)`,
            `30352: 28" x 5' Folding Personal Mat (11 lbs)`,
            `30362: 28" x 6' Folding Personal Mat (12 lbs)`,
        ],
        usedBy: [],
        category: 'training mats',
        subCategory: 'personal mats',
        group: '',
        urlCategory: 'training mats',
        urlSubCategory: 'personal mats',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ffolding-personal-mat-30242-HQ1.jpg?alt=media&token=6d3ab3a7-7549-409c-ae53-a64a5bd7017e",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ffolding-personal-mat-30242-HQ1.jpg?alt=media&token=6d3ab3a7-7549-409c-ae53-a64a5bd7017e",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ffolding-personal-mat-30242-HQ2.jpg?alt=media&token=90e915c3-c4b0-4b72-a53c-ce3505ca7f40",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ffolding-personal-mat-30242-HQ3.jpg?alt=media&token=9d113d2d-a31b-4196-b06b-51514cd38945"
        ]
    },{
        id: 'p357',
        name: `Non-folding Personal Mat`,
        sku: 'dem24',
        description: `2" high quality polyfoam is covered with easy-to-clean, long wearing, 18 oz vinyl. Convenient side carrying handles on one long side.`,
        details: [
            `2" polyfoam filling`,
            `Covered with 18 oz vinyl`,
            `Convenient carrying handles on one long side`,
        ],
        includes: [
            ``
        ],
        options: [
            `DEM24: 2' x 4' Non-folding Personal Mat (7 lbs)`,
            `DEM25: 2' x 5' Non-folding Personal Mat (9 lbs)`,
            `DEM26: 2' x 6' Non-folding Personal Mat (11 lbs)`,
            `DEM36: 3' x 6' Non-folding Personal Mat (16 lbs)`,
        ],
        usedBy: [],
        category: 'training mats',
        subCategory: 'personal mats',
        group: '',
        urlCategory: 'training mats',
        urlSubCategory: 'personal mats',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fnon-folding-personal-mat-dem24-HQ1.jpg?alt=media&token=7be91f67-2890-4d3b-a649-888497396810",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fnon-folding-personal-mat-dem24-HQ1.jpg?alt=media&token=7be91f67-2890-4d3b-a649-888497396810",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fnon-folding-personal-mat-dem24-HQ2.jpg?alt=media&token=5d900361-9acb-4d1b-b5c0-a9df8644032f",
        ]
    },{
        id: 'p358',
        name: `Economy Personal Exercise Mat`,
        sku: 'exm124',
        description: `1" polyfoam covered in 18 oz vinyl is used for this mat. The non-folding design features a carrying handle on one long side.`,
        details: [
            `1" polyfoam filling`,
            `Covered with 18 oz vinyl`,
            `Convenient carrying handle on one long side`,
        ],
        includes: [
            ``
        ],
        options: [
            `EXM124: 2' x 4' Economy Personal Exercise Mat (4 lbs)`,
            `EXM125: 2' x 5' Economy Personal Exercise Mat (5 lbs)`,
            `EXM126: 2' x 6' Economy Personal Exercise Mat (8 lbs)`,
            `EXM127: 30" x 5' Economy Personal Exercise Mat (6 lbs)`,
            `EXM136: 30" x 6' Economy Personal Exercise Mat (8 lbs)`,
        ],
        usedBy: [],
        category: 'training mats',
        subCategory: 'personal mats',
        group: '',
        urlCategory: 'training mats',
        urlSubCategory: 'personal mats',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Feconomy-personal-exercise-mat-exm124-HQ1.jpg?alt=media&token=390899b4-f7b7-4f6f-bc12-0403d3935df4",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Feconomy-personal-exercise-mat-exm124-HQ1.jpg?alt=media&token=390899b4-f7b7-4f6f-bc12-0403d3935df4",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Feconomy-personal-exercise-mat-exm124-HQ2.jpg?alt=media&token=a384d5bd-5887-457f-8804-4c84b06cba6f",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Feconomy-personal-exercise-mat-exm124-HQ3.jpg?alt=media&token=8bc5353f-34fe-47b5-b87c-bcb725c819e2"
        ]
    },{
        id: 'p359',
        name: `Non-folding Landing Mat`,
        sku: 'lm406',
        description: `Great for physical education activities and competitive gymnastics. Made with high quality polyfoam and covered with easy-to-clean 18 oz vinyl.`,
        details: [
            `Polyfoam filling`,
            `Handles are triple stitched`,
        ],
        includes: [
            ``
        ],
        options: [
            `4" Thick`,
            `LM406: 4' x 6' x 4" (21 lbs)`,
            `LM408: 4' x 8' x 4" (28 lbs)`,
            `LM410: 5' x 10' x 4" (44 lbs)`,
            `LM412: 6' x 12' x 4" (63 lbs)`,
            `8" Thick`,
            `LM806: 4' x 6' x 8" (32 lbs)`,
            `LM808: 4' x 8' x 8" (43 lbs)`,
            `LM810: 5' x 10' x 8" (67 lbs)`,
            `LM812: 6' x 12' x 8" (96 lbs)`,
            `10" Thick`,
            `LM106: 4' x 6' x 10" (40 lbs)`,
            `LM108: 4' x 8' x 10" (53 lbs)`,
            `LM110: 5' x 10' x 10" (83 lbs)`,
            `LM112: 6' x 12' x 10" (120 lbs)`,
            `12" Thick`,
            `LM124: 4' x 6' x 12" (48 lbs)`,
            `LM126: 4' x 8' x 12" (64 lbs)`,
            `LM128: 5' x 10' x 12" (100 lbs)`,
            `LM129: 6' x 12' x 12" (144 lbs)`,
            `18" Thick`,
            `LM612: 6' x 12' x 18" (180 lbs)`,
            `24" Thick`,
            `LM624: 6' x 12' x 24" (216 lbs)`,
        ],
        usedBy: [],
        category: 'training mats',
        subCategory: 'landing mats',
        group: '',
        urlCategory: 'training mats',
        urlSubCategory: 'landing mats',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fnon-folding-landing-mat-lm406-HQ1.jpg?alt=media&token=dfdec10f-1f6a-46e5-9361-bb093f808ca1",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fnon-folding-landing-mat-lm406-HQ1.jpg?alt=media&token=dfdec10f-1f6a-46e5-9361-bb093f808ca1",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fnon-folding-landing-mat-lm406-HQ2.jpg?alt=media&token=c1deed77-3ac3-4d42-9137-cce124598f6c"
        ]
    },{
        id: 'p360',
        name: `Non-folding Dismount Mat`,
        sku: 'clm7',
        description: `Great for physical education activities and competitive gymnastics. Features polyfoam with HiD Ethacell base and covered with easy-to-clean 18 oz vinyl.`,
        details: [
            `Features polyfoam with HiD Ethacell base`,
            `Handles are triple stitched`,
        ],
        includes: [
            ``
        ],
        options: [
            `4" Thick`,
            `CLM7: 5' x 10' x 4" (70 lbs)`,
            `CLM8: 6' x 12' x 4" (82 lbs)`,
            `12" Thick`,
            `CLM9: 5' x 10' x 12" (112 lbs)`,
            `CLM10: 6' x 12' x 12" (140 lbs)`,
        ],
        usedBy: [],
        category: 'training mats',
        subCategory: 'landing mats',
        group: '',
        urlCategory: 'training mats',
        urlSubCategory: 'landing mats',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fweb-prod-image-coming-soon.jpg?alt=media&token=b54177c2-2a98-46a0-8446-23ea7b30f18c",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fweb-prod-image-coming-soon.jpg?alt=media&token=b54177c2-2a98-46a0-8446-23ea7b30f18c"
        ]
    },{
        id: 'p361',
        name: `Folding Dismount Mat`,
        sku: 'fclm1',
        description: `Great for physical education activities and competitive gymnastics. Features polyfoam with HiD Ethacell base and covered with easy-to-clean 18 oz vinyl. Mat covers are sewn with a hange which permits the mat to be folded, requiring less storage space.`,
        details: [
            `Features polyfoam with HiD Ethacell base`,
            `Hing allows the mat to be folded for easier storage`,
            `Handles are triple stitched`,
        ],
        includes: [
            ``
        ],
        options: [
            `4" Thick`,
            `FCLM1: 5' x 10' x 4" (70 lbs)`,
            `FCLM2: 6' x 12' x 4" (82 lbs)`,
            `FCLM3: 8' x 12' x 4" (115 lbs)`,
            `5" Thick`,
            `FCLM7: 8' x 16' x 5" (150 lbs)`,
            `12" Thick`,
            `FCLM4: 5' x 10' x 12" (112 lbs)`,
            `FCLM5: 6' x 12' x 12" (140 lbs)`,
            `15" Thick`,
            `FCLM6: 6' x 18' x 15" (134 lbs)`,
        ],
        usedBy: [],
        category: 'training mats',
        subCategory: 'landing mats',
        group: '',
        urlCategory: 'training mats',
        urlSubCategory: 'landing mats',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fweb-prod-image-coming-soon.jpg?alt=media&token=b54177c2-2a98-46a0-8446-23ea7b30f18c",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fweb-prod-image-coming-soon.jpg?alt=media&token=b54177c2-2a98-46a0-8446-23ea7b30f18c"
        ]
    },{
        id: 'p362',
        name: `Pole Vault Pit`,
        sku: 'pv202126',
        description: `The Pole Vault Pit is a high-quality, durable piece of equipment that is perfect for use in track and field competitions. It is constructed with 18 ounce coated vinyl and features a two inch thick landing pad for added safety and protection. The steel snap hooks are zinc coated for added durability, and the pole vault pit is heat sealed for a stronger seam. Plus, with a 10 year warranty, you can be confident in its longevity. This Pole Vault Pit meets all NFHS and NCAA guidelines, so it is perfect for use in official competitions. Overall, this is a fantastic choice for any track and field program.`,
        details: [
            `19'9"W x 21'D`,
            `Choose from 26"H, 28"H, or 32"H`,
            `18 oz coated vinyl`,
            `Includes 2" thick landing pad`,
            `Zinc coated steel snap hooks & D rings`,
            `Heat sealed for a stronger seam`,
            `Meets NFHS & NCAA guidelines`,
            `No upcharge for custom colored pits`,
            `10 year warranty`,
        ],
        includes: [
            ``
        ],
        options: [
            `Pole Vault Pits`,
            `PV202126: 19'9"W x 21'D x 26"H`,
            `PV202128: 19'9"W x 21'D x 28"H`,
            `PV202132: 19'9"W x 21'D x 32"H`,
            `Covers`,
            `WC202126: Weather Cover for PV202126`,
            `WC202128: Weather Cover for PV202128`,
            `WC202132: Weather Cover for PV202132`,
            `GC2021: Ground Cover for PV202126-32`,
        ],
        usedBy: [],
        category: 'Track and Field',
        subCategory: 'pits',
        group: 'Pole Vault Pits',
        urlCategory: 'Track and Field',
        urlSubCategory: 'pits',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpole-vault-pit-pv202126-HQ1.jpg?alt=media&token=a06cdcb9-7015-4f0f-8d43-accfa942f1f5",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpole-vault-pit-pv202126-HQ1.jpg?alt=media&token=a06cdcb9-7015-4f0f-8d43-accfa942f1f5",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpole-vault-pit-pv202126-HQ2.jpg?alt=media&token=579586d3-5d16-47cc-9e45-98acd400797f"
        ]
    },{
        id: 'p363',
        name: `Pole Vault Pit`,
        sku: 'pv202326',
        description: `The Pole Vault Pit is a high-quality, durable piece of equipment that is perfect for use in track and field competitions. It is constructed with 18 ounce coated vinyl and features a two inch thick landing pad for added safety and protection. The steel snap hooks are zinc coated for added durability, and the pole vault pit is heat sealed for a stronger seam. Plus, with a 10 year warranty, you can be confident in its longevity. This Pole Vault Pit meets all NFHS and NCAA guidelines, so it is perfect for use in official competitions. Overall, this is a fantastic choice for any track and field program.`,
        details: [
            `19'9"W x 23'D`,
            `Choose from 26"H, 28"H, or 32"H`,
            `18 oz coated vinyl`,
            `Includes 2" thick landing pad`,
            `Zinc coated steel snap hooks & D rings`,
            `Heat sealed for a stronger seam`,
            `Meets NFHS & NCAA guidelines`,
            `No upcharge for custom colored pits`,
            `10 year warranty`,
        ],
        includes: [
            ``
        ],
        options: [
            `Pole Vault Pits`,
            `PV202326: 19'9"W x 23'D x 26"H`,
            `PV202328: 19'9"W x 23'D x 28"H`,
            `PV202332: 19'9"W x 23'D x 32"H`,
            `Covers`,
            `WC202326: Weather Cover for PV202126`,
            `WC202328: Weather Cover for PV202128`,
            `WC202332: Weather Cover for PV202132`,
            `GC2023: Ground Cover for PV202326-32`,
        ],
        usedBy: [],
        category: 'Track and Field',
        subCategory: 'pits',
        group: 'Pole Vault Pits',
        urlCategory: 'Track and Field',
        urlSubCategory: 'pits',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpole-vault-pit-pv202326-HQ1.jpg?alt=media&token=bb578124-cf89-4457-9b52-fb094eb71617",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpole-vault-pit-pv202326-HQ1.jpg?alt=media&token=bb578124-cf89-4457-9b52-fb094eb71617",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpole-vault-pit-pv202326-HQ2.jpg?alt=media&token=10e07ff5-79d4-4528-9aaf-8ff6544aab62"
        ]
    },{
        id: 'p364',
        name: `Pole Vault Pit`,
        sku: 'pv222428',
        description: `The Pole Vault Pit is a high-quality, durable piece of equipment that is perfect for use in track and field competitions. It is constructed with 18 ounce coated vinyl and features a two inch thick landing pad for added safety and protection. The steel snap hooks are zinc coated for added durability, and the pole vault pit is heat sealed for a stronger seam. Plus, with a 10 year warranty, you can be confident in its longevity. This Pole Vault Pit meets all NFHS and NCAA guidelines, so it is perfect for use in official competitions. Overall, this is a fantastic choice for any track and field program.`,
        details: [
            `22'W x 24'D`,
            `Choose from 28"H or 32"H`,
            `18 oz coated vinyl`,
            `Includes 2" thick landing pad`,
            `Zinc coated steel snap hooks & D rings`,
            `Heat sealed for a stronger seam`,
            `Meets NFHS & NCAA guidelines`,
            `No upcharge for custom colored pits`,
            `10 year warranty`,
        ],
        includes: [
            ``
        ],
        options: [
            `Pole Vault Pits`,
            `PV222428: 22'W x 24'D x 28"H`,
            `PV222432: 22'W x 24'D x 32"H`,
            `Covers`,
            `WC222428: Weather Cover for PV222428`,
            `WC222432: Weather Cover for PV222432`,
            `GC2224: Ground Cover for PV222428 & 32`,
        ],
        usedBy: [],
        category: 'Track and Field',
        subCategory: 'pits',
        group: 'Pole Vault Pits',
        urlCategory: 'Track and Field',
        urlSubCategory: 'pits',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpole-vault-pit-pv222428-HQ1.jpg?alt=media&token=999d3ce2-713b-442d-b014-28233a940c0b",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpole-vault-pit-pv222428-HQ1.jpg?alt=media&token=999d3ce2-713b-442d-b014-28233a940c0b",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpole-vault-pit-pv222428-HQ2.jpg?alt=media&token=65ba14ca-d981-4845-b069-702bddde7410"
        ]
    },{
        id: 'p365',
        name: `Pole Vault Pit`,
        sku: 'pv222728',
        description: `The Pole Vault Pit is a high-quality, durable piece of equipment that is perfect for use in track and field competitions. It is constructed with 18 ounce coated vinyl and features a two inch thick landing pad for added safety and protection. The steel snap hooks are zinc coated for added durability, and the pole vault pit is heat sealed for a stronger seam. Plus, with a 10 year warranty, you can be confident in its longevity. This Pole Vault Pit meets all NFHS and NCAA guidelines, so it is perfect for use in official competitions. Overall, this is a fantastic choice for any track and field program.`,
        details: [
            `22'W x 27'D`,
            `Choose from 28"H or 32"H`,
            `18 oz coated vinyl`,
            `Includes 2" thick landing pad`,
            `Zinc coated steel snap hooks & D rings`,
            `Heat sealed for a stronger seam`,
            `Meets NFHS & NCAA guidelines`,
            `No upcharge for custom colored pits`,
            `10 year warranty`,
        ],
        includes: [
            ``
        ],
        options: [
            `Pole Vault Pits`,
            `PV222728: 22'W x 27'D x 28"H`,
            `PV222732: 22'W x 27'D x 32"H`,
            `Covers`,
            `WC222728: Weather Cover for PV222728`,
            `WC222732: Weather Cover for PV222732`,
            `GC2227: Ground Cover for PV222728 & 32`,
        ],
        usedBy: [],
        category: 'Track and Field',
        subCategory: 'pits',
        group: 'Pole Vault Pits',
        urlCategory: 'Track and Field',
        urlSubCategory: 'pits',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpole-vault-pit-pv222728-HQ1.jpg?alt=media&token=f4396fcb-6a8a-4145-9859-2a4b8fc6363c",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpole-vault-pit-pv222728-HQ1.jpg?alt=media&token=f4396fcb-6a8a-4145-9859-2a4b8fc6363c",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpole-vault-pit-pv222728-HQ2.jpg?alt=media&token=4541bbc6-c405-41f3-993b-0c25b1ac7f75"
        ]
    },{
        id: 'p366',
        name: `Preferred Landing Zone Option`,
        sku: 'plz',
        description: `Add a preferred landing zone to any of our Pole Vault Pits.`,
        details: [
            `Optional feature for Pole Vault Pits`,
        ],
        includes: [
            ``
        ],
        options: [
            `PLZ: 8' x 10' Preferred Landing Zone Option`,
        ],
        usedBy: [],
        category: 'Track and Field',
        subCategory: 'pits',
        group: 'Pole Vault Pits',
        urlCategory: 'Track and Field',
        urlSubCategory: 'pits',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpreferred-landing-zone-plz-HQ1.jpg?alt=media&token=86544e73-3b23-4d06-93ab-c692f4261f15",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpreferred-landing-zone-plz-HQ1.jpg?alt=media&token=86544e73-3b23-4d06-93ab-c692f4261f15"
        ]
    },{
        id: 'p367',
        name: `Tri-Mat Safety Pad`,
        sku: 'wm480',
        description: `Great for padding hard surfaces around your Pole Vault Pit. Article 7-4-7 states "Any hard or unyielding surfaces such as concrete, metal, wood or asphalt, around the landing pad must be padded or cushioned with a minimum of 2" dense foam or other suitable material."`,
        details: [
            `Adds padding to the area around your Pole Vault Pit`
        ],
        includes: [
        ],
        options: [
            `WM480: 4' x 8' Tri-Mat Safety Pad (10 lbs each)`,
        ],
        usedBy: [],
        category: 'Track and Field',
        subCategory: 'pits',
        group: 'Pole Vault Pits',
        urlCategory: 'Track and Field',
        urlSubCategory: 'pits',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ftri-mat-safety-pad-pole-vault-wm480-HQ1.jpg?alt=media&token=07f5d329-99a2-43c3-843d-e8ef858750ea",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ftri-mat-safety-pad-pole-vault-wm480-HQ1.jpg?alt=media&token=07f5d329-99a2-43c3-843d-e8ef858750ea"
        ]
    },{
        id: 'p368',
        name: `High Jump Pit`,
        sku: 'hj81620',
        description: `10 year warranty. Meets NFHS & NCAA guidelines. 2" thick landing pad and heat sealed for a stronger seam. Steel snap hooks. Steel "D" rings.`,
        details: [
            `16'6"W x 8'D`,
            `Choose from 20", 24", 26", or 28" height`,
            `Includes 2" thick landing pad`,
            `Heat sealed for a stronger seam`,
            `Steel snap hooks`,
            `Steel D rings`,
            `Meets NFHS and NCAA guidelines`,
            `10 year warranty`,
        ],
        includes: [
            ``
        ],
        options: [
            `High Jump Pits`,
            `HJ81620: 16'6"W x 8'D x 20"H`,
            `HJ81624: 16'6"W x 8'D x 24"H`,
            `HJ81626: 16'6"W x 8'D x 26"H`,
            `HJ81628: 16'6"W x 8'D x 28"H`,
            `Covers`,
            `WC81620: Weather Cover for HJ81620`,
            `WC81624: Weather Cover for HJ81624`,
            `WC81626: Weather Cover for HJ81626`,
            `WC81628: Weather Cover for HJ81628`,
            `GC816: Ground Cover for HJ81620-28`,
        ],
        usedBy: [],
        category: 'Track and Field',
        subCategory: 'pits',
        group: 'High Jump Pits',
        urlCategory: 'Track and Field',
        urlSubCategory: 'pits',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fhigh-jump-pits-hj81624-HQ2.jpg?alt=media&token=5aa4a8db-ae0d-456b-995a-e9d10f7b9b77",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fhigh-jump-pits-hj81624-HQ2.jpg?alt=media&token=5aa4a8db-ae0d-456b-995a-e9d10f7b9b77",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fhigh-jump-pits-hj81624-HQ3.jpg?alt=media&token=53518e88-1ce8-46dc-8a44-527ace7dd6a9",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fhigh-jump-pits-hj81624-HQ4.jpg?alt=media&token=681cd585-6229-4399-b29e-9949b3706158",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fhigh-jump-pits-hj81624-HQ5.jpg?alt=media&token=3ed16dcd-190a-4e56-bbda-565abe9c1a47",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fhigh-jump-pit-hj81620-HQ1.jpg?alt=media&token=562c80b6-81f9-4747-9f6d-34759d6221a4"
        ]
    },{
        id: 'p369',
        name: `High Jump Pit`,
        sku: 'hJ101824',
        description: `10 year warranty. Meets NFHS & NCAA guidelines. 2" thick landing pad and heat sealed for a stronger seam. Steel snap hooks. Steel "D" rings.`,
        details: [
            `18'W x 10'D`,
            `Choose from 24", 26", 28", or 32" height`,
            `Includes 2" thick landing pad`,
            `Heat sealed for a stronger seam`,
            `Steel snap hooks`,
            `Steel D rings`,
            `Meets NFHS and NCAA guidelines`,
            `10 year warranty`,
        ],
        includes: [
            ``
        ],
        options: [
            `High Jump Pits`,
            `HJ101824: 18'W x 10'D x 24"H`,
            `HJ101826: 18'W x 10'D x 26"H`,
            `HJ101828: 18'W x 10'D x 28"H`,
            `HJ101832: 18'W x 10'D x 32"H`,
            `Covers`,
            `WC101824: Weather Cover for HJ101824`,
            `WC101826: Weather Cover for HJ101826`,
            `WC101828: Weather Cover for HJ101828`,
            `WC101832: Weather Cover for HJ101832`,
            `GC1018: Ground Cover for HJ101824-32`,
        ],
        usedBy: [],
        category: 'Track and Field',
        subCategory: 'pits',
        group: 'High Jump Pits',
        urlCategory: 'Track and Field',
        urlSubCategory: 'pits',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fhigh-jump-pit-hj101824-HQ2.jpg?alt=media&token=266c90a3-5d86-4bbb-8320-6343a1917aef",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fhigh-jump-pit-hj101824-HQ2.jpg?alt=media&token=266c90a3-5d86-4bbb-8320-6343a1917aef",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fhigh-jump-pit-hj101824-HQ3.jpg?alt=media&token=b224ad38-248f-449e-ad49-6ab3741735a7",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fhigh-jump-pit-hj101824-HQ4.jpg?alt=media&token=3087912d-6cad-42c8-aec4-4c85bd816ab7",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fhigh-jump-pit-hj101824-HQ1.jpg?alt=media&token=1defb676-d4bb-430a-b47a-1e86e7b09d9d"
        ]
    },{
        id: 'p370',
        name: `High Jump Pit`,
        sku: 'hJ112224',
        description: `10 year warranty. Meets NFHS & NCAA guidelines. 2" thick landing pad and heat sealed for a stronger seam. Steel snap hooks. Steel "D" rings.`,
        details: [
            `22'W x 11'D`,
            `Choose from 24", 26", 28", or 32" height`,
            `Includes 2" thick landing pad`,
            `Heat sealed for a stronger seam`,
            `Steel snap hooks`,
            `Steel D rings`,
            `Meets NFHS and NCAA guidelines`,
            `10 year warranty`,
        ],
        includes: [
            ``
        ],
        options: [
            `High Jump Pits`,
            `HJ112224: 22'W x 11'D x 24"H`,
            `HJ112226: 22'W x 11'D x 26"H`,
            `HJ112228: 22'W x 11'D x 28"H`,
            `HJ112232: 22'W x 11'D x 32"H`,
            `Covers`,
            `WC112224: Weather Cover for HJ112224`,
            `WC112226: Weather Cover for HJ112226`,
            `WC112228: Weather Cover for HJ112228`,
            `WC112232: Weather Cover for HJ112232`,
            `GC1122: Ground Cover for HJ112224-32`,
        ],
        usedBy: [],
        category: 'Track and Field',
        subCategory: 'pits',
        group: 'High Jump Pits',
        urlCategory: 'Track and Field',
        urlSubCategory: 'pits',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fhigh-jump-pits-hj112224-HQ1.jpg?alt=media&token=6671b667-f04d-40e4-a833-73a41ba7cd73",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fhigh-jump-pits-hj112224-HQ1.jpg?alt=media&token=6671b667-f04d-40e4-a833-73a41ba7cd73"
        ]
    },{
        id: 'p371',
        name: `Jr High Jump Pit`,
        sku: 'jr61218',
        description: `Fisher Junior Pits are designed specifically for the Junior High track program. They are manufactured using heavy duty 18 oz. vinyl and vinyl mesh with a full 10 year warranty. The sizes and dimensions offered are geared toward junior programs and are not intended for use with more advanced programs. Optional weather and ground covers are available.`,
        details: [
            `12'W x 6'D x 18"H`,
            `Choose from 20"H or 28"H`,
            `Manufactured using 18 oz vinyl and vinyl mesh`,
            `10 year warranty`,
        ],
        includes: [
            ``
        ],
        options: [
            `High Jump Pits`,
            `JR61218: 12'W x 6'D x 18"H`,
            `JR61224: 12'W x 6'D x 24"H`,
            `Covers`,
            `WC61218: Weather Cover for JR61218`,
            `WC61224: Weather Cover for JR61224`,
            `GC612: Ground Cover for JR61218 & 24`,
        ],
        usedBy: [],
        category: 'Track and Field',
        subCategory: 'pits',
        group: 'High Jump Pits',
        urlCategory: 'Track and Field',
        urlSubCategory: 'pits',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fjr-high-jump-pit-jr61224-HQ1.jpg?alt=media&token=9de8cfef-6676-4378-9f65-2661db5acfad",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fjr-high-jump-pit-jr61224-HQ1.jpg?alt=media&token=9de8cfef-6676-4378-9f65-2661db5acfad"
        ]
    },{
        id: 'p372',
        name: `Tri-Mat Safety Pad`,
        sku: 'wm240',
        description: `Great for padding hard surfaces around your High Jump Pit. Article 7-4-7 states "Any hard or unyielding surfaces such as concrete, metal, wood or asphalt, around the landing pad must be padded or cushioned with a minimum of 2" dense foam or other suitable material."`,
        details: [
            `Add padding to the area around your High Jump Pit`
        ],
        includes: [
            ``
        ],
        options: [
            `WM240: 2' x 4' (5 lbs each)`,
        ],
        usedBy: [],
        category: 'Track and Field',
        subCategory: 'pits',
        group: 'High Jump Pits',
        urlCategory: 'Track and Field',
        urlSubCategory: 'pits',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ftri-mat-safety-pad-high-jump-pit-wm240-HQ1.jpg?alt=media&token=59e877cd-eada-4c0e-9eae-0389591a141d",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ftri-mat-safety-pad-high-jump-pit-wm240-HQ1.jpg?alt=media&token=59e877cd-eada-4c0e-9eae-0389591a141d"
        ]
    },{
        id: 'p373',
        name: `Pole Vault Pit Package`,
        sku: 'pv202126pk',
        description: `The Pole Vault Pit Package includes (1) Pole Vault Pit, (1) pair of Pole Vault Standards, (1) Fiberglass Crossbar, (2) Pole Vault Standard Pads, and (1) Weather Cover.`,
        details: [
            `Includes everything you need to get started with the Pole Vault Pit`
        ],
        includes: [
            `PV202126: (1) Pole Vault Pit`,
            `PVST1510: (1) Pair of Pole Vault Standards`,
            `BM1502: (1) Fiberglass Crossbar`,
            `CBL100: (1) Steel Crossbar Lifter`,
            `PVS246016: (2) Pole Vault Standard Pads`,
            `WC202126: (1) Weather Cover`,
        ],
        options: [
            ``,
        ],
        usedBy: [],
        category: 'Track and Field',
        subCategory: 'pits',
        group: 'Combo Packages',
        urlCategory: 'Track and Field',
        urlSubCategory: 'pits',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpole-vault-pit-package-pv202126pk-HQ1.jpg?alt=media&token=b159550c-6a3a-4cc5-92a2-6334a43ac6f3",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpole-vault-pit-package-pv202126pk-HQ1.jpg?alt=media&token=b159550c-6a3a-4cc5-92a2-6334a43ac6f3"
        ]
    },{
        id: 'p374',
        name: `High Jump Pit Package`,
        sku: 'hj81624pk',
        description: `The High Jump Pit Package includes (1) High Jump Pit, (1) pair of High Jump Standards, (1) Fiberglass Crossbar, (2) High Jump Standard Pads, and (1) Weather Cover.`,
        details: [
            `Includes everything you need to get started with the High Jump Pit`
        ],
        includes: [
            `HJ81624: (1) High Jump Pit`,
            `HJST1210: (1) Pair of High Jump Pit Standards`,
            `BM1501: (1) Fiberglass Crossbar`,
            `SS100: (2) High Jump Standard Pads`,
            `WC81624: (1) Weather Cover`,
        ],
        options: [
            ``,
        ],
        usedBy: [],
        category: 'Track and Field',
        subCategory: 'pits',
        group: 'Combo Packages',
        urlCategory: 'Track and Field',
        urlSubCategory: 'pits',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fhigh-jump-pit-package-hj81624pk-HQ1.jpg?alt=media&token=6323b108-2b81-474a-a011-53dc10262aaf",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fhigh-jump-pit-package-hj81624pk-HQ1.jpg?alt=media&token=6323b108-2b81-474a-a011-53dc10262aaf"
        ]
    },{
        id: 'p375',
        name: `Combination Pit Package`,
        sku: 'pvhj2624c',
        description: `The Combination Pit Package includes (1) Pole Vault Pit, (1) High Jump Pit, (1) pair of Pole Vault Pit Standards, (1) pair of High Jump Standards, (2) Fiberglass Crossbars, (1) Steel Crossbar Lifter, (2) Pole Vault Standard Pads, (2) High Jump Standard Pads, and (2) Weather Covers.`,
        details: [
            `Includes everything you need to get started with the Pole Vault Pit and High Jump Pit`
        ],
        includes: [
            `PV202126: (1) Pole Vault Pit`,
            `HJ81624: (1) High Jump Pit`,
            `PVST1510: (1) Pair of Pole Vault Pit Standards`,
            `HJST1210: (1) Pair of High Jump Pit Standards`,
            `BM1502: (1) Fiberglass Crossbar`,
            `BM1501: (1) Fiberglass Crossbar`,
            `CBL100: (1) Steel Crossbar Lifter`,
            `PVS246016: (2) Pole Vault Standard Pads`,
            `SS100: (2) High Jump Standard Pads`,
            `WC202126: (1) Weather Cover`,
            `WC81624: (1) Weather Cover`,
        ],
        options: [
            ``,
        ],
        usedBy: [],
        category: 'Track and Field',
        subCategory: 'pits',
        group: 'Combo Packages',
        urlCategory: 'Track and Field',
        urlSubCategory: 'pits',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fcombination-pit-package-pvhj2624c-HQ1.jpg?alt=media&token=c487cad9-0828-4e91-8819-67ba3c5109e3",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fcombination-pit-package-pvhj2624c-HQ1.jpg?alt=media&token=c487cad9-0828-4e91-8819-67ba3c5109e3"
        ]
    },{
        id: 'p376',
        name: `Pole Vault Standard`,
        sku: 'pvst1510',
        description: `1" x 3" powder coated steel tube. Height from 6' to 18'. Meets NCAA and NFHS specifications.`,
        details: [
            `Height from 6' to 18'`,
            `1" x 3" powder coated steel tube`,
            `Meets NCAA and NFHS specifications`,
        ],
        includes: [
            ``
        ],
        options: [
            `PVST15010: Pole Vault Standard`,
        ],
        usedBy: [],
        category: 'Track and Field',
        subCategory: 'pits',
        group: 'Pit Standards',
        urlCategory: 'Track and Field',
        urlSubCategory: 'pits',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpole-vault-standard-pvst1510-HQ1.jpg?alt=media&token=e925f730-fa9d-41d5-9f77-b726f3293917",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpole-vault-standard-pvst1510-HQ1.jpg?alt=media&token=e925f730-fa9d-41d5-9f77-b726f3293917",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpole-vault-standard-pvst1510-HQ2.jpg?alt=media&token=690a67e3-9a49-4d5c-a960-fafb66d91ab9",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpole-vault-standard-pvst1510-HQ3.jpg?alt=media&token=23988a00-1f50-471a-818b-0a68c8a67ee1"
        ]
    },{
        id: 'p377',
        name: `Deluxe Pole Vault Standard`,
        sku: 'pvst1520',
        description: `1" x 3" powder coated steel tube. Height from 6' to 18'. Meets NCAA and NFHS specifications.`,
        details: [
            `Height from 6' to 18'`,
            `1" x 3" powder coated steel tube`,
            `Meets NCAA and NFHS specifications`,
        ],
        includes: [
            ``
        ],
        options: [
            `PVST15020: Deluxe Pole Vault Standard`,
        ],
        usedBy: [],
        category: 'Track and Field',
        subCategory: 'pits',
        group: 'Pit Standards',
        urlCategory: 'Track and Field',
        urlSubCategory: 'pits',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fdeluxe-pole-vault-standard-pvst1520-HQ1.jpg?alt=media&token=e644c286-1aa2-49dd-a9a0-f77e56c64340",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fdeluxe-pole-vault-standard-pvst1520-HQ1.jpg?alt=media&token=e644c286-1aa2-49dd-a9a0-f77e56c64340",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fdeluxe-pole-vault-standard-pvst1520-HQ2.jpg?alt=media&token=7fcfc144-2171-4750-abc7-ce1b126ad57d"
        ]
    },{
        id: 'p378',
        name: `High Jump Standard`,
        sku: 'hjst1210',
        description: `1.5" x 1.5" powder coated steel tube. Height from 2'3" to 8'6". Meets NCAA and NFHS specifications.`,
        details: [
            `Height from 2'3" to 8'6"`,
            `1.5" x 1.5" powder coated steel tube`,
            `Meets NCAA and NFHS specifications`,
        ],
        includes: [
            ``
        ],
        options: [
            `HJST1210: High Jump Standard`,
        ],
        usedBy: [],
        category: 'Track and Field',
        subCategory: 'pits',
        group: 'Pit Standards',
        urlCategory: 'Track and Field',
        urlSubCategory: 'pits',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fhigh-jump-standard-hjst1210-HQ1.jpg?alt=media&token=5ed28cf1-5b08-4463-86c4-43ff572d833a",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fhigh-jump-standard-hjst1210-HQ1.jpg?alt=media&token=5ed28cf1-5b08-4463-86c4-43ff572d833a",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fhigh-jump-standard-hjst1210-HQ2.jpg?alt=media&token=dc054b49-1764-4c1f-98cf-61e6646f285c"
        ]
    },{
        id: 'p379',
        name: `Deluxe High Jump Standard`,
        sku: 'hjst1220',
        description: `1.5" x 1.5" powder coated steel tube. Height from 2'3" to 8'6". Meets NCAA and NFHS specifications.`,
        details: [
            `Height from 2'3" to 8'6"`,
            `1.5" x 1.5" powder coated steel tube`,
            `Meets NCAA and NFHS specifications`,
        ],
        includes: [
            ``
        ],
        options: [
            `HJST1220: Deluxe High Jump Standard`,
            `HJWK1220: Wheel Kit for HJST1220 (optional)`,
        ],
        usedBy: [],
        category: 'Track and Field',
        subCategory: 'pits',
        group: 'Pit Standards',
        urlCategory: 'Track and Field',
        urlSubCategory: 'pits',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fdeluxe-high-jump-standard-hjst1220-HQ1.jpg?alt=media&token=a5031e3b-b423-4908-94c8-87803bbcf17f",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fdeluxe-high-jump-standard-hjst1220-HQ1.jpg?alt=media&token=a5031e3b-b423-4908-94c8-87803bbcf17f",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fdeluxe-high-jump-standard-hjst1220-HQ2.jpg?alt=media&token=94008a4b-77d8-443d-bd41-6bad951bfcd7",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fdeluxe-high-jump-standard-hjst1220-HQ3.jpg?alt=media&token=9f51e4dd-18a9-4346-9618-90c193ab566a"
        ]
    },{
        id: 'p380',
        name: `Pole Vault Standard Pad`,
        sku: 'pvs246016',
        description: `Most economical standard pad. Outside Dim: 28" W x 65" L x 16" H Inside Dim: 24" W x 60" L x 8" H Meets ASTM recommendations with at least 8" of padding on top surface and 2" of padding on all sides. 2 piece units are easy to set up and remove. Covered in 18 oz vinyl. Comes with Velcro measurement tape for accurate placement of standards through full range of motion.`,
        details: [
            `Meets ASTM recommendations with at least 8" of padding on top surface and 2" of padding on all sides`,
            `2 piece units are easy to set up and remove`,
            `Covered in 18 oz vinyl`,
            `Comes with Velcro measurement tape for accurate placement of standards through full range of motion`,
            `Outside Dimensions: 28"W x 65"L x 16"H`,
            `Inside Dimensions: 24"W x 60"L x 8"H`,
        ],
        includes: [
            ``
        ],
        options: [
            `PVS246016: Pole Vault Standard Pad`,
        ],
        usedBy: [],
        category: 'Track and Field',
        subCategory: 'pits',
        group: 'Standard Pads',
        urlCategory: 'Track and Field',
        urlSubCategory: 'pits',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpole-vault-standard-pad-pvs246016-HQ1.jpg?alt=media&token=c7b69f8d-ccbb-4011-a58e-e453a826cca8",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpole-vault-standard-pad-pvs246016-HQ1.jpg?alt=media&token=c7b69f8d-ccbb-4011-a58e-e453a826cca8"
        ]
    },{
        id: 'p381',
        name: `Tapered Pole Vault Standard Pad`,
        sku: 'pvs26t',
        description: `Meets ASTM recommendations with at least 8" of padding on top surface and 2" of padding on all sides. 2 piece units are easy to set up and remove. Covered in 18 oz vinyl. Comes with Velcro measurement tape for accurate placement of standards through full range of motion.`,
        details: [
            `Meets ASTM recommendations with at least 8" of padding on top surface and 2" of padding on all sides`,
            `2 piece units are easy to set up and remove`,
            `Covered in 18 oz vinyl`,
            `Comes with Velcro measurement tape for accurate placement of standards through full range of motion`,
        ],
        includes: [
            ``
        ],
        options: [
            `PVS26T: 26" Tapered Pole Vault Standard Pad - Outside Dimensions: 28"W x 65"L x 26" > 22"H - Inside Dimensions: 24"W x 60"L x 8"H`,
            `PVS32T: 32" Tapered Pole Vault Standard Pad - Outside Dimensions: 28"W x 65"L x 32" > 25"H - Inside Dimensions: 24"W x 60"L x 8"H`,
        ],
        usedBy: [],
        category: 'Track and Field',
        subCategory: 'pits',
        group: 'Standard Pads',
        urlCategory: 'Track and Field',
        urlSubCategory: 'pits',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ftapered-pole-vault-standard-pad-pvs26t-HQ1.jpg?alt=media&token=c7841497-ccf8-4a29-a8f9-77656ddb42b5",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ftapered-pole-vault-standard-pad-pvs26t-HQ1.jpg?alt=media&token=c7841497-ccf8-4a29-a8f9-77656ddb42b5"
        ]
    },{
        id: 'p382',
        name: `Sqaure High Jump Standard Pad`,
        sku: 'ss100',
        description: `Encloses the entire standard base. 2" x 5" cutout to fit around HJ Standard. 18 oz. vinyl. 4 lbs. each.`,
        details: [
            `Encloses the entire standard base`,
            `2" x 5" cutout to fit around HJ standard`,
            `18 oz vinyl`
        ],
        includes: [
            ``
        ],
        options: [
            `SS100: Square High Jump Standard - 22"sq x 3"H (4 lbs each)`
        ],
        usedBy: [],
        category: 'Track and Field',
        subCategory: 'pits',
        group: 'Standard Pads',
        urlCategory: 'Track and Field',
        urlSubCategory: 'pits',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fsquare-high-jump-standard-pad-ss100-HQ1.jpg?alt=media&token=972607fa-89d7-4b01-9241-f287da3cd86f",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fsquare-high-jump-standard-pad-ss100-HQ1.jpg?alt=media&token=972607fa-89d7-4b01-9241-f287da3cd86f",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fsquare-high-jump-standard-pad-ss100-HQ2.jpg?alt=media&token=f7f9d28c-d518-40d6-9cf8-38001560add5"
        ]
    },{
        id: 'p383',
        name: `Vault Box Pad`,
        sku: 'vbp200',
        description: `Brightly colored gold pad protects athletes from sharp metal or fiberglass vault box edges. Does not interfere with the plant area or pole action. Bright color highlights target area of the vault box. 18 oz. heavy duty vinyl and 4" thick high density foam. One size fits all. Use with any pit. Meets ASTM standard F2949 for 2015 track season.`,
        details: [
            `Brightly colored gold pad protects athletes from sharp metal or fiberglass vault box edges`,
            `Does not interfere with the plant area or pole action`,
            `Bright color highlights targets area of the vault box`,
            `18 oz heavy duty vinyl and 4" thick high density foam`,
            `One size fits all... use with any pit`,
            `Meets ASTM standard F2949 for 2015 track season`,
        ],
        includes: [
            ``
        ],
        options: [
            `VBP200: Vault Box Pad`
        ],
        usedBy: [],
        category: 'Track and Field',
        subCategory: 'pits',
        group: 'Pole Vault Pits',
        urlCategory: 'Track and Field',
        urlSubCategory: 'pits',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fvault-box-pad-vbp200-HQ1.jpg?alt=media&token=cdfad6e9-af30-4572-aad9-77adfb76bf60",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fvault-box-pad-vbp200-HQ1.jpg?alt=media&token=cdfad6e9-af30-4572-aad9-77adfb76bf60"
        ]
    },{
        id: 'p384',
        name: `Pole Vault Crossbar`,
        sku: 'bm1502',
        description: `Fisher's Pole Vault Crossbars feature a one piece design and styrene core.`,
        details: [
            `One piece design`,
            `Styrene core`,
        ],
        includes: [
            ``
        ],
        options: [
            `BM1502: Pole Vault Crossbar - 14'10" Long (7 lbs)`
        ],
        usedBy: [],
        category: 'Track and Field',
        subCategory: 'pits',
        group: 'Crossbars',
        urlCategory: 'Track and Field',
        urlSubCategory: 'pits',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fcrossbar-HQ1.jpg?alt=media&token=5dfb0a08-9751-4095-ac8b-d296fc6ac08c",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fcrossbar-HQ1.jpg?alt=media&token=5dfb0a08-9751-4095-ac8b-d296fc6ac08c",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpole-vault-crossbar-bm1502-HQ1.jpg?alt=media&token=e9a9677a-ed48-47eb-a5bd-458d95f35591"
        ]
    },{
        id: 'p385',
        name: `High Jump Crossbar`,
        sku: 'bm1501',
        description: `Fisher's High Jump Crossbars feature a one piece design and styrene core.`,
        details: [
            `One piece design`,
            `Styrene core`,
        ],
        includes: [
            ``
        ],
        options: [
            `BM1501: High Jump Crossbar - 13'1/2" Long (7 lbs)`
        ],
        usedBy: [],
        category: 'Track and Field',
        subCategory: 'pits',
        group: 'Crossbars',
        urlCategory: 'Track and Field',
        urlSubCategory: 'pits',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fcrossbar-HQ1.jpg?alt=media&token=5dfb0a08-9751-4095-ac8b-d296fc6ac08c",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fcrossbar-HQ1.jpg?alt=media&token=5dfb0a08-9751-4095-ac8b-d296fc6ac08c",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fhigh-jump-crossbar-bm1501-HQ1.jpg?alt=media&token=024c3832-0083-4722-b744-2fc9b1b48d73",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fhigh-jump-crossbar-bm1501-HQ2.jpg?alt=media&token=45900484-103b-4721-b90a-1d86e2ffa441"
        ]
    },{
        id: 'p386',
        name: `Crossbar Lifter`,
        sku: 'cbl100',
        description: `Fisher's Steel Crossbar Lifter allows you to set up your crossbar with ease.`,
        details: [
            `Helps you set up your crossbar`
        ],
        includes: [
            ``
        ],
        options: [
            `CBL100: Steel Crossbar Lifter (3 lbs)`
        ],
        usedBy: [],
        category: 'Track and Field',
        subCategory: 'pits',
        group: 'Crossbars',
        urlCategory: 'Track and Field',
        urlSubCategory: 'pits',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fcrossbar-lifter-cbl100-HQ1.jpg?alt=media&token=db5ebfc3-fbd9-4436-a7fb-74f0fb222f8a",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fcrossbar-lifter-cbl100-HQ1.jpg?alt=media&token=db5ebfc3-fbd9-4436-a7fb-74f0fb222f8a",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fcrossbar-lifter-cbl100-HQ2.jpg?alt=media&token=d5c54640-4d36-4eaf-b394-df55e107dc1d"
        ]
    },{
        id: 'p387',
        name: `Cast Aluminum Vault Box`,
        sku: 'tavb',
        description: `This high quality vault box is designed to give years of trouble free use. The thick walled aluminum casting will not warp, crack, or rust. Inside corners are rounded for easy rotation of pole in box. Ideal for universities, colleges and high schools who are looking for a top of the line vault box that meets all IAAF, NCAA, and NFHS specifications.`,
        details: [
            `Designed to give years of trouble-free use`,
            `Thick walled aluminum casting will not warp, crack, or rust`,
            `Inside corners are rounded for easy rotation`,
            `Meets all IAAF, NCAA, and NFHS specifications`,
        ],
        includes: [
            ``
        ],
        options: [
            `TAVB: Cast Aluminum Vault Box (66 lbs)`
        ],
        usedBy: [],
        category: 'Track and Field',
        subCategory: 'pits',
        group: 'Pole Vault Boxes',
        urlCategory: 'Track and Field',
        urlSubCategory: 'pits',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fcast-aluminum-vault-box-tavb-HQ1.jpg?alt=media&token=d0d734e3-787d-49b8-8864-573000641c4b",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fcast-aluminum-vault-box-tavb-HQ1.jpg?alt=media&token=d0d734e3-787d-49b8-8864-573000641c4b"
        ]
    },{
        id: 'p388',
        name: `Cast Aluminum Lid`,
        sku: 'tavbl',
        description: `This lid is designed to give attractive level top-surface for when TAVB not in use. Lid has textured finish to match look of TAVB but may also have synthetic surface applied to match runway.`,
        details: [
            `Textured finish to match look of TAVB`,
            `Optional synthetic surface can be applied to match runway`,
        ],
        includes: [
            ``
        ],
        options: [
            `TAVBL: Cast Aluminum Lid (33 lbs)`
        ],
        usedBy: [],
        category: 'Track and Field',
        subCategory: 'pits',
        group: 'Pole Vault Boxes',
        urlCategory: 'Track and Field',
        urlSubCategory: 'pits',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fcast-aluminum-lid-tavbl-HQ1.jpg?alt=media&token=aca03db7-348c-4b52-9228-3f701474a8a2",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fcast-aluminum-lid-tavbl-HQ1.jpg?alt=media&token=aca03db7-348c-4b52-9228-3f701474a8a2"
        ]
    },{
        id: 'p389',
        name: `Steel Vault Box`,
        sku: 'vbs100',
        description: `Heavy gauge steel featuring a curved front lip which eliminates it as a pole obstacle even when the runway is worn down, Has flared sides and 105 degree angle.`,
        details: [
            `Made with heavy gauge steel`,
            `Features flared sides and 105 degree angle`,
        ],
        includes: [
            ``
        ],
        options: [
            `VBS100: Steel Vault Box (65 lbs)`
        ],
        usedBy: [],
        category: 'Track and Field',
        subCategory: 'pits',
        group: 'Pole Vault Boxes',
        urlCategory: 'Track and Field',
        urlSubCategory: 'pits',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fsteel-vault-box-vbs100-HQ1.jpg?alt=media&token=42c56691-2b02-4f22-b503-dec85b438aad",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fsteel-vault-box-vbs100-HQ1.jpg?alt=media&token=42c56691-2b02-4f22-b503-dec85b438aad"
        ]
    },{
        id: 'p390',
        name: `Steel Lid`,
        sku: 'tsvbl',
        description: `Steel lid for VBS100`,
        details: [
            `Made with heavy gauge steel`,
            `Features flared sides and 105 degree angle`,
        ],
        includes: [
            ``
        ],
        options: [
            `VBS100: Steel Lid (35 lbs)`
        ],
        usedBy: [],
        category: 'Track and Field',
        subCategory: 'pits',
        group: 'Pole Vault Boxes',
        urlCategory: 'Track and Field',
        urlSubCategory: 'pits',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fsteel-lid-tsvbl-HQ1.jpg?alt=media&token=f178b684-4bfb-495f-b5c1-dd6c7960ffac",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fsteel-lid-tsvbl-HQ1.jpg?alt=media&token=f178b684-4bfb-495f-b5c1-dd6c7960ffac"
        ]
    },{
        id: 'p391',
        name: `Stainless Steel Vault Box`,
        sku: 'tssvb',
        description: `Smooth, finished stainless box made of 12 gauge stainless steel will not rust, crack or split. The preferred box for universities, colleges, and high schools, where they are looking for a top quality box that will give years of trouble-free use.`,
        details: [
            `Smooth, finished stainless box made of 12 gauge stainless steel`,
            `Will not rust, crack, or split`,
        ],
        includes: [
            ``
        ],
        options: [
            `TSSVB: Stainless Steel Vault Box (65 lbs)`
        ],
        usedBy: [],
        category: 'Track and Field',
        subCategory: 'pits',
        group: 'Pole Vault Boxes',
        urlCategory: 'Track and Field',
        urlSubCategory: 'pits',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fstainless-steel-vault-box-tssvb-HQ1.jpg?alt=media&token=1c8cf06c-e0bb-450a-9efa-26103ace8844",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fstainless-steel-vault-box-tssvb-HQ1.jpg?alt=media&token=1c8cf06c-e0bb-450a-9efa-26103ace8844"
        ]
    },{
        id: 'p392',
        name: `Pole Vault Slide Box`,
        sku: 'tpvsb',
        description: `This lightweight fiberglass slide box is reinforced for years of heavy use. Bright white, it is highly visible and slides straight way. Use indoors or out for fast development of the most crucial part of the vault : run and plant.`,
        details: [
            `Made from lightweight fiberglass`,
            `Highly visible, bright white color`,
            `Slides straight way`,
        ],
        includes: [
            ``
        ],
        options: [
            `TPVSB: Pole Vault Slide Box (15 lbs)`
        ],
        usedBy: [],
        category: 'Track and Field',
        subCategory: 'pits',
        group: 'Pole Vault Boxes',
        urlCategory: 'Track and Field',
        urlSubCategory: 'pits',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpole-vault-slide-box-tpvsb-HQ1.jpg?alt=media&token=a0dabcab-e152-4f7d-8e77-98ffea2e7c97",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpole-vault-slide-box-tpvsb-HQ1.jpg?alt=media&token=a0dabcab-e152-4f7d-8e77-98ffea2e7c97"
        ]
    },{
        id: 'p393',
        name: `Round Aluminum Hurdle`,
        sku: 'hs1250ra',
        description: `Hurdle height adjusts from 30" to 42". Aluminum round tube construction. Push button adjustment every 3". Uprights available in 11 colors. Free screening on orders of 24 hurdles or more. Meets National Federation regulations. Lexan® gate board.`,
        details: [
            `Hurdle height adjusts from 30" to 42"`,
            `Aluminum round tube construction`,
            `Push button adjustment every 3"`,
            `Uprights available in 11 colors`,
            `Free screening on 24 or more hurdles`,
            `Meets National Federation regulations`,
            `Lexan® gate board`,
        ],
        includes: [
            ``
        ],
        options: [
            `HS1250RA: Round Aluminum Hurdle`,
            `5842: Replacement Gate Board`,
        ],
        usedBy: [],
        category: 'Track and Field',
        subCategory: 'track equipment',
        group: 'Hurdles',
        urlCategory: 'Track and Field',
        urlSubCategory: 'track equipment',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fround-aluminum-hurdle-hs1250ra-HQ1.jpg?alt=media&token=5fc131f9-ff6c-4538-b827-313664beddf2",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fround-aluminum-hurdle-hs1250ra-HQ1.jpg?alt=media&token=5fc131f9-ff6c-4538-b827-313664beddf2",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fround-aluminum-hurdle-hs1250ra-HQ2.jpg?alt=media&token=b668aa06-606d-4118-a6c2-a11b976e0dec",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fround-aluminum-hurdle-hs1250ra-HQ3.jpg?alt=media&token=54fba306-a604-4129-9405-2d49fa730a32"
        ]
    },{
        id: 'p394',
        name: `Square Aluminum Hurdle`,
        sku: 'hs1450sa',
        description: `Hurdle height adjusts from 30" to 42". Aluminum square tube construction. Push button adjustment every 3". Uprights available in 11 colors. Free screening on orders of 24 hurdles or more. Meets National Federation regulations. Lexan® gate board.`,
        details: [
            `Hurdle height adjusts from 30" to 42"`,
            `Aluminum square tube construction`,
            `Push button adjustment every 3"`,
            `Uprights available in 11 colors`,
            `Free screening on 24 or more hurdles`,
            `Meets National Federation regulations`,
            `Lexan® gate board`,
        ],
        includes: [
            ``
        ],
        options: [
            `HS1450SA: Square Aluminum Hurdle`,
            `5842: Replacement Gate Board`,
        ],
        usedBy: [],
        category: 'Track and Field',
        subCategory: 'track equipment',
        group: 'Hurdles',
        urlCategory: 'Track and Field',
        urlSubCategory: 'track equipment',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fsquare-aluminum-hurdle-hs1450sa-HQ1.jpg?alt=media&token=b71a83fa-6ca4-48d3-b9da-633652291720",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fsquare-aluminum-hurdle-hs1450sa-HQ1.jpg?alt=media&token=b71a83fa-6ca4-48d3-b9da-633652291720",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fsquare-aluminum-hurdle-hs1450sa-HQ2.jpg?alt=media&token=4b7a24cd-0b5e-4f03-a1dd-65647eb17f0d",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fsquare-aluminum-hurdle-hs1450sa-HQ3.jpg?alt=media&token=407abe84-9644-4e67-bfea-3498f8e98f37"
        ]
    },{
        id: 'p395',
        name: `Hurdle Cart`,
        sku: 'mhc',
        description: `Fisher's Hurdle Cart features an all aluminum construction and can hold up to 16 hurdles. `,
        details: [
            `All aluminum construction`,
            `Holds up to 16 hurdles`,
            `4 pneumatic wheels`,
            `Wheels at handle end swivel for easy handling`,
        ],
        includes: [
            ``
        ],
        options: [
            `MHC: Hurdle Cart`,
        ],
        usedBy: [],
        category: 'Track and Field',
        subCategory: 'track equipment',
        group: 'Hurdles',
        urlCategory: 'Track and Field',
        urlSubCategory: 'track equipment',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fhurdle-cart-mhc-HQ1.jpg?alt=media&token=4d776ab4-e9e5-4789-8016-f1ac689df07e",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fhurdle-cart-mhc-HQ1.jpg?alt=media&token=4d776ab4-e9e5-4789-8016-f1ac689df07e"
        ]
    },{
        id: 'p396',
        name: `Tray System`,
        sku: 'ttss',
        description: `This 8" wide tray system is the most popular tray system for both the college and high school market. The 8" wide board meets all the specifications for college and high school. The weatherproof high-density urethane top surface of the board makes an excellent rake off surface. The take-off board is mounted to a steel framework with 6 adjusting bolts so that you can level the board with the runway surface. The heavy-duty tray is made of bright zinc plated steel.`,
        details: [
            `Meets all the specifications for college and high school`,
            `Weatherproof, high-density urethane top surface`,
            `Heavy-duty tray is made of bright zinc plated steel`,
        ],
        includes: [
            ``
        ],
        options: [
            `TTSS: Tray System (75 lbs)`,
        ],
        usedBy: [],
        category: 'Track and Field',
        subCategory: 'track equipment',
        group: 'Toe Boards',
        urlCategory: 'Track and Field',
        urlSubCategory: 'track equipment',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ftray-system-ttss-HQ1.jpg?alt=media&token=38e09860-12f3-4907-b15e-79129d346023",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ftray-system-ttss-HQ1.jpg?alt=media&token=38e09860-12f3-4907-b15e-79129d346023"
        ]
    },{
        id: 'p397',
        name: `International Take-off Tray System`,
        sku: 'ttics',
        description: `Made the same as our TTSS board with the addition of a foul board and cover. Foul board is made of 1/2" plywood and can easily be taken out and plasticine put in for meets. Cover is made of heavy-duty steel.`,
        details: [
            `Meets all the specifications for college and high school`,
            `Weatherproof, high-density urethane top surface`,
            `Heavy-duty tray is made of bright zinc plated steel`,
            `Foul board is made of 1/2" plywood and can easily be swapped for plasticine for meets`,
            `Cover is made of heavy-duty steel`,
        ],
        includes: [
            ``
        ],
        options: [
            `TTICS: International Take-off Tray System (105 lbs)`,
        ],
        usedBy: [],
        category: 'Track and Field',
        subCategory: 'track equipment',
        group: 'Toe Boards',
        urlCategory: 'Track and Field',
        urlSubCategory: 'track equipment',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Finternational-take-off-tray-system-ttics-HQ1.jpg?alt=media&token=8351a583-03a0-419a-bda7-d80482b901aa",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Finternational-take-off-tray-system-ttics-HQ1.jpg?alt=media&token=8351a583-03a0-419a-bda7-d80482b901aa",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Finternational-take-off-tray-system-ttics-HQ2.jpg?alt=media&token=94ac70e9-8567-479e-b5d6-4eaaed4b3047"
        ]
    },{
        id: 'p398',
        name: `Poly High School Take-off Board`,
        sku: 'tpolytb',
        description: `New problem solving technology Poly take-off board eliminates duarbility and cracking problems often experienced in wood boards. Long lasting poly surface comes with a 50year warranty. This two part poly board has a softer texture 3/4" tp board for better plants. Attached to high density 3/4" grey poly under board to eliminate warping.`,
        details: [
            `Eliminates cracking and durability problems often experienced in wood boards`,
            `Soft texture 3/4" top board for better plants`,
            `High desnity 3/4" grey poly under board to eliminate warping`,
            `5 year warranty`,
        ],
        includes: [
            ``
        ],
        options: [
            `TPOLYTB: Poly High School Take-off Board (48 lbs)`,
        ],
        usedBy: [],
        category: 'Track and Field',
        subCategory: 'track equipment',
        group: 'Toe Boards',
        urlCategory: 'Track and Field',
        urlSubCategory: 'track equipment',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpoly-high-school-take-off-board-tpolytb-HQ1.jpg?alt=media&token=085c3e0c-9fd4-4e3c-bf9d-56f18c6813f9",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpoly-high-school-take-off-board-tpolytb-HQ1.jpg?alt=media&token=085c3e0c-9fd4-4e3c-bf9d-56f18c6813f9",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fpoly-high-school-take-off-board-tpolytb-HQ2.jpg?alt=media&token=c46dce20-d992-4bc6-bcc2-a97176017146"
        ]
    },{
        id: 'p399',
        name: `High School Take-off Board`,
        sku: 'thstb',
        description: `Complies with current high school rules. 1-1/4" x 20" x 48" marine grade plywood board sealed, primed, and finished with a white enamel paint.`,
        details: [
            `Complies with current high school rules`,
            `Marine grade plywood board is sealed, primed, and finished with a white enamel paint`,
        ],
        includes: [
            ``
        ],
        options: [
            `THSTB: High School Take-off Board - 1 1/4" x 20" x 48" (32 lbs)`,
        ],
        usedBy: [],
        category: 'Track and Field',
        subCategory: 'track equipment',
        group: 'Toe Boards',
        urlCategory: 'Track and Field',
        urlSubCategory: 'track equipment',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fhigh-school-take-off-board-thstb-HQ1.jpg?alt=media&token=4132718e-be41-41c3-84fa-2d1d126f251c",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fhigh-school-take-off-board-thstb-HQ1.jpg?alt=media&token=4132718e-be41-41c3-84fa-2d1d126f251c"
        ]
    },{
        id: 'p400',
        name: `Tray for THSTB`,
        sku: 'thstbt',
        description: `For permanent installation in synthetic runways, this 48" x 20" stainless steel tray system works with out THSTB take-off board. Drain holes for all weather conditions.`,
        details: [
            `Can be installed in synthetic runways`,
            `Made from stainless steel`,
            `Features drain holes for all weather conditions`,
        ],
        includes: [
            ``
        ],
        options: [
            `THSTBT: Tray for THSTB - 20" x 48" (36 lbs)`,
        ],
        usedBy: [],
        category: 'Track and Field',
        subCategory: 'track equipment',
        group: 'Toe Boards',
        urlCategory: 'Track and Field',
        urlSubCategory: 'track equipment',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ftray-for-thstb-thstbt-HQ1.jpg?alt=media&token=8f1bd024-cca4-4151-893f-eb33c42a8835",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ftray-for-thstb-thstbt-HQ1.jpg?alt=media&token=8f1bd024-cca4-4151-893f-eb33c42a8835"
        ]
    },{
        id: 'p401',
        name: `Long Jump Triple Jump Take-off Board`,
        sku: 'tlt',
        description: `This official take-off board is made with the same technology as our TPOLYTB board. 48" long by 8" wide. 3/4" polyurethane top surface on 1-1/2" x 8" plastic underboard.`,
        details: [
            `3/4" thick polyurethane top`,
            `1 1/2" thick plastic underboard`,
        ],
        includes: [
            ``
        ],
        options: [
            `TLT: Long Jump Triple Jump Take-off Board - 8" x 48" (32 lbs)`,
        ],
        usedBy: [],
        category: 'Track and Field',
        subCategory: 'track equipment',
        group: 'Toe Boards',
        urlCategory: 'Track and Field',
        urlSubCategory: 'track equipment',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Flong-jump-triple-jump-take-off-board-tlt-HQ1.jpg?alt=media&token=2eee6900-1a91-4fad-83f3-f382aafa4824",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Flong-jump-triple-jump-take-off-board-tlt-HQ1.jpg?alt=media&token=2eee6900-1a91-4fad-83f3-f382aafa4824",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Flong-jump-triple-jump-take-off-board-tlt-HQ2.jpg?alt=media&token=52203085-fabc-41e2-b93c-600bf8a0068a"
        ]
    },{
        id: 'p402',
        name: `Cast Aluminum Toe Board`,
        sku: 'tatb',
        description: `Cast aluminum construction, meets all rule specifications. Designed for level pads.`,
        details: [
            `Cast aluminum construction`,
            `Meets all rule specifications`,
            `Designed for level pads (most common)`,
        ],
        includes: [
            ``
        ],
        options: [
            `TPTB: Cast Aluminum Toe Board - for level pad (24 lbs)`
        ],
        usedBy: [],
        category: 'Track and Field',
        subCategory: 'track equipment',
        group: 'Toe Boards',
        urlCategory: 'Track and Field',
        urlSubCategory: 'track equipment',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fcast-aluminum-toe-board-tatb-HQ1.jpg?alt=media&token=e69f4f03-f8f5-478e-9a86-9025a2d9e753",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fcast-aluminum-toe-board-tatb-HQ1.jpg?alt=media&token=e69f4f03-f8f5-478e-9a86-9025a2d9e753"
        ]
    },{
        id: 'p403',
        name: `Depressed Cast Aluminum Toe Board`,
        sku: 'tatbdp',
        description: `Cast aluminum construction, meets all rule specifications.`,
        details: [
            `Cast aluminum construction`,
            `Meets all rule specifications`,
        ],
        includes: [
            ``
        ],
        options: [
            `TPTBDP: Depressed Cast Aluminum Toe Board (20 lbs)`
        ],
        usedBy: [],
        category: 'Track and Field',
        subCategory: 'track equipment',
        group: 'Toe Boards',
        urlCategory: 'Track and Field',
        urlSubCategory: 'track equipment',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fdepressed-cast-aluminum-toe-board-tatbdp-HQ1.jpg?alt=media&token=4d7e9dc2-b2d7-49b6-a96c-76ca31419d6f",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fdepressed-cast-aluminum-toe-board-tatbdp-HQ1.jpg?alt=media&token=4d7e9dc2-b2d7-49b6-a96c-76ca31419d6f"
        ]
    },{
        id: 'p404',
        name: `Aluminum Baton`,
        sku: 'ab',
        description: `Aluminum Batons have safety rounded ends.`,
        details: [
            `Safety rounded ends`,
            `Available in Black, Gold, Green, Royal, Scarlet, and Silver`,
        ],
        includes: [
            ``
        ],
        options: [
            `AB: Aluminum Baton`
        ],
        usedBy: [],
        category: 'Track and Field',
        subCategory: 'track equipment',
        group: 'Batons',
        urlCategory: 'Track and Field',
        urlSubCategory: 'track equipment',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Faluminum-baton-ab-HQ1.jpg?alt=media&token=b5262415-3d71-4490-be6f-fe3beaf09477",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Faluminum-baton-ab-HQ1.jpg?alt=media&token=b5262415-3d71-4490-be6f-fe3beaf09477",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Faluminum-baton-ab-HQ2.jpg?alt=media&token=4f8553d3-2643-4341-b9b0-a2a82dcfe5f8"
        ]
    },{
        id: 'p405',
        name: `Plastic Baton`,
        sku: 'pb',
        description: `Plastic Batons are great for practice.`,
        details: [
            `Available Gold only`,
        ],
        includes: [
            ``
        ],
        options: [
            `PB: Plastic Baton`
        ],
        usedBy: [],
        category: 'Track and Field',
        subCategory: 'track equipment',
        group: 'Batons',
        urlCategory: 'Track and Field',
        urlSubCategory: 'track equipment',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fplastic-baton-pb-HQ1.jpg?alt=media&token=3b0eae47-af31-4633-a723-bacd97ab0350",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fplastic-baton-pb-HQ1.jpg?alt=media&token=3b0eae47-af31-4633-a723-bacd97ab0350"
        ]
    },{
        id: 'p406',
        name: `Championship Starting Block`,
        sku: 'tchamp',
        description: `5" wide 4-position adjustable pedals. 2 1/2" center rail. Lightweight aluminum construction. Fitting High School and College completion. Anchor spikes and all-weather spikes included.`,
        details: [
            `5" wide 4-position adjustable pedals`,
            `2 1/2" center rail`,
            `Lightweight aluminum construction`,
            `Fitting High School and College competition`,
            `Anchor spikes and all-weather spikes included`,
        ],
        includes: [
            ``
        ],
        options: [
            `TCHAMP: Championship Starting Block (11 lbs)`
        ],
        usedBy: [],
        category: 'Track and Field',
        subCategory: 'track equipment',
        group: 'Starter Blocks',
        urlCategory: 'Track and Field',
        urlSubCategory: 'track equipment',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fchampionship-starting-block-tchamp-HQ1.jpg?alt=media&token=43929bfb-e964-4a57-a451-53a6047bb228",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fchampionship-starting-block-tchamp-HQ1.jpg?alt=media&token=43929bfb-e964-4a57-a451-53a6047bb228",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fchampionship-starting-block-tchamp-HQ2.jpg?alt=media&token=16da3803-8791-472a-bc4c-3a6e0edafe30"
        ]
    },{
        id: 'p407',
        name: `Cheetah Elite Aluminum Starting Block`,
        sku: 'tceb',
        description: `5" wide 4-position adjustable pedals. 3" center rail. Lightweight aluminum construction. Fitting Collegiate and International competition. Anchor spikes and all-weather spikes included.`,
        details: [
            `5" wide 4-position adjustable pedals`,
            `3" center rail`,
            `Lightweight aluminum construction`,
            `Fitting High School and College competition`,
            `Anchor spikes and all-weather spikes included`,
        ],
        includes: [
            ``
        ],
        options: [
            `TCEB: Cheetah Elite Aluminum Starting Block (16 lbs)`
        ],
        usedBy: [],
        category: 'Track and Field',
        subCategory: 'track equipment',
        group: 'Starter Blocks',
        urlCategory: 'Track and Field',
        urlSubCategory: 'track equipment',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fcheetah-elite-aluminum-starting-block-tceb-HQ1.jpg?alt=media&token=3cc1ce39-1997-47b7-b301-7e6ebe438335",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fcheetah-elite-aluminum-starting-block-tceb-HQ1.jpg?alt=media&token=3cc1ce39-1997-47b7-b301-7e6ebe438335",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fcheetah-elite-aluminum-starting-block-tceb-HQ2.jpg?alt=media&token=733d48f5-a601-4230-8964-a10e4c6e3cd3"
        ]
    },{
        id: 'p408',
        name: `Olympian Adjustable Starting Block`,
        sku: 'toly',
        description: `4" adjustable pedals. Pedal adjusts to virtually any angle. Snap lock plunger locks pedals into position quickly. Includes all-weather and anchor spikes with adjustment wrench.`,
        details: [
            `4" adjustable pedals`,
            `Pedals adjust to virtually any angle`,
            `Snap lock plunger locks pedals into position quickly`,
            `Includes all-weather and anchor spikes with adjustment wrench`,
        ],
        includes: [
            ``
        ],
        options: [
            `TOLY: Olympian Adjustable Starting Block (12 lbs)`
        ],
        usedBy: [],
        category: 'Track and Field',
        subCategory: 'track equipment',
        group: 'Starter Blocks',
        urlCategory: 'Track and Field',
        urlSubCategory: 'track equipment',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Folympian-adjustable-starting-block-toly-HQ1.jpg?alt=media&token=732c4abd-3f9e-41b5-bad4-24d00971e96a",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Folympian-adjustable-starting-block-toly-HQ1.jpg?alt=media&token=732c4abd-3f9e-41b5-bad4-24d00971e96a"
        ]
    },{
        id: 'p409',
        name: `Speed Start Starting Block`,
        sku: 'tstar',
        description: `Adjustment pins are spring loaded so pedals can be quickly moved. Includes all-weather spikes and anchor spikes.`,
        details: [
            `Adjustment pins are spring loaded so pedals can be quickly moved`,
            `Anchor spikes and all-weather spikes included`,
        ],
        includes: [
            ``
        ],
        options: [
            `TSTAR: Speed Star Starting Block (11 lbs)`
        ],
        usedBy: [],
        category: 'Track and Field',
        subCategory: 'track equipment',
        group: 'Starter Blocks',
        urlCategory: 'Track and Field',
        urlSubCategory: 'track equipment',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fspeed-star-starting-block-tstar-HQ1.jpg?alt=media&token=31d67fff-4458-4074-8f6f-7e09bdeee8c1",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fspeed-star-starting-block-tstar-HQ1.jpg?alt=media&token=31d67fff-4458-4074-8f6f-7e09bdeee8c1"
        ]
    },{
        id: 'p410',
        name: `Wide Track Starting Block`,
        sku: 'ttrack',
        description: `5" wide pedals. Spring loaded pedal adjustment pins. Includes all-weather and anchor spikes.`,
        details: [
            `5" wide pedals`,
            `Spring loaded pedal adjustment pins`,
            `Anchor spikes and all-weather spikes included`,
        ],
        includes: [
            ``
        ],
        options: [
            `TTRACK: Wide Track Starting Block (11 lbs)`
        ],
        usedBy: [],
        category: 'Track and Field',
        subCategory: 'track equipment',
        group: 'Starter Blocks',
        urlCategory: 'Track and Field',
        urlSubCategory: 'track equipment',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fwide-track-starting-block-ttrack-HQ1.jpg?alt=media&token=2ce09898-8ac8-431b-be86-f8a8f4ff1498",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fwide-track-starting-block-ttrack-HQ1.jpg?alt=media&token=2ce09898-8ac8-431b-be86-f8a8f4ff1498"
        ]
    },{
        id: 'p411',
        name: `Indoor Starting Blocks`,
        sku: 'tisb',
        description: `Red rubber blocks (color may vary). Adhere to any clean floor without glue. No holes in floor. Removes easily from floor surfaces.`,
        details: [
            `Red rubber blocks (color may vary)`,
            `Adhere to any clean floor without glue`,
            `No holes in floor`,
            `Removes easily from floor surfaces`,
        ],
        includes: [
            ``
        ],
        options: [
            `TISB: 1 Pair of Indoor Starting Blocks (9 lbs)`
        ],
        usedBy: [],
        category: 'Track and Field',
        subCategory: 'track equipment',
        group: 'Starter Blocks',
        urlCategory: 'Track and Field',
        urlSubCategory: 'track equipment',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Findoor-starting-block-tisb-HQ1.jpg?alt=media&token=f8621566-f5c1-463e-babe-47ae4f94106c",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Findoor-starting-block-tisb-HQ1.jpg?alt=media&token=f8621566-f5c1-463e-babe-47ae4f94106c"
        ]
    },{
        id: 'p196',
        name: 'Titan Sideline Track Protector',
        sku: 'ttso',
        description: `Titan Sideline Track Protectors are constructed using our 12 oz Titan Mesh material and allow for water to easily flow though without retaining any moisture. Titan Mesh is spike-proof, which keeps your track safe. Available in gray, beige, black, red, royal, columbia blue, purple, orange, navy, forest green, and maroon.`,
        details: [
            `Built from the toughest mesh available, 12 oz Titan Mesh`,
            `Protect your track from wear and tear`,
            `Water will flow through mesh without holding any moisture`,
            `Customize with school colors and your team mascot or logo`,
        ],
        includes: [
            ``
        ],
        options: [
            `TTSO: Custom Track Protector`,
            `TTP630: Track Protector 6'W x 30'L`,
            `TTP630W: Track Protector 6'W x 30'L (weighted)`,
            `TTP1230: Track Protector 12'W x 30'L`,
            `TTP1230W: Track Protector 12'W x 30'L (weighted)`,
            `TTP1830: Track Protector 18'W x 30'L`,
            `TTP1830W: Track Protector 18'W x 30'L (weighted)`,
            `TTP1840: Track Protector 18'W x 40'L`,
            `TTP1840W: Track Protector 18'W x 40'L (weighted)`,
        ],
        usedBy: [],
        category: 'football Track and Field',
        subCategory: 'gameday track equipment',
        group: 'Track Protectors',
        urlCategory: 'football Track and Field',
        urlSubCategory: 'gameday track equipment',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ftitan-sideline-track-marker-ttso-2400-6.jpg?alt=media&token=f380327c-a34c-4848-8706-b309a57578ff",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ftitan-sideline-track-marker-ttso-2400-6.jpg?alt=media&token=f380327c-a34c-4848-8706-b309a57578ff",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ftitan-sideline-track-marker-ttso-2400-5.jpg?alt=media&token=dd55a71c-4ebd-41e7-90f5-8827cc5fab37",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ftitan-sideline-track-marker-ttso-HQ1.jpg?alt=media&token=9a8aa0ac-90a9-4194-ac48-f25ccf817862",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ftitan-sideline-track-marker-ttso-HQ2.jpg?alt=media&token=0976e073-11a7-4532-8408-6c25bd06ca87",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ftitan-sideline-track-marker-ttso-HQ3.jpg?alt=media&token=05ca29ff-6159-4c67-8ae2-ec1e937bd360",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Ftitan-sideline-track-marker-ttso-HQ4.jpg?alt=media&token=7df62094-040b-47fc-8b63-0a0b8d93b0a8"
        ]
    },{
        id: 'p412',
        name: `Roll Out Runway`,
        sku: 'sr34b',
        description: `Made to be rolled over existing worn out runways. Use as a protective surface on all-weather track during football games or other activities. Made with black rubber/urethane mix. Custom lengths are available. Minimum order of 50'.`,
        details: [
            `Made to be rolled over existing worn out runways`,
            `Use as a protective surface on all-weather track during football games or other activities`,
            `Made with black rubber/urethane mix`,
            `Custom lengths are available`,
            `Minimum order of 50'`,
        ],
        includes: [
            ``
        ],
        options: [
            `SR34B: Roll Out Runway 3'W x 1/2"Thick`,
            `SR44B: Roll Out Runway 4'W x 1/2"Thick`,
            `SR38B: Roll Out Runway 3'W x 3/8"Thick`,
            `SR48B: Roll Out Runway 4'W x 3/8"Thick`,
        ],
        usedBy: [],
        category: 'football Track and Field',
        subCategory: 'gameday track equipment',
        group: 'Track Protectors',
        urlCategory: 'football Track and Field',
        urlSubCategory: 'gameday track equipment',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Froll-out-runway-sr34b-HQ2.jpg?alt=media&token=66cbcf27-48e2-4df9-96b9-48e936f54707",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Froll-out-runway-sr34b-HQ2.jpg?alt=media&token=66cbcf27-48e2-4df9-96b9-48e936f54707",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Froll-out-runway-sr34b-HQ1.jpg?alt=media&token=027f1d5d-0bb6-4b83-897a-1a1612d486c8"
        ]
    },{
        id: 'p415',
        name: `Aluminum Discus Cage with Ground Sleeve`,
        sku: 'dc1300',
        description: `504 Barrier net. (6)2.5" square x 13'6" angled poles. (6)3" x 3" x 30" ground sleeves.`,
        details: [
            `(6) 2.5" sqaure x 13'6" angled poles`,
            `(6) 3" x 3" x 30" ground sleeves`,
            `(1) 504 Barrier net`,
        ],
        includes: [
            ``
        ],
        options: [
            `DC1300: Aluminum Discus Cage with Ground Sleeve`,
        ],
        usedBy: [],
        category: 'Track and Field',
        subCategory: 'field equipment',
        group: 'Discus',
        urlCategory: 'Track and Field',
        urlSubCategory: 'field equipment',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Faluminum-discus-cage-with-ground-sleeve-dc1300-HQ1.jpg?alt=media&token=a1b72870-0681-4b9a-90b3-0676dc5f54ff",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Faluminum-discus-cage-with-ground-sleeve-dc1300-HQ1.jpg?alt=media&token=a1b72870-0681-4b9a-90b3-0676dc5f54ff",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Faluminum-discus-cage-with-ground-sleeve-dc1300-HQ2.jpg?alt=media&token=13168fc1-d594-4cd3-b34e-c812e3ad753c",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Faluminum-discus-cage-with-ground-sleeve-dc1300-HQ3.jpg?alt=media&token=2ff7d712-65fc-424c-9a01-bc07a747475e"
        ]
    },{
        id: 'p413',
        name: `Discus Cage`,
        sku: 'dc100',
        description: `Weather treated nylon netting. 57-1/2' x 11-1/2' safe practice area. (6)14" sq. steel bases with anchor holes. 18 anchor stakes for added support.`,
        details: [
            `Weather treated nylon netting`,
            `57'6" x 11'6" safe practice area`,
            `(6) 14"sq. steel bases with anchor holes`,
            `18 anchor stakes for added support`,
        ],
        includes: [
            ``
        ],
        options: [
            `DC100: Discus Cage`,
            `DC100NET: Discus Cage Net (fits DC100 and DC100P)`,
        ],
        usedBy: [],
        category: 'Track and Field',
        subCategory: 'field equipment',
        group: 'Discus',
        urlCategory: 'Track and Field',
        urlSubCategory: 'field equipment',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fdiscus-cage-dc100-HQ1.jpg?alt=media&token=c096144b-2f1a-45a6-8ebb-968ff999d2b2",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fdiscus-cage-dc100-HQ1.jpg?alt=media&token=c096144b-2f1a-45a6-8ebb-968ff999d2b2"
        ]
    },{
        id: 'p414',
        name: `Portable Discus Cage`,
        sku: 'dc100p',
        description: `Weather treated nylon netting. 57-1/2' x 11-1/2' safe practice area. (6)24" square steel bases. (6)sand bags included for added support.`,
        details: [
            `Weather treated nylon netting`,
            `57'6" x 11'6" safe practice area`,
            `(6) 24" sqaure steel bases`,
            `(6) Sand bags for added support`,
        ],
        includes: [
            ``
        ],
        options: [
            `DC100P: Portable Discus Cage`,
            `DC100NET: Discus Cage Net (fits DC100 and DC100P)`,
        ],
        usedBy: [],
        category: 'Track and Field',
        subCategory: 'field equipment',
        group: 'Discus',
        urlCategory: 'Track and Field',
        urlSubCategory: 'field equipment',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fportable-discus-cage-dc100p-HQ1.jpg?alt=media&token=c0b8f767-96b4-4cee-9ad2-d8525d35b625",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fportable-discus-cage-dc100p-HQ1.jpg?alt=media&token=c0b8f767-96b4-4cee-9ad2-d8525d35b625"
        ]
    },{
        id: 'p416',
        name: `Wood Discus`,
        sku: 'dw476',
        description: `Seasoned hardwood with steel outer rim. Meets HS & College regulations.`,
        details: [
            `Seasoned hardwood with steel outer rim`,
            `Meets H.S and College regulations`,
        ],
        includes: [
            ``
        ],
        options: [
            `DW476: Wood Discus (2 kilos)`,
            `DW477: Wood Discus (1 kilo)`,
            `DW478: Wood Discus (1.61 kilo H.S boys)`,
            `DISC200: 2-Discus Bag`,
        ],
        usedBy: [],
        category: 'Track and Field',
        subCategory: 'field equipment',
        group: 'Discus',
        urlCategory: 'Track and Field',
        urlSubCategory: 'field equipment',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fwood-discus-dw476-HQ1.jpg?alt=media&token=470e476f-6561-4eaa-b428-8364487eb359",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fwood-discus-dw476-HQ1.jpg?alt=media&token=470e476f-6561-4eaa-b428-8364487eb359",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fwood-discus-dw476-HQ2.jpg?alt=media&token=b944cc6b-e083-4183-951b-43e5f65794c2"
        ]
    },{
        id: 'p417',
        name: `Rubber Discus`,
        sku: 'dr576',
        description: `Solid 1 piece construction. Molded for close tolerance in size and weight. Meets HS & College regulations.`,
        details: [
            `Solid 1 piece construction`,
            `Molded for close tolerance in size and weight`,
            `Meets H.S and College regulations`,
        ],
        includes: [
            ``
        ],
        options: [
            `DR576: Rubber Discus (2 kilos - College)`,
            `DR577: Rubber Discus (1 kilo)`,
            `DR578: Rubber Discus (1.61 kilo H.S. boys)`,
            `DISC200: 2-Discus Bag`,
        ],
        usedBy: [],
        category: 'Track and Field',
        subCategory: 'field equipment',
        group: 'Discus',
        urlCategory: 'Track and Field',
        urlSubCategory: 'field equipment',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Frubber-discus-dr576-HQ1.jpg?alt=media&token=9442bb10-99e6-4713-854b-d6375d0258f4",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Frubber-discus-dr576-HQ1.jpg?alt=media&token=9442bb10-99e6-4713-854b-d6375d0258f4",
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Frubber-discus-dr576-HQ2.jpg?alt=media&token=f60953ac-2470-4fa9-b2b4-24be4e6182b6"
        ]
    },{
        id: 'p418',
        name: `Cast Iron Shot`,
        sku: 'spc6',
        description: `Conforms to all High School and College specifications.`,
        details: [
            `Conforms to all high school and collegiate specifications`,
        ],
        includes: [
            ``
        ],
        options: [
            `SPC6: 6 pound shot`,
            `SPC8: 8 pound shot`,
            `SPC12: 12 pound shot`,
            `SPC16: 16 pound shot`,
            `SPC4: 4 kilo shot`,
        ],
        usedBy: [],
        category: 'Track and Field',
        subCategory: 'field equipment',
        group: 'Shot Put',
        urlCategory: 'Track and Field',
        urlSubCategory: 'field equipment',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fcast-iron-shot-spc6-HQ1.jpg?alt=media&token=ed8c665e-d720-45f1-a2ed-b775bbca1bcf",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fcast-iron-shot-spc6-HQ1.jpg?alt=media&token=ed8c665e-d720-45f1-a2ed-b775bbca1bcf"
        ]
    },{
        id: 'p419',
        name: `Indoor Shot`,
        sku: 'spp8',
        description: `Lead filled and sealed with a plastic plug. Covered in a PVC plastic shell.`,
        details: [
            `Lead filled`,
            `Sealed with a plastic plug`,
            `PVC plastic shell covering`,
        ],
        includes: [
            ``
        ],
        options: [
            `SPP8: 8 pound indoor shot`,
            `SPP12: 12 pound indoor shot`,
            `SPP16: 16 pound indoor shot`,
            `SPP4K: 4 kilo indoor shot`,
        ],
        usedBy: [],
        category: 'Track and Field',
        subCategory: 'field equipment',
        group: 'Shot Put',
        urlCategory: 'Track and Field',
        urlSubCategory: 'field equipment',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Findoor-shots-spp8-HQ1.jpg?alt=media&token=0025cf2d-b367-49f9-88d7-852948f0ae39",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Findoor-shots-spp8-HQ1.jpg?alt=media&token=0025cf2d-b367-49f9-88d7-852948f0ae39"
        ]
    },{
        id: 'p420',
        name: `Long Jump Pit Cover`,
        sku: 'ljc100',
        description: `Protects your pits from weather. 14 anchor pockets. Made with vinyl coated mesh.`,
        details: [
            `Protects your pits from weather`,
            `14 anchor pockets`,
            `Made with vinyl coated mesh`,
        ],
        includes: [
            ``
        ],
        options: [
            `LJC100: Pit Cover 12' x 18'`,
            `LJPC1125: Pit Cover 11'6" x 25'`,
            `LJCSO: Special Size Pit Cover`,
        ],
        usedBy: [],
        category: 'Track and Field',
        subCategory: 'field equipment',
        group: '',
        urlCategory: 'Track and Field',
        urlSubCategory: 'field equipment',
        image: "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Flong-jump-pit-cover-ljc100-HQ1.jpg?alt=media&token=8a2442bb-9ab4-4ba5-84e2-3aecef497863",
        images: [
            "https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Flong-jump-pit-cover-ljc100-HQ1.jpg?alt=media&token=8a2442bb-9ab4-4ba5-84e2-3aecef497863"
        ]
    },
]
export default productData

// https://firebasestorage.googleapis.com/v0/b/fisher-website-r.appspot.com/o/products%2Fweb-prod-image-coming-soon.jpg?alt=media&token=b54177c2-2a98-46a0-8446-23ea7b30f18c